import generalObjDataLayerKeysBuilder from '@/modern/utils/objDataLayerBuilder'

const useObjDataLayer = () => {
  const dispatchObjDataLayer = (eventLabel = 'customPageView') => {
    document.dispatchEvent(
      new CustomEvent(eventLabel, { detail: objDataLayer })
    )
    console.log('Evento disparado:', eventLabel)
    console.log('Objeto disparado', objDataLayer)
  }

  const buildObjDataLayer = () => {
    objDataLayer = generalObjDataLayerKeysBuilder()
  }

  return [dispatchObjDataLayer, buildObjDataLayer]
}

export default useObjDataLayer
