import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 3H6C4.35 3 3 4.35 3 6V18C3 19.65 4.35 21 6 21H18C19.65 21 21 19.65 21 18V6C21 4.35 19.65 3 18 3ZM18 5C18.542 5 19 5.458 19 6V18C19 18.542 18.542 19 18 19H6C5.458 19 5 18.542 5 18V6C5 5.458 5.458 5 6 5H18Z"
      fill="#2E2D37"
    />
  </svg>
)
