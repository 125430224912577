import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0034 14C19.0034 15.103 18.1064 16 17.0034 16H7.00037C6.73437 16 6.48037 16.105 6.29337 16.293L5.00037 17.586V8C5.00037 6.897 5.89737 6 7.00037 6H17.0034C18.1064 6 19.0034 6.897 19.0034 8V14ZM17.0034 4H7.00037C4.79437 4 3.00037 5.794 3.00037 8V20C3.00037 20.404 3.24337 20.769 3.61737 20.924C3.74137 20.975 3.87137 21 4.00037 21C4.26037 21 4.51537 20.898 4.70737 20.707L7.41437 18H17.0034C19.2094 18 21.0034 16.206 21.0034 14V8C21.0034 5.794 19.2094 4 17.0034 4ZM16 8H7.99997C7.44797 8 6.99997 8.448 6.99997 9C6.99997 9.552 7.44797 10 7.99997 10H16C16.552 10 17 9.552 17 9C17 8.448 16.552 8 16 8ZM14 12H7.99997C7.44797 12 6.99997 12.448 6.99997 13C6.99997 13.552 7.44797 14 7.99997 14H14C14.552 14 15 13.552 15 13C15 12.448 14.552 12 14 12Z"
      fill="#2E2D37"
    />
  </svg>
)
