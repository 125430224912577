import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9993 8.91348L16.2923 12.2065C16.6833 12.5975 17.3153 12.5975 17.7063 12.2065C18.0973 11.8155 18.0973 11.1835 17.7063 10.7925L12.7073 5.79248C12.6143 5.70048 12.5033 5.62748 12.3813 5.57648C12.1373 5.47548 11.8613 5.47548 11.6173 5.57648C11.4953 5.62748 11.3843 5.70048 11.2913 5.79248L6.29226 10.7925C6.09726 10.9875 5.99926 11.2435 5.99926 11.4995C5.99926 11.7555 6.09726 12.0115 6.29226 12.2065C6.68326 12.5975 7.31526 12.5975 7.70626 12.2065L10.9993 8.91348V17.4995C10.9993 18.0515 11.4473 18.4995 11.9993 18.4995C12.5513 18.4995 12.9993 18.0515 12.9993 17.4995V8.91348Z"
      fill="#2E2D37"
    />
  </svg>
)
