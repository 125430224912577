import React, { useState, useEffect, useMemo, useCallback } from 'react'

import {
  getUserTokenAuth,
  getLoggedUserProperties,
  onClientSide,
  logOut as resetToken
} from 'utils/utils'
import useAuth from '@/modern/actions/useAuth'

import { config } from 'config/config'
import {
  getNavbarLinks,
  linkBuilder,
  Navbar,
} from '@webmotors/buyer-design-system'
import useFavorites from '@/modern/actions/useFavorites'

import { set } from 'lodash'

import './Header.scss'
import useObjDataLayer from '@/modern/hooks/useObjDataLayer'

const Header = ({ fixedHeader }) => {
    const [user, setState] = useState(null)
    const { favoritesCounter } = useFavorites()
    const { logout } = useAuth()
    const [dispatchObjDataLayer, buildObjDataLayer] = useObjDataLayer()

    function handleMenuLogOut() {
      resetToken()
      logout()
      setState(null)
    }

    function getCookieUser() {
      let _userData = getLoggedUserProperties()
      const _cookieToken = getUserTokenAuth()
      if (_userData && _cookieToken) {
        setState({name: _userData.name})
      }
    }

    const handleMenuServicesInsurance = useCallback(() => {
      buildObjDataLayer()
      dispatchObjDataLayer('autoComparaMenuServicos')
    }, [buildObjDataLayer, dispatchObjDataLayer])

    const navbarLinks = useMemo(() => {
      return linkBuilder(getNavbarLinks)
        .user(user)
        .url(`${config.urlApp}`)
        .edit('header_logIn_logOut', (link) => {
          set(link, 'props.onClick', handleMenuLogOut)

          return link
        })
        .edit('header_btn_favorite', (link) => {
          return {
            ...link,
            badge: favoritesCounter || undefined
          }
        })
        .edit('header_services_insurance', (link) => {
          set(link, 'props.onClick', handleMenuServicesInsurance)
          return link
        })
        .build()
    }, [user, handleMenuLogOut, favoritesCounter, handleMenuServicesInsurance])

  const updateNavbarLinks = navbarLinks.links.map((item) => {
    if (item.label === "Entrar") {
      return {
        ...item,
        links: item.links.map((subItem) => {
          if (subItem.label === 'Login') {
            return {
              ...subItem,
              href: subItem.href + '&r=' + (onClientSide() ? encodeURIComponent(window.location) : ''),
            };
          }
          return subItem;
        }),
      };
    }
    return item;
  });

  const newNavBarLinks = {...navbarLinks, links: updateNavbarLinks}

    useEffect(() => {
      getCookieUser()
    }, [])


    return (
      <header className={`Header${fixedHeader ? 'fixo' : ''}`}>
        <Navbar {...newNavBarLinks} />
      </header>
    )
}
export default Header

