import styled from 'styled-components'

export const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #2e2d37;
`

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
`

export const Text = styled.p`
  font-size: 12px;
  line-height: 150%;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`

export const SwitchArea = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
  max-height: 245px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-right: 7px white solid;
  }
`

export const Strong = styled.strong``

export const Link = styled.a`
  font-weight: bold;
  color: #f3123c;
`

export const IconButton = styled.button`
  cursor: pointer;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
`

export const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`
