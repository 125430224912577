import slugify from 'slugify'
import { config } from 'config/config'
import {
  VEHICLE_TYPE,
  AD_TYPE,
  SELLER_TYPE,
  COOKIES,
  SYSTEM,
  ORIGIN_PAGES,
  TEXT,
  DISPLAY_CARD,
  FAIR,
  zeroKmModels,
} from 'utils/constants'
import { getEncryptedCookie, setEncryptedCookie } from '@/modern/utils/cookies'
import {
  getMegaFairId,
  getBlackFairId,
  isAdInFair,
  isBlackFairActive,
  isMegaFairActive,
} from '@/modern/utils/fairs'
import get from 'lodash/get'
import { STATES } from './constants'

export function onClientSide() {
  return (
    typeof window !== 'undefined' &&
    window.document &&
    typeof document !== 'undefined'
  )
}
export function getApiUrl() {
  return onClientSide() ? config.urlApi : config.urlApiServer
}

export function getApiUrlSantander() {
  return onClientSide() ? config.urlApiSantander : config.urlApiSantanderServer
}

export function formatNumber(value) {
  if (value && value !== 0 && value !== '0') {
    return value
      .toString()
      .replace(/([.,]\d+)$/g, '')
      .replace(/([^0-9])/g, '')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&.')
  }
  return '---'
}
export function removeAccents(str) {
  const accentsIn =
    'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøĎďDŽdžÈÉÊËèéêëðÇçČčÐÌÍÎÏìíîïÙÚÛÜùúûüĽĹľĺÑŇňñŔŕŠšŤťŸÝÿýŽž'
  const accentsOut =
    'AAAAAAaaaaaasOOOOOOOooooooDdDZdzEEEEeeeeeCcCcDIIIIiiiiUUUUuuuuLLllNNnnRrSsTtYYyyZz'
  const strArr = str.split('')
  strArr.forEach((letter, index) => {
    const i = accentsIn.indexOf(letter)
    if (i !== -1) {
      strArr[index] = accentsOut[i]
    }
  })
  return strArr.join('')
}
export function floatToCurrency(value, precision = 2) {
  if (value && typeof value === 'number') {
    return value
      .toFixed(precision)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  return '0'
}
export function currencyToFloat(value) {
  if (value && typeof value === 'string')
    return parseFloat(value.replace(/[.]/g, '').replace(',', '.'))
  return false
}
export function cleanText(text) {
  return removeAccents(
    text
      .toLowerCase()
      .replace(/[.\s]+/g, '-')
      .replace(/-&-/g, '-')
  )
}
export function validateCPF(cpf) {
  const cleanCpf = cpf.replace(/[^\d]+/g, '')
  if (cleanCpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cleanCpf.length !== 11 ||
    cleanCpf === '00000000000' ||
    cleanCpf === '11111111111' ||
    cleanCpf === '22222222222' ||
    cleanCpf === '33333333333' ||
    cleanCpf === '44444444444' ||
    cleanCpf === '55555555555' ||
    cleanCpf === '66666666666' ||
    cleanCpf === '77777777777' ||
    cleanCpf === '88888888888' ||
    cleanCpf === '99999999999'
  )
    return false

  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i++) add += parseInt(cleanCpf.charAt(i), 10) * (10 - i)

  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cleanCpf.charAt(9), 10)) return false

  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i++)
    add += parseInt(cleanCpf.charAt(i), 10) * (11 - i)

  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cleanCpf.charAt(10), 10)) return false

  return true
}
export function checkCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf == '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false
  // Valida 1o digito
  let add = 0
  for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev == 10 || rev == 11) rev = 0
  if (rev != parseInt(cpf.charAt(9))) return false
  // Valida 2o digito
  add = 0
  for (var i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev == 10 || rev == 11) rev = 0
  if (rev != parseInt(cpf.charAt(10))) return false
  return true
}

export function phoneValidation(telefone) {
  // retira todos os caracteres menos os numeros
  const novoTelefone = telefone.replace(/\D/g, '')

  // verifica se tem a qtde de numero correto
  if (!(novoTelefone.length >= 10 && novoTelefone.length <= 11)) return false;

  // Se tiver 11 caracteres, verificar se começa com 9 o celular
  if (
    novoTelefone.length === 11 &&
    parseInt(novoTelefone.substring(2, 3), 10) !== 9
  )
    return false

  // Verifica caso o telefone seja fixo se ele começa com
  // um número que não seja nem 0 e nem 1
  if(
    novoTelefone.length === 10 &&
    parseInt(novoTelefone.substring(2, 3), 10) < 2
  )
  return false

  // verifica se não é nenhum numero digitado errado (propositalmente)
  for (let n = 0; n < 10; n++) {
    // um for de 0 a 9.
    // estou utilizando o metodo Array(q+1).join(n) onde "q" é a quantidade e n é o
    // caractere a ser repetido
    if (
      novoTelefone === new Array(11).join(n) ||
      novoTelefone === new Array(12).join(n)
    )
      return false
  }
  // DDDs validos
  const codigosDDD = [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
    37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
    65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  ]
  // verifica se o DDD é valido (sim, da pra verificar rsrsrs)
  if (codigosDDD.indexOf(parseInt(novoTelefone.substring(0, 2), 10)) === -1)
    return false

  // E por ultimo verificar se o numero é realmente válido. Até 2016 um celular pode
  // ter 8 caracteres, após isso somente numeros de telefone e radios (ex. Nextel)
  // vão poder ter numeros de 8 digitos (fora o DDD), então esta função ficará inativa
  // até o fim de 2016, e se a ANATEL realmente cumprir o combinado, os numeros serão
  // validados corretamente após esse período.
  // NÃO ADICIONEI A VALIDAÇÂO DE QUAIS ESTADOS TEM NONO DIGITO, PQ DEPOIS DE 2016 ISSO NÃO FARÁ DIFERENÇA
  // Não se preocupe, o código irá ativar e desativar esta opção automaticamente.
  // Caso queira, em 2017, é só tirar o if.

  if (new Date().getFullYear() < 2017) return true
  if (
    telefone.length === 10 &&
    [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3), 10)) === -1
  )
    return false

  // se passar por todas as validações acima, então está tudo certo
  return true
}

export function cellPhoneValidation(phoneNumber) {
  if (!phoneValidation(phoneNumber)) {
    return false;
  }

  const cleanNumber = phoneNumber.replace(/\D/g, '');
  const checkDigitNine = parseInt(cleanNumber.substring(2, 3), 10) !== 9;
  if (cleanNumber.length !== 11 || checkDigitNine) {
    return false;
  }
  return true;
};

export function phoneMask(phone) {
  const cleanPhone = phone
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(/\s+/g, '')
  if (cleanPhone.length === 10) {
    return `(${cleanPhone.substring(0, 2)}) ${cleanPhone.substring(
      2,
      6
    )}-${cleanPhone.substring(6, 10)}`
  }
  return phone
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function convertToDate(day, month, year) {
  let date = new Date(`${year}-${month}-${day}`)

  if (Number.isNaN(date)) date = new Date('1900-01-01T00:00:00')

  return new Date(date.getTime() - date.getTimezoneOffset() * -60000)
}

export function setFormatDate(data) {
  if (data !== '0001-01-01T00:00:00' && data !== '1900-01-01T00:00:00') {
    const dt = new Date(data)
    const day = dt.getDate()
    const month = dt.getMonth() + 1
    const dateFormat = `${day < 10 ? `0${day}` : day}/${
      month < 10 ? `0${month}` : month
    }/${dt.getFullYear()}`
    return dateFormat
  }
  return undefined
}
export function setFormatDateMonthYear(data) {
  if (data !== '0001-01-01T00:00:00' && data !== '1900-01-01T00:00:00') {
    const dt = new Date(data)
    const day = dt.getDate()
    const month = dt.getMonth() + 1
    const dateFormat = `${day < 10 ? `0${day}` : day}/${
      month < 10 ? `0${month}` : month
    }/${dt.getFullYear()}`
    return dateFormat.substr(3)
  }
  return undefined
}
export function difBetweenDates(to, from) {
  let months =
    to.getMonth() -
    from.getMonth() +
    12 * (to.getFullYear() - from.getFullYear())

  if (to.getDate() < from.getDate()) {
    months--
  }

  return months
}
export function validateCurrentDate(currentDate) {
  if (currentDate <= new Date()) return createUrlIdc
  return false
}
export function cleanString(_str, _opt) {
  if (_str) {
    _str = _str.toString().trim()
    if (typeof _opt === 'object') {
      if (_opt.toLowerCase) {
        _str = _str.toLowerCase()
      }
      if (_opt.removeAccents || _opt.wordOnly) {
        _str = removeAccents(_str)
      }
      if (_opt.removeDots) {
        _str = _str.replace(/\./g, '')
      }
      if (_opt.removeSpecial) {
        _str = _str
          .replace(/[\.\/\\\_\s\-]/g, '-')
          .replace(/(\-$)/, '')
          .replace(/[^\wÀ-ÿ\d\-\&]/, '-')
      }
      if (_opt.wordOnly) {
        _str = _str.replace(/\W/g, '')
      }
      if (_opt.slugify) {
        _str = slugify(_str)
      }
      if (_opt.capitalize) {
        _str = _str.charAt(0).toUpperCase() + _str.slice(1)
      }
      if (_opt.capitalizeAll) {
        _str = _str.replace(/[\wÀ-ÿ]\S*/g, _s => {
          return _s.charAt(0).toString().toUpperCase() + _s.substr(1)
        })
      }
    }
    return _str
  }
  return ''
}
export function checkisNumber(val) {
  let isNumber = false
  if (/\d*([.,\/]?\d+)/.test(val)) isNumber = true
  return isNumber
}
export function getCookie(name) {
  if (onClientSide()) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length == 2) {
      return decodeURIComponent(parts.pop().split(';').shift())
    }
    return undefined
  }
}
export function useCookie(tokenNumber, name) {
  if (onClientSide()) {
    if (tokenNumber && parseInt(tokenNumber) > 0) {
      let token = ''
      for (let _i = 0; _i < parseInt(tokenNumber); _i++) {
        token += getCookie(`${name}_${_i}`)
      }
      return token
    }

    return ''
  }
}

export function setCookie(
  name,
  value,
  days,
  mins,
  withoutEncode = false,
  domain = ''
) {
  if (onClientSide()) {
    let expires
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toGMTString()}`
    } else if (mins) {
      const date = new Date()
      date.setTime(date.getTime() + mins * 60 * 1000)
      expires = `; expires=${date.toGMTString()}`
    } else {
      expires = ''
    }
    document.cookie = `${name}=${
      withoutEncode ? value : encodeURIComponent(value)
    }${expires}; path=/;domain=${domain}`
  }
}

export function clearCookie(name) {
  if (onClientSide()) {
    setCookie(name, '', -10)
  }
}

export function onEnterFormSubmit(e, callback) {
  if (e.charCode === 13 || e.keyCode === 13) {
    callback()
  }
}
export function createCookieBig(cname, cvalue, exdays) {
  if (onClientSide()) {
    // Remove o cookie para não ler mais o mesmo
    const _cookieNumber = getCookie(cname)
    if (_cookieNumber) {
      for (var _i = 0; _i < parseInt(_cookieNumber); _i++) {
        setCookie(`${cname}_${_i}`, '', -50)
      }
      setCookie(cname, '0', -50)
    }

    let _jsonData = JSON.parse(
      JSON.stringify({
        identification: cvalue.service.identification,
        simulation: cvalue.service.simulation,
        vehicle: cvalue.service.vehicle,
        customer: cvalue.service.customer,
      })
    )

    if (_jsonData?.customer) {
      _jsonData.customer.name = window.btoa(_jsonData?.customer?.name)
      _jsonData.customer.email = window.btoa(_jsonData?.customer?.email)
      _jsonData.customer.celPhone = window.btoa(_jsonData?.customer?.celPhone)
      _jsonData.customer.birthday = window.btoa(_jsonData?.customer?.birthday)
      _jsonData.customer.cpf = window.btoa(_jsonData?.customer?.cpf)
    }

    if (_jsonData.identification?.request) {
      _jsonData.identification.request.Email = window.btoa(
        _jsonData.identification.request.Email
      )
      _jsonData.identification.request.customer.dateOfBirthFoundation =
        window.btoa(
          _jsonData.identification.request.customer.dateOfBirthFoundation
        )
      _jsonData.identification.request.customer.document = window.btoa(
        _jsonData.identification.request.customer.document
      )

      // //delete para diminuir tamanho do cookie
      delete _jsonData.identification.response?.atxResponse.insurances
      delete _jsonData.identification.request?.customer.newsletter
      delete _jsonData.identification.request?.customer.email
      delete _jsonData.identification.request?.customer.cellphone
    }

    _jsonData = JSON.stringify(_jsonData)

    if (cname === 'wm_enquire_loan') {
      _jsonData = _jsonData
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[();]/g, '')
    }

    const _d = new Date()
    _d.setTime(_d.getTime() + exdays * 24 * 60 * 60 * 1000)
    const _expires = `expires=${_d.toUTCString()}`
    const _domain =
      window.location.origin.indexOf('localhost') > 0
        ? 'localhost'
        : 'webmotors.com.br'
    if (_jsonData.length > 370) {
      const _splited = _jsonData.match(/.{1,370}/g)
      setCookie(cname, _splited.length, _expires, undefined, true, _domain)
      for (var _i = 0; _i < _splited.length; _i++) {
        setCookie(
          `${cname}_${_i}`,
          _splited[_i],
          _expires,
          undefined,
          true,
          _domain
        )
      }
    } else setCookie(cname, _jsonData, _expires, undefined, true, _domain)
  }
}
export function handleFormCookie(form, cookieCanBeUsed) {
  if (!cookieCanBeUsed) return

  const cookieData = getEncryptedCookie(COOKIES.FORM_DATA) || {}
  const newCookieData = {
    ...cookieData,
    Principal: form,
  }

  setEncryptedCookie(COOKIES.FORM_DATA, newCookieData)
}
export function handleLeadCookie(uniqueId, cookieCanBeUsed) {
  let leadCoookie = cookieCanBeUsed && getCookie(COOKIES.SENT_LEADS)
  if (!leadCoookie) {
    if (cookieCanBeUsed) setCookie(COOKIES.SENT_LEADS, uniqueId)
    leadCoookie = uniqueId
  } else {
    let cookieArr = leadCoookie.split(',')
    if (cookieArr.indexOf(`${uniqueId}`) === -1) {
      if (cookieArr.length === 10) {
        cookieArr = cookieArr.slice(1)
      }
      cookieArr.push(uniqueId)
      leadCoookie = cookieArr.join(',')
      if (cookieCanBeUsed) setCookie(COOKIES.SENT_LEADS, leadCoookie)
    }
  }
}
export function renderDateString(date) {
  date = parseInt(date, 10)
  date = new Date(date)
  const months = {
    0: 'Jan',
    1: 'Fev',
    2: 'Mar',
    3: 'Abr',
    4: 'Mai',
    5: 'Jun',
    6: 'Jul',
    7: 'Ago',
    8: 'Set',
    9: 'Out',
    10: 'Nov',
    11: 'Dez',
  }
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
}
export function handleScrollTo(element, direction) {
  !direction && (direction = 'start')
  element.scrollIntoView({ block: direction, behavior: 'smooth' })
}
let scrollTimer
export function handleSmoothScroll(to, container, interval) {
  if (scrollTimer) {
    clearInterval(scrollTimer)
  }
  if (to && onClientSide()) {
    container = !container ? window : container
    const currentPosition = container.pageYOffset || 0
    const { body } = document
    const html = document.documentElement
    let targetOffset
    let targetHeight
    if (typeof to === 'object') {
      targetOffset = to.getBoundingClientRect().top + currentPosition || 0
      targetHeight = to.getBoundingClientRect().height || 0
    } else if (typeof to === 'number' && typeof container !== 'undefined') {
      targetOffset = to
      targetHeight = 0
    }
    const windowHeight = container.innerHeight
    const difference = targetOffset - currentPosition
    const targetY = difference
    if (targetOffset) {
      const startTime = Date.now()
      let percentage = 0
      function step() {
        let yScroll
        const elapsed = Date.now() - startTime
        const settings = {
          duration: 500,
          easing: {
            easeInOutQuad(t, b, c, d) {
              t /= d / 2
              if (t < 1) return (c / 2) * t * t + b
              t--
              return (-c / 2) * (t * (t - 2) - 1) + b
            },
          },
        }
        if (elapsed > settings.duration) {
          clearTimeout(scrollTimer)
          container.scrollTo(0, targetOffset)
        }
        percentage = elapsed / settings.duration
        if (percentage > 1) {
          clearTimeout(scrollTimer)
          container.scrollTo(0, targetOffset)
        } else {
          yScroll = settings.easing.easeInOutQuad(
            elapsed,
            currentPosition,
            targetY,
            settings.duration
          )
          container.scrollTo(0, yScroll)
          scrollTimer = setTimeout(step, interval || 10)
        }
      }
      scrollTimer = setTimeout(step, interval || 10)
    }
  }
}
let __firstSatellite = true
export function submitAnalytics(pageName, parameter, obj) {
  if (onClientSide()) {
    if (pageName.substring(0, 1) == '/') {
      pageName = pageName.substring(1)
    }
    pageName = `/webmotors/catalogo/${pageName}`
    if (typeof dataLayer !== 'undefined') {
      dataLayer[0].pageName = pageName
    }
    !parameter && (parameter = 'customPageView')
    if (typeof objDataLayer !== 'undefined') {
      // objDataLayer should be declared as global var
      if (obj && typeof obj === 'object') {
        obj.usrID && (obj.usrID = generateMD5Hash(obj.usrID))
        objDataLayer = obj
      } else {
        objDataLayer = {}
      }
    }
    if (!(parameter == 'customPageView' && __firstSatellite == true)) {
      if (typeof _satellite !== 'undefined') {
        _satellite.track(parameter)
      }
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({ event: parameter })
      }
    }
  }
  __firstSatellite = false
}
export function submitSatellite(parameter) {
  if (
    parameter &&
    typeof parameter === 'string' &&
    typeof _satellite !== 'undefined' &&
    onClientSide()
  ) {
    _satellite.track(parameter)
  }
}
export function parseArgs(value, minLimit, maxLimit) {
  const optionals = []
  let fuel
  let gear
  let min
  let max
  if (value) {
    let args
    args = value.split('+')
    const regex = new RegExp(/(de\-(\d+|qualquer)\-a\-(\d+|qualquer))/)
    for (let i = 0; i < args.length; i++) {
      if (regex.test(args[i])) {
        args[i].replace(regex, function () {
          if (arguments[2] != 'qualquer' || !arguments[2]) {
            min = parseInt(arguments[2])
          } else {
            min = minLimit
          }
          if (
            arguments[3] == 'qualquer' ||
            !arguments[3].trim() ||
            parseInt(arguments[3]) >= maxLimit
          ) {
            max = maxLimit
          } else {
            max = parseInt(arguments[3])
          }
        })
      } else if (args[i].indexOf('combustivel-') == 0) {
        fuel = args[i].replace('combustivel-', '')
      } else if (args[i].indexOf('cambio-') == 0) {
        gear = args[i].replace('cambio-', '')
      } else {
        optionals.push(args[i])
      }
    }
  }
  return { optionals, min, max, fuel, gear }
}
export function sortArrayAlphabeticallyByObjKey(array, key) {
  if (
    typeof array === 'object' &&
    array.constructor.name == 'Array' &&
    array[0] &&
    array[0][key]
  ) {
    return array.sort((a, b) => {
      const x =
        typeof a[key] === 'string'
          ? removeAccents(a[key].toLowerCase().trim())
          : a[key]
      const y =
        typeof b[key] === 'string'
          ? removeAccents(b[key].toLowerCase().trim())
          : b[key]
      if (x > y) return 1
      if (x < y) return -1
      return 0
    })
  }
  return array
}
export function sortArrayAlphabeticallyDescendingByObjKey(array, key) {
  if (
    typeof array === 'object' &&
    array.constructor.name === 'Array' &&
    array[0] &&
    array[0][key]
  ) {
    return array.sort((a, b) => {
      const x =
        typeof a[key] === 'string'
          ? removeAccents(a[key].toLowerCase().trim())
          : a[key];
      const y =
        typeof b[key] === 'string'
          ? removeAccents(b[key].toLowerCase().trim())
          : b[key];
      if (x > y) return -1;
      if (x < y) return 1;
      return 0;
    });
  }
  return array;
}

export function reorderFilter(originalArray = [], orderArray = []) {
  if (!Array.isArray(originalArray)) {
    return originalArray
  }
  const slugifyString = name => {
    return slugify(name, { lower: true, trim: true, replacement: '' })
  }
  const elementIndices = new Map(
    originalArray.map((obj, index) => [slugifyString(obj.name), index])
  )

  return orderArray
    .map(name => elementIndices.get(slugifyString(name)))
    .filter(index => index !== undefined)
    .map(index => originalArray[index])
    .concat(
      originalArray.filter(obj => !orderArray.includes(slugifyString(obj.name)))
    )
}

export function sortObject(object, value) {
  // sort object by value
  if (
    value &&
    typeof value === 'string' &&
    object &&
    typeof object === 'object'
  ) {
    const sortable = []
    const newObj = {}
    Object.keys(object).forEach(function (key) {
      object[key].objId = key
      if (object[key].Id) delete object[key].Id
      sortable.push(object[key])
    })
    sortable.sort(function (a, b) {
      return a[value] - b[value]
    })
    for (let i = 0; i < sortable.length; i++) {
      newObj[sortable[i].objId] = sortable[i]
      delete newObj[sortable[i].objId].objId
    }
    return newObj
  }
  return {}
}
export function generateMD5Hash(value) {
  const MD5 = function (s) {
    function L(k, d) {
      return (k << d) | (k >>> (32 - d))
    }
    function K(G, k) {
      let I
      let d
      let F
      let H
      let x
      F = G & 2147483648
      H = k & 2147483648
      I = G & 1073741824
      d = k & 1073741824
      x = (G & 1073741823) + (k & 1073741823)
      if (I & d) {
        return x ^ 2147483648 ^ F ^ H
      }
      if (I | d) {
        if (x & 1073741824) {
          return x ^ 3221225472 ^ F ^ H
        }
        return x ^ 1073741824 ^ F ^ H
      }
      return x ^ F ^ H
    }
    function r(d, F, k) {
      return (d & F) | (~d & k)
    }
    function q(d, F, k) {
      return (d & k) | (F & ~k)
    }
    function p(d, F, k) {
      return d ^ F ^ k
    }
    function n(d, F, k) {
      return F ^ (d | ~k)
    }
    function u(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(r(F, aa, Z), k), I))
      return K(L(G, H), F)
    }
    function f(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(q(F, aa, Z), k), I))
      return K(L(G, H), F)
    }
    function D(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(p(F, aa, Z), k), I))
      return K(L(G, H), F)
    }
    function t(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(n(F, aa, Z), k), I))
      return K(L(G, H), F)
    }
    function e(G) {
      let Z
      const F = G.length
      const x = F + 8
      const k = (x - (x % 64)) / 64
      const I = (k + 1) * 16
      const aa = Array(I - 1)
      let d = 0
      let H = 0
      while (H < F) {
        Z = (H - (H % 4)) / 4
        d = (H % 4) * 8
        aa[Z] = aa[Z] | (G.charCodeAt(H) << d)
        H++
      }
      Z = (H - (H % 4)) / 4
      d = (H % 4) * 8
      aa[Z] = aa[Z] | (128 << d)
      aa[I - 2] = F << 3
      aa[I - 1] = F >>> 29
      return aa
    }
    function B(x) {
      let k = ''
      let F = ''
      let G
      let d
      for (d = 0; d <= 3; d++) {
        G = (x >>> (d * 8)) & 255
        F = `0${G.toString(16)}`
        k += F.substr(F.length - 2, 2)
      }
      return k
    }
    function J(k) {
      k = k.replace(/rn/g, 'n')
      let d = ''
      for (let F = 0; F < k.length; F++) {
        const x = k.charCodeAt(F)
        if (x < 128) {
          d += String.fromCharCode(x)
        } else if (x > 127 && x < 2048) {
          d += String.fromCharCode((x >> 6) | 192)
          d += String.fromCharCode((x & 63) | 128)
        } else {
          d += String.fromCharCode((x >> 12) | 224)
          d += String.fromCharCode(((x >> 6) & 63) | 128)
          d += String.fromCharCode((x & 63) | 128)
        }
      }
      return d
    }
    let C = Array()
    let P
    let h
    let E
    let v
    let g
    let Y
    let X
    let W
    let V
    const S = 7
    const Q = 12
    const N = 17
    const M = 22
    const A = 5
    const z = 9
    const y = 14
    const w = 20
    const o = 4
    const m = 11
    const l = 16
    const j = 23
    const U = 6
    const T = 10
    const R = 15
    const O = 21
    s = J(s)
    C = e(s)
    Y = 1732584193
    X = 4023233417
    W = 2562383102
    V = 271733878
    for (P = 0; P < C.length; P += 16) {
      h = Y
      E = X
      v = W
      g = V
      Y = u(Y, X, W, V, C[P + 0], S, 3614090360)
      V = u(V, Y, X, W, C[P + 1], Q, 3905402710)
      W = u(W, V, Y, X, C[P + 2], N, 606105819)
      X = u(X, W, V, Y, C[P + 3], M, 3250441966)
      Y = u(Y, X, W, V, C[P + 4], S, 4118548399)
      V = u(V, Y, X, W, C[P + 5], Q, 1200080426)
      W = u(W, V, Y, X, C[P + 6], N, 2821735955)
      X = u(X, W, V, Y, C[P + 7], M, 4249261313)
      Y = u(Y, X, W, V, C[P + 8], S, 1770035416)
      V = u(V, Y, X, W, C[P + 9], Q, 2336552879)
      W = u(W, V, Y, X, C[P + 10], N, 4294925233)
      X = u(X, W, V, Y, C[P + 11], M, 2304563134)
      Y = u(Y, X, W, V, C[P + 12], S, 1804603682)
      V = u(V, Y, X, W, C[P + 13], Q, 4254626195)
      W = u(W, V, Y, X, C[P + 14], N, 2792965006)
      X = u(X, W, V, Y, C[P + 15], M, 1236535329)
      Y = f(Y, X, W, V, C[P + 1], A, 4129170786)
      V = f(V, Y, X, W, C[P + 6], z, 3225465664)
      W = f(W, V, Y, X, C[P + 11], y, 643717713)
      X = f(X, W, V, Y, C[P + 0], w, 3921069994)
      Y = f(Y, X, W, V, C[P + 5], A, 3593408605)
      V = f(V, Y, X, W, C[P + 10], z, 38016083)
      W = f(W, V, Y, X, C[P + 15], y, 3634488961)
      X = f(X, W, V, Y, C[P + 4], w, 3889429448)
      Y = f(Y, X, W, V, C[P + 9], A, 568446438)
      V = f(V, Y, X, W, C[P + 14], z, 3275163606)
      W = f(W, V, Y, X, C[P + 3], y, 4107603335)
      X = f(X, W, V, Y, C[P + 8], w, 1163531501)
      Y = f(Y, X, W, V, C[P + 13], A, 2850285829)
      V = f(V, Y, X, W, C[P + 2], z, 4243563512)
      W = f(W, V, Y, X, C[P + 7], y, 1735328473)
      X = f(X, W, V, Y, C[P + 12], w, 2368359562)
      Y = D(Y, X, W, V, C[P + 5], o, 4294588738)
      V = D(V, Y, X, W, C[P + 8], m, 2272392833)
      W = D(W, V, Y, X, C[P + 11], l, 1839030562)
      X = D(X, W, V, Y, C[P + 14], j, 4259657740)
      Y = D(Y, X, W, V, C[P + 1], o, 2763975236)
      V = D(V, Y, X, W, C[P + 4], m, 1272893353)
      W = D(W, V, Y, X, C[P + 7], l, 4139469664)
      X = D(X, W, V, Y, C[P + 10], j, 3200236656)
      Y = D(Y, X, W, V, C[P + 13], o, 681279174)
      V = D(V, Y, X, W, C[P + 0], m, 3936430074)
      W = D(W, V, Y, X, C[P + 3], l, 3572445317)
      X = D(X, W, V, Y, C[P + 6], j, 76029189)
      Y = D(Y, X, W, V, C[P + 9], o, 3654602809)
      V = D(V, Y, X, W, C[P + 12], m, 3873151461)
      W = D(W, V, Y, X, C[P + 15], l, 530742520)
      X = D(X, W, V, Y, C[P + 2], j, 3299628645)
      Y = t(Y, X, W, V, C[P + 0], U, 4096336452)
      V = t(V, Y, X, W, C[P + 7], T, 1126891415)
      W = t(W, V, Y, X, C[P + 14], R, 2878612391)
      X = t(X, W, V, Y, C[P + 5], O, 4237533241)
      Y = t(Y, X, W, V, C[P + 12], U, 1700485571)
      V = t(V, Y, X, W, C[P + 3], T, 2399980690)
      W = t(W, V, Y, X, C[P + 10], R, 4293915773)
      X = t(X, W, V, Y, C[P + 1], O, 2240044497)
      Y = t(Y, X, W, V, C[P + 8], U, 1873313359)
      V = t(V, Y, X, W, C[P + 15], T, 4264355552)
      W = t(W, V, Y, X, C[P + 6], R, 2734768916)
      X = t(X, W, V, Y, C[P + 13], O, 1309151649)
      Y = t(Y, X, W, V, C[P + 4], U, 4149444226)
      V = t(V, Y, X, W, C[P + 11], T, 3174756917)
      W = t(W, V, Y, X, C[P + 2], R, 718787259)
      X = t(X, W, V, Y, C[P + 9], O, 3951481745)
      Y = K(Y, h)
      X = K(X, E)
      W = K(W, v)
      V = K(V, g)
    }
    const i = B(Y) + B(X) + B(W) + B(V)
    return i.toLowerCase()
  }
  return MD5(value)
}
export function logOut() {
  var domainList = [
    `domain=.${document.location.hostname}`,
    `domain=.webmotors.com.br`,
  ]

  if (onClientSide()) {
    for (let d = 0; d < domainList.length; d++) {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        if (
          cookies[i].split('=')[0].includes('token') ||
          cookies[i].split('=')[0].includes('authCookie') ||
          cookies[i].split('=')[0].includes('webmotors.user.properties') ||
          cookies[i].split('=')[0].includes('webmotors.jwt.token.hk') ||
          cookies[i].split('=')[0].includes('webmotors.jwt.token')
        ) {
          document.cookie = `${cookies[i]
            .split('=')[0]
            .trim()}= ; expires=Fri, 31 Dec 1999 23:59:59 GMT; path=/; ${
            domainList[d]
          }`
        }
      }
    }
  }
}
// Metodo que retorna os filtros selecionados segundo a URL do Ryvus
export function parseSearchFilters(url, query, tipoveiculo, category, hotDeal) {
  /**
   * url: estoque
   * query: tipoveiculo=carros&isElegibleVehicleInspection=true
   */

  // interpreta a URL de busca e separa em filtros
  const filters = {}

  tipoveiculo && (filters.tipoveiculo = tipoveiculo)

  if (url && typeof url === 'string') {
    url = url.split('/')
    const parseRegexes = {
      anode: /^de\.(.*)/,
      anoate: /^ate\.(.*)/,
      portas: /^portas\.(.*)/,
      cor: /^cor\.(.*)/,
    }

    for (let i = 0; i < url.length; i++) {
      Object.keys(parseRegexes).forEach(function (value) {
        if (parseRegexes[value].test(url[i])) {
          filters[value] = parseRegexes[value].exec(url[i])[1]
          url[i] = ''
        }
      })
    }

    url = url.filter(String)
    url[0] && (filters.estadocidade = url[0])
    url[1] && (filters.marca1 = url[1])
    url[2] && (filters.modelo1 = url[2])
    url[3] && (filters.versao1 = url[3])
  }

  const _paramsValid = [
    'estadocidade',
    'tipoveiculo',
    'marca1',
    'modelo1',
    'versao1',
    'marca2',
    'modelo2',
    'versao2',
    'marca3',
    'ccde',
    'ccate',
    'modelo3',
    'versao3',
    'estado',
    'cidade',
    'media',
    'anunciante',
    'anode',
    'anoate',
    'kmde',
    'kmate',
    'precode',
    'precoate',
    'km',
    'cilindrada',
    'palavrachave',
    'cambio',
    'combustivel',
    'opcionais',
    'cor',
    'atributos',
    'carroceria',
    'finalplaca',
    'blindado',
    'refrigeracao',
    'partida',
    'motor',
    'numeromarcha',
    'tipofreio',
    'alimentacao',
    'corsecundaria',
    'necessidade',
    'portas',
    'idrevendedor',
    'feirao',
    'localizacao',
    'o',
    'vistoriado',
  ]

  const _paramsArray = [
    'anunciante',
    'opcionais',
    'finalplaca',
    'blindado',
    'cor',
    'cambio',
    'carroceria',
    'atributos',
    'refrigeracao',
    'partida',
    'motor',
    'numeromarcha',
    'tipofreio',
    'alimentacao',
    'corsecundaria',
    'necessidade',
    'portas',
    'feirao',
    'combustivel',
  ]

  for (let i = 0; i < _paramsArray.length; i++) {
    if (
      filters[_paramsArray[i]] &&
      typeof filters[_paramsArray[i]] === 'string'
    ) {
      filters[_paramsArray[i]] = [
        cleanString(filters[_paramsArray[i]].toLowerCase(), { capitalize: 1 }),
      ]
    }
  }

  if (query && typeof query === 'string') {
    query = query.replace(/^\?/, '').split('&')

    for (let i = 0; i < query.length; i++) {
      const queryValue = query[i].split('=')

      if (_paramsValid.includes(queryValue[0].toLowerCase())) {
        if (_paramsArray.includes(queryValue[0])) {
          queryValue[1] = decodeURIComponent(queryValue[1]).split('|')
        }
        filters[queryValue[0].toLowerCase()] = queryValue[1]
      }
    }
  }
  if (hotDeal) {
    if (filters.feirao && !filters.feirao.includes(hotDeal)) {
      filters.feirao.unshift(hotDeal)
    } else {
      filters.feirao = [hotDeal]
    }
  }
  if (category) {
    if (filters.necessidade && !filters.necessidade.includes(category)) {
      filters.necessidade.unshift(category)
    } else {
      filters.necessidade = [category]
    }
  }

  filters.blindado != null &&
    filters.blindado.length > 1 &&
    delete filters.blindado

  return filters
}

export function generateSearchURL(filters) {
  // gera URL a partir dos filtros de busca
  if (filters && typeof filters === 'object') {
    const route = []
    route.push(filters.tipoveiculo ? filters.tipoveiculo : 'carros')
    route.push(
      filters.estadocidade
        ? generateLocationLeftHand(filters.estadocidade)
        : 'estoque'
    )
    filters.marca1 &&
      route.push(
        encodeURIComponent(
          cleanString(decodeURIComponent(filters.marca1), {
            toLowerCase: 1,
            removeAccents: 1,
            removeDots: 1,
            slugify: 1,
          })
        )
      )
    filters.modelo1 &&
      route.push(
        encodeURIComponent(
          cleanString(decodeURIComponent(filters.modelo1), {
            toLowerCase: 1,
            removeAccents: 1,
            removeDots: 1,
            slugify: 1,
          })
        )
      )
    filters.versao1 &&
      route.push(
        cleanString(decodeURIComponent(filters.versao1), {
          toLowerCase: 1,
          removeAccents: 1,
          removeDots: 1,
          slugify: 1,
        })
      )
    filters.anode && route.push(`de.${filters.anode}`)
    filters.anoate && route.push(`ate.${filters.anoate}`)

    const query = generateSearchQuery(filters)
    const searchURL = route.join('/') + (query ? `?${query}` : '')
    return searchURL
  }
  return ''
}
export function generateSearchQuery(filters) {
  // gera os parâmetros do query da url
  if (filters && typeof filters === 'object') {
    const blockParams = [
      'p',
      'qt',
      'idcmpint',
      'autocomplete',
      'estado',
      'cidade',
    ] // impedir estes parâmetros de aparecerem na url
    let query = []
    Object.keys(filters).forEach(function (value) {
      let blockThis = false
      if (filters[value] != '') {
        if (blockParams.includes(value)) {
          blockThis = true
        }

        if (value == 'estadocidade' && typeof filters[value] === 'string') {
          filters[value] = generateLocationRightHand(filters[value])
        }

        if (value == 'localizacao') {
          if (
            typeof filters[value] === 'object' &&
            filters[value].latitude &&
            filters[value].longitude &&
            typeof filters[value].raio === 'number' &&
            filters.estadocidade
          ) {
            filters[value] =
              `${filters[value].latitude},${filters[value].longitude}` +
              'x' +
              `${filters[value].raio}km`
          } else {
            blockThis = true
          }
        }

        if (Array.isArray(filters[value])) {
          query.push(`${value}=${encodeURIComponent(filters[value].join('|'))}`)
        } else if (!blockThis) {
          query.push(
            `${value}=${
              value == 'estadocidade' || value == 'localizacao'
                ? filters[value]
                : encodeURIComponent(filters[value])
            }`
          )
        }
      }
    })
    query = query.join('&')
    return query
  }
}
export function generateLocationState(cityState) {
  //   recebe no padrão do filtro (Santa Rosa de Goiás - GO || São Paulo) e
  //   e retorna apenas o estado em sigla (go || sp) ou apenas br
  if (
    cityState &&
    typeof cityState === 'string' &&
    cityState.trim().toLowerCase() != 'estoque'
  ) {
    cityState = cityState.split(' - ')
    cityState = cityState[1] ? cityState[1] : returnStateShort(cityState[0])
    cityState = cityState.toLowerCase()
  }
  return cityState || 'br'
}
export function generateLocationLeftHand(cityState) {
  //   recebe no padrão do filtro (Santa Rosa de Goiás - GO || São Paulo) e
  //   retorna no padrão da ESQUERDA da url (go-santa-rosa-de-goias || sp)
  if (
    cityState &&
    typeof cityState === 'string' &&
    cityState.trim().toLowerCase() != 'brasil'
  ) {
    cityState = cityState.trim()
    if (cityState.includes(' - ')) {
      cityState = cityState.split(' - ')
      cityState[0] = removeAccents(cityState[0].trim().toLowerCase()).replace(
        /\s/g,
        '-'
      )
      cityState[1] = removeAccents(cityState[1].trim().toLowerCase())
      cityState = `${cityState[1]}-${cityState[0]}`
    } else {
      cityState = returnStateShort(cityState).toLowerCase()
    }
    if (cityState) {
      return cityState
    }
  }
  return 'estoque'
}
export function generateLocationRightHand(cityState) {
  //   recebe no padrão do filtro (Santa Rosa de Goiás - GO || São Paulo) e retorna no padrão da DIREITA da url,
  //   com maiusculas preservadas e tudo encodado ( Goi%C3%A1s%20-%20Santa%20Rosa%20de%20Goi%C3%A1s || S%C3%A3o%20Paulo )
  if (
    cityState &&
    typeof cityState === 'string' &&
    cityState.trim().toLowerCase() != 'brasil'
  ) {
    cityState = cityState.trim()
    if (cityState.includes(' - ')) {
      cityState = cityState.split(' - ')
      cityState[0] = cityState[0].trim()
      cityState[1] = returnStateLong(cityState[1]).trim()
      cityState = `${cityState[1]} - ${cityState[0]}`
    }
    if (cityState) {
      return encodeURIComponent(cityState)
    }
  }
  return ''
}
export function splitCityState(cityState, longState) {
  //   recebe no padrão do filtro (Santa Rosa de Goiás - GO || São Paulo) e retorna separado estado e cidade
  if (
    cityState &&
    typeof cityState === 'string' &&
    cityState.trim().toLowerCase() != 'brasil'
  ) {
    cityState = cityState.trim()
    if (cityState.includes(' - ')) {
      cityState = cityState.split(' - ')
      cityState[0] = cityState[0].trim()
      cityState[1] = longState
        ? returnStateLong(cityState[1]).trim()
        : cityState[1].trim()
      return { state: cityState[1], city: cityState[0] }
    }
    if (cityState) {
      return { state: encodeURIComponent(cityState), city: undefined }
    }
  }
  return { state: undefined, city: undefined }
}
export function generateFilterLocationFromCookie(cityState) {
  //   recebe no padrão do cookie (São Paulo - Campinas || Santa Catarina) já sem encoding
  //   e devolve no padrão do filtro (Campinas - SP || Santa Catarina)
  if (
    cityState &&
    typeof cityState === 'string' &&
    cityState.trim().toLowerCase() != 'brasil' &&
    cityState.trim().toLowerCase() != 'estoque'
  ) {
    cityState = cityState.trim()
    if (cityState.includes(' - ')) {
      cityState = cityState.split(' - ')
      cityState[0] = returnStateShort(cityState[0].trim())
      cityState[1] = cityState[1].trim()
      cityState = `${cityState[1]} - ${cityState[0]}`
    }
    if (cityState) {
      return cityState
    }
  }
  return ''
}
export function returnStateShort(state) {
  if (state && typeof state === 'string') {
    const cleanState = removeAccents(state.trim().toLowerCase())
    const estados = {
      acre: 'AC',
      alagoas: 'AL',
      amazonas: 'AM',
      amapa: 'AP',
      bahia: 'BA',
      ceara: 'CE',
      'distrito federal': 'DF',
      'espirito santo': 'ES',
      goias: 'GO',
      maranhao: 'MA',
      'minas gerais': 'MG',
      'mato grosso do sul': 'MS',
      'mato grosso': 'MT',
      para: 'PA',
      paraiba: 'PB',
      pernambuco: 'PE',
      piaui: 'PI',
      parana: 'PR',
      'rio de janeiro': 'RJ',
      'rio grande do norte': 'RN',
      rondonia: 'RO',
      roraima: 'RR',
      'rio grande do sul': 'RS',
      'santa catarina': 'SC',
      'sao paulo': 'SP',
      sergipe: 'SE',
      tocantins: 'TO',
    }
    if (cleanState && estados[cleanState]) {
      return estados[cleanState]
    }
  }
  return state
}
export function returnStateLong(state) {
  if (typeof state === 'string') {
    state = removeAccents(state.trim().toUpperCase())
    const estados = {
      AC: 'Acre',
      AL: 'Alagoas',
      AM: 'Amazonas',
      AP: 'Amapá',
      BA: 'Bahia',
      CE: 'Ceara',
      DF: 'Distrito Federal',
      ES: 'Espírito Santo',
      GO: 'Goiás',
      MA: 'Maranhão',
      MG: 'Minas Gerais',
      MS: 'Mato Grosso do Sul',
      MT: 'Mato Grosso',
      PA: 'Pará',
      PB: 'Paraíba',
      PE: 'Pernambuco',
      PI: 'Piauí',
      PR: 'Paraná',
      RJ: 'Rio de Janeiro',
      RN: 'Rio Grande do Norte',
      RO: 'Rondônia',
      RR: 'Roraima',
      RS: 'Rio Grande do Sul',
      SC: 'Santa Catarina',
      SP: 'São Paulo',
      SE: 'Sergipe',
      TO: 'Tocantins',
    }
    if (state && typeof state === 'string' && estados[state]) {
      return estados[state]
    }
  }
  return state
}
export function handleUFName(cityState) {
  if (cityState && typeof cityState === 'string') {
    const estados = {
      acre: 'AC',
      alagoas: 'AL',
      amazonas: 'AM',
      amapa: 'AP',
      bahia: 'BA',
      ceara: 'CE',
      'distrito federal': 'DF',
      'espirito santo': 'ES',
      goias: 'GO',
      maranhao: 'MA',
      'minas gerais': 'MG',
      'mato grosso do sul': 'MS',
      'mato grosso': 'MT',
      para: 'PA',
      paraiba: 'PB',
      pernambuco: 'PE',
      piaui: 'PI',
      parana: 'PR',
      'rio de janeiro': 'RJ',
      'rio grande do norte': 'RN',
      rondonia: 'RO',
      roraima: 'RR',
      'rio grande do sul': 'RS',
      'santa catarina': 'SC',
      'sao paulo': 'SP',
      sergipe: 'SE',
      tocantins: 'TO',
    }
    cityState = decodeURIComponent(cityState).split('-')
    const uf = estados[removeAccents(cityState[0])]
    if (uf) {
      if (cityState[1]) return `${uf} - ${cityState[1]}`
      return cityState[0]
    }

    return cityState
  }
  return 'brasil'
}

export function getUf(cityState) {
  if (cityState && typeof cityState === 'string') {
    const estados = {
      acre: 'AC',
      alagoas: 'AL',
      amazonas: 'AM',
      amapa: 'AP',
      bahia: 'BA',
      ceara: 'CE',
      'distrito federal': 'DF',
      'espirito santo': 'ES',
      goias: 'GO',
      maranhao: 'MA',
      'minas gerais': 'MG',
      'mato grosso do sul': 'MS',
      'mato grosso': 'MT',
      para: 'PA',
      paraiba: 'PB',
      pernambuco: 'PE',
      piaui: 'PI',
      parana: 'PR',
      'rio de janeiro': 'RJ',
      'rio grande do norte': 'RN',
      rondonia: 'RO',
      roraima: 'RR',
      'rio grande do sul': 'RS',
      'santa catarina': 'SC',
      'sao paulo': 'SP',
      sergipe: 'SE',
      tocantins: 'TO',
    }

    const formattedState = removeAccents(cityState).toLowerCase().trim()

    return estados[formattedState]
  }
}
// Monta URL para o tracking de links externos
export function createURL(url, instName, areaName, linkName, dontUseTag) {
  const _cookieChannel = getCookieChannel()
  const _origem = _cookieChannel || 'webmotors'
  let _url = url
  const _len = _url.length

  if (_url[_url.length - 1] == '/') _url = _url.slice(0, _len - 1)
  if (_url.endsWith('tabela-fipe')) _url += '/'
  const _areaName = areaName ? `:${areaName}` : ''
  const _tag = `inst=${instName}:${_origem}${_areaName}::${linkName}`
  const _concat = _url.indexOf('?') == -1 ? '?' : '&'
  _url = removeAccents(_url + (!dontUseTag ? _concat + _tag : ''))
    .toLowerCase()
    .split(' ')
    .join('-')

  if (_cookieChannel)
    _url = `${_url}${
      _url.indexOf('?') == -1 ? '?' : '&'
    }origem=${_cookieChannel}`
  return _url
}
// Monta URL para o tracking dos links internos
export function createUrlIdc(url, name1, name2, name3) {
  const _cookieChannel = getCookieChannel()
  name1 = name1 || _cookieChannel || 'webmotors'

  const _name1 = name1 && name1.length > 0 ? `:${name1}` : ''
  const _name2 = name2 && name2.length > 0 ? `:${name2}` : ''
  const _name3 = name3 && name3.length > 0 ? name3 : ''
  const _concat = url.indexOf('?') == -1 ? '?' : '&'
  let _url = `${url}${_concat}idcmpint=t1:c17:m07${_name1}${_name2}::${_name3}`

  if (_cookieChannel) _url = `${_url}&origem=${_cookieChannel}`
  return _url
}

export function removeLkidParameter(url) {
  const [baseUrl, queryString] = url.split('?')
  if (!queryString) {
    return url
  }

  const queryParams = queryString.split('&')
  const updatedParams = queryParams.filter(param => {
    const [key, value] = param.split('=')
    return key !== 'lkid'
  })

  const updatedQueryString = updatedParams.join('&')
  return baseUrl + (updatedQueryString ? `?${updatedQueryString}` : '')
}

export function createUrlWithLkId(url, id) {
  if (!url || !id) return url
  if (!url.includes('?')) return `${url}?lkid=${id}`
  const clearUrl = removeLkidParameter(url)
  const urlPart1 = clearUrl.split('?')[0]
  const urlPart2 = clearUrl.split('?')[1]

  let newUrl = `${urlPart1}?lkid=${id}`
  if (urlPart2) newUrl += `&${urlPart2}`
  return newUrl
}
export function setDescription(vehicleType, adsCount) {
  const s = adsCount == 1 ? '' : 's'
  if (vehicleType == VEHICLE_TYPE.car) return `carro${s} encontrado${s}`
  return `moto${s} encontrada${s}`
}
export function getTypeVehicle(vehicleType) {
  if (
    vehicleType == 'motos-novas' ||
    vehicleType == 'motos-usadas' ||
    vehicleType == 'motos'
  )
    return VEHICLE_TYPE.bike
  return VEHICLE_TYPE.car
}
export function createUrlAd(item, vehicleType, extraParam, hotDeal) {
  let _urlFinal
  const _baseUrl = config.urlApp
  let _make = item.Specification.Make.Value
  let _model = item.Specification.Model.Value

  let _version = item.Specification.Version && item.Specification.Version.Value
  const _numberPorts = item.Specification.NumberPorts
    ? item.Specification.NumberPorts
    : ''
  const _yearFabrication =
    item.Specification.YearFabrication == item.Specification.YearModel
      ? parseInt(item.Specification.YearModel)
      : `${parseInt(item.Specification.YearFabrication)}-${parseInt(
          item.Specification.YearModel
        )}`
  const _uniqueId = item.UniqueId
  const _cubicCentimeter = item.Specification.CubicCentimeter
    ? item.Specification.CubicCentimeter
    : undefined
  const _extraParam = extraParam ? `?${extraParam}` : ''
  _make = cleanString(_make, {
    toLowerCase: 1,
    removeDots: 1,
    removeAccents: 1,
    removeSpecial: 1,
    slugify: 1,
  })
  _model = cleanString(_model, {
    toLowerCase: 1,
    removeDots: 1,
    removeAccents: 1,
    removeSpecial: 1,
    slugify: 1,
  })
  _version = cleanString(_version, {
    toLowerCase: 1,
    removeDots: 1,
    removeAccents: 1,
    removeSpecial: 1,
    slugify: 1,
  })
  if (hotDeal) {
    if (vehicleType == VEHICLE_TYPE.car)
      _urlFinal = `${_baseUrl}ofertas/feiroes/${hotDeal}/comprar/${_make}/${_model}/${_version}/${_numberPorts}-portas/${_yearFabrication}/${_uniqueId}${_extraParam}`
    else
      _urlFinal = `${_baseUrl}ofertas/feiroes/${hotDeal}/comprar/${_make}/${_model}/${parseInt(
        _cubicCentimeter
      )}cc/${_yearFabrication}/${_uniqueId}${_extraParam}`
  } else if (vehicleType == VEHICLE_TYPE.car) {
    _urlFinal = `${_baseUrl}comprar/${_make}/${_model}/${_version}/${_numberPorts}-portas/${_yearFabrication}/${_uniqueId}${_extraParam}`
  } else
    _urlFinal = `${_baseUrl}comprar/${_make}/${_model}/${parseInt(
      _cubicCentimeter
    )}cc/${_yearFabrication}/${_uniqueId}${_extraParam}`
  return _urlFinal ? _urlFinal.toLowerCase() : ''
}
export function createUrlPhotoAd(photoPath, vehicleType, adType, photoSize) {
  let _urlPhotad = config.noPhoto
  if (photoPath) {
    if (photoPath.includes('https://')) {
      return photoPath
    }

    if (vehicleType == VEHICLE_TYPE.BIKE) {
      if (adType == AD_TYPE.NEW)
        _urlPhotad = `${config.photosBikeUrlNew}${
          config.photosGigant
        }${photoPath.replace(/\\/g, '/')}${photoSize || ''}`
      else
        _urlPhotad = `${config.photosBikeUrlUsed}${
          config.photosGigant
        }${photoPath.replace(/\\/g, '/')}${photoSize || ''}`
    } else if (adType == AD_TYPE.NEW)
      _urlPhotad = `${config.photosUrlNew}${
        config.photosGigant
      }${photoPath.replace(/\\/g, '/')}${photoSize || ''}`
    else
      _urlPhotad = `${config.photosUrlUsed}${
        config.photosGigant
      }${photoPath.replace(/\\/g, '/')}${photoSize || ''}`
  }
  return _urlPhotad.toLowerCase()
}
export function nextChar(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}
export function getItemAttribute(array, aceitaTroca) {
  const _list = array.split('|')
  let _listForSend = ''
  for (const key in _list) {
    {
      if (_list[key]) {
        if (aceitaTroca && _list[key] == 'aceita troca') return 'troca'
        if (_list[key] != 'aceita troca') {
          _listForSend += `${removeAccents(_list[key])},`
        }
      }
    }
  }
  return _listForSend.substr(0, _listForSend.length - 1)
}
export function getItemFormatted(array) {
  const _list = decodeURI(array).toLowerCase().split('|')
  let _listForSend = ''
  for (const key in _list) {
    {
      if (_list.hasOwnProperty(key)) {
        _listForSend += `${removeAccents(_list[key])},`
      }
    }
  }
  return _listForSend.substr(0, _listForSend.length - 1)
}
export function getItemFormattedLocation(element) {
  if (!element || element === 'estoque') {
    return {
      locationState: undefined,
      locationCity: undefined,
    }
  }

  const [locationState, locationCity] = decodeURI(element).split('-')

  const uf = getUf(locationState)

  return {
    locationState: locationState && uf ? uf.trim() : undefined,
    locationCity: locationCity ? `${locationCity.trim()} ${uf}` : undefined,
  }
}
export function getItemFormattedRate(element, isRate) {
  const _elementSplit = decodeURI(element).split('x')

  if (_elementSplit && _elementSplit.length > 0) {
    if (!isRate) {
      return _elementSplit.length > 0 ? _elementSplit[0] : TEXT.NAO
    }
    return _elementSplit.length > 1 ? _elementSplit[1] : TEXT.NAO
  }
  return TEXT.NAO
}
export function getValueFormatted(item) {
  return removeAccents(decodeURI(item).toLowerCase())
}
export function getItemUrl(query, type) {
  if (query && typeof query === 'string') {
    query = query.replace(/^\?/, '').split('&')
    for (let i = 0; i < query.length; i++) {
      const _queryValue = query[i].split('=')

      if (_queryValue[0] == type)
        return decodeURIComponent(removeAccents(_queryValue[1]))
    }
  }
}
export function getItemUrlOrigin(query, type) {
  const _value = getItemUrl(query, type)
  if (_value && ORIGIN_PAGES[_value]) return ORIGIN_PAGES[_value]
  return ''
}
export function getDataLayerUrl(location) {
  if (location) {
    const _a = []
    const params = location.search.replace(/\?/g, '').split('&')
    if (params && params.length > 0) {
      let pos
      let np
      let hd
      params.map(obj => {
        const a = obj.split('=')
        if (a[0] == 'pos') pos = a[1]
        else if (a[0] == 'np') np = a[1]
        else if (a[0] == 'hd') hd = a[1]
      })
      return { pos, np, hd }
    }
    return undefined
  }
  return undefined
}
export function sendDataLayer(objeto, eventType) {
  if (objDataLayer && typeof objDataLayer !== 'undefined') {
    objDataLayer = objeto
  }
  if (eventType) sendDispatchEvent(eventType)
}

export function sendDispatchEvent(eventType) {
  if (onClientSide()) {
    console.log(eventType, objDataLayer)
    document.dispatchEvent(new CustomEvent(eventType, { detail: objDataLayer }))
    console.log('eventType', eventType)
  }
}

export function getItemPosition(list) {
  const _arrayTipoCard = []
  let _i = 0
  let _l = 'a'

  for (const key in list) {
    const _elm = list[key]
    if (_elm.Seller.AdType.id == SELLER_TYPE.LOJA.ID)
      _arrayTipoCard.push({
        Id: _elm.UniqueId,
        Position: getStringPosition(_l, _elm.UniqueId, 'g'),
      })
    else if (_elm.Seller.AdType.id == SELLER_TYPE.CONCESSIONARIA.ID)
      _arrayTipoCard.push({
        Id: _elm.UniqueId,
        Position: getStringPosition(_l, _elm.UniqueId, 'm'),
      })
    else if (_elm.Seller.AdType.id == SELLER_TYPE.MONTADORA.ID)
      _arrayTipoCard.push({
        Id: _elm.UniqueId,
        Position: getStringPosition(_l, _elm.UniqueId, 'p'),
      })
    else if (
      _elm.Seller.AdType.id == SELLER_TYPE.MONTADORA.ID &&
      elm.Seller.Cnpj &&
      elm.Seller.Cnpj == '07707650003489'
    )
      _arrayTipoCard.push({
        Id: _elm.UniqueId,
        Position: getStringPosition(_l, _elm.UniqueId, 'o'),
      })
    else if (!_elm.HotDeal && _elm.Seller) {
      if (_elm.Seller.AdType.id == SELLER_TYPE.LOJA.ID) {
        switch (_elm.ProductCode) {
          case '1493':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'h'),
            })
            break
          case '1572':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, ''),
            })
            break
          case '1616':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'f'),
            })
            break
          case '1618':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'e'),
            })
            break
          default:
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(l, _elm.UniqueId, 'd'),
            })
            break
        }
      } else if (_elm.Seller.AdType.id == SELLER_TYPE.CONCESSIONARIA.ID) {
        switch (_elm.ProductCode) {
          case '1493':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'n'),
            })
            break
          case '1572':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, ''),
            })
            break
          case '1616':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'l'),
            })
            break
          case '1618':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'j'),
            })
            break
          default:
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'i'),
            })
            break
        }
      } else if (_elm.Seller.AdType.id == SELLER_TYPE.PESSOA_FISICA.ID) {
        switch (_elm.ProductCode) {
          case '674':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'c'),
            })
            break
          case '1236':
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'b'),
            })
            break
          default:
            _arrayTipoCard.push({
              Id: _elm.UniqueId,
              Position: getStringPosition(_l, _elm.UniqueId, 'a'),
            })
            break
        }
      }
    }
    _i++
    _l = nextChar(_l)
  }

  let _posicoes = ''
  _arrayTipoCard.forEach(element => {
    _posicoes += `${element.Position},`
  })
  return {
    array: _arrayTipoCard,
    text: _posicoes.substr(0, _posicoes.length - 1),
  }
}
export function getStringPosition(l, uniqueId, letter) {
  return `${l}${uniqueId}${letter}:`
}
export function getTypeAd(element) {
  const _arrayelement = element ? element.split('-') : undefined

  if (_arrayelement) {
    if (_arrayelement.length > 0) return 'todos'
    if (_arrayelement.length > 1) return _arrayelement[1]
  }
  return TEXT.NAO
}
export function getUsrId() {
  let _userData = getLoggedUserProperties()

  return _userData ? generateMD5Hash(_userData.ID.toString()) : undefined
}

export function maskNumberFormat(prefix, { data, input = '', selection }) {
  const newSelection = {
    start: selection.start,
    end: selection.end,
  }

  let value = removeSelectedRange(
    data.replace(/(\D)/g, text => (text === ' ' ? ' ' : '')),
    newSelection
  )
  const inputValue = input.replace(/\D/g, '')
  const oldLength = value.length

  value =
    value.slice(0, newSelection.start) +
    inputValue +
    value.slice(newSelection.start, value.length)
  value = value
    .replace(/\s/g, '')
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, text => `${text}.`)

  let index = newSelection.start
  if (inputValue) {
    index = Math.max(0, value.length - oldLength + index)
  }
  newSelection.end = newSelection.start = index

  return {
    value,
    maskedValue: value,
    visibleValue: value,
    selection: newSelection,
  }
}
function removeSelectedRange(value, selection) {
  if (selection.start === selection.end) {
    return value
  }

  if (selection.end < selection.start) {
    const tmp = selection.end
    selection.end = selection.start
    selection.start = tmp
  }

  if (value.length > selection.start) {
    return value
      .slice(0, selection.start)
      .concat(value.slice(selection.end, value.length))
  }

  return value
}

export function getFilterSelected(filters, sellerName) {
  if (filters) {
    const listFilters = []
    if (filters.idrevendedor) {
      delete filters.idrevendedor
      sellerName && listFilters.push(sellerName)
    }
    if (filters.estadocidade) {
      if (filters.estadocidade == 'estoque') listFilters.push('Brasil')
      else if (~filters.estadocidade.indexOf('-')) {
        filters.estadocidade = filters.estadocidade.split('-')
        let stateName = filters.estadocidade.shift()
        if (stateName.length > 2) {
          stateName = returnStateShort(decodeURI(stateName))
        }
        const cityName = cleanString(
          decodeURI(filters.estadocidade.join(' ')),
          {
            capitalizeAll: 1,
          }
        )
        listFilters.push(`${cityName} - ${stateName}`)
      } else if (
        typeof filters.estadocidade === 'string' &&
        filters.estadocidade.length == 2
      ) {
        listFilters.push(returnStateLong(filters.estadocidade))
      } else {
        listFilters.push(decodeURI(filters.estadocidade))
      }
    }
    if (filters.marca1) {
      let _veiculo = decodeURI(filters.marca1).trim()
      if (filters.modelo1) {
        _veiculo += ` ${decodeURI(filters.modelo1).trim()}`
        if (filters.versao1) {
          _veiculo += ` ${decodeURI(filters.versao1).trim()}`
        }
      }
      listFilters.push(_veiculo)
    }
    if (filters.marca2) {
      let _veiculo = decodeURI(filters.marca2).trim()
      if (filters.modelo2) {
        _veiculo += ` ${decodeURI(filters.modelo2).trim()}`
        if (filters.versao2) {
          _veiculo += ` ${decodeURI(filters.versao2).trim()}`
        }
      }
      listFilters.push(_veiculo)
    }
    if (filters.marca3) {
      let _veiculo = decodeURI(filters.marca3).trim()
      if (filters.modelo3) {
        _veiculo += ` ${decodeURI(filters.modelo3).trim()}`
        if (filters.versao3) {
          _veiculo += ` ${decodeURI(filters.versao3).trim()}`
        }
      }
      listFilters.push(_veiculo)
    }
    if (filters.anode || filters.anoate) {
      let _year = ''
      _year +=
        filters.anode && !filters.anoate ? `de ${parseInt(filters.anode)}` : ''
      _year +=
        filters.anode && filters.anoate ? `de ${parseInt(filters.anode)}` : ''
      _year +=
        filters.anode && filters.anoate ? ` a ${parseInt(filters.anoate)}` : ''
      _year +=
        !filters.anode && filters.anoate
          ? `até ${parseInt(filters.anoate)}`
          : ''
      listFilters.push(_year)
    }
    if (filters.precode && filters.precode != 0 && filters.precode != '') {
      const _price =
        filters.precoate == 0 || !filters.precoate
          ? `R$ ${formatNumber(filters.precode)}`
          : `R$ ${formatNumber(filters.precode)} até R$ ${formatNumber(
              filters.precoate
            )}`
      listFilters.push(_price)
    } else if (
      filters.precoate &&
      filters.precoate != 0 &&
      filters.precoate != ''
    ) {
      const _price =
        filters.precode == 0 || !filters.precode
          ? `R$ ${formatNumber(filters.precoate)}`
          : `R$ ${formatNumber(filters.precode)} até R$ ${formatNumber(
              filters.precoate
            )}`
      listFilters.push(_price)
    }

    if (filters.kmde && filters.kmde != 0 && filters.kmde != '') {
      const _km =
        filters.kmate == 0 || !filters.kmate
          ? `${formatNumber(filters.kmde)} km`
          : `${formatNumber(filters.kmde)} até ${formatNumber(
              filters.kmate
            )} km`
      listFilters.push(_km)
    } else if (filters.kmate && filters.kmate != 0 && filters.kmate != '') {
      const _km =
        filters.kmde == 0 || !filters.kmde
          ? `${formatNumber(filters.kmate)} km`
          : `${formatNumber(filters.kmde)} até ${formatNumber(
              filters.kmate
            )} km`
      listFilters.push(_km)
    }

    const arrayAttributes = [
      'opcionais',
      'cambio',
      'cor',
      'atributos',
      'combustivel',
    ]

    arrayAttributes.forEach(attribute => {
      if (filters[attribute] && filters[attribute] != '') {
        if (typeof filters[attribute] === 'string') {
          filters[attribute] = [filters[attribute]]
        }
        filters[attribute].forEach(key => {
          listFilters.push(cleanString(key.toLowerCase(), { capitalize: 1 }))
        })
      }
    })

    if (filters.finalplaca && filters.finalplaca != '') {
      filters.finalplaca.forEach(key => {
        listFilters.push(`Placas ${key}`)
      })
    }

    for (var key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (
          key != 'idcmpint' &&
          key != 'tipoveiculo' &&
          key != 'o' &&
          key != 'p' &&
          key != 'qt' &&
          key != 'autocomplete'
        ) {
          if (
            key != 'estadocidade' &&
            key != 'localizacao' &&
            key != 'anoate' &&
            key != 'anode' &&
            key != 'precode' &&
            key != 'precoate' &&
            key != 'kmde' &&
            key != 'kmate' &&
            key != 'estadocidade' &&
            key != 'finalplaca' &&
            key != 'cor' &&
            key != 'cambio' &&
            key != 'combustivel' &&
            key != 'atributos' &&
            key != 'opcionais' &&
            key.indexOf('marca') != 0 &&
            key.indexOf('modelo') != 0 &&
            key.indexOf('versao') != 0
          ) {
            const value = filters[key]
            let valueArray
            if (value.indexOf('|') != -1) {
              valueArray = value.split('|')
              for (var key in valueArray) {
                if (valueArray.hasOwnProperty(key)) {
                  if (valueArray[key]) listFilters.push(valueArray[key])
                }
              }
            } else if (filters[key]) listFilters.push(value)
          }
        } else if (key == 'tipoveiculo' && !!~filters[key].indexOf('-')) {
          listFilters.push(filters[key].split('-')[1])
        }
      }
    }
    return listFilters
  }
  return []
}
export function cleanArray(arr) {
  if (Array.isArray(arr)) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) {
        newArr.push(arr[i])
      }
    }
    return newArr
  }
  return arr
}
export function removeQueryParameter(url, parameter, notEncoded) {
  !notEncoded && (url = decodeURIComponent(url))
  url = url.split('?')
  const index1 = url.length - 1
  if (url[index1]) {
    url[index1] = url[index1].split('&')
    for (let index2 = 0; index2 < url[index1].length; index2++) {
      if (new RegExp(`^${parameter.trim()}=`).test(url[index1][index2])) {
        delete url[index1][index2]
        url[index1] = cleanArray(url[index1])
      }
    }
    url[index1] = url[index1].join('&')
  }
  url = url.join('?')
  !notEncoded && (url = encodeURIComponent(url))
  return url
}
export function getQueryParameter(query, key) {
  if (query && typeof query === 'string') {
    query = query.trim()
  } else {
    return undefined
  }
  if (key && typeof key === 'string') {
    key = key.trim()
  } else {
    return undefined
  }
  query = query.replace('?', '')
  query = query.split('&')
  for (let i = 0; i < query.length; i++) {
    if (query[i].split('=')[0] == key) {
      return query[i].split('=')[1]
    }
  }
  return undefined
}
export function insertQueryParameter(query, key, value) {
  query && typeof query === 'string' && (query = query.trim())
  key && typeof key === 'string' && (key = key.trim())
  value && typeof value === 'string' && (value = value.trim())
  key = encodeURI(key)
  value = encodeURIComponent(value)
  if (query.indexOf('&') === 0) {
    query = query.slice(1)
  }
  query = query.replace('?', '')
  var query = query.split('&')
  let i = query.length
  let x
  let newQuery
  while (i--) {
    x = query[i].split('=')
    if (x[0] == key) {
      x[1] = value
      query[i] = x.join('=')
      break
    }
  }
  if (i < 0) {
    query[query.length] = [key, value].join('=')
  }
  query = query.join('&')
  if (query.indexOf('&') === 0) {
    query = query.slice(1)
  }
  return query
}
export function removeTextCodeFromError(text) {
  if (text) {
    if (Array.isArray(text)) {
      const _array = []
      text.forEach(_item => {
        if (_item) {
          _array.push(_item.replace(/[@][\w]*[\d]/, ''))
        }
      })
      return _array
    }
    return text ? text.replace(/[@][\w]*[\d]/, '') : ''
  }
  return ''
}
export function setSearchDone() {
  setCookie(COOKIES.TRACKING_FROM, 'buscaRealizada', 1, 0)
}
export function setEventToLazyDispatch(event) {
  setCookie(COOKIES.TRACKING_FROM, event, 1, 0)
}
export function checkTrackingFrom(event) {
  const TRACKING_FROM = getCookie(COOKIES.TRACKING_FROM)
  if (!TRACKING_FROM && !event) {
    sendDispatchEvent('customPageView')
  } else if (TRACKING_FROM) {
    sendDispatchEvent(TRACKING_FROM)
  } else {
    sendDispatchEvent(event)
  }
  clearCookie(COOKIES.TRACKING_FROM)
}
export function validateURL(textval) {
  const urlregex =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}
export function imageExists(imageSrc, good, bad) {
  const img = new Image()
  img.onload = good
  img.onerror = bad
  img.src = imageSrc
}
export function checkStringifiedJSON(json) {
  if (
    /^[\],:{}\s]*$/.test(
      json
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          ']'
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  ) {
    return true
  }
  return false
}
export function getArrayHotDeal(hotDeal) {
  const arr = []
  hotDeal.map(_item => {
    arr.push(_item.Value.trim())
  })
  return arr
}
export function renderPhotoSize(url, width, height) {
  if (url && width && height && typeof url === 'string') {
    url = url.includes('{0}') && height ? url.replace('{0}', height) : url
    url = url.includes('{1}') && width ? url.replace('{1}', width) : url
  }
  return url
}
export function parseLocationObj(source, arr) {
  const location = arr
  for (let i = 0; i < source.length; i++) {
    if (source[i].Count) {
      const locData = JSON.parse(source[i].Name)
      let hasState
      let posState = 0
      for (let ii = 0; ii < location.length; ii++) {
        if (location[ii].name == locData.State) {
          hasState = 1
          posState = ii
        }
      }
      if (hasState) {
        let hasCity
        for (let iii = 0; iii < location[posState].items.length; iii++) {
          if (location[posState].items[iii].name == locData.City) {
            hasCity = 1
          }
        }
        if (!hasCity) {
          location[posState].items.push({
            name: locData.City,
            latitude: locData.Latitude,
            longitude: locData.Longitude,
            raio: locData.raio,
          })
        }
      } else if (locData.State && locData.State !== '') {
        location.push({
          name: locData.State,
          items: [
            {
              name: locData.City,
              latitude: locData.Latitude,
              longitude: locData.Longitude,
              raio: locData.raio,
            },
          ],
        })
      }
    }
  }
  return location
}

export function getUserTokenAuth() {
  return getCookie(config.cookieJwt)
}

export function parseJwt(token) {
  try {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]))
    } else {
      return token
    }
  } catch (e) {
    return null
  }
}

export function getLoggedUserProperties({ formattedPhoneNumber = true } = {}) {
  const _cookieToken = getUserTokenAuth()
  let _userData = parseJwt(_cookieToken)
  let userProperties

  if (_userData && _cookieToken) {
    userProperties = {}

    if (_userData['email'])
      userProperties.email = _userData['email'].replace(/\+/g, ' ')

    if (_userData['custom:name_naturalperson'])
      userProperties.name = decodeURIComponent(
        escape(_userData['custom:name_naturalperson'].replace(/\+/g, ' '))
      )

    if (_userData['custom:id_naturalperson'])
      userProperties.ID = _userData['custom:id_naturalperson'].replace(
        /\+/g,
        ' '
      )

      if (_userData['phone_number']) {
        if (formattedPhoneNumber) {
          userProperties.phoneNumber = _userData['phone_number'].replace(/\+/g, ' ')
        } else {
          userProperties.phoneNumber = _userData['phone_number']
        }
      }

    if (_userData['cognito:username'])
      userProperties.cognitoUserName = _userData['cognito:username'].replace(
        /\+/g,
        ' '
      )
  }
  return userProperties
}
export function orderObjectKeys(obj) {
  if (typeof obj !== 'object' || obj.constructor.name == 'Array') {
    return undefined
  }
  return Object.keys(obj)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key],
      }),
      {}
    )
}

export function setChannelCookie(location) {
  let _param =
    location && location.search
      ? getItemUrl(location.search, 'origem')
      : undefined

  if (_param) {
    _param = _param ? _param.toLowerCase() : ''

    if (
      _param == SYSTEM.COMPREAUTO.toLowerCase() ||
      _param == SYSTEM.MEUCARANGO.toLowerCase() ||
      _param == SYSTEM.BLUCARROS.toLowerCase() ||
      _param == SYSTEM.JOINVILLECARROS.toLowerCase() ||
      _param == SYSTEM.FLORIPACARROS.toLowerCase() ||
      _param == SYSTEM.ITAJAICARROS.toLowerCase()
    ) {
      setCookie(COOKIES.CHANNEL, _param)
      return _param
    }

    clearCookie(COOKIES.CHANNEL)
  } else {
    clearCookie(COOKIES.CHANNEL)
  }

  return undefined
}

export function getCookieChannel() {
  const _cookieChannel = getCookie(COOKIES.CHANNEL)

  if (_cookieChannel) {
    return _cookieChannel
  }
  return undefined
}

export function getCookieLocation() {
  // gera no padrão do filtro ('Campinas - SP' ou "São Paulo" ou vazio para Brasil)
  let _searchCookie = getCookie(COOKIES.FILTER_SEARCH)
  if (
    _searchCookie &&
    typeof _searchCookie === 'string' &&
    _searchCookie.indexOf('{') == 0
  ) {
    _searchCookie = JSON.parse(_searchCookie)
  }

  let _cookieLocation =
    _searchCookie != undefined && _searchCookie.estadocidade != undefined
      ? _searchCookie.estadocidade
      : 'estoque'
  _cookieLocation = generateFilterLocationFromCookie(
    decodeURIComponent(_cookieLocation)
  )
  return _cookieLocation
}

export function getCookieLocationLeft() {
  const _cookie = generateLocationLeftHand(getCookieLocation())
  return _cookie || 'estoque'
}

export function getCookieLocationRight() {
  const _cookie = generateLocationRightHand(getCookieLocation())
  return _cookie || 'estoque'
}

export function getLocationFormatted() {
  const uf = generateLocationState(
    generateFilterLocationFromCookie(
      decodeURIComponent(getCookieLocationRight())
    )
  )
  const state = returnStateLong(uf)
  const cityState = generateFilterLocationFromCookie(
    decodeURIComponent(getCookieLocationRight())
  )

  return { uf, state, cityState }
}

export function getCityLocation(text) {
  const { state, cityState } = getLocationFormatted()
  if (state == 'BR') return `${text} no Brasil`

  return `${text} em ${cityState}`
}

export function getCurrentChannel() {
  let _cookieChannel = getCookieChannel()
  if (_cookieChannel) {
    if (_cookieChannel.toLowerCase() == SYSTEM.COMPREAUTO.toLowerCase())
      _cookieChannel = SYSTEM.COMPREAUTO
    else if (_cookieChannel.toLowerCase() == SYSTEM.MEUCARANGO.toLowerCase())
      _cookieChannel = SYSTEM.MEUCARANGO
    else if (_cookieChannel.toLowerCase() == SYSTEM.ITAJAICARROS.toLowerCase())
      _cookieChannel = SYSTEM.ITAJAICARROS
    else if (_cookieChannel.toLowerCase() == SYSTEM.FLORIPACARROS.toLowerCase())
      _cookieChannel = SYSTEM.FLORIPACARROS
    else if (_cookieChannel.toLowerCase() == SYSTEM.BLUCARROS.toLowerCase())
      _cookieChannel = SYSTEM.BLUCARROS
    else if (
      _cookieChannel.toLowerCase() == SYSTEM.JOINVILLECARROS.toLowerCase()
    )
      _cookieChannel = SYSTEM.JOINVILLECARROS
  } else {
    _cookieChannel = SYSTEM.WEBMOTORS
  }

  return _cookieChannel
}

export function getChannelParameter() {
  const channel = getCurrentChannel()

  if (
    channel == SYSTEM.JOINVILLECARROS ||
    channel == SYSTEM.FLORIPACARROS ||
    channel == SYSTEM.BLUCARROS ||
    channel == SYSTEM.ITAJAICARROS
  ) {
    return '&channel=BuscaCarros'
  }
  if (channel == SYSTEM.WEBMOTORS) {
    return ''
  }

  return `&channel=${channel}`
}
export function parseLocation(loc) {
  if (loc && typeof loc === 'string') {
    loc = loc.split(/[\,x]/g)
    if ((loc.length = 3)) {
      return { latitude: loc[0], longitude: loc[1], raio: parseInt(loc[2]) }
    }
    return undefined
  }
}

export function getVehicleFilterCookie() {
  let _searchCookie = getCookie(COOKIES.FILTER_SEARCH)
  if (
    _searchCookie &&
    typeof _searchCookie === 'string' &&
    _searchCookie.indexOf('{') == 0
  ) {
    _searchCookie = JSON.parse(_searchCookie)
  }
  return _searchCookie
}
export function forceDownload(_filename, _contentType, _data) {
  const _element = document.createElement('a')
  _element.setAttribute(
    'href',
    (_contentType || 'data:text/plain;charset=utf-8,') + _data
  )
  _element.setAttribute('download', _filename)
  _element.style.display = 'none'
  document.body.appendChild(_element)
  _element.click()
  document.body.removeChild(_element)
}
export function getPathNameByEnvironment() {
  let _pathName = ''
  switch (process.env.NODE_ENV) {
    case 'development':
      _pathName = 'http://localhost:8081/public/assets/'
      break
    case 'homologation':
      _pathName = 'https://hportal.webmotors.com.br/assets/'
      break
    case 'blue':
      _pathName = 'https://azulportal.webmotors.com.br/assets/'
      break
  }
  return _pathName
}

export function calculateAdsEnd(
  searchResult,
  displayPerPage = 1,
  quantityAds = 3
) {
  const MAX_PER_PAGE = 10
  const calculate = Math.round(searchResult.length / displayPerPage)
  const qtdPerPages = calculate === 0 ? 1 : calculate
  return qtdPerPages * quantityAds >= MAX_PER_PAGE
    ? MAX_PER_PAGE
    : qtdPerPages * quantityAds
}

export function calculateAdsStart(actualPage, quantityAds = 3) {
  return (actualPage - 1) * quantityAds
}

export function getNumberAds(cardPreferences = DISPLAY_CARD.column) {
  const THREE_COLUMNS = 3
  const FOUR_COLUMNS = 4
  return cardPreferences === DISPLAY_CARD.column ? THREE_COLUMNS : FOUR_COLUMNS
}

export function getSeals(
  ad,
  hasMegaFair,
  megaFair,
  hasVehicleInspection = false,
  hasBlackFair,
  blackFair,
  hasRespondsFast = false
) {
  const {
    GoodDeal,
    VipAutopago,
    Seller: { TrocaComTroco, CarDelivery },
    IsElegible360View: View360,
  } = ad

  const adSeals = {
    CarDelivery,
    TrocaComTroco,
    VipAutopago,
    GoodDeal,
    View360,
  }

  const blackFairId = getBlackFairId(hasBlackFair, blackFair)

  if (
    isBlackFairActive(hasBlackFair) &&
    ad.HotDeal &&
    isAdInFair(ad.HotDeal, blackFairId)
  ) {
    adSeals[FAIR.blackFair.name] = true
  }

  const megaFairId = getMegaFairId(hasMegaFair, megaFair)

  if (
    isMegaFairActive(hasMegaFair) &&
    ad.HotDeal &&
    isAdInFair(ad.HotDeal, megaFairId)
  ) {
    adSeals[FAIR.megaFair.name] = true
  }

  if (hasVehicleInspection) {
    adSeals['vistoriado'] = true
  }

  if (hasRespondsFast) {
    adSeals['RespondeRapido'] = true
  }

  const seals = Object.entries(adSeals)
    .filter(seal => {
      const [_, value] = seal

      if (value) {
        return true
      }

      return false
    })
    .map(filteredValue => filteredValue[0])

  return seals
}

export function roundPrice(price) {
  return Math.round(price / 1000) * 1000
}

export function getLastItemPath(path) {
  return path.substring(path.lastIndexOf('/') + 1)
}

export const isZeroKmBrand = (model = '') =>
  zeroKmModels.includes(model.toUpperCase())

export const reducerFactory = (initialState, handlers) => {
  return (state = initialState, action) => {
    const handler = handlers[action.type]

    if (handler) {
      return handler(state, action)
    }

    return state
  }
}

export function checkCharsMessage(message, minChars) {
  if (message && message.replace(/ /g, '').length > minChars) {
    return false
  }
  return true
}

export function cleanObject(obj) {
  if (typeof obj === 'string') {
    return obj.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  } else if (typeof obj === 'object' && obj !== null) {
    for (const prop in obj) {
      obj[prop] = cleanObject(obj[prop]);
    }
  }
  return obj;
}

export function hasNewCardLayout() {
  const { newCardLayout, env } = config.abTest
    let hasNewCardLayout = false
    if (onClientSide()) {
      hasNewCardLayout =
        window.localStorage.getItem(`${env}_${newCardLayout.keys}`) ===
        'true'
    }
    return hasNewCardLayout
}

export function hasLocationFilters(urlString) {
  try {
    const url = new URL(urlString)
    const filterKeys = ['localizacao', 'idrevendedor']

    const hasEstadoCidade =
    url.searchParams.get('estadocidade') !== null &&
    url.searchParams.get('estadocidade') !== 'estoque'

    const paramsKeys =  [...url.searchParams.keys()]
    return (
      hasEstadoCidade ||
      filterKeys.some(filterKey =>
        paramsKeys.some(paramKey => filterKey === paramKey.toLowerCase())
      )
    )
  } catch (error) {
    return false
  }
}

export function hasPandora() {
  const { pandora, env } = config.abTest
  let hasPandora = false
  if (onClientSide()) {
    hasPandora =
      window.localStorage.getItem(`${env}_${pandora.currentTestKey}`) ===
      'true'
  }
  return hasPandora
}

export function getStandardRadius(filter, defaultValue = 100) {
  if (!onClientSide()) {
    return defaultValue
  }

  if (!filter || (filter && !filter.name)) {
    return defaultValue
  }

  const location = removeAccents(filter.name ? filter.name.toLowerCase() : '');

  const RADIUS = {
    ['curitiba - pr']: 50,
    ['brasília - df']: 100,
    ['rio de janeiro - rj']: 50,
    ['recife - pe']: 100,
    ['porto alegre - rs']: 50,
  }

  return get(RADIUS, location.toLocaleLowerCase(), defaultValue)
}

export function hasLocationInUrl(urlString) {
  try {
    if (!onClientSide()) { return false }
    if (hasLocationFilters(urlString)) { return true }

    const states_UF = STATES.map(state => state.uf.toLowerCase())

    const url = new URL(urlString);
    const path = url.pathname.toLowerCase();
    const STATES_JOINED = states_UF.join('|')
    const STATE_REGEX = new RegExp(`\/(${STATES_JOINED})(\/|-|$)`)

    return STATE_REGEX.test(path)
  } catch (error) {
    return false
  }
}

export function checkStateIsAllowedList(stateName) {
  const allowedStateList = ['São Paulo', 'Pernambuco', 'Ceará', 'Minas Gerais']
  return allowedStateList.includes(stateName)
}

export function saveDataToStorage(storageKey, data) {
  if (onClientSide()) {
    localStorage.setItem(storageKey, JSON.stringify(data))
  }
}

export function updateLocationDataLayer(locationData) {
  if (!onClientSide()) return

  const optin = window?.objDataLayer?.user?.pii?.location?.optin

  if (!optin || optin.state || optin.city) return

  optin.state = locationData?.state
  optin.city = locationData?.city
}

function loadDataFromStorage(storageKey) {
  if (onClientSide()) {
    const storedData = localStorage.getItem(storageKey)
    return storedData ? JSON.parse(storedData) : {}
  }
  return {}
}

export const userPiiLead = () => {
  const storageKey = 'userPiiLead'

  let userData = loadDataFromStorage(storageKey)

  if (onClientSide()) {
    const { Principal = {} } = getEncryptedCookie(COOKIES.FORM_DATA) || {}
    const cleanPhone = phone =>
      phone.replace(/\D/g, '').replace(/^(\d{2})(\d+)$/, '0$1-$2')

    if (Principal.email || Principal.fullName || Principal.numberPhone) {
      userData = {
        email: Principal.email?.toLowerCase(),
        name: Principal.fullName?.toLowerCase(),
        phoneNumber: Principal.numberPhone && cleanPhone(Principal.numberPhone),
      }

      saveDataToStorage(storageKey, userData)
    }
  }

  return userData
}

export const userPiiLocation = () => {
  const storageKey = 'userPiiLocation'

  let locationData = loadDataFromStorage(storageKey)

  if (onClientSide()) {
    let geolocationCookie = getCookie(COOKIES.GEOLOCATION)
    let geolocationCookieParsed

    if (geolocationCookie && geolocationCookie.substring(0, 1) === '{') {
      try {
        geolocationCookieParsed = JSON.parse(geolocationCookie)
      } catch (e) {
        return { state: undefined, city: undefined }
      }

      const { estadocidade = undefined } = geolocationCookieParsed

      if (typeof estadocidade === 'string') {
        const [state = undefined, city = undefined] = estadocidade.split(' - ')

        locationData = {
          state: cleanString(state, {
            toLowerCase: 1,
            removeAccents: 1,
            slugify: 1,
          }),
          city: cleanString(city, {
            toLowerCase: 1,
            removeAccents: 1,
            slugify: 1,
          }),
        }

        saveDataToStorage(storageKey, locationData)
      }
    }
  }

  updateLocationDataLayer(locationData)

  return locationData
}