import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9209 18.353C14.9619 17.184 15.2349 15.579 16.3069 14.507C17.1159 13.698 18.0799 13.308 18.9009 13.123C18.5239 15.451 16.9979 17.394 14.9209 18.353ZM11.0749 18.932C11.0959 17.426 10.8349 14.857 9.06988 13.093C7.78488 11.808 6.25288 11.291 5.06688 11.088C5.11688 10.701 5.19388 10.321 5.30588 9.957C6.64788 9.735 9.14388 9.4 11.9999 9.4C14.8119 9.4 17.2449 9.715 18.6929 9.955C18.8049 10.319 18.8819 10.696 18.9329 11.082C17.7349 11.28 16.1899 11.796 14.8929 13.093C13.1279 14.858 12.8839 17.425 12.9159 18.933C12.6149 18.973 12.3109 19 11.9999 19C11.6859 19 11.3789 18.972 11.0749 18.932ZM5.09988 13.131C5.91088 13.319 6.85888 13.71 7.65588 14.507C8.72688 15.578 9.01388 17.176 9.06488 18.346C6.99788 17.386 5.47888 15.45 5.09988 13.131ZM11.9999 5C14.2689 5 16.2829 6.09 17.5629 7.768C16.0799 7.575 14.1479 7.4 11.9999 7.4C9.85588 7.4 7.92488 7.574 6.43788 7.768C7.71788 6.089 9.73188 5 11.9999 5ZM11.9999 3C7.02888 3 2.99988 7.029 2.99988 12C2.99988 16.971 7.02888 21 11.9999 21C16.9709 21 20.9999 16.971 20.9999 12C20.9999 7.029 16.9709 3 11.9999 3ZM13.7998 12C13.7998 11.006 12.9938 10.2 11.9998 10.2C11.0058 10.2 10.1998 11.006 10.1998 12C10.1998 12.994 11.0058 13.8 11.9998 13.8C12.9938 13.8 13.7998 12.994 13.7998 12Z"
      fill="#2E2D37"
    />
  </svg>
)
