import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 15H20V12C20 11.448 19.552 11 19 11C18.448 11 18 11.448 18 12V15H15C14.448 15 14 15.448 14 16C14 16.552 14.448 17 15 17H18V20C18 20.552 18.448 21 19 21C19.552 21 20 20.552 20 20V17H23C23.552 17 24 16.552 24 16C24 15.448 23.552 15 23 15ZM11 16H5.5H5V15H7C7.552 15 8 14.552 8 14C8 13.448 7.552 13 7 13H5V12H11H15C15.552 12 16 11.552 16 11C16 10.448 15.552 10 15 10H11H5.618L7.618 6H16.382L17.105 7.447C17.353 7.941 17.953 8.143 18.447 7.895C18.941 7.647 19.142 7.047 18.895 6.553L17.895 4.553C17.725 4.214 17.379 4 17 4H7C6.621 4 6.275 4.214 6.105 4.553L3.105 10.553C3.1 10.565 3.098 10.578 3.092 10.59C3.083 10.612 3.078 10.634 3.069 10.656C3.033 10.755 3.012 10.857 3.008 10.961C3.007 10.975 3 10.986 3 11V17C3 17.064 3.025 17.12 3.036 17.18C3.014 17.284 3 17.39 3 17.5V18.5C3 19.325 3.675 20 4.5 20H5.5C6.325 20 7 19.325 7 18.5V18H11C11.552 18 12 17.552 12 17C12 16.448 11.552 16 11 16Z"
      fill="#2E2D37"
    />
  </svg>
)
