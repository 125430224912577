import Immutable from 'immutee'

export const SET_LOCATION_SITE_MAP =
  'buyer/locationSiteMap/SET_LOCATION_SITE_MAP'

const initialState = {
  fetched: false,
  isValidPage: true,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)

  return action.type === SET_LOCATION_SITE_MAP
    ? immutable
        .set('isValidPage', action.payload.isValidPage)
        .set('fetched', true)
        .done()
    : state
}

export function setLocationSiteMap(payload) {
  return function (dispatch) {
    return dispatch({
      type: SET_LOCATION_SITE_MAP,
      payload,
    })
  }
}
