const versionFile = '1.0.1'

const defaultConfigurations = {
  cookieJwt: 'webmotors.jwt.token',
  cookieManagement: '#',
  randomUrl: versionFile,
  noPhoto: `/assets/img/webp/vehicle_no_photo.webp?t=${versionFile}`,
  badge: `/assets/img/badge.svg?t=${versionFile}`,
  iconWebmotors: `/assets/img/webmotors.svg?t=${versionFile}`,
  iconMeucarango: `/assets/img/channels/meucarango-wm.svg?t=${versionFile}`,
  iconCompreauto: `/assets/img/channels/compreauto-wm.svg?t=${versionFile}`,
  iconBlucarros: `/assets/img/channels/blucarros-wm.svg?t=${versionFile}`,
  iconFloripacarros: `/assets/img/channels/floripa-wm.svg?t=${versionFile}`,
  iconItajaicarros: `/assets/img/channels/itajai-wm.svg?t=${versionFile}`,
  iconJoinvillecarros: `/assets/img/channels/joinville-wm.svg?t=${versionFile}`,
  userPhoto: `/assets/img/webp/photo-default-user.webp?t=${versionFile}`,
  photosGigant: 'gigante/',
  photoSearchResultBig: '?s=fill&w=249&h=178&q=70&t=true',
  photoSearchResultMedium: '?s=fill&w=209&h=164&q=70',
  photoSearchResultLittle: '?s=fill&w=90&h=70&q=70',
  photoDetailBig: '?s=fill&w=1920&h=1440&q=75',
  photoDetailMedium: '?s=fill&w=552&h=414&q=60',
  photoDetailLittle: '?s=fill&w=75&h=56&q=60',
  orderfilter: 1,
  ordinations: [
    {
      value: 1,
      name: 'Mais relevantes',
      id: 'FilterOrderListMostRelevant',
    },
    { value: 6, name: 'Maior preço', id: 'FilterOrderListBiggestPrice' },
    { value: 5, name: 'Menor preço', id: 'FilterOrderListLowestPrice' },
    { value: 3, name: 'Ano mais novo', id: 'FilterOrderListNewYear' },
    { value: 4, name: 'Menor km', id: 'FilterOrderListLowerMileage' },
  ],
  actualPage: 1,
  displayPerPage: 24,
  userProperties: 'webmotors.user.properties',
  system: 'WebMotors',
  urlUber:
    'https://www.uber.com/a/drive-pp?irgwc=1&utm_term=U-gxxNzhoxyJR5K0JSywsQuwUklxpARe90WF2U0&adg_id=324858&cid=1389457&utm_content=&utm_campaign=affiliate-ir-Webmotors-Brazil_25_-99_national_d_all_acq_cpa_en&utm_source=affiliate-ir',
  urlAPM: 'https://apm.wmaws.com.br',
  urlAppIphone:
    'https://itunes.apple.com/br/app/webmotors-comprar-e-vender/id496509047?mt=8',
  urlAppAndroid:
    'https://play.google.com/store/apps/details?id=hands.android.webmotors&hl=pt_BR',
  urlHelloEthic: 'https://aloetica.com.br/webmotors',
  urlSantander: 'https://www.santander.com.br/',
  urlMeuCarango: 'https://www.meucarango.com.br/',
  urlCompreAuto: 'https://www.compreauto.com.br/',
  urlCarSales: 'https://www.carsales.com.au/',
  urlChileAutos: 'https://www.chileautos.cl/',
  urlDeMotores: 'https://www.demotores.com.ar/',
  urlSoloAutos: 'https://www.soloautos.mx/',
  urlAutoInsurance: 'https://www.autocompara.com.br/',
  urlAutoCompara: 'https://www.autocompara.com.br/',
  urlFazTudo: 'https://webmotors.com.br/vender/faztudo',
  urlTyreSales: 'https://www.tyresales.com.au/',
  urlTwitter: 'https://twitter.com/webmotors',
  urlFacebook: 'https://www.facebook.com/webmotors',
  urlInstagram: 'https://www.instagram.com/webmotors/',
  urlYoutube: 'https://www.youtube.com/webmotors',
  urlInstitutional: 'https://institucional.webmotors.com.br/',
  urlConsumerProtection:
    'https://cdn.webmotors.com.br/webmotors/Content/pdf/cdc.pdf?v=32673',
  urlWorkWithUs: 'https://webmotors.gupy.io/',
  urlPublicity: 'https://publicidade.webmotors.com.br/',
  urlContatcUs: 'https://ajuda.webmotors.com.br/hc/pt-br/',
  urlWMHelp: 'https://ajuda.webmotors.com.br',
  urlHowToSell:
    'https://ajuda.webmotors.com.br/hc/pt-br/articles/360038764251-Como-anunciar-/',
  urlHowToBuy: 'https://ajuda.webmotors.com.br/hc/pt-br/',
  urlLegalPerson: 'https://ajuda.cockpit.com.br/hc/pt-br/',
  urlSiteCarsales: 'https://www.carsales.com.au/',
  urlSiteLoop: 'https://www.loopcarros.com.br/',
  urlSellMyCar: 'https://www.webmotors.com.br/vender/',
  urlCockPit: 'https://cockpit.webmotors.com.br/',
  useSSR: true,
  showMenuOffer: false,
  showRecommendedOffer: true,
  showTagOffer: true,
  blackFeiraoId: 1085,
  blackFeiraoNome: 'blackfeirao',
  urlAboutUs: 'https://www.webmotors.com.br/institucional/',
  blackFeiraoActive: false,
  urlSecurity: 'https://www.webmotors.com.br/seguranca/',
  urlPrivacyPolitics:
    'https://www.webmotors.com.br/seguranca/politica-de-privacidade/',
  urlLGPD: 'https://www.webmotors.com.br/seguranca/lgpd/',
  blackCouponId: 1085,
  megaFeiraoId: 1168,
  megaFeiraoActive: false,
  cookieEncryptionSecret: 'twY2NMFSKMskM8NteG9bwBkx',
  FF_AutomaticFair: true,
  bannerPJUrl: 'https://image.webmotors.com.br/bannerseller/hml',
  urlHotDealImages:
    'https://image.webmotors.com.br/feiroes/imagens/hml/_portal/',
  showCarDeliveryExtendedStamp: false,
  urlVistoriado: 'https://www.cockpit.com.br/solutions/vistoriado',
  urlVistoriadoRB:
    'https://www.webmotors.com.br/carros/estoque?Oportunidades=Vistoriado',
  searchResult: {
    image: {
      width: 249,
      height: 186,
      imageHandlerRender: '?s=fill&w=249&h=186&q=70',
    },
  },
  adReports: 'ad_reports',
  blackFairAdAIsKeyActive: 'blackFairAdAIsKeyActive',
  promoBlackFairao2024: 'hasPromoBlackFairao2024',
  hideModalPromoBlackFairao2024: 'hideModalBlackFair',
  abTest: {
    fakeTest1: {
      mboxName: 'mbox-target-api-test-1',
      keys: 'hml_teste_1',
    },
    fakeTest2: {
      mboxName: 'mbox-target-api-test-2',
      keys: {
        test2: 'teste_2',
        test3: 'teste_3',
        test4: 'teste_4',
      },
    },
    fakeTest3: {
      mboxName: 'mbox-target-api-test-3',
      keys: {
        test2: 'teste_2',
        test3: 'teste_3',
        test4: 'teste_4',
      },
    },
    pandora: {
      mboxName: 'mbox-ab-mecanismo-de-busca-desktop',
      sessionKey: 'pandoraDesk',
      currentTestKey: 'pandoraDesk',
    },
    cardLayout: {
      mboxName: 'mbox-resultado-busca-novoscards',
      sessionKey: 'card_layout_session',
      keys: 'cardlayout_test',
    },
    newCardLayout: {
      mboxName: 'mbox-resultado-busca-novoscardsv2',
      keys: 'new_cardlayout_test',
    },
    financingInternalizationDesk: {
      mboxName: 'mbox-internalizacao-financiamento-desk',
      key: 'ab3_test_financing_internalization',
    },
  },
}

const developmentConfigurations = {
  ...defaultConfigurations,
  abTest: {
    ...defaultConfigurations.abTest,
    env: 'hml',
    c2c: {
      key: 'dev_adb_c2c_test',
    },
    megafair: {
      key: 'dev_adb_megafair_test',
    },
    lastSearches: {
      key: 'dev_ab_lastSearches_test',
    },
  },
  baseUrl: 'https://hportal.webmotors.com.br',
  cookieJwt: 'webmotors.jwt.token.hk',
  urlApi: 'https://hportal.webmotors.com.br/api/',
  urlApiServer: 'https://hportal.webmotors.com.br/api/',
  urlApiSantander: 'http://localhost/WebMotors.Api/santanderservices/',
  urlApiSantanderServer: 'http://localhost/WebMotors.Api/santanderservices/',
  urlApp: 'http://localhost:8080/',
  photosBikeUrlNew: 'https://himage.webmotors.com.br/_fotos/AnuncioMotoNovos/',
  photosBikeUrlUsed:
    'https://himage.webmotors.com.br/_fotos/AnuncioMotoUsados/',
  photosUrlUsed: 'https://himage.webmotors.com.br/_fotos/AnuncioUsados/',
  photosUrlNew: 'https://himage.webmotors.com.brf_fotos/AnuncioNovos/',
  urlSellMyCar: 'https://hportal.webmotors.com.br/vender/',
  urlSellMyBike: 'https://hportal.webmotors.com.br/vender/moto',
  urlTableFipe: 'https://www.webmotors.com.br/tabela-fipe/',
  urlKM: 'https://hportal.webmotors.com.br/catalogo',
  urlFinancig: 'https://hportal.webmotors.com.br/financiamento/',
  urlNewsWM1: 'https://www.webmotors.com.br/wm1/',
  urlMyAdverts: 'https://hportal.webmotors.com.br/garagem',
  urlEditProfile: 'https://hportal.webmotors.com.br/garagem/perfil',
  urlGarageChat: 'https://hportal.webmotors.com.br/garagem/propostas',
  urlMyNegotiations:
    'https://hportal.webmotors.com.br/garagem/propostas/compra',
  urlPosts: 'https://hportal.webmotors.com.br/garagem/propostas/compra',
  urlAdvancedSearch: 'https://hportal.webmotors.com.br/carros/estoque',
  urlUsedOrNewCars: 'https://hportal.webmotors.com.br/carros-usados/estoque',
  urlNewCars: 'https://hportal.webmotors.com.br/carros-novos/estoque',
  urlUsedOrNewMotorbikes: 'https://hportal.webmotors.com.br/motos/estoque',
  urlNewMotorsbike: 'https://hportal.webmotors.com.br/motos-novas/estoque',
  urlAutoPayment: 'https://autopago.webmotors.com.br',
  urlManageMyAdvertising: 'https://hportal.webmotors.com.br/garagem',
  urlBaseLogin: 'https://hportal.webmotors.com.br',
  urlLogin: 'https://hportal.webmotors.com.br/login?lkid=1364&r=',
  userProperties: 'webmotors.user.properties',
  makeLogoCatalogo: 'https://www.webmotors.com.br/aplicativos/logos/',
  userToken: 'webmotors.auth.token',
  sellerShieldImg: 'https://s3.amazonaws.com/webmotors/blindagem-fotos/hml/',
  urlHotDeal: 'http://localhost:8080/ofertas',
  urlHotDealRegulation:
    'https://s3.amazonaws.com/webmotorshub/HK/Feirao/clientes/',
  urlMapSite: 'http://localhost:8080/mapasite',
  urlConsumerProtectionCode:
    'https://cdn.webmotors.com.br/webmotors/Content/pdf/cdc.pdf?v:32673',
  signatureIds: [3855806, 3846888],
  urlImgS3: 'https://www.webmotors.com.br/imagens/hk/portal/',
  blackFeiraoId: 9975,
  blackFeiraoNome: 'blackfeirao2020',
  blackFeiraoUrl:
    'https://www.webmotors.com.br/ofertas/feiroes/blackfeirao2020',
  urlApiShowRoom: 'https://hportal.webmotors.com.br/api/',
  recommendationEnable: false,
  blackCouponId: 9975,
  megaFeiraoId: 9953,
  megaFeiraoActive: false,
  urlVistoriadoRB:
    'https://hportal.webmotors.com.br/carros/estoque?Oportunidades=Vistoriado',
  cookieDomains: 'localhost',
}

const homologationConfigurations = {
  ...defaultConfigurations,
  abTest: {
    ...defaultConfigurations.abTest,
    env: 'hml',
    c2c: {
      key: 'hml_adb_c2c_test',
    },
    megafair: {
      key: 'hml_adb_megafair_test',
    },
    lastSearches: {
      key: 'hml_ab_lastSearches_test',
    },
  },
  baseUrl: 'https://hportal.webmotors.com.br',
  cookieJwt: 'webmotors.jwt.token.hk',
  urlApi: 'https://hportal.webmotors.com.br/api/',
  urlApiServer: 'https://hportal.webmotors.com.br/api/',
  urlApiSantander: 'https://hportal.webmotors.com.br/api/santanderservices/',
  urlApiSantanderServer:
    'https://hportal.webmotors.com.br/api/santanderservices/',
  urlApp: 'https://hportal.webmotors.com.br/',
  photosBikeUrlNew: 'https://himage.webmotors.com.brr/_fotos/AnuncioMotoNovos/',
  photosBikeUrlUsed:
    'https://himage.webmotors.com.br/_fotos/AnuncioMotoUsados/',
  photosUrlUsed: 'https://himage.webmotors.com.br/_fotos/AnuncioUsados/',
  photosUrlNew: 'https://himage.webmotors.com.br/_fotos/AnuncioNovos/',
  urlSellMyCar: 'https://hportal.webmotors.com.br/vender/',
  urlSellMyBike: 'https://hportal.webmotors.com.br/vender/moto',
  urlTableFipe: 'https://hportal.webmotors.com.br/tabela-fipe/',
  urlKM: 'https://www.webmotors.com.br/catalogo',
  urlFinancig: 'https://hportal.webmotors.com.br/financiamento/',
  urlNewsWM1: 'https://www.webmotors.com.br/wm1/',
  urlMyAdverts: 'https://hportal.webmotors.com.br/garagem',
  urlEditProfile: 'https://hportal.webmotors.com.br/garagem/perfil',
  urlGarageChat: 'https://hportal.webmotors.com.br/garagem/propostas',
  urlMyNegotiations:
    'https://hportal.webmotors.com.br/garagem/propostas/compra',
  urlPosts: 'https://hportal.webmotors.com.br/garagem/propostas/compra',
  urlAdvancedSearch: 'https://hportal.webmotors.com.br/carros/estoque',
  urlUsedOrNewCars: 'https://hportal.webmotors.com.br/carros-usados/estoque',
  urlNewCars: 'https://hportal.webmotors.com.br/carros-novos/estoque',
  urlUsedOrNewMotorbikes: 'https://hportal.webmotors.com.br/motos/estoque',
  urlNewMotorsbike: 'https://hportal.webmotors.com.br/motos-novas/estoque',
  urlAutoPayment: 'https://autopago.webmotors.com.br',
  urlManageMyAdvertising: 'https://hportal.webmotors.com.br/garagem',
  urlBaseLogin: 'https://hportal.webmotors.com.br',
  urlLogin: 'https://hportal.webmotors.com.br/login?lkid=1364&r=',
  userProperties: 'webmotors.user.properties',
  makeLogoCatalogo: 'https://www.webmotors.com.br/aplicativos/logos/',
  userToken: 'webmotors.auth.token.hk',
  sellerShieldImg: 'https://s3.amazonaws.com/webmotors/blindagem-fotos/hml/',
  urlHotDeal: 'https://hportal.webmotors.com.br/ofertas',
  urlHotDealRegulation:
    'https://s3.amazonaws.com/webmotorshub/HK/Feirao/clientes/',
  urlMapSite: 'https://hportal.webmotors.com.br/mapasite',
  urlConsumerProtectionCode:
    'https://cdn.webmotors.com.br/webmotors/Content/pdf/cdc.pdf?v:32673',
  signatureIds: [3855806, 3846888],
  urlImgS3: 'https://www.webmotors.com.br/imagens/hk/portal/',
  blackFeiraoId: 9975,
  blackFeiraoNome: 'blackfeirao2020',
  blackFeiraoUrl:
    'https://www.webmotors.com.br/ofertas/feiroes/blackfeirao2020',
  urlApiShowRoom: 'https://hportal.webmotors.com.br/api/',
  recommendationEnable: false,
  blackCouponId: 9975,
  megaFeiraoId: 9953,
  megaFeiraoActive: false,
  urlVistoriadoRB:
    'https://hportal.webmotors.com.br/carros/estoque?Oportunidades=Vistoriado',
  cookieDomains: [
    '.hportal.webmotors.com.br',
    'hportal.webmotors.com.br',
    'www.hportal.webmotors.com.br',
    '.www.hportal.webmotors.com.br',
    '.webmotors.com.br',
    'webmotors.com.br',
    'www.webmotors.com.br',
    '.www.webmotors.com.br',
  ],
}

const blueConfigurations = {
  ...defaultConfigurations,
  abTest: {
    ...defaultConfigurations.abTest,
    env: 'azl',
    c2c: {
      key: 'azl_adb_c2c_test',
    },
    megafair: {
      key: 'azl_adb_megafair_test',
    },
    lastSearches: {
      key: 'azl_ab_lastSearches_test',
    },
  },
  baseUrl: 'https://azulportal.webmotors.com.br',
  cookieJwt: 'webmotors.jwt.token',
  urlApi: 'https://azulportal.webmotors.com.br/api/',
  urlApiServer: 'https://wvusdhfp4l.execute-api.us-east-1.amazonaws.com/azl/',
  urlApiSantander: 'https://azulportal.webmotors.com.br/pf/santanderservices/',
  urlApiSantanderServer:
    'https://azulportal.webmotors.com.br/pf/santanderservices/',
  urlApp: 'https://azulportal.webmotors.com.br/',
  photosBikeUrlNew: 'https://image.webmotors.com.br/_fotos/AnuncioMotoNovos/',
  photosBikeUrlUsed: 'https://image.webmotors.com.br/_fotos/AnuncioMotoUsados/',
  photosUrlUsed: 'https://image.webmotors.com.br/_fotos/AnuncioUsados/',
  photosUrlNew: 'https://image.webmotors.com.br/_fotos/AnuncioNovos/',
  urlSellMyBike: 'https://www.webmotors.com.br/vender/moto',
  urlTableFipe: 'https://azulportal.webmotors.com.br/tabela-fipe/',
  urlKM: 'https://www.webmotors.com.br/catalogo',
  urlFinancig: 'https://azulportal.webmotors.com.br/financiamento/',
  urlNewsWM1: 'https://www.webmotors.com.br/wm1/',
  urlMyAdverts: 'https://www.webmotors.com.br/garagem',
  urlEditProfile: 'https://www.webmotors.com.br/garagem/perfil',
  urlGarageChat: 'https://www.webmotors.com.br/garagem/propostas',
  urlMyNegotiations: 'https://www.webmotors.com.br/garagem/propostas/compra',
  urlPosts: 'https://www.webmotors.com.br/garagem/propostas/compra',
  urlAdvancedSearch: 'https://azulportal.webmotors.com.br/carros/estoque',
  urlUsedOrNewCars: 'https://azulportal.webmotors.com.br/carros-usados/estoque',
  urlNewCars: 'https://azulportal.webmotors.com.br/carros-novos/estoque',
  urlUsedOrNewMotorbikes: 'https://azulportal.webmotors.com.br/motos/estoque',
  urlNewMotorsbike: 'https://azulportal.webmotors.com.br/motos-novas/estoque',
  urlAutoPayment: 'https://autopago.webmotors.com.br',
  urlManageMyAdvertising: 'https://www.webmotors.com.br/garagem',
  urlBaseLogin: 'https://azulportal.webmotors.com.br',
  urlLogin: 'https://azulportal.webmotors.com.br/login?lkid=1364&r=',
  urlFazTudo: 'https://azulportal.webmotors.com.br/vender/faztudo',
  makeLogoCatalogo: 'https://www.webmotors.com.br/aplicativos/logos/',
  bannerPJUrl: 'https://image.webmotors.com.br/bannerseller/prd',
  userToken: 'webmotors.auth.token',
  sellerShieldImg: '/img/blindagem/',
  urlHotDeal: 'https://azulportal.webmotors.com.br/ofertas',
  urlHotDealImages:
    'https://image.webmotors.com.br/feiroes/imagens/prd/_portal/',
  urlHotDealRegulation:
    'https://s3.amazonaws.com/webmotorshub/Feirao/clientes/',
  urlMapSite: 'https://azulportal.webmotors.com.br/mapasite',
  urlConsumerProtectionCode:
    'https://cdn.webmotors.com.br/webmotors/Content/pdf/cdc.pdf?v:32673',
  signatureIds: [3880141],
  urlImgS3: 'https://www.webmotors.com.br/imagens/azul/portal/',
  urlApiShowRoom: 'https://azulportal.webmotors.com.br/api/',
  recommendationEnable: false,
  urlVistoriadoRB:
    'https://azulportal.webmotors.com.br/carros/estoque?Oportunidades=Vistoriado',
  cookieDomains: '.azulportal.webmotors.com.br',
}

const productionConfigurations = {
  ...defaultConfigurations,
  abTest: {
    ...defaultConfigurations.abTest,
    env: 'prd',
    c2c: {
      key: 'prd_adb_c2c_test',
    },
    megafair: {
      key: 'prd_adb_megafair_test',
    },
    lastSearches: {
      key: 'prd_ab_lastSearches_test',
    },
  },
  baseUrl: 'https://www.webmotors.com.br',
  cookieJwt: 'webmotors.jwt.token',
  urlApi: 'https://www.webmotors.com.br/api/',
  urlApiServer: 'https://wvusdhfp4l.execute-api.us-east-1.amazonaws.com/prd/',
  urlApiSantander: 'https://www.webmotors.com.br/pf/santanderservices/',
  urlApiSantanderServer: 'https://www.webmotors.com.br/pf/santanderservices/',
  urlApp: 'https://www.webmotors.com.br/',
  photosBikeUrlNew: 'https://image.webmotors.com.br/_fotos/AnuncioMotoNovos/',
  photosBikeUrlUsed: 'https://image.webmotors.com.br/_fotos/AnuncioMotoUsados/',
  photosUrlUsed: 'https://image.webmotors.com.br/_fotos/AnuncioUsados/',
  photosUrlNew: 'https://image.webmotors.com.br/_fotos/AnuncioNovos/',
  urlSellMyBike: 'https://www.webmotors.com.br/vender/moto',
  urlTableFipe: 'https://www.webmotors.com.br/tabela-fipe/',
  urlKM: 'https://www.webmotors.com.br/catalogo',
  urlFinancig: 'https://www.webmotors.com.br/financiamento/',
  urlNewsWM1: 'https://www.webmotors.com.br/wm1/',
  urlMyAdverts: 'https://www.webmotors.com.br/garagem',
  urlEditProfile: 'https://www.webmotors.com.br/garagem/perfil',
  urlGarageChat: 'https://www.webmotors.com.br/garagem/propostas',
  urlMyNegotiations: 'https://www.webmotors.com.br/garagem/propostas/compra',
  urlPosts: 'https://www.webmotors.com.br/garagem/propostas/compra',
  urlAdvancedSearch: 'https://www.webmotors.com.br/carros/estoque',
  urlUsedOrNewCars: 'https://www.webmotors.com.br/carros-usados/estoque',
  urlNewCars: 'https://www.webmotors.com.br/carros-novos/estoque',
  urlUsedOrNewMotorbikes: 'https://www.webmotors.com.br/motos/estoque',
  urlNewMotorsbike: 'https://www.webmotors.com.br/motos-novas/estoque',
  urlAutoPayment: 'https://autopago.webmotors.com.br',
  urlManageMyAdvertising: 'https://www.webmotors.com.br/garagem',
  urlBaseLogin: 'https://www.webmotors.com.br',
  urlLogin: 'https://www.webmotors.com.br/login?lkid=1364&r=',
  urlFazTudo: 'https://www.webmotors.com.br/vender/faztudo',
  makeLogoCatalogo: 'https://www.webmotors.com.br/aplicativos/logos/',
  bannerPJUrl: 'https://image.webmotors.com.br/bannerseller/prd',
  userToken: 'webmotors.auth.token',
  sellerShieldImg: '/img/blindagem/',
  urlHotDeal: 'https://www.webmotors.com.br/ofertas',
  urlHotDealImages:
    'https://image.webmotors.com.br/feiroes/imagens/prd/_portal/',
  urlHotDealRegulation:
    'https://s3.amazonaws.com/webmotorshub/Feirao/clientes/',
  urlMapSite: 'https://www.webmotors.com.br/mapasite',
  urlConsumerProtectionCode:
    'https://cdn.webmotors.com.br/webmotors/Content/pdf/cdc.pdf?v:32673',
  signatureIds: [3880141],
  urlImgS3: 'https://www.webmotors.com.br/imagens/prod/portal/',
  urlApiShowRoom: 'https://www.webmotors.com.br/api/',
  recommendationEnable: false,
  cookieDomains: '.webmotors.com.br',
}

const configurationsSets = {
  test: developmentConfigurations,
  development: developmentConfigurations,
  homologation: homologationConfigurations,
  blue: blueConfigurations,
  production: productionConfigurations,
}

const configurations = nodeEnv => configurationsSets[nodeEnv]

export const config = configurations(process.env.NODE_ENV)
