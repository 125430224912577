import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_DETAIL = 'buyer/detail/FETCH_DETAIL'
export const FETCH_DETAIL_FAILURE = 'buyer/detail/FETCH_DETAIL_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_DETAIL:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('fetched', true)
        .done()

    case FETCH_DETAIL_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('requested', action.requested)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

function createUrl(data) {
  let url = `${getApiUrl()}detail/${data.vehicleType}/${data.idAnuncio}`
  if (data.brand && data.model && data.year) {
    if (data.vehicleType == 'car' && data.version && data.doors) {
      url = `${getApiUrl()}detail/${data.vehicleType}/${data.brand}/${
        data.model
      }/${data.version}/${data.doors}/${data.year}/${data.idAnuncio}`
    } else if (data.vehicleType == 'bike' && data.cc) {
      url = `${getApiUrl()}detail/${data.vehicleType}/${data.brand}/${
        data.model
      }/${data.cc}/${data.year}/${data.idAnuncio}`
    }
  }

  return url
}

export function fetchDetail(data) {
  const url = createUrl(data)
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_DETAIL,
          payload: res.data,
          requested: data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_DETAIL_FAILURE,
          payload: err.stack,
          requested: data,
        })
      })
  }
}
