export function getVisitorData({ adobeOrg }) {
  return new Promise((resolve, reject) => {
    let visitor = {}
    let sdid = null
    let mcmid = null

    function handleVisitorData(ids) {
      const { MCMID = '' } = ids
      const { _supplementalDataIDCurrent: supplementalDataID = '' } = visitor
      sdid = supplementalDataID || visitor.getSupplementalDataID()
      mcmid = MCMID
      resolve({
        sdid: supplementalDataID || visitor.getSupplementalDataID(),
        mcmid: MCMID,
      })
    }

    if (window.Visitor) {
      visitor = window.Visitor.getInstance(adobeOrg)
      if (process.env.NODE_ENV !== 'production') {
        window.visitor = visitor
      }
      visitor.getVisitorValues(handleVisitorData)
      setTimeout(() => {
        if (!sdid || !mcmid) reject(new Error('Visitor is not defined'))
      }, 1000)
    } else {
      reject(new Error('Visitor is not defined'))
    }
  })
}
