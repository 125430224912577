import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86864 17L7.83164 15.555C7.94164 15.391 7.99964 15.197 7.99964 15V11C7.99964 8.794 9.79464 7 11.9996 7C14.2056 7 15.9996 8.794 15.9996 11V15C15.9996 15.197 16.0586 15.391 16.1676 15.555L17.1316 17H6.86864ZM11.9996 20C11.4486 20 10.9996 19.551 10.9996 19H12.9996C12.9996 19.551 12.5516 20 11.9996 20ZM19.8316 17.445L17.9996 14.697V11C17.9996 8.776 16.7816 6.836 14.9796 5.799C14.8746 4.24 13.5856 3 11.9996 3C10.4136 3 9.12564 4.24 9.02064 5.799C7.21864 6.836 5.99964 8.776 5.99964 11V14.697L4.16764 17.445C3.96364 17.752 3.94464 18.146 4.11864 18.472C4.29264 18.797 4.63164 19 4.99964 19H8.99964C8.99964 20.654 10.3456 22 11.9996 22C13.6546 22 14.9996 20.654 14.9996 19H18.9996C19.3686 19 19.7076 18.797 19.8816 18.472C20.0556 18.146 20.0366 17.752 19.8316 17.445Z"
      fill="#2E2D37"
    />
  </svg>
)
