import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { clearCookie, setCookie, getCookie } from 'utils/utils'
import { withCookieNotice } from '@/store/context/cookie-notice-context'

import { cookieCategoryIsActive } from '@/modern/utils/cookies'
import { COOKIES } from 'utils/constants'

import './Main.scss'

class Main extends Component {
  constructor(props) {
    super(props)

    const { cookieNoticeContext } = this.props
    const { cookies } = cookieNoticeContext

    this.cookiesState = {
      sentLeads: cookieCategoryIsActive(cookies, COOKIES.SENT_LEADS),
      visitor: cookieCategoryIsActive(cookies, COOKIES.VISITOR),
    }
  }

  componentDidMount() {
    this.handleVisitor()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.handleVisitor()
    }
  }

  handleVisitor() {
    if (this.cookiesState.visitor) {
      if (getCookie(COOKIES.VISITOR)) {
        setCookie(COOKIES.VISITOR, '1', false, 60)
      } else {
        setCookie(COOKIES.VISITOR, '1', false, 60)

        if (this.cookiesState.sentLeads) clearCookie(COOKIES.SENT_LEADS)
      }
    }
  }

  render() {
    return (
      <main
        role="main"
        className={`page${
          this.props.className ? ` ${this.props.className}` : null
        }`}
        style={this.props.style ? this.props.style : undefined}
      >
        {this.props.children}
      </main>
    )
  }
}

export default withCookieNotice(withRouter(Main))
