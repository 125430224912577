import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Button.scss'

export default class Button extends Component {
    constructor(props) {
      super(props);
		  this.handleOnClick = this.handleOnClick.bind(this);
    }

	handleOnClick() {
		this.props.onClick();
	}
	render() {
    let CustomButton = 'button';
    const { linkTo,  href, noAction, className, onClick, id, name, value, type, target, disabled, children} = this.props;

    if(linkTo){
      CustomButton = Link;
    }else if(href){
      CustomButton = 'a';
    }else if(noAction){
      CustomButton = 'span';
    }

		return (
			<CustomButton
				className={'Button' + (className ? ' ' + className : '')}
				onClick={onClick ? onClick : undefined}
				href={ href && !linkTo ? href : undefined }
				to={ linkTo ? linkTo : undefined }
				id={id ? id : undefined}
				name={name ? name : undefined}
				value={value ? value: undefined}
				type = {type ? type : undefined}
				target = {target ? target : undefined}
				rel="noreferrer"
        data-qa={this.props.dataQA ? this.props.dataQA : null }
        data-testid={this.props.dataTestId}
        disabled={disabled ? 'disabled' : undefined}
			>
				{children}
			</CustomButton>
		)
	}
}
