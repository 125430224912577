import { onClientSide } from '@/utils/utils'
import UrlParse from 'url-parse'

const defaultFeatureFlags = {
  FF_AutomaticFairs: true,
  FF_OPTIONS: {},
}

const developmentFeatureFlags = {
  ...defaultFeatureFlags,
  FF_OPTIONS: {
    ...defaultFeatureFlags.FF_OPTIONS,
  },
}

const homologationFeatureFlags = {
  ...defaultFeatureFlags,
  FF_OPTIONS: {
    ...defaultFeatureFlags.FF_OPTIONS,
  },
}

const blueFeatureFlags = {
  ...defaultFeatureFlags,
  FF_OPTIONS: {
    ...defaultFeatureFlags.FF_OPTIONS,
  },
}

const productionFeatureFlags = {
  ...defaultFeatureFlags,
  FF_AutomaticFairs: true,
  FF_OPTIONS: {
    ...defaultFeatureFlags.FF_OPTIONS,
  },
}

const featureFlagConfigurationsSets = {
  test: developmentFeatureFlags,
  development: developmentFeatureFlags,
  homologation: homologationFeatureFlags,
  blue: blueFeatureFlags,
  production: productionFeatureFlags,
}

const getFeatureFlagConfigurationsSet = nodeEnv =>
  featureFlagConfigurationsSets[nodeEnv]

export const featureFlagConstants = getFeatureFlagConfigurationsSet(
  process.env.NODE_ENV
)

export const getFeatureFlag = flag => featureFlagConstants[flag]

const LOCAL_STORAGE_KEY = 'FF'
const QUERY_PARAM_KEY = 'ff'

const getFlagsFromUrl = flag => {
  if (!onClientSide()) return false

  const url = new UrlParse(window.location.href, true)
  const queryParams = url.query
  return (
    QUERY_PARAM_KEY in queryParams &&
    queryParams[QUERY_PARAM_KEY].indexOf(flag) >= 0
  )
}

const getFlagsFromLocalStorage = () => {
  let lStorage = {}
  try {
    lStorage = localStorage.getItem(LOCAL_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
      : {}
  } catch (e) {
    lStorage = {}
  }

  return lStorage
}

const getFlagState = flag => {
  return (
    getFeatureFlag(flag) === true ||
    getFlagsFromUrl(flag) ||
    getFlagsFromLocalStorage()[flag] ||
    false
  )
}

const getFeatureOptions = feature => {
  return featureFlagConstants.FF_OPTIONS[feature]
}

const featureFlags = {
  automaticFairs: getFlagState('FF_AutomaticFairs'),
}

export { featureFlags, getFlagState, getFeatureOptions }
