import { getLoggedUserProperties, onClientSide } from '@/utils/utils'
import { COOKIES } from '@/utils/constants'
import { getEncryptedCookie } from '@/modern/utils/cookies'

export function dispatchInsiderTrack({ event = 'events', properties = [] }) {
  if (!onClientSide()) return
  if (!window.Insider) {
    console.error('Insider SDK not found!')
    return
  }
  window.Insider.track(event, properties)
}

export function createInsiderTrackObject({
  properties = {},
  cookieConfig = {},
}) {
  if (!onClientSide()) return
  const userData = getLoggedUserProperties({ formattedPhoneNumber: false })
  const { Principal: { newsletter = false } = {} } =
    getEncryptedCookie(COOKIES.FORM_DATA) || {}

  const { showCookieNotice = null, cookies = {} } = cookieConfig
  let gdprOptin = null
  if (showCookieNotice === false)
    gdprOptin = Object.values(cookies).some(value => value === true)

  const user = {
    ...(userData && {
      name: userData.name || '',
      email: userData.email || '',
      phone_number: userData.phoneNumber || '',
    }),
    ...(showCookieNotice === false &&
      gdprOptin !== null && { gdpr_optin: gdprOptin }),
    custom: { newsletter },
  }

  window.insider_object = {
    ...properties,
    user,
  }
}

export function insertInsiderTag({ id = 'insiderTag' } = {}) {
  let script = document.getElementById(id)
  if (!script) {
    script = document.createElement('script')
    script.async = true
    script.id = id
    script.src = '//webmotors.api.useinsider.com/ins.js?id=10005945'

    const node = document.getElementsByTagName('script')[0]
    node.parentNode.insertBefore(script, node)
  }

  return () => {
    document.head.removeChild(script)
  }
}
