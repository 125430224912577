import React, { Component } from 'react'

import './WhiteBox.scss';

export default class WhiteBox extends Component {
	render() {
		return (
			<div id="WhiteBox" className={"WhiteBox " + (this.props.className ? this.props.className : '')}>
				{ this.props.children ? this.props.children : '' }
			</div>
		)
	}
}
