import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M9.8835 21H13.5061V11.9215H16.0363L16.3062 8.8828H13.5124C13.5124 8.8828 13.5124 7.74642 13.5124 7.14998C13.5124 6.43425 13.6568 6.15173 14.3474 6.15173C14.9062 6.15173 16.3062 6.15173 16.3062 6.15173V3C16.3062 3 14.2407 3 13.7949 3C11.1015 3 9.8835 4.18661 9.8835 6.45937C9.8835 8.43704 9.8835 8.8828 9.8835 8.8828H8V11.9592H9.8835V21Z"
      fill="#2E2D37"
    />
  </svg>
)
