import { config } from 'config/config.js'
import { featureFlags } from 'config/featureFlags'

const isBlackFairDateActive = () => {
  let checkBlackFairDate = true
  if(typeof window !== 'undefined' && 
    typeof window.FairsDateChecker !== 'undefined' && 
    typeof window.FairsDateChecker.checkBlackFair !== 'undefined'){
    checkBlackFairDate = window.FairsDateChecker.checkBlackFair()
  }
  return checkBlackFairDate
}

const isMegaFairDateActive = () => {
  let checkMegaFairDate = true
  if(typeof window !== 'undefined' && 
    typeof window.FairsDateChecker !== 'undefined' && 
    typeof window.FairsDateChecker.checkMegaFair !== 'undefined'){
    checkMegaFairDate = window.FairsDateChecker.checkMegaFair()
  }
  return checkMegaFairDate
}

export const isBlackFairActive = hasBlackFair =>
  (isBlackFairDateActive() && featureFlags.automaticFairs && hasBlackFair) || config.blackFeiraoActive

  export const isMegaFairActive = hasMegaFair => 
  (isMegaFairDateActive() && featureFlags.automaticFairs && hasMegaFair) || config.megaFeiraoActive


export const hasSpecialFairActive = (hasMegaFair, hasBlackFair) =>
  isMegaFairActive(hasMegaFair) || isBlackFairActive(hasBlackFair)

export const getBlackFairClassName = hasBlackFair =>
  isBlackFairActive(hasBlackFair) ? 'theme--blackfair' : null

export const getBlackFairId = (hasBlackFair, blackFair) => {
  let blackFairId = null

  if (isBlackFairActive(hasBlackFair) && featureFlags.automaticFairs) {
    blackFairId = blackFair.id
  } else if (isBlackFairActive(hasBlackFair)) {
    blackFairId = config.blackFeiraoId
  }

  return blackFairId
}

export const getMegaFairId = (hasMegaFair, megaFair) => {
  if (!megaFair || !megaFair.length) {
    return null
  }

  const checkFair = megaFair.find(item => item.slug === 'megafeirao')

  if(isMegaFairActive(hasMegaFair) && featureFlags.automaticFairs && checkFair) {
    return checkFair.id
  }

  if (isMegaFairActive(hasMegaFair)) {
    return config.megaFeiraoId
  }

  return null
}

export const isAdInFair = (adFairs, fairId) =>
  adFairs.some(fair => +fair.Id === fairId)
