import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0203 7.04139H17.0003C15.5743 7.04139 14.2143 7.65139 13.2663 8.71539C13.1783 8.81339 13.0953 8.91439 13.0163 9.01939C13.0153 9.02039 13.0153 9.02039 13.0153 9.02139C13.0153 9.02139 13.0153 9.02139 13.0143 9.02139L9.4013 13.7984C9.4013 13.7984 9.4013 13.7994 9.4003 13.7994L9.3993 13.8004C8.8253 14.5634 7.9523 14.9994 7.0003 14.9994H6.9873C6.1863 14.9964 5.4343 14.6814 4.8693 14.1124C4.3053 13.5434 3.9963 12.7894 3.9993 11.9874C4.0063 10.3374 5.3513 8.99939 6.9993 8.99939H7.0123C7.9243 9.00339 8.7743 9.41539 9.3453 10.1294C9.6903 10.5614 10.3183 10.6294 10.7503 10.2864C11.1823 9.94039 11.2523 9.31239 10.9073 8.88039C9.9573 7.69139 8.5413 7.00639 7.0203 6.99939H6.9993C4.2523 6.99939 2.0113 9.22939 1.9993 11.9784C1.9943 13.3144 2.5093 14.5724 3.4493 15.5204C4.3903 16.4694 5.6433 16.9944 6.9793 16.9994H6.9993C8.5623 16.9994 10.0543 16.2554 10.9953 15.0054L10.9973 15.0034C10.9973 15.0034 10.9983 15.0034 10.9983 15.0024L14.6093 10.2294C14.6093 10.2284 14.6093 10.2284 14.6103 10.2274C14.6573 10.1664 14.7063 10.1064 14.7583 10.0474C15.3283 9.40739 16.1443 9.04139 16.9993 9.04139H17.0123C18.6663 9.04839 20.0063 10.3994 19.9993 12.0544C19.9963 12.8554 19.6813 13.6074 19.1123 14.1714C18.5463 14.7324 17.7963 15.0414 16.9993 15.0414H16.9863C16.0783 15.0384 15.2303 14.6284 14.6593 13.9184C14.3133 13.4884 13.6843 13.4194 13.2533 13.7664C12.8233 14.1114 12.7543 14.7414 13.1013 15.1714C14.0513 16.3544 15.4643 17.0354 16.9783 17.0414H17.0003C18.3283 17.0414 19.5773 16.5274 20.5203 15.5914C21.4683 14.6514 21.9943 13.3974 21.9993 12.0624C22.0113 9.30539 19.7773 7.05339 17.0203 7.04139Z"
      fill="#2E2D37"
    />
  </svg>
)
