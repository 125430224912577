import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getFairs } from '@/ducks/fairs'
import CookieNotice from '@/modern/components/CookieNotice'

import Main from '@/container/Main'
import { useTargetApi as targetApi } from '@/modern/hooks/useTargetApi'
import { config } from 'config/config'
import Routes from './routers'

export default () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const { pandora, env } = config.abTest
    targetApi({
      mboxes: [
        {
          name: pandora.mboxName,
          index: 1,
          env,
        },
      ],
    })
    dispatch(getFairs({ type: 'all' }))
  }, [dispatch])

  return (
    <Main>
      <CookieNotice />
      <Routes />
    </Main>
  )
}
