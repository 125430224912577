import { CHANNEL } from '@/utils/constants'
import { getApiUrl } from '@/utils/utils'
import axios from 'axios'
import Immutable from 'immutee'

export const FETCH_RECOMMENDED_VEHICLES =
  'buyer/recommendedVehicles/FETCH_RECOMMENDED_VEHICLES'
export const FETCH_RECOMMENDED_VEHICLES_SUCCESS =
  'buyer/recommendedVehicles/FETCH_RECOMMENDED_VEHICLES_SUCCESS'
export const FETCH_RECOMMENDED_VEHICLES_FAILURE =
  'buyer/recommendedVehicles/FETCH_RECOMMENDED_VEHICLES_FAILURE'
export const FETCH_RECOMMENDED_VEHICLES_LOADING =
  'buyer/recommendedVehicles/FETCH_RECOMMENDED_VEHICLES_LOADING'

const initialState = {
  fetched: false,
  isLoading: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_RECOMMENDED_VEHICLES:
      return immutable.set('isLoading', true).done()

    case FETCH_RECOMMENDED_VEHICLES_SUCCESS:
      return immutable
        .set('result', action.payload)
        .set('isLoading', false)
        .set('fetched', true)
        .done()

    case FETCH_RECOMMENDED_VEHICLES_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('isLoading', false)
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchRecommendedVehiclesNew({
  idAdvertiser,
  idCampaign,
  displayPerPage,
}) {
  return dispatch => {
    const url = `${getApiUrl()}recommendation`
    const data = {
      IdAdvertiser: idAdvertiser,
      IdCampaing: idCampaign,
      DisplayPerPage: displayPerPage,
      IdChannel: CHANNEL.WEBMOTORS,
    }

    dispatch({ type: FETCH_RECOMMENDED_VEHICLES })

    return axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        dispatch({
          type: FETCH_RECOMMENDED_VEHICLES_SUCCESS,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_RECOMMENDED_VEHICLES_FAILURE,
          payload: err.stack,
        })
      })
  }
}
