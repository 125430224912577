import styled from 'styled-components'

export const Container = styled.button`
  border: 0 none;
  background: transparent;
  transition: all 0.15s ease-in;

  ${({ disabled }) => disabled && 'opacity: 0.3'}

  ${({ disabled }) =>
    !disabled &&
    `
    &:hover,
    &:focus {
      transform: scale(1.05);
    }
    &:active {
      transition: all 0.10s ease-out;
      transform: scale(1);
    }
  `}
`
export const Inner = styled.span`
  ${({ variation, size, outline, theme }) => {
    const buttonSpacing = size === 'small' ? '11px 16px' : '16px 20px'
    let textColor = theme.colors.white
    let borderColor = theme.colors.primary1
    let bgColor = theme.colors.primary1

    // Primary Outlined
    if (outline && variation === 'primary') {
      textColor = theme.colors.primary1
      borderColor = theme.colors.primary1
      bgColor = theme.colors.white
    }

    // Secondary
    if (variation === 'secondary') {
      borderColor = theme.colors.gray1
      bgColor = theme.colors.gray1

      // Secondary outlined
      if (outline) {
        textColor = theme.colors.gray1
        bgColor = theme.colors.white
      }
    }

    // Ghost
    if (variation === 'ghost') {
      textColor = theme.colors.primary1
      borderColor = 'transparent'
      bgColor = 'transparent'
    }

    // Cancel
    if (variation === 'cancel') {
      textColor = theme.colors.gray2
      borderColor = theme.colors.gray2
      bgColor = theme.colors.white
    }

    return `
      align-items: center;
      background: ${bgColor};
      border: 1px solid ${borderColor};
      border-radius: 8px;
      color: ${textColor};
      cursor: pointer;
      display: inline-flex;
      font-size: 16px;
      font-family: ${theme.fonts.poppins};
      font-weight: bold;
      padding: ${buttonSpacing};
    `
  }};
`
