import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0058 10.947C19.457 10.947 19.0116 11.395 19.0116 11.947C19.0116 15.836 15.8659 19 11.9995 19C8.13405 19 4.9884 15.836 4.9884 11.947C4.9884 8.058 8.13405 4.894 11.9995 4.894C13.0504 4.894 14.0754 5.134 15.007 5.579H13.7791C13.2303 5.579 12.7849 6.027 12.7849 6.579C12.7849 7.131 13.2303 7.579 13.7791 7.579H17.3374C17.8862 7.579 18.3316 7.131 18.3316 6.579V3C18.3316 2.447 17.8862 2 17.3374 2C16.7886 2 16.3432 2.447 16.3432 3V4.028C15.0219 3.294 13.5336 2.894 11.9995 2.894C7.03745 2.894 3 6.955 3 11.947C3 16.939 7.03745 21 11.9995 21C16.9626 21 21 16.939 21 11.947C21 11.395 20.5546 10.947 20.0058 10.947Z"
      fill="#2E2D37"
    />
  </svg>
)
