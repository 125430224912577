import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 19H17V11H7V19ZM9 7.09407C9 5.39651 10.346 4.01506 12 4.01506C13.654 4.01506 15 5.39651 15 7.09407V8.99998H9V7.09407ZM17 9.02205V7.03782C17 4.2599 14.757 1.99988 12 1.99988C9.243 1.99988 7 4.2599 7 7.03782V9.02205H6C5.448 9.02205 5 9.44379 5 9.99998V19.9924C5 20.5486 5.448 21 6 21H18C18.552 21 19 20.5486 19 19.9924V9.99998C19 9.44379 18.552 9.02205 18 9.02205H17ZM12 16.9696C13.104 16.9696 14 16.0668 14 14.9544C14 13.8421 13.104 12.9393 12 12.9393C10.896 12.9393 10 13.8421 10 14.9544C10 16.0668 10.896 16.9696 12 16.9696Z"
      fill="#2E2D37"
    />
  </svg>
)
