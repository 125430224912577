import React from 'react'
import Switch from '@/modern/components/Switch'
import Icon from '@/modern/components/Icon'
import { clearCookie } from 'utils/utils'
import { getCookiesInCategory } from '@/modern/utils/cookies'
import switchesData from './switchesData'
import {
  useCookieNoticeState,
  useCookieNoticeDispatch,
} from '../../../../store/context/cookie-notice-context'
import * as S from './style'
import { Text } from '../style'

const Switches = () => {
  const { cookies } = useCookieNoticeState()
  const { toggleCategory } = useCookieNoticeDispatch()

  const clearCookies = cookiesArray => {
    cookiesArray.forEach(cookie => {
      clearCookie(cookie)
    })
  }

  React.useEffect(() => {
    Object.keys(cookies).forEach(category => {
      if (!cookies[category]) {
        const cookiesArray = getCookiesInCategory(category)
        clearCookies(cookiesArray)
      }
    })
  }, [cookies])

  return (
    <S.Switches>
      <S.Switch>
        <Icon name="Check_1" fill="#2E2D37" size="40" />
        <S.Info>
          <S.Title>Essenciais</S.Title>
          <Text>
            São cookies indispensáveis pra você acelerar por aqui e manter suas
            preferências de navegação. Sem eles, nada de Webmotors. Triste, né?
          </Text>
        </S.Info>
      </S.Switch>
      {switchesData.map(({ title, text, cookieCategory }) => (
        <S.Switch key={cookieCategory}>
          <Switch
            isOn={cookies[cookieCategory]}
            onToggle={() => toggleCategory(cookieCategory)}
            name={title}
          />
          <S.Info>
            <S.Title>{title}</S.Title>
            <Text>{text}</Text>
          </S.Info>
        </S.Switch>
      ))}
    </S.Switches>
  )
}

export default Switches
