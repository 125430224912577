import { hasPandora } from '@/utils/utils'
import { api } from './api'

export default {
  all(vehicleType) {
    const pandora = hasPandora()
    const requestConfig = {
      params: {
        actualPage: 1,
        displayPerPage: 24,
        order: 1,
        showMenu: true,
        showCount: true,
        showBreadCrumb: true,
        testAB: false,
        returnUrl: false,
        url: window.location.href,
        pandora,
      },
    }
    return api.get(`search/${vehicleType}`, requestConfig)
  },
}
