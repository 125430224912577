import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9249 2C7.55886 2 3.17215 6.188 2.87796 11.443L1.71932 10.293C1.32639 9.902 0.688635 9.902 0.294697 10.293C-0.0982325 10.684 -0.0982325 11.316 0.294697 11.707L3.14495 14.536C3.34142 14.731 3.59934 14.829 3.85726 14.829C4.11518 14.829 4.37311 14.731 4.56957 14.536L7.41983 11.707C7.81276 11.316 7.81276 10.684 7.41983 10.293C7.02589 9.902 6.38813 9.902 5.9952 10.293L4.89601 11.383C5.21539 7.26 8.6903 4 12.9249 4C17.369 4 20.985 7.589 20.985 12C20.985 16.411 17.369 20 12.9249 20C10.5713 20 8.34069 18.982 6.80524 17.207C6.44254 16.787 5.80579 16.738 5.38465 17.099C4.9625 17.459 4.91313 18.09 5.27584 18.509C7.19515 20.728 9.98294 22 12.9249 22C18.4803 22 23 17.514 23 12C23 6.486 18.4803 2 12.9249 2ZM13 7C12.448 7 12 7.448 12 8V12C12 12.13 12.026 12.26 12.077 12.382C12.128 12.504 12.201 12.615 12.293 12.708L15.2928 15.707C15.4878 15.902 15.7438 16 15.9998 16C16.2558 16 16.5118 15.902 16.7068 15.707C17.0977 15.316 17.0977 14.684 16.7068 14.293L13.9999 11.586V8C13.9999 7.448 13.5519 7 13 7Z"
      fill="#2E2D37"
    />
  </svg>
)
