import React from 'react'
import { useSelector } from 'react-redux'
import PhotoSlider from 'components/PhotoSlider'
import { config } from 'config/config'
import GoodDealSeal from 'components/GoodDealSeal'
import TagBlackFeirao from 'components/TagBlackFeirao'
import { isBlackFairActive as isBlackFairActiveHelper } from '@/modern/utils/fairs'

import './CardVehicle.scss'

export default props => {
  const { hasBlackFair } = useSelector(state => state.fairs)

  function showCustomTag() {
    if (config.showTagOffer) {
      const isBlackFairActive = isBlackFairActiveHelper(hasBlackFair)

      if (isBlackFairActive) {
        return <TagBlackFeirao />
      }

      return (
        <strong
          id="TagVehicleFeirao"
          className="CardVehicle__seal CardVehicle__seal--feirao"
        >
          Oferta destaque
        </strong>
      )
    }
    return null
  }

  return (
    <div id={props.id ? props.id : undefined} className="CardVehicle">
      <figure>
        <PhotoSlider
          linkCarousel={props.data.linkCard}
          listPhotos={props.data.listPhotos}
          tag={props.data.tag}
          className={'CardVehicle__thumb'}
          alt='Foto do veículo'
          width={176}
          height={136}
        ></PhotoSlider>
      </figure>
      <a
        rel="noreferrer nofollow"
        href={props.data.linkCard}
        onClick={() => {
          props.onClick && props.onClick()
        }}
        target="_blank"
        id={`IdDoAnuncioFeirao_${props.data.uniqueId}`}
        className="CardVehicle__link"
      >
        <div className="CardVehicle__details">
          <div
            className={
              'CardVehicle__details__header' +
              (!config.showTagOffer
                ? ' CardVehicle__details__header--noSeal'
                : '')
            }
          >
            {showCustomTag()}
            {props.data.goodDeal ? <GoodDealSeal /> : ''}
            <h2 className="CardVehicle__details__header__title">
              {props.data.title}
            </h2>
            <h3 className="CardVehicle__details__header__description">
              {props.data.description}
            </h3>
          </div>
          <div className="CardVehicle__details__content">
            <strong
              className={`${
                props.data.price.length > 20
                  ? 'CardVehicle__details__content__price-resize'
                  : 'CardVehicle__details__content__price'
              }`}
            >
              {props.data.price}
            </strong>
          </div>
          <div className="CardVehicle__details__specification">
            <span className="CardVehicle__details__specification__year">
              {props.data.year}
            </span>
            <span
              className={
                'CardVehicle__details__specification__km' +
                (props.data.odometer == '0'
                  ? ' CardVehicle__details__specification__km--zero'
                  : '')
              }
            >
              {props.data.km}
            </span>
          </div>
          <div className="CardVehicle__details__footer">
            <span className="CardVehicle__details__footer__state">
              {props.data.city}
            </span>
          </div>
        </div>
      </a>
    </div>
  )
}
