import { useDispatch, useSelector } from 'react-redux'

export default function useModal(key) {
  const dispatch = useDispatch()
  const modal = useSelector(state => state.modal[key])

  const openModal = () => {
    dispatch({
      type: '@modal/OPEN',
      key,
    })
  }

  const closeModal = () => {
    dispatch({
      type: '@modal/CLOSE',
      key,
    })
  }

  return {
    openModal,
    closeModal,
    modal,
  }
}
