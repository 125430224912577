import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 9C19.448 9 19 9.448 19 10V18H5V10C5 9.448 4.552 9 4 9C3.448 9 3 9.448 3 10V18C3 19.103 3.897 20 5 20H19C20.103 20 21 19.103 21 18V10C21 9.448 20.552 9 20 9Z"
      fill="#2E2D37"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 6H19V5C19 4.448 18.552 4 18 4H16C15.448 4 15 4.448 15 5V6H9V5C9 4.448 8.552 4 8 4H6C5.448 4 5 4.448 5 5V6H3C2.448 6 2 6.448 2 7C2 7.552 2.448 8 3 8H21C21.552 8 22 7.552 22 7C22 6.448 21.552 6 21 6Z"
      fill="#2E2D37"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0546 9.16831C12.5936 8.86131 11.9736 8.98631 11.6676 9.44531L9.66756 12.4453C9.46356 12.7523 9.44456 13.1473 9.61756 13.4723C9.79256 13.7973 10.1316 14.0003 10.4996 14.0003H11.6316L10.6676 15.4453C10.3616 15.9053 10.4856 16.5263 10.9456 16.8323C11.1156 16.9463 11.3086 17.0003 11.4986 17.0003C11.8226 17.0003 12.1386 16.8443 12.3316 16.5553L14.3316 13.5553C14.5366 13.2483 14.5556 12.8543 14.3816 12.5283C14.2076 12.2033 13.8686 12.0003 13.4996 12.0003H12.3686L13.3316 10.5553C13.6376 10.0953 13.5136 9.47431 13.0546 9.16831Z"
      fill="#2E2D37"
    />
  </svg>
)
