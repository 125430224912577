import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8.67112L15.414 6.08512L16.5 4.99912L19.086 7.58512L18 8.67112ZM8.50697 18.1641L5.27497 18.8101L5.92097 15.5781L14 7.49912L16.586 10.0851L8.50697 18.1641ZM20.5 6.17112L17.914 3.58512C17.135 2.80512 15.865 2.80512 15.086 3.58512L4.29297 14.3781C4.15297 14.5181 4.05797 14.6951 4.01997 14.8891L3.01997 19.8891C2.95397 20.2171 3.05697 20.5561 3.29297 20.7921C3.48197 20.9821 3.73697 21.0851 3.99997 21.0851C4.06497 21.0851 4.13097 21.0791 4.19597 21.0661L9.19597 20.0661C9.38997 20.0271 9.56697 19.9321 9.70697 19.7921L20.5 8.99912C21.28 8.21912 21.28 6.95112 20.5 6.17112Z"
      fill="#2E2D37"
    />
  </svg>
)
