import { useSelector, useDispatch } from 'react-redux'
import useCookies from '@/modern/hooks/useCookies'

export default () => {
    const userIsAuth = useSelector(state => state.userIsAuth)
    const dispatch = useDispatch()
    const { clearUserInCookie} = useCookies()

    const setUserIsAuth = value => {
        dispatch({type: '@userIsAuth/SET', userIsAuth: value})
    }

    const logout = () => {
        clearUserInCookie()
        setUserIsAuth(false)
        dispatch({ type: '@favorites/CLEAR_LIST' })
        dispatch({ type: '@favoritesCounter/CLEAR_LIST' })
    }

    return {
        userIsAuth,
        setUserIsAuth,
        logout
    }
}
