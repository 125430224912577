import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.707 12.293C8.316 11.902 7.684 11.902 7.293 12.293L7 12.586L6.707 12.293C6.316 11.902 5.684 11.902 5.293 12.293C4.902 12.684 4.902 13.316 5.293 13.707L5.586 14L5.293 14.293C4.902 14.684 4.902 15.316 5.293 15.707C5.488 15.902 5.744 16 6 16C6.256 16 6.512 15.902 6.707 15.707L7 15.414L7.293 15.707C7.488 15.902 7.744 16 8 16C8.256 16 8.512 15.902 8.707 15.707C9.098 15.316 9.098 14.684 8.707 14.293L8.414 14L8.707 13.707C9.098 13.316 9.098 12.684 8.707 12.293ZM20 16C20 16.551 19.551 17 19 17H5C4.449 17 4 16.551 4 16V8C4 7.449 4.449 7 5 7H19C19.551 7 20 7.449 20 8V16ZM13.707 12.293C13.316 11.902 12.684 11.902 12.293 12.293L12 12.586L11.707 12.293C11.316 11.902 10.684 11.902 10.293 12.293C9.902 12.684 9.902 13.316 10.293 13.707L10.586 14L10.293 14.293C9.902 14.684 9.902 15.316 10.293 15.707C10.488 15.902 10.744 16 11 16C11.256 16 11.512 15.902 11.707 15.707L12 15.414L12.293 15.707C12.488 15.902 12.744 16 13 16C13.256 16 13.512 15.902 13.707 15.707C14.098 15.316 14.098 14.684 13.707 14.293L13.414 14L13.707 13.707C14.098 13.316 14.098 12.684 13.707 12.293ZM19 5H5C3.346 5 2 6.346 2 8V16C2 17.654 3.346 19 5 19H19C20.654 19 22 17.654 22 16V8C22 6.346 20.654 5 19 5ZM18.707 12.293C18.316 11.902 17.684 11.902 17.293 12.293L17 12.586L16.707 12.293C16.316 11.902 15.684 11.902 15.293 12.293C14.902 12.684 14.902 13.316 15.293 13.707L15.586 14L15.293 14.293C14.902 14.684 14.902 15.316 15.293 15.707C15.488 15.902 15.744 16 16 16C16.256 16 16.512 15.902 16.707 15.707L17 15.414L17.293 15.707C17.488 15.902 17.744 16 18 16C18.256 16 18.512 15.902 18.707 15.707C19.098 15.316 19.098 14.684 18.707 14.293L18.414 14L18.707 13.707C19.098 13.316 19.098 12.684 18.707 12.293ZM8 10H16C16.552 10 17 9.552 17 9C17 8.448 16.552 8 16 8H8C7.448 8 7 8.448 7 9C7 9.552 7.448 10 8 10Z"
      fill="#2E2D37"
    />
  </svg>
)
