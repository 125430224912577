import generalKeysBuilder from '@/modern/utils/trackerObjectsBuilder'

const useTracker = () => {
  const dispatchEvent = (eventLabel, eventObject) => {
    document.dispatchEvent(new CustomEvent(eventLabel, { detail: eventObject }))
    console.log('Evento disparado:', eventLabel)
    console.log('Objeto disparado', eventObject)
  }

  const buildEventObject = (ad, neededKeys = undefined) => {
    const obj = generalKeysBuilder(ad, neededKeys)
    return obj
  }

  return [dispatchEvent, buildEventObject]
}

export default useTracker
