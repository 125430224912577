import styled from 'styled-components'

export const FavoritesModal = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    width: 460px;
    svg{
        margin: 0 0 0 -15px;
    }
`

export const Heading = styled.div`
    font-size: 32px;
    font-weight: 700;
    margin-top: 12px;
    color: #2E2E37;
`
export const Text = styled.div`
    margin-top: 40px;
    font-size: 16px;
    line-height: 1.5em;
    color: #2E2E37;
`

export const ButtonsContainer = styled.div`
    display: flex;
    margin-top: 60px;
    button,
    a{
        height: 40px;
        text-transform: none;
        font-size: 12px;
        font-weight: 700;
        width: 130px;
        letter-spacing: unset;
        & + button,
        & + a{
            background-color: white;
            color: #f3123c;
            margin-left: 8px;
        }
    }
`

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const IconButton = styled.button`
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    position: relative;
    bottom: 20px;
`
