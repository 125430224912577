import React from 'react'

const useToggle = (initialOn = false) => {
  const [on, setOn] = React.useState(!!initialOn)
  const toggle = () => setOn(prevOn => !prevOn)
  const turnOn = () => setOn(true)
  const turnOff = () => setOn(false)

  return { on, toggle, turnOn, turnOff }
}

export default useToggle
