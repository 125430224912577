import styled from 'styled-components'

export const IconContainer = styled.div`
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.15em;
    font-size: ${props => `${props.size}px`};
    & > svg {
        width: ${props => `${props.size}px`};
        height: ${props => `${props.size}px`};
        & > path{
            fill: ${props => props.fill ? props.fill : 'auto'};
        }
    }
`
