import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 19C11.897 19 11 18.103 11 17C11 15.897 11.897 15 13 15C14.103 15 15 15.897 15 17C15 18.103 14.103 19 13 19ZM21 16H16.858C16.411 14.28 14.858 13 13 13C11.142 13 9.589 14.28 9.142 16H4V14H6C6.552 14 7 13.552 7 13C7 12.448 6.552 12 6 12H4V10.856L6.311 10.495C8.217 10.197 10.022 9.242 11.587 7.678C12.678 6.611 14.241 6 15.876 6H21C21.552 6 22 5.552 22 5C22 4.448 21.552 4 21 4H15.876C13.691 4 11.672 4.798 10.189 6.247C10.187 6.25 10.121 6.315 10.118 6.318C8.91 7.526 7.487 8.287 6.002 8.519L2.846 9.012C2.359 9.088 2 9.507 2 10V17C2 17.552 2.448 18 3 18H9.142C9.589 19.72 11.142 21 13 21C14.858 21 16.411 19.72 16.858 18H21C21.552 18 22 17.552 22 17C22 16.448 21.552 16 21 16Z"
      fill="#2E2D37"
    />
  </svg>
)
