import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 18.9995H18V11.9995C18 11.4475 17.552 10.9995 17 10.9995H7C6.448 10.9995 6 11.4475 6 11.9995V18.9995H5V9.5535L12 5.1785L19 9.5535V18.9995ZM8 18.9995H16V16.9995H8V18.9995ZM8 14.9995H16V12.9995H8V14.9995ZM12.53 3.1515C12.206 2.9495 11.794 2.9495 11.47 3.1515L3.47 8.1515C3.178 8.3345 3 8.6545 3 8.9995V19.9995C3 20.5515 3.448 20.9995 4 20.9995H20C20.552 20.9995 21 20.5515 21 19.9995V8.9995C21 8.6545 20.822 8.3345 20.53 8.1515L12.53 3.1515Z"
      fill="#2E2D37"
    />
  </svg>
)
