import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3642 12.293L17.5352 8.464C17.1442 8.074 16.5112 8.074 16.1212 8.464C15.7302 8.855 15.7302 9.488 16.1212 9.878L18.2432 12H12.9982C12.4472 12 12.0002 11.552 12.0002 11.001V4C12.0002 3.448 11.5522 3 11.0002 3C10.4472 3 10.0002 3.448 10.0002 4V11.001C10.0002 12.655 11.3452 14 12.9982 14H18.2432L16.1212 16.122C15.7302 16.512 15.7302 17.145 16.1212 17.536C16.3162 17.731 16.5722 17.829 16.8282 17.829C17.0842 17.829 17.3402 17.731 17.5352 17.536L21.3642 13.707C21.5512 13.52 21.6572 13.265 21.6572 13C21.6572 12.735 21.5512 12.48 21.3642 12.293Z"
      fill="#2E2D37"
    />
  </svg>
)
