import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 18H5C4.449 18 4 17.551 4 17V11H20V17C20 17.551 19.551 18 19 18ZM5 6H19C19.551 6 20 6.449 20 7V8H4V7C4 6.449 4.449 6 5 6ZM19 4H5C3.346 4 2 5.346 2 7V17C2 18.654 3.346 20 5 20H19C20.654 20 22 18.654 22 17V7C22 5.346 20.654 4 19 4Z"
      fill="#2E2D37"
    />
  </svg>
)
