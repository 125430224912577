import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { fetchCategories } from 'ducks/categories'
import { fetchBestSellers } from 'ducks/bestSellers'
import { fetchServerSide404 } from 'ducks/serverSide404'
import { fetchDeclareSeo } from 'ducks/declareSeo'
import { config } from 'config/config'
import Main from 'components/Main'
import Page from '@/modern/components/Page'
import BestSeller from 'components/BestSeller'
import StatusHeader from 'components/Header/status'
import HomeCategories from 'container/Home/HomeCategories'
import HomeProducts from 'container/Home/HomeProducts'
import PlaceHolder from 'components/PlaceHolder'
import RecommendedVehicles from 'components/RecommendedVehicles'

import { withCookieNotice } from '@/store/context/cookie-notice-context'
import { cookieCategoryIsActive } from '@/modern/utils/cookies'

import { productsConfig } from 'config/config.products'
import { VEHICLE_TYPE, COOKIES, ORIGIN_PAGES } from 'utils/constants'
import {
  getCookie,
  generateLocationState,
  sendDataLayer,
  getCookieLocation,
  getCookieLocationLeft,
  getCookieLocationRight,
  onClientSide,
} from 'utils/utils'

class NotFound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataLayerSent: false,
	  recommendationTimestamp: new Date().toISOString().replace(/T/, ' ').replace(/Z/, '')
    }
    if (config.useSSR) {
      if (typeof document === 'undefined' && !this.props.declareSeo.fetched) {
        this.props.dispatch(fetchDeclareSeo('NotFound'))
      }
      if (
        typeof document === 'undefined' &&
        !this.props.serverSide404.fetched
      ) {
        this.props.dispatch(fetchServerSide404(true))
      }
    }
  }

  componentDidMount() {
    if (onClientSide()) window.scrollTo(0, 0)
    this.getCookieVehicle()
    this.getCategoryResult()
    this.getBestSellers()
  }

  componentDidUpdate() {
    if (
      !this.state.dataLayerSent &&
      this.props.bestSellers &&
      (this.props.bestSellers.fetched || this.props.bestSellers.error) &&
      this.props.categories &&
      (this.props.categories.fetched || this.props.categories.error)
    ) {
      this.setState({ dataLayerSent: true }, () => {
        const page = {
          pageName: '/webmotors/comprar/error-404',
          flowType: 'Fluxo Comprar',
          pageType: 'Errors',
		  recommendationTimestamp: this.state.recommendationTimestamp
        }
        const objDataLayer = {
          page,
        }
        sendDataLayer && sendDataLayer(objDataLayer, 'customPageView')
      })
    }
  }

  getCookieVehicle() {
    // gera no padrão do filtro ('Campinas - SP' ou "São Paulo" ou vazio para Brasil)
    const { cookies } = this.props.cookieNoticeContext
    const isCookieCategoryActive = cookieCategoryIsActive(
      cookies,
      COOKIES.FILTER_SEARCH
    )

    let _searchCookie =
      isCookieCategoryActive && getCookie(COOKIES.FILTER_SEARCH)
    if (
      _searchCookie &&
      typeof _searchCookie === 'string' &&
      _searchCookie.indexOf('{') == 0
    ) {
      _searchCookie = JSON.parse(_searchCookie)
    }
    const vehicleType =
      _searchCookie && _searchCookie.lastType
        ? _searchCookie.lastType
        : VEHICLE_TYPE.car

    this.setState({ vehicleType })
  }

  getBestSellers() {
    const { cookies } = this.props.cookieNoticeContext
    const isCookieCategoryActive = cookieCategoryIsActive(
      cookies,
      COOKIES.FILTER_SEARCH
    )

    if (!this.props.bestSellers.fetched) {
      const location = isCookieCategoryActive && getCookieLocation()
      this.props.dispatch(
        fetchBestSellers(
          location != '' && location != 'estoque'
            ? generateLocationState(location)
            : 'br'
        )
      )
    }
  }

  getCategoryResult() {
    if (!this.props.categories.fetched)
      this.props.dispatch(fetchCategories()).then(() => {
        this.setState({ checkFetchCarousel: true })
      })
  }

  render() {
    const _locationLeft = getCookieLocationLeft()
    const _locationRight = getCookieLocationRight()
    return (
      <Page openFooter headerBackLinkTo="/" url={this.props.location}>
        <Helmet>
          <title>404</title>
        </Helmet>
        <Main className="page--notFound">
          <StatusHeader
            title="EITA! Deu algum erro."
            subtitle="Eu juro que não esperava por isso! :("
            contentText="Não fique triste, separamos algumas recomendações para você:"
          />
          {this.state.vehicleType == VEHICLE_TYPE.car ? (
            <Fragment>
              <HomeCategories
                source={
                  this.props.categories && this.props.categories.fetched
                    ? this.props.categories.result
                    : []
                }
                locationLeft={_locationLeft}
                locationRight={_locationRight}
                title={
                  this.props.categories &&
                  this.props.categories.result.length ? (
                    'Categorias'
                  ) : (
                    <PlaceHolder width="px" px="100" />
                  )
                }
                id="CarouselCarsForSale"
                className=" CarouselContainer--forsale"
                origin={ORIGIN_PAGES.OFERTAS.KEY}
              />
              <BestSeller
                source={
                  this.state.vehicleType == VEHICLE_TYPE.car
                    ? this.props.bestSellers.result
                    : []
                }
                locationLeft={_locationLeft}
                locationRight={_locationRight}
                title={
                  this.props.bestSellers.fetched &&
                  this.props.bestSellers.result ? (
                    'Carros mais buscados'
                  ) : (
                    <PlaceHolder width="px" px="100" />
                  )
                }
                id="CarouselMostWanted"
              />
            </Fragment>
          ) : undefined}
          <RecommendedVehicles
            title={
              this.props.recommendedVehicles &&
              this.props.recommendedVehicles.fetched &&
              this.props.recommendedVehicles.result ? (
                'Recomendados para você'
              ) : (
                <PlaceHolder width="px" px="100" />
              )
            }
            vehicleType={this.state.vehicleType}
			origin={ORIGIN_PAGES.NOTFOUND.KEY}
			recommendationTimestamp={this.state.recommendationTimestamp}
          />
          <HomeProducts
            source={productsConfig || []}
            title={
              productsConfig.length ? (
                'Na Webmotors'
              ) : (
                <PlaceHolder width="px" px="100" />
              )
            }
            id="CarouselProductsWeb"
          />
        </Main>
      </Page>
    )
  }
}

export default withCookieNotice(
  connect(store => {
    return {
      categories: store.categoriesState,
      bestSellers: store.bestSellersState,
      serverSide404: store.serverSide404State,
      recommendedVehicles: store.recommendedVehiclesState,
      declareSeo: store.declareSeoState,
    }
  })(NotFound)
)
