export default (state = true, { type, firstLoadedRoute = null }) => {
  let newState

  switch (type) {
  case '@firstLoadedRoute/SET':
      newState = firstLoadedRoute === null ? !state : firstLoadedRoute
      break
  default:
      newState = state
      break
  }

  return newState
}
