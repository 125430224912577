import React from 'react'

export default () => (
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none">
    <path
      d="M11 20H3C1.35 20 0 18.65 0 17V3C0 1.35 1.35 0 3 0H11C12.65 0 14 1.35 14 3V17C14 18.65 12.65 20 11 20ZM3 2C2.45 2 2 2.45 2 3V17C2 17.55 2.45 18 3 18H11C11.55 18 12 17.55 12 17V3C12 2.45 11.55 2 11 2H3Z"
      fill="#2E2D37"
    />
    <path
      d="M7 17C7.55228 17 8 16.5523 8 16C8 15.4477 7.55228 15 7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17Z"
      fill="#2E2D37"
    />
  </svg>
)
