import Immutable from 'immutee'

export const FETCH_RESPONSIVE = 'buyer/responsive/FETCH_RESPONSIVE_FULFILLED'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_RESPONSIVE:
      return immutable.set('result', action.payload).set('fetched', true).done()
    default:
      return state
  }
}

export function fetchResponsive(
  mobileVersion,
  width,
  height,
  browser,
  isRetina
) {
  return function (dispatch) {
    return dispatch({
      type: FETCH_RESPONSIVE,
      payload: {
        mobileVersion,
        dimensions: { width, height },
        browser,
        isRetina,
      },
    })
  }
}
