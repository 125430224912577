import React, { Component } from 'react'
import Slider from 'react-slick'

import { COOKIES } from 'utils/constants'
import { setEventToLazyDispatch } from 'utils/utils'
import { cookieCategoryIsActive } from '@/modern/utils/cookies'
import { withCookieNotice } from '@/store/context/cookie-notice-context'

import CarouselContainer from 'components/CarouselContainer'
import CardModelo from 'components/CarouselContainer/CardModelo'

class BestSeller extends Component {
  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1930,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1368,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  }

  renderCards() {
    const { cookies } = this.props.cookieNoticeContext
    const isCookieCategoryActive = cookieCategoryIsActive(
      cookies,
      COOKIES.TRACKING_FROM
    )

    if (this.props.source && this.props.source.length) {
      const _renderMap = this.props.source.map((item, index) => (
        <div key={index} className="slick-padding">
          <CardModelo
            make={item.make ? item.make : ''}
            model={item.model ? item.model : ''}
            year={
              item.anode
                ? item.anode + (item.anoate ? ` - ${item.anoate}` : '')
                : ''
            }
            anode={item.anode ? item.anode : ''}
            anoate={item.anoate ? item.anoate : ''}
            img={item.img ? item.img : ''}
            key={`bestSellers${item.title}${index}`}
            id={item.id ? item.id : ''}
            order={item.order ? parseInt(item.order) + 1 : ''}
            locationLeft={this.props.locationLeft}
            locationRight={this.props.locationRight}
            onClick={() => {
              if (isCookieCategoryActive) {
                setEventToLazyDispatch('buscaRealizada')
              }
            }}
            lastSearch={this.props.lastSearch}
          />
        </div>
      ))
      return _renderMap.length ? _renderMap : <span />
    }
    return false
  }

  render() {
    const { customSettings } = this.props

    const settings = {
      ...this.settings,
      ...customSettings,
    }

    return (
      <CarouselContainer
        id={this.props.id}
        title={this.props.title}
      >
        <Slider {...settings}>{this.renderCards()}</Slider>
      </CarouselContainer>
    )
  }
}

BestSeller.defaultProps = {
  customSettings: {},
}

export default withCookieNotice(BestSeller)
