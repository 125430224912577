import { getUserTokenAuth } from 'utils/utils'
import { api } from './api'
import { hasPandora } from '@/utils/utils'

const headers = () => {
  return {
    headers: { Authorization: `${getUserTokenAuth()}` },
  }
}

export default {
  all() {
    if (!getUserTokenAuth()) {
      return Promise.resolve([[], []])
    }
    const pandora = hasPandora()
    const requestConfig = {
      ...headers(),
      params: {
        actual_page: 1,
        display_per_page: 9999,
        sort: 1,
        site: true,
        pandora,
      },
    }

    return Promise.all([
      api.get('extras/showroom?vehicle_type=1', requestConfig),
      api.get('extras/showroom?vehicle_type=2', requestConfig),
    ])
  },
  store(id, type) {
    const pandora = hasPandora()
    const data = {
      advertise_id: +id,
      vehicle_type: type === 'car' ? 1 : 2,
      site: true,
    }
    const requestConfig = {
      ...headers(),
      params: { pandora },
    }

    return api.post('extras/showroom', data, requestConfig)
  },
  destroy(excludeFavoriteKey) {
    const pandora = hasPandora()
    const requestConfig = {
      ...headers(),
      data: { guid: excludeFavoriteKey },
      params: { pandora },
    }

    return api.delete(`extras/showroom`, requestConfig)
  },
}
