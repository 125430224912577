import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import './CarouselContainer.scss';

export default class CardGradient extends Component {
	renderGradient() {
		let hexRegex = /^\#*([a-fA-F0-9]{3,6})$/
		let style = {}
		if (this.props.startColor && hexRegex.test(this.props.startColor) && this.props.endColor && hexRegex.test(this.props.endColor)) {
			style.background = ('linear-gradient(90deg, #' + hexRegex.exec(this.props.startColor)[1] + ' 0%, #' + hexRegex.exec(this.props.endColor)[1] + ' 100%)')
		}
		if (this.props.textColor && hexRegex.test(this.props.textColor)) {
			style.color = '#' + hexRegex.exec(this.props.textColor)[1]
		}
		return style
	}
	renderBgImg() {
		if (this.props.bgImg && typeof this.props.bgImg === 'string') {
			return (<div className='CardGradient__bgImg' style={{ backgroundImage: `url(` + this.props.bgImg.replace('webmotors-catalogo.s3.amazonaws.com', 'webmotors.com.br').replace(/\s/g, '%20') + `?s=fill&h=160&w=260&q=70)` }} />)
		}
		return false
	}
	handleClick() {
		this.props.onClick && this.props.onClick()
	}
	render() {
		let CustomTag = 'a';
		this.props.linkTo && (CustomTag = Link);
		if (this.props.title) {
			return (
				<CustomTag
					id={this.props.id ? this.props.id : undefined}
					className="CardGradient"
					href={this.props.href && !this.props.linkTo ? this.props.href : undefined}
					to={this.props.linkTo ? this.props.linkTo : undefined}
					target={this.props.target ? this.props.target : undefined}
					rel={this.props.rel ? this.props.rel : ''}
					style={this.renderGradient()}
					onClick={this.handleClick.bind(this)}>
					{this.renderBgImg()}
					{<Fragment><span className='shadow'></span><h3>{this.props.title}</h3></Fragment>}
				</CustomTag>
			)
		}
		return false
	}
}
