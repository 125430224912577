import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5C16.552 5 17 5.449 17 6C17 6.551 16.552 7 16 7C15.448 7 15 6.551 15 6C15 5.449 15.448 5 16 5ZM4 7H13.185C13.599 8.162 14.698 9 16 9C17.302 9 18.401 8.162 18.815 7H20C20.553 7 21 6.552 21 6C21 5.448 20.553 5 20 5H18.815C18.401 3.838 17.302 3 16 3C14.698 3 13.599 3.838 13.185 5H4C3.447 5 3 5.448 3 6C3 6.552 3.447 7 4 7ZM8 13C7.448 13 7 12.551 7 12C7 11.449 7.448 11 8 11C8.552 11 9 11.449 9 12C9 12.551 8.552 13 8 13ZM20 11H10.815C10.401 9.838 9.302 9 8 9C6.698 9 5.599 9.838 5.185 11H4C3.447 11 3 11.448 3 12C3 12.552 3.447 13 4 13H5.185C5.599 14.162 6.698 15 8 15C9.302 15 10.401 14.162 10.815 13H20C20.553 13 21 12.552 21 12C21 11.448 20.553 11 20 11ZM16 19C15.448 19 15 18.551 15 18C15 17.449 15.448 17 16 17C16.552 17 17 17.449 17 18C17 18.551 16.552 19 16 19ZM20 17H18.815C18.401 15.838 17.302 15 16 15C14.698 15 13.599 15.838 13.185 17H4C3.447 17 3 17.448 3 18C3 18.552 3.447 19 4 19H13.185C13.599 20.162 14.698 21 16 21C17.302 21 18.401 20.162 18.815 19H20C20.553 19 21 18.552 21 18C21 17.448 20.553 17 20 17Z"
      fill="#2E2D37"
    />
  </svg>
)
