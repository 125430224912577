import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9229 3.61824C20.8209 3.37324 20.6269 3.17924 20.3819 3.07724C20.2599 3.02624 20.1299 3.00024 19.9999 3.00024H16.9999C16.4469 3.00024 15.9999 3.44824 15.9999 4.00024C15.9999 4.55224 16.4469 5.00024 16.9999 5.00024H17.5859L12.4999 10.0862L9.20688 6.79324C8.81688 6.40224 8.18388 6.40224 7.79288 6.79324L3.29288 11.2932C2.90188 11.6832 2.90188 12.3162 3.29288 12.7072C3.48788 12.9022 3.74388 13.0002 3.99988 13.0002C4.25588 13.0002 4.51188 12.9022 4.70688 12.7072L8.49988 8.91424L11.7929 12.2072C12.1839 12.5972 12.8169 12.5972 13.2069 12.2072L18.9999 6.41424V7.00024C18.9999 7.55224 19.4469 8.00024 19.9999 8.00024C20.5529 8.00024 20.9999 7.55224 20.9999 7.00024V4.00024C20.9999 3.87024 20.9739 3.74024 20.9229 3.61824ZM3.99998 15C3.44698 15 2.99998 15.448 2.99998 16V20C2.99998 20.552 3.44698 21 3.99998 21C4.55298 21 4.99998 20.552 4.99998 20V16C4.99998 15.448 4.55298 15 3.99998 15ZM7.99998 12C7.44698 12 6.99998 12.448 6.99998 13V20C6.99998 20.552 7.44698 21 7.99998 21C8.55298 21 8.99998 20.552 8.99998 20V13C8.99998 12.448 8.55298 12 7.99998 12ZM16 12C15.447 12 15 12.448 15 13V20C15 20.552 15.447 21 16 21C16.553 21 17 20.552 17 20V13C17 12.448 16.553 12 16 12ZM20 10C19.447 10 19 10.448 19 11V20C19 20.552 19.447 21 20 21C20.553 21 21 20.552 21 20V11C21 10.448 20.553 10 20 10ZM12 15C11.447 15 11 15.448 11 16V20C11 20.552 11.447 21 12 21C12.553 21 13 20.552 13 20V16C13 15.448 12.553 15 12 15Z"
      fill="#2E2D37"
    />
  </svg>
)
