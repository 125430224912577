import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 13H9C8.448 13 8 13.448 8 14C8 14.552 8.448 15 9 15H15C15.552 15 16 14.552 16 14C16 13.448 15.552 13 15 13ZM6 20V4H14V7C14 7.552 14.448 8 15 8H18L18.001 20H6ZM9 12H15C15.552 12 16 11.552 16 11C16 10.448 15.552 10 15 10H9C8.448 10 8 10.448 8 11C8 11.552 8.448 12 9 12ZM19.922 6.615C19.872 6.494 19.798 6.384 19.707 6.292L15.707 2.293C15.616 2.202 15.506 2.128 15.385 2.078C15.264 2.027 15.134 2 15 2H6C4.897 2 4 2.897 4 4V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V7C20 6.866 19.973 6.736 19.922 6.615ZM15 16H9C8.448 16 8 16.448 8 17C8 17.552 8.448 18 9 18H15C15.552 18 16 17.552 16 17C16 16.448 15.552 16 15 16Z"
      fill="#2E2D37"
    />
  </svg>
)
