import React from 'react'
import PropTypes from 'prop-types'
import Tag from '../Tag'

const TagBlackFeirao = ({ width, height }) => (
  <Tag
    id="TagVehicleBlackFair"
    src="/assets/img/webp/blackfeirao/tag_sm.webp"
    name="Black Feirão"
    width={width}
    height={height}
  />
)

Tag.defaultProps = {
  width: '107px',
  height: '18px',
}

Tag.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}

export default TagBlackFeirao
