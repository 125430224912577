import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19H19V11.454L12 5.32901L5 11.454V19H8V17C8 14.794 9.794 13 12 13C14.206 13 16 14.794 16 17V19ZM20 21H15C14.448 21 14 20.552 14 20V17C14 15.897 13.103 15 12 15C10.897 15 10 15.897 10 17V20C10 20.552 9.552 21 9 21H4C3.448 21 3 20.552 3 20V11C3 10.711 3.125 10.437 3.341 10.248L11.341 3.24801C11.718 2.91701 12.282 2.91701 12.659 3.24801L20.659 10.248C20.875 10.437 21 10.711 21 11V20C21 20.552 20.552 21 20 21Z"
      fill="#2E2D37"
    />
  </svg>
)
