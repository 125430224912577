import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_PROPOSAL = 'buyer/proposal/FETCH_PROPOSAL'
export const FETCH_PROPOSAL_FAILURE = 'buyer/proposal/FETCH_PROPOSAL_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_PROPOSAL:
      return immutable.set('fetched', true).done()

    case FETCH_PROPOSAL_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchProposal(data) {
  const url = `${getApiUrl()}detail/proposal/send`
  const pandora = hasPandora()
  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const requestData = {
    ...data,
    pandora,
  }

  return dispatch => {
    return axios
      .post(url, requestData, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_PROPOSAL,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_PROPOSAL_FAILURE,
          payload: err.stack,
        })
      })
  }
}
