import favorites from './favorites'
import favoritesCounter from './favoritesCounter'
import firstLoadedRoute from './firstLoadedRoute'
import userIsAuth from './userIsAuth'
import modal from './modal'
import filters from './filters'
import newSellerInfo from './newSellerInfo'
import leadInfo from './leadInfo'

export default {
    favorites,
    favoritesCounter,
    firstLoadedRoute,
    userIsAuth,
    modal,
    filters,
    newSellerInfo,
    leadInfo
}
