export default (state = 0, { type, count = 0 }) => {
    let newState

    switch (type) {
    case '@favoritesCounter/ADD_ITEM':
      newState = state + 1
      break
    case '@favoritesCounter/REMOVE_ITEM':
      newState = state - 1
      break
    case '@favoritesCounter/SET_LIST':
        newState = count
        break
    case '@favoritesCounter/CLEAR_LIST':
        newState = 0
        break
    default:
        newState = state
        break
    }

    return newState
}
