import React from 'react'
import PropTypes from 'prop-types'

import useToggle from '@/modern/hooks/useToggle'

import { Checkbox, Toggle } from './style'

const Switch = ({ onToggle, isOn, name }) => {
  const { on, toggle, turnOn, turnOff } = useToggle(isOn)
  const switchId = `switch-${name}`

  React.useEffect(() => {
    if (isOn) {
      turnOn()
    } else {
      turnOff()
    }
  }, [isOn, turnOn, turnOff])

  const handleToggle = () => {
    toggle()

    if (onToggle) {
      onToggle(!on)
    }
  }

  return (
    <React.Fragment>
      <Checkbox
        id={switchId}
        name={name}
        onChange={handleToggle}
        checked={on}
      />
      <Toggle htmlFor={switchId} />
    </React.Fragment>
  )
}

Switch.defaultProps = {
  isOn: false,
  onToggle: null,
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  isOn: PropTypes.bool,
  onToggle: PropTypes.func,
}

export default Switch
