import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.707 8.293L7.70701 4.293C7.61501 4.201 7.50401 4.128 7.38201 4.077C7.13801 3.976 6.86201 3.976 6.61801 4.077C6.49601 4.128 6.38501 4.201 6.29301 4.293L2.29301 8.293C1.90201 8.684 1.90201 9.316 2.29301 9.707C2.68401 10.098 3.31601 10.098 3.70701 9.707L6.00001 7.414V19C6.00001 19.552 6.44801 20 7.00001 20C7.55201 20 8.00001 19.552 8.00001 19V7.414L10.293 9.707C10.488 9.902 10.744 10 11 10C11.256 10 11.512 9.902 11.707 9.707C12.098 9.316 12.098 8.684 11.707 8.293ZM21.707 14.293C21.316 13.902 20.684 13.902 20.293 14.293L18 16.586V5C18 4.448 17.552 4 17 4C16.448 4 16 4.448 16 5V16.586L13.707 14.293C13.316 13.902 12.684 13.902 12.293 14.293C11.902 14.684 11.902 15.316 12.293 15.707L16.293 19.707C16.385 19.799 16.496 19.872 16.618 19.923C16.74 19.974 16.87 20 17 20C17.13 20 17.26 19.974 17.382 19.923C17.504 19.872 17.615 19.799 17.707 19.707L21.707 15.707C22.098 15.316 22.098 14.684 21.707 14.293Z"
      fill="#2E2D37"
    />
  </svg>
)
