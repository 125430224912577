import React, { Component } from 'react'
import PhotoSlider from 'components/PhotoSlider'
import GoodDealSeal from 'components/GoodDealSeal'
import VistoriadoStamp from '@/modern/components/Stamps/Vistoriado'

import './CardVehicle.scss'

export default class CardVehicle extends Component {
  constructor(props) {
    super(props)
  }

  handleClick() {
    this.props.onClick && this.props.onClick()
  }

  render() {
    const { id, data } = this.props
    const {
      linkCard,
      listPhotos,
      tag,
      uniqueId,
      goodDeal,
      make,
      model,
      description,
      price,
      year,
      odometer,
      km,
      city,
      IsElegibleVehicleInspection,
    } = data

    return (
      <div id={id ? id : undefined} className="CardVehicle">
        <div className="CardVehicle__FigureWrapper">
          {IsElegibleVehicleInspection && <VistoriadoStamp />}

          <figure>
            <PhotoSlider
              linkCarousel={linkCard}
              listPhotos={listPhotos}
              tag={tag}
              className={'CardVehicle__thumb'}
              alt='Foto do veículo' width={176} height={136}
            />
          </figure>
        </div>
        <a
          rel="noreferrer nofollow"
          href={linkCard}
          onClick={this.handleClick.bind(this)}
          target="_blank"
          id={`IdDoAnuncioDefault_${uniqueId}`}
          className="CardVehicle__link"
        >
          <div className="CardVehicle__details">
            <div className="CardVehicle__details__header">
              {goodDeal ? <GoodDealSeal /> : ''}
              <h2 className="CardVehicle__details__header__title">
                {make} <strong>{model}</strong>
              </h2>
              <h3 className="CardVehicle__details__header__description">
                {description}
              </h3>
            </div>
            <div className="CardVehicle__details__content">
              <strong
                className={`${
                  price.length > 20
                    ? 'CardVehicle__details__content__price-resize'
                    : 'CardVehicle__details__content__price'
                }`}
              >
                {price}
              </strong>
            </div>
            <div className="CardVehicle__details__specification">
              <span className="CardVehicle__details__specification__year">
                {year}
              </span>
              <span
                className={
                  'CardVehicle__details__specification__km' +
                  (odometer == '0'
                    ? ' CardVehicle__details__specification__km--zero'
                    : '')
                }
              >
                {km}
              </span>
            </div>
            <div className="CardVehicle__details__footer">
              <span className="CardVehicle__details__footer__state">
                {city}
              </span>
            </div>
          </div>
        </a>
      </div>
    )
  }
}
