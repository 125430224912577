import axios from 'axios'
import Immutable from 'immutee'
import { getApiUrl, getChannelParameter, hasPandora } from '../utils/utils'

import { config } from '../../config/config'
import { VEHICLE_TYPE } from '../utils/constants'

export const FETCH_RECOMMENDEDVEHICLES =
  'buyer/recommendedVehicles/FETCH_RECOMMENDEDVEHICLES'
export const FETCH_RECOMMENDEDVEHICLES_FAILURE =
  'buyer/recommendedVehicles/FETCH_RECOMMENDEDVEHICLES_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_RECOMMENDEDVEHICLES:
      return immutable
        .set('result', action.payload)
        .set('requestedData', action.requestedData)
        .set('fetched', true)
        .done()

    case FETCH_RECOMMENDEDVEHICLES_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('requestedData', action.requestedData)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchRecommendedVehicles(data) {
  const channelParameter = getChannelParameter()
  const pandora = hasPandora()

  return dispatch => {
    const url =
      `${getApiUrl()}search/${data.vehicleType}?url=${config.urlApp}${
        !data.sellerID ? data.url : VEHICLE_TYPE[data.vehicleType.toUpperCase()]
      }${encodeURIComponent(
        data.sellerID ? `?idrevendedor=${data.sellerID}` : ''
      )}&actualPage=1` +
      `&order=1` +
      `&showMenu=false` +
      `&showCount=false` +
      `&testAB=` +
      `&returnUrl=false` +
      `&displayPerPage=${data.displayPerPage || 10}${channelParameter}` +
      `&pandora=${pandora}`

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: FETCH_RECOMMENDEDVEHICLES,
          payload: res.data,
          requestedData: data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_RECOMMENDEDVEHICLES_FAILURE,
          payload: err.stack,
          requestedData: data,
        })
      })
  }
}
