import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_PROPOSALVEHICLEINTEREST =
  'buyer/proposal/FETCH_PROPOSALVEHICLEINTEREST'
export const FETCH_PROPOSALVEHICLEINTEREST_FAILURE =
  'buyer/proposal/FETCH_PROPOSALVEHICLEINTEREST_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_PROPOSALVEHICLEINTEREST:
      return immutable.set('fetched', true).done()

    case FETCH_PROPOSALVEHICLEINTEREST_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchProposalVehicleInterest(data) {
  const url = `${getApiUrl()}loan/proposal`
  const pandora = hasPandora()
  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: { pandora },
  }

  return dispatch => {
    return axios
      .post(url, data, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_PROPOSALVEHICLEINTEREST,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_PROPOSALVEHICLEINTEREST_FAILURE,
          payload: err.stack,
        })
      })
  }
}
