import Alert_1 from "./assets/Alert 1"
import Close_3 from "./assets/Close 3"
import Instagram from "./assets/Instagram"
import Play2 from "./assets/Play2"
import Alert_2 from "./assets/Alert 2"
import Color from "./assets/Color"
import Keys from "./assets/Keys"
import Plus_1 from "./assets/Plus 1"
import Arrow_1 from "./assets/Arrow 1"
import Config from "./assets/Config"
import Like from "./assets/Like"
import Plus_2 from "./assets/Plus 2"
import Arrow_10 from "./assets/Arrow 10"
import Crown from "./assets/Crown"
import LinkedIn from "./assets/LinkedIn"
import Radio_1 from "./assets/Radio 1"
import Arrow_11 from "./assets/Arrow 11"
import Cylinder from "./assets/Cylinder"
import Local from "./assets/Local"
import Radio_2 from "./assets/Radio 2"
import Arrow_12 from "./assets/Arrow 12"
import Directions from "./assets/Directions"
import Lock_1 from "./assets/Lock 1"
import Reply from "./assets/Reply"
import Arrow_13 from "./assets/Arrow 13"
import Door from "./assets/Door"
import Lock_2 from "./assets/Lock 2"
import Rotate_1 from "./assets/Rotate 1"
import Arrow_14 from "./assets/Arrow 14"
import Edit from "./assets/Edit"
import Menu from "./assets/Menu"
import Rotate_2 from "./assets/Rotate 2"
import Arrow_2 from "./assets/Arrow 2"
import Emoji_1 from "./assets/Emoji 1"
import Message_1 from "./assets/Message 1"
import Santander_red from "./assets/Santander red"
import Arrow_3 from "./assets/Arrow 3"
import Emoji_2 from "./assets/Emoji 2"
import Message_2 from "./assets/Message 2"
import Santander_white from "./assets/Santander white"
import Arrow_4 from "./assets/Arrow 4"
import Emoji_3 from "./assets/Emoji 3"
import Message_3 from "./assets/Message 3"
import Search from "./assets/Search"
import Arrow_5 from "./assets/Arrow 5"
import Emoji_4 from "./assets/Emoji 4"
import Microphone from "./assets/Microphone"
import Security from "./assets/Security"
import Arrow_6 from "./assets/Arrow 6"
import Estoque from "./assets/Estoque"
import Minus from "./assets/Minus"
import Send from "./assets/Send"
import Arrow_7 from "./assets/Arrow 7"
import ExitFullScreen from "./assets/ExitFullScreen"
import Mobile from "./assets/Mobile"
import Share_1 from "./assets/Share 1"
import Arrow_8 from "./assets/Arrow 8"
import Eye from "./assets/Eye"
import Money_1 from "./assets/Money 1"
import Share_2 from "./assets/Share 2"
import Arrow_9 from "./assets/Arrow 9"
import Facebook from "./assets/Facebook"
import Money_2 from "./assets/Money 2"
import Shield from "./assets/Shield"
import Autopago from "./assets/Autopago"
import Filter from "./assets/Filter"
import Motorcycle from "./assets/Motorcycle"
import Sound from "./assets/Sound"
import Battery from "./assets/Battery"
import Flag from "./assets/Flag"
import Next from "./assets/Next"
import Star_1 from "./assets/Star 1"
import Block from "./assets/Block"
import Foward from "./assets/Foward"
import Notification from "./assets/Notification"
import Star_2 from "./assets/Star 2"
import Bullet from "./assets/Bullet"
import Frame from "./assets/Frame"
import Odometer from "./assets/Odometer"
import Steering_wheel from "./assets/Steering wheel"
import Calendar from "./assets/Calendar"
import FullScreen from "./assets/FullScreen"
import Options_1 from "./assets/Options 1"
import Target from "./assets/Target"
import Camera from "./assets/Camera"
import Garage from "./assets/Garage"
import Options_2 from "./assets/Options 2"
import Transmission from "./assets/Transmission"
import Car_1 from "./assets/Car 1"
import Gas from "./assets/Gas"
import Order from "./assets/Order"
import Trash from "./assets/Trash"
import Car_2 from "./assets/Car 2"
import GooglePlus from "./assets/GooglePlus"
import Out from "./assets/Out"
import Twitter from "./assets/Twitter"
import Car_3 from "./assets/Car 3"
import Graph from "./assets/Graph"
import Paper from "./assets/Paper"
import Visualization_1 from "./assets/Visualization 1"
import Car_4 from "./assets/Car 4"
import Hand from "./assets/Hand"
import Person_1 from "./assets/Person 1"
import Visualization_2 from "./assets/Visualization 2"
import Car_5 from "./assets/Car 5"
import Heart_1 from "./assets/Heart 1"
import Person_2 from "./assets/Person 2"
import Visualization_3 from "./assets/Visualization 3"
import Car_6 from "./assets/Car 6"
import Heart_2 from "./assets/Heart 2"
import Person_3 from "./assets/Person 3"
import Wallet from "./assets/Wallet"
import Card from "./assets/Card"
import Help from "./assets/Help"
import Person_4 from "./assets/Person 4"
import WhatsApp from "./assets/WhatsApp"
import Check_1 from "./assets/Check 1"
import History from "./assets/History"
import Person_5 from "./assets/Person 5"
import Wheel from "./assets/Wheel"
import Check_2 from "./assets/Check 2"
import Home from "./assets/Home"
import Phone from "./assets/Phone"
import WM1 from "./assets/WM1"
import Checkbox_1 from "./assets/Checkbox 1"
import ID from "./assets/ID"
import Pin_1 from "./assets/Pin 1"
import YouTube from "./assets/YouTube"
import Checkbox_2 from "./assets/Checkbox 2"
import Image from "./assets/Image"
import Pin_2 from "./assets/Pin 2"
import Zoom_in from "./assets/Zoom in"
import Clock from "./assets/Clock"
import Infinity_1 from "./assets/Infinity"
import Placeholder from "./assets/Placeholder"
import Zoom_out from "./assets/Zoom out"
import Close_1 from "./assets/Close 1"
import Info from "./assets/Info"
import Plate from "./assets/Plate"
import Close_2 from "./assets/Close 2"
import Inner from "./assets/Inner"
import Play from "./assets/Play"
import BrokenHeart from "./assets/BrokenHeart"
import Question from "./assets/Question"

export default {
	Alert_1,
	Close_3,
	Instagram,
	Play2,
	Alert_2,
	Color,
	Keys,
	Plus_1,
	Arrow_1,
	Config,
	Like,
	Plus_2,
	Arrow_10,
	Crown,
	LinkedIn,
	Radio_1,
	Arrow_11,
	Cylinder,
	Local,
	Radio_2,
	Arrow_12,
	Directions,
	Lock_1,
	Reply,
	Arrow_13,
	Door,
	Lock_2,
	Rotate_1,
	Arrow_14,
	Edit,
	Menu,
	Rotate_2,
	Arrow_2,
	Emoji_1,
	Message_1,
	Santander_red,
	Arrow_3,
	Emoji_2,
	Message_2,
	Santander_white,
	Arrow_4,
	Emoji_3,
	Message_3,
	Search,
	Arrow_5,
	Emoji_4,
	Microphone,
	Security,
	Arrow_6,
	Estoque,
	Minus,
	Send,
	Arrow_7,
	ExitFullScreen,
	Mobile,
	Share_1,
	Arrow_8,
	Eye,
	Money_1,
	Share_2,
	Arrow_9,
	Facebook,
	Money_2,
	Shield,
	Autopago,
	Filter,
	Motorcycle,
	Sound,
	Battery,
	Flag,
	Next,
	Star_1,
	Block,
	Foward,
	Notification,
	Star_2,
	Bullet,
	Frame,
	Odometer,
	Steering_wheel,
	Calendar,
	FullScreen,
	Options_1,
	Target,
	Camera,
	Garage,
	Options_2,
	Transmission,
	Car_1,
	Gas,
	Order,
	Trash,
	Car_2,
	GooglePlus,
	Out,
	Twitter,
	Car_3,
	Graph,
	Paper,
	Visualization_1,
	Car_4,
	Hand,
	Person_1,
	Visualization_2,
	Car_5,
	Heart_1,
	Person_2,
	Visualization_3,
	Car_6,
	Heart_2,
	Person_3,
	Wallet,
	Card,
	Help,
	Person_4,
	WhatsApp,
	Check_1,
	History,
	Person_5,
	Wheel,
	Check_2,
	Home,
	Phone,
	WM1,
	Checkbox_1,
	ID,
	Pin_1,
	YouTube,
	Checkbox_2,
	Image,
	Pin_2,
	Zoom_in,
	Clock,
	Infinity_1,
	Placeholder,
	Zoom_out,
	Close_1,
	Info,
	Plate,
	Close_2,
	Inner,
	Play,
	BrokenHeart,
  Question
}
