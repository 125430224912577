import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 19.9855H17V12H7V19.9855ZM9 9.99998V7.03589C9 5.36997 10.346 4.01428 12 4.01428C13.654 4.01428 15 5.36997 15 7.03589C15 7.59186 15.448 8.04309 16 8.04309C16.552 8.04309 17 7.59186 17 7.03589C17 4.25903 14.757 1.99988 12 1.99988C9.243 1.99988 7 4.25903 7 7.03589V9.99998H6C5.448 9.99998 5 10.444 5 11V20.9927C5 21.5487 5.448 21.9999 6 21.9999H18C18.552 21.9999 19 21.5487 19 20.9927V11C19 10.444 18.552 9.99998 18 9.99998H9ZM14 16.0143C14 14.9023 13.104 13.9999 12 13.9999C10.896 13.9999 10 14.9023 10 16.0143C10 17.1262 10.896 18.0287 12 18.0287C13.104 18.0287 14 17.1262 14 16.0143Z"
      fill="#2E2D37"
    />
  </svg>
)
