import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00309 17.9901C4 17.996 4 18 4 18L20 11.995L4.00309 6L6.4947 11.6044C6.6058 11.8543 6.6058 12.1368 6.4947 12.3857L4.00309 17.9901ZM4.04527 20C3.50209 20 2.97016 19.7883 2.57632 19.3926C2.00143 18.8161 1.84083 17.9652 2.16612 17.2276H2.16715L4.47286 11.999L2.16715 6.7714C1.84083 6.03284 2.00143 5.18294 2.57632 4.6064C3.15122 4.02886 4.02072 3.84596 4.78895 4.13523L20.6947 10.1461C21.4885 10.4454 22 11.173 22 11.999C22 12.8251 21.4885 13.5527 20.6947 13.8519L4.78895 19.8638C4.54652 19.9553 4.29487 20 4.04527 20Z"
      fill="#2E2D37"
    />
  </svg>
)
