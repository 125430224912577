import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29299 13.7931L11.293 8.79313C11.684 8.40213 12.316 8.40213 12.707 8.79313L17.707 13.7931C18.098 14.1841 18.098 14.8161 17.707 15.2071C17.316 15.5981 16.684 15.5981 16.293 15.2071L12 10.9141L7.70699 15.2071C7.31599 15.5981 6.68399 15.5981 6.29299 15.2071C6.09799 15.0121 5.99999 14.7561 5.99999 14.5001C5.99999 14.2441 6.09799 13.9881 6.29299 13.7931Z"
      fill="#2E2D37"
    />
  </svg>
)
