import { config } from 'config/config.js';

const productsConfigs = () => {
    const productsConfigsArr = [
        {
            "id": "itemFinancing",
            "Nome": "Financiamento",
            "href": config.urlFinancig+"?lkid=1068",
            "target": "_self"
        },
        {
            "id": "itemVehicleSale",
            "Nome": "Venda seu veículo",
            "href": config.urlSellMyCar,
            "target": "_self"
        },
        {
            "id": "itemcatalog",
            "Nome": "Catálogo de 0KM",
            "href": config.urlKM,
            "target": "_self"
        },
        {
            "id": "itemSafe",
            "Nome": "Seguro",
            "href": config.urlAutoCompara,
            "target": "_self"
        },
        {
            "id": "itemTableFipe",
            "Nome": "Tabela Fipe",
            "href": config.urlTableFipe,
            "target": "_self"
        },
        {
            "id": "itemAutoPayment",
            "Nome": "AutoPago",
            "href": config.urlAutoPayment,
            "target": "_self"
        }]
    return productsConfigsArr;
}

export const productsConfig = productsConfigs();
