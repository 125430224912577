import React, { Component } from 'react';

import CarouselContainer from 'components/CarouselContainer';
import PlaceHolder from 'components/PlaceHolder';

export default class HomePlaceholder extends Component {
	renderPlaceHolder() {
		let listPlaceHolder = []
		for (let index = 0; index < 8; index++) {
			listPlaceHolder.push(<PlaceHolder key={index + 1}></PlaceHolder>)
		}
		return listPlaceHolder
	}
	render() {	
		return (	
			<CarouselContainer className={this.props.className ? this.props.className : ''} id={this.props.id ? this.props.id : ''} title={this.props.title ? this.props.title : ''}>
				<div className='CarouselContainer__placeholder'>
					{this.renderPlaceHolder()}
				</div>	
			</CarouselContainer>
		)
	}
}