import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 22C18.447 22 18 21.552 18 21C18 20.449 17.552 20 17 20H7C6.448 20 6 20.449 6 21C6 21.552 5.553 22 5 22C4.447 22 4 21.552 4 21C4 19.346 5.346 18 7 18H17C18.654 18 20 19.346 20 21C20 21.552 19.553 22 19 22ZM7 4C6.448 4 6 4.449 6 5V13C6 13.551 6.448 14 7 14H17C17.552 14 18 13.551 18 13V5C18 4.449 17.552 4 17 4H7ZM17 16H7C5.346 16 4 14.654 4 13V5C4 3.346 5.346 2 7 2H17C18.654 2 20 3.346 20 5V13C20 14.654 18.654 16 17 16Z"
      fill="#2E2D37"
    />
  </svg>
)
