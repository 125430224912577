import React from 'react'

const useLocalStorage = (key, defaultValue = '') => {
  const [state, setState] = React.useState(() => {
    let value

    try {
      value = JSON.parse(
        window.localStorage.getItem(key) || JSON.stringify(defaultValue)
      )
    } catch (e) {
      value = defaultValue
    }
    return value
  })

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state))
  }, [state, key])

  return [state, setState]
}

export default useLocalStorage
