import searchResultState from './ducks/searchResult'
import autoCompleteCarState from './ducks/autoCompleteCar'
import autoCompleteBikeState from './ducks/autoCompleteBike'
import categoriesState from './ducks/categories'
import detailState from './ducks/detail'
import fairsReducer from './ducks/fairs'
import filtersCarLocationState from './ducks/filtersCarLocation'
import filtersBikeLocationState from './ducks/filtersBikeLocation'
import proposalState from './ducks/proposal'
import proposalVehicleInterestState from './ducks/proposalVehicleInterest'
import reportAdvertState from './ducks/reportAdvert'
import preRegistrationState from './ducks/financingPreRegistration'
import simulateState from './ducks/financingSimulate'
import c2cState from './ducks/financingC2C'
import phonesDetailState from './ducks/phonesDetail'
import recommendedVehiclesState from './ducks/recommendedVehicles'
import recommendedVehiclesNewState from './ducks/recommendedVehiclesNew'
import responsiveState from './ducks/responsive'
import bestSellersState from './ducks/bestSellers'
import dealerInfoState from './ducks/dealerInfo'
import dealerScheduleState from './ducks/dealerSchedule'
import vehicleSimulateState from './ducks/financingVehicle'
import versionsSimulateState from './ducks/financingVersions'
import serverSide404State from './ducks/serverSide404'
import averagePriceState from './ducks/averagePrice'
import optinRecaptureState from './ducks/optinRecapture'
import hotDealState from './ducks/hotDeal'
import geolocationState from './ducks/geolocation'
import declareSeoState from './ducks/declareSeo'
import cetFileState from './ducks/financingCet'
import newReducers from './modern/reducers'
import reportAdvertReasonsState from './ducks/reportAdvertReasons'
import locationSiteMapState from './ducks/locationSiteMap'
import advertiserAreaState from './ducks/advertiserArea'

export default {
  ...newReducers,
  searchResultState,
  autoCompleteCarState,
  autoCompleteBikeState,
  categoriesState,
  detailState,
  fairs: fairsReducer,
  filtersCarLocationState,
  filtersBikeLocationState,
  proposalState,
  proposalVehicleInterestState,
  reportAdvertState,
  recommendedVehiclesState,
  recommendedVehiclesNewState,
  phonesDetailState,
  responsiveState,
  bestSellersState,
  simulateState,
  c2cState,
  preRegistrationState,
  dealerInfoState,
  dealerScheduleState,
  vehicleSimulateState,
  versionsSimulateState,
  serverSide404State,
  averagePriceState,
  optinRecaptureState,
  hotDealState,
  geolocationState,
  declareSeoState,
  cetFileState,
  reportAdvertReasonsState,
  locationSiteMapState,
  advertiserAreaState,
}
