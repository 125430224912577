import axios from 'axios'
import Immutable from 'immutee'
import { config } from 'config/config'

export const FETCH_GEOLOCATION = 'buyer/geolocation/FETCH_GEOLOCATION'
export const GEOLOCATION_PERMISSION = 'buyer/geolocation/GEOLOCATION_PERMISSION'
export const FETCH_GEOLOCATION_FAILURE =
  'buyer/geolocation/FETCH_GEOLOCATION_FAILURE'
export const FETCH_GEOLOCATION_LOADING =
  'buyer/geolocation/FETCH_GEOLOCATION_LOADING'

const initialState = {
  fetched: false,
  result: [],
  error: null,
  isPending: false,
  data: {
    city: null,
    state: null,
    lat: null,
    long: null,
  },
  userHasPermissionLocation: false,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_GEOLOCATION_LOADING:
      return immutable
        .set('error', null)
        .set('result', [])
        .set('fetched', false)
        .set('isPending', true)
        .done()

    case GEOLOCATION_PERMISSION:
      return immutable
        .set(
          'userHasPermissionLocation',
          Boolean(action.payload.lat && action.payload.long)
        )
        .done()

    case FETCH_GEOLOCATION:
      return immutable
        .set('result', action.payload)
        .set('data', {
          city: action.payload.response['edge-city'] || null,
          state: action.payload.response['edge-region'] || null,
          lat: action.payload.lat,
          long: action.payload.long,
        })
        .set('isPending', false)
        .set('fetched', true)
        .done()

    case FETCH_GEOLOCATION_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('isPending', false)
        .set('fetched', false)
        .done()
    default:
      return state
  }
}

export function fetchGeolocation(data) {
  let url = `${config.urlApi}location`
  if (data && data.latitude && data.longitude) {
    url += `?lat=${data.latitude}&long=${data.longitude}`
  }
  return dispatch => {
    dispatch({ type: FETCH_GEOLOCATION_LOADING })
    dispatch({
      type: GEOLOCATION_PERMISSION,
      payload: {
        lat: (data && data.latitude) || null,
        long: (data && data.longitude) || null,
      },
    })
    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: FETCH_GEOLOCATION,
          payload: {
            ...res.data,
            lat: (data && data.latitude) || null,
            long: (data && data.longitude) || null,
          },
        })
        return res.data
      })
      .catch(err => {
        dispatch({
          type: FETCH_GEOLOCATION_FAILURE,
          payload: err.stack,
        })
      })
  }
}
