import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2933 17.7072L8.29325 12.7073C7.90225 12.3163 7.90225 11.6842 8.29325 11.2933L13.2933 6.29325C13.6842 5.90225 14.3163 5.90225 14.7073 6.29325C15.0982 6.68425 15.0982 7.31625 14.7073 7.70725L10.4142 12.0002L14.7073 16.2933C15.0982 16.6842 15.0982 17.3162 14.7073 17.7072C14.5122 17.9022 14.2562 18.0002 14.0002 18.0002C13.7442 18.0002 13.4883 17.9022 13.2933 17.7072Z"
      fill="#2E2D37"
    />
  </svg>
)
