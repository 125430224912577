import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routeConfig from '../routes';
import NotFound from '../container/NotFound';

const renderMergedProps = (component, ...rest) => {
	const finalProps = Object.assign({}, ...rest);	
	
	return (
		React.createElement(component, finalProps)
	);
};

const PropsRoute = ({ component, ...rest }) => {
	return (
		<Route {...rest}
			render={(routeProps) => {
				return renderMergedProps(component, routeProps, rest);
			}}
		/>
	);
};

export default props => (
	<Switch>
		{routeConfig.map((route, index) => 
          <PropsRoute key={'routeKey' + index} {...route} {...props} />
        )}
		<Route component={NotFound} />
	</Switch>
);