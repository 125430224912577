import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9728 20H8.92676L8.06976 8H9.99976H14.9998H16.8298L15.9728 20ZM10.9998 6H13.9998V4H10.9998V6ZM19.9998 6H17.9038H15.9998V3C15.9998 2.448 15.5528 2 14.9998 2H9.99976C9.44776 2 8.99976 2.448 8.99976 3V6H6.99476H4.99976C4.44776 6 3.99976 6.448 3.99976 7C3.99976 7.552 4.44776 8 4.99976 8H6.06376L6.99776 21.071C7.03476 21.595 7.47076 22 7.99476 22H16.9038C17.4288 22 17.8638 21.595 17.9018 21.071L18.8348 8H19.9998C20.5528 8 20.9998 7.552 20.9998 7C20.9998 6.448 20.5528 6 19.9998 6Z"
      fill="#2E2D37"
    />
  </svg>
)
