import Immutable from 'immutee'

export const FETCH_SERVERSIDE404 =
  'buyer/responsive/FETCH_SERVERSIDE404_FULFILLED'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_SERVERSIDE404:
      return immutable.set('result', action.payload).set('fetched', true).done()
    default:
      return state
  }
}

export function fetchServerSide404(status) {
  return function (dispatch) {
    return dispatch({
      type: FETCH_SERVERSIDE404,
      payload: { status },
    })
  }
}
