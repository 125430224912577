import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrlSantander } from '../utils/utils'

export const FETCH_SIMULATE = 'buyer/proposal/FETCH_SIMULATE'
export const FETCH_SIMULATE_FAILURE = 'buyer/proposal/FETCH_SIMULATE_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_SIMULATE:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_SIMULATE_FAILURE:
      return immutable
        .set('error', action.payload.stack)
        .set('errorID', action.payload.statusCode)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchSimulate(data) {
  const _url = `${getApiUrlSantander()}financiamento/simulacao`
  return dispatch => {
    return axios
      .post(_url, data)
      .then(res => {
        dispatch({
          type: FETCH_SIMULATE,
          payload: res.data.Result,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_SIMULATE_FAILURE,
          payload: {
            stack:
              err.response.data.Messages.length > 0
                ? err.response.data.Messages[0]
                : err.stack,
            statusCode: err.response.data.StatusCode,
          },
        })
        throw err
      })
  }
}
