import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12.0264C20 12.5654 19.914 13.0964 19.743 13.6084L18.507 17.3164C18.371 17.7254 17.99 18.0004 17.559 18.0004H9V10.4144L14.351 5.06337C14.699 5.19437 14.956 5.51437 14.995 5.89737L14.03 9.75737C13.955 10.0564 14.022 10.3724 14.212 10.6154C14.401 10.8584 14.692 11.0004 15 11.0004H20V12.0264ZM4 18.0004H7V11.0004H4V18.0004ZM21 9.00037H16.281L16.97 6.24237C16.99 6.16337 17 6.08137 17 6.00037C17 4.34537 15.654 3.00037 14 3.00037C13.735 3.00037 13.48 3.10537 13.293 3.29337L7.586 9.00037H3C2.448 9.00037 2 9.44737 2 10.0004V19.0004C2 19.5524 2.448 20.0004 3 20.0004H8H17.559C18.852 20.0004 19.996 19.1754 20.405 17.9484L21.641 14.2404C21.879 13.5254 22 12.7804 22 12.0264V10.0004C22 9.44737 21.552 9.00037 21 9.00037Z"
      fill="#2E2D37"
    />
  </svg>
)
