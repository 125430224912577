import styled from 'styled-components'
import vars from '@/scss/variables'

const { colorGrey9, radius } = vars
const doubleTheRadius = 2

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 201;
  display: ${props => (props.show ? 'block' : 'none')};
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colorGrey9}B2;
  z-index: 20;
  display: block;
`

export const WhiteBox = styled.div`
  position: fixed;
  top: 50%;

  @media screen and (max-width: 1366px) {
    top: 53.5%;
  }

  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  min-width: 400px;
  width: ${props => props.smaller && '800px'};
  background: white;
  z-index: 21;
  height: auto;
  border-radius: ${`${radius * doubleTheRadius}px`};
`

export const Close = styled.div`
  background-image: url('/assets/img/close.svg');
  position: absolute;
  width: 20px;
  height: 14px;
  right: 20px;
  top: 20px;
  z-index: 22;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`
