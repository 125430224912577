const generalObjDataLayerKeysBuilder = () => {
  const { href, search } = window.location
  const urlParams = new URLSearchParams(search)

  return {
    site: {
      domain: 'webmotors.com.br',
      country: 'brasil',
      server: 'web',
      environment: 'comprar',
      subEnvironment: 'comprar',
      clientType: 'pf',
      platform: 'desktop',
      brandOrigin: urlParams.get('origem') || 'webmotors',
      tmsVersion: undefined,
    },
    page: {
      flowType: 'fluxo-comprar',
      url: href,
      trackingCode: {
        idcmp: urlParams.get('idcmp') || undefined,
        idcmpint: urlParams.get('idcmpint') || undefined,
        icid: urlParams.get('icid') || undefined,
        lkid: urlParams.get('lkid') || undefined,
      },
    },
    user: {
      loginStatus: 2, //
    },
  }
}

const changeDataLayerInfo = (customKeys, node) => {
  objDataLayer = {
    ...objDataLayer,
    [node]: {
      ...objDataLayer[node],
      ...customKeys,
    },
  }
}

const siteKeyChanger = (customKeys = {}) =>
  changeDataLayerInfo(customKeys, 'site')

const pageKeyChanger = (customKeys = {}) =>
  changeDataLayerInfo(customKeys, 'page')

const userKeyChanger = (customKeys = {}) =>
  changeDataLayerInfo(customKeys, 'user')

const listItensKeyChanger = (customKeys = {}) =>
  changeDataLayerInfo(customKeys, 'listItens')

export default generalObjDataLayerKeysBuilder
export { siteKeyChanger, pageKeyChanger, userKeyChanger, listItensKeyChanger }
