import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.96674 18.0332C8.71074 18.0332 8.45474 17.9352 8.25974 17.7402L4.29274 13.7733C3.90274 13.3833 3.90274 12.7502 4.29274 12.3593C4.68374 11.9693 5.31674 11.9693 5.70674 12.3593L8.96674 15.6193L18.2927 6.29325C18.6837 5.90225 19.3167 5.90225 19.7067 6.29325C20.0977 6.68325 20.0977 7.31625 19.7067 7.70725L9.67374 17.7402C9.47874 17.9352 9.22274 18.0332 8.96674 18.0332Z"
      fill="#2E2D37"
    />
  </svg>
)
