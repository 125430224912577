import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { fetchResponsive } from 'ducks/responsive.js'
import { connect } from 'react-redux'
import { getLoggedUserProperties } from '@/utils/utils'

let browser
let mainComponent

class Main extends Component {
  constructor(props) {
    super(props);
    this.handleResponsive = this.handleResponsive.bind(this);
    this.isRetinaDisplay = this.isRetinaDisplay.bind(this);
    this.getViewportDimensions = this.getViewportDimensions.bind(this);
  }
  componentDidMount() {
    mainComponent = this
    if (this.props.request && this.props.request.userAgent) {
      const isMobile = /iPhone|Mobile|Android|BlackBerry/.test(
        this.props.request.userAgent
      )
      this.props.dispatch(fetchResponsive(isMobile))
    }
    window.addEventListener('resize', this.handleResponsive, false)
    if (typeof navigator !== 'undefined' && navigator && navigator.userAgent) {
      if (
        !!~navigator.userAgent.indexOf('537.36') &&
        !!~navigator.userAgent.indexOf('Chrome')
      ) {
        browser = 'chrome'
      }
      if (
        !!~navigator.userAgent.indexOf('537.31') &&
        !!~navigator.userAgent.indexOf('Safari')
      ) {
        browser = 'safari'
      }
      if (~navigator.userAgent.indexOf('Firefox')) {
        browser = 'firefox'
      }
      if (~navigator.userAgent.indexOf('MSIE')) {
        browser = 'msie'
      }
      if (~navigator.userAgent.indexOf('Edge')) {
        browser = 'edge'
      }
      if (
        (!!~navigator.userAgent.indexOf('iPhone') ||
          !!~navigator.userAgent.indexOf('iPad')) &&
        !!~navigator.userAgent.indexOf('Safari')
      ) {
        browser = 'iphone'
      }
      if (
        !!~navigator.userAgent.indexOf('Android') &&
        !!~navigator.userAgent.indexOf('Safari')
      ) {
        browser = 'android'
      }
    }
    this.handleResponsive()
    this.handleVerifyUserIsAuth()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.responsive.browser &&
      this.props.responsive.browser != browser
    ) {
      this.handleResponsive()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResponsive, false)
  }

  handleVerifyUserIsAuth() {
    const { dispatch } = this.props
    const userIsAuth = getLoggedUserProperties()
    if (!userIsAuth) {
      return dispatch({
        type: '@userIsAuth/SET',
        userIsAuth: false,
      })
    }
    return dispatch({
      type: '@userIsAuth/SET',
      userIsAuth: true,
    })
  }

  isRetinaDisplay() {
    if (window.matchMedia) {
      const mq = window.matchMedia(
        'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)'
      );
      return mq && mq.matches;
    }
    return false;
  };
  
  getViewportDimensions() {
    const body = document.getElementsByTagName('body')[0];
    const width = window.innerWidth || document.documentElement.clientWidth || body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || body.clientHeight;
    return { width, height };
  };
  
  handleResponsive() {
    const isRetina = this.isRetinaDisplay();
    const { width, height } = this.getViewportDimensions();
    const isMobile = width < 768;
  
    mainComponent.props.dispatch(
      fetchResponsive(isMobile, width, height, browser, isRetina)
    );
  };
  

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Webmotors</title>
        </Helmet>
        {this.props.children}
      </Fragment>
    )
  }
}

export default connect(store => {
  return {
    responsive: store.responsiveState,
  }
})(Main)
