import React from 'react'
import ModalContainer from '@/modern/components/ModalContainer'
import Button from '@/components/Button'
import Icon from '@/modern/components/Icon'
import useTracker from '@/modern/hooks/useTracker'
import useModal from '@/modern/actions/useModal'
import { config } from '../../../../config/config'
import * as S from './style'

const { urlLogin } = config

const FavoritesModal = () => {
  const { closeModal, modal } = useModal('favorites')
  const [dispatchEvent] = useTracker()
  const dataTestLabel = 'modal_firstfavorite'
  const dataTestButton ='login_modalfavorite'

  const handleCloseModal = () => {
    dispatchEvent('sairModal', {})
    closeModal()
  }

  return (
    <ModalContainer show={modal} useWhiteBox onBgClick={() => {}}>
      <S.FavoritesModal data-test-component={dataTestLabel}>
        <S.FlexContainer>
          <Icon name="Heart_2" fill="#f3123c" size="56" />
          <S.IconButton onClick={handleCloseModal}>
            <Icon name="Close_1" fill="#2E2E37" size="20" />
          </S.IconButton>
        </S.FlexContainer>
        <S.Heading>Este é seu</S.Heading>
        <S.Heading>primeiro favorito!</S.Heading>
        <S.Text>
          Entre em sua conta para acessar seus favoritos e acompanhar as
          negociações de onde estiver.
        </S.Text>
        <S.ButtonsContainer data-test-component={dataTestButton}>
          <Button href={urlLogin + encodeURIComponent(window.location.href)}>Fazer login</Button>
        </S.ButtonsContainer>
      </S.FavoritesModal>
    </ModalContainer>
  )
}

export default FavoritesModal
