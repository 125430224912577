const initialState = {
  carDelivery: false,
  trocaComTroco: false,
  videoChamada: false,
  superSealOff: false,
  'visão360°': false,
  vistoriado: false,
}

export default (state = initialState, { type, key, value = false }) => {
  let newState

  switch (type) {
    case '@filter/TOGGLE':
      newState = state
      newState[key] = !newState[key]
      break
    case '@filter/SET':
      newState = state
      newState[key] = value
      break
    case '@filter/RESET':
      newState = {
        carDelivery: false,
        trocaComTroco: false,
        videoChamada: false,
        superSealOff: state.superSealOff,
        'visão360°': false,
        vistoriado: false,
      }
      break
    default:
      newState = state
      break
  }

  return newState
}
