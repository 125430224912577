import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_ADVERTISER_AREA = 'buyer/advertiser/FETCH_ADVERTISER_AREA'
export const FETCH_ADVERTISER_AREA_FAILURE =
  'buyer/advertiser/FETCH_ADVERTISER_AREA_FAILURE'
export const FETCH_ADVERTISER_AREA_LOADING =
  'buyer/advertiser/FETCH_ADVERTISER_AREA_LOADING'

const initialState = {
  fetched: false,
  result: {},
  error: null,
  isPending: true,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_ADVERTISER_AREA:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('fetched', true)
        .set('isPending', false)
        .done()

    case FETCH_ADVERTISER_AREA_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('requested', action.requested)
        .set('result', {})
        .set('fetched', false)
        .set('isPending', false)
        .done()
    case FETCH_ADVERTISER_AREA_LOADING:
      return immutable.set('isPending', true).done()
    default:
      return state
  }
}

function createUrl(data) {
  const { type, brand, model, version, year, idAnuncio } = data
  return `${getApiUrl()}advertiser-area/detail/${type}/${brand}/${model}/${version}/${year}/${idAnuncio}`
}

export function fetchAdvertiserAreaDetail(data) {
  const url = createUrl(data)
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    dispatch({ type: FETCH_ADVERTISER_AREA_LOADING })
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_ADVERTISER_AREA,
          payload: res.data,
          requested: data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_ADVERTISER_AREA_FAILURE,
          payload: err.stack,
          requested: data,
        })
      })
  }
}
