export default (state = false, { type, userIsAuth = null }) => {
  let newState

  switch (type) {
  case '@userIsAuth/SET':
      newState = userIsAuth === null ? !state : userIsAuth
      break
  default:
      newState = state
      break
  }

  return newState
}
