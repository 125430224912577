import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3955 15.2012C7.9545 15.5332 7.8665 16.1602 8.1985 16.6012C8.5305 17.0432 9.1575 17.1322 9.5985 16.7992C10.9915 15.7502 13.0115 15.7492 14.4055 16.8002C14.5855 16.9352 14.7965 17.0012 15.0065 17.0012C15.3105 17.0012 15.6095 16.8642 15.8065 16.6022C16.1385 16.1612 16.0505 15.5342 15.6085 15.2022C13.5165 13.6262 10.4885 13.6262 8.3955 15.2012ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM10 10.5C10 10.32 9.963 10.149 9.905 9.989C9.937 9.992 9.968 10 9.999 10C10.366 10 10.719 9.798 10.895 9.447C11.142 8.953 10.941 8.353 10.447 8.105L8.447 7.105C7.952 6.858 7.353 7.059 7.105 7.553C6.858 8.047 7.059 8.647 7.553 8.895L7.968 9.102C7.403 9.317 7 9.859 7 10.5C7 11.329 7.672 12 8.5 12C9.328 12 10 11.329 10 10.5ZM16.0322 9.1021L16.4472 8.8941C16.9412 8.6471 17.1412 8.0471 16.8942 7.5531C16.6482 7.0591 16.0482 6.8581 15.5532 7.1051L13.5532 8.1051C13.0582 8.3521 12.8582 8.9531 13.1052 9.4471C13.2812 9.7981 13.6342 10.0001 14.0012 10.0001C14.0322 10.0001 14.0632 9.9921 14.0952 9.9891C14.0372 10.1491 14.0002 10.3201 14.0002 10.5001C14.0002 11.3291 14.6722 12.0001 15.5002 12.0001C16.3282 12.0001 17.0002 11.3291 17.0002 10.5001C17.0002 9.8591 16.5972 9.3171 16.0322 9.1021Z"
      fill="#2E2D37"
    />
  </svg>
)
