import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.46 11.18L9.5 7.13998C8.84 6.74998 8 7.22998 8 7.99998V16.09C8 16.86 8.84 17.34 9.5 16.95L16.46 12.91C17.12 12.52 17.12 11.57 16.46 11.18Z"
      fill="#2E2D37"
    />
  </svg>
)
