import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9336 21.972C11.8676 21.972 11.8026 21.966 11.7376 21.952C5.47062 20.699 2.95462 13.306 3.00062 9.626V7.372C3.00062 6.888 3.34862 6.472 3.82562 6.388C3.87862 6.378 9.10262 5.415 11.1016 2.417C11.4726 1.861 12.3946 1.861 12.7656 2.417C14.5326 5.067 18.8486 6.132 19.7926 6.337C19.7966 6.338 19.8036 6.339 19.8096 6.341C19.8166 6.342 19.8226 6.344 19.8286 6.345C20.3526 6.473 20.6966 6.999 20.5816 7.53C20.5256 7.793 20.3726 8.011 20.1696 8.151L12.6406 15.679C12.2496 16.07 11.6176 16.07 11.2266 15.679L8.22662 12.679C7.83562 12.288 7.83562 11.656 8.22662 11.265C8.61762 10.874 9.24962 10.874 9.64062 11.265L11.9336 13.558L17.6596 7.833C15.9496 7.29 13.5806 6.284 11.9336 4.56C9.82462 6.766 6.53162 7.796 5.00062 8.176V9.639C4.96362 12.616 7.02062 18.816 11.9336 19.949C15.5586 19.112 17.7066 15.455 18.5126 12.271C18.6466 11.735 19.1906 11.409 19.7266 11.547C20.2626 11.682 20.5866 12.226 20.4506 12.761C19.4816 16.594 16.7866 21.021 12.1296 21.952C12.0646 21.966 11.9996 21.972 11.9336 21.972Z"
      fill="#2E2D37"
    />
  </svg>
)
