import { api } from './api'

export default {
  delivery({ data, sessionId }) {
    if (!data || !sessionId) return null

    return api.post(
      `https://webmotors.tt.omtrdc.net/rest/v1/delivery?client=webmotors&sessionId=${sessionId}`,
      data,
      {
        timeout: 2000,
      }
    )
  },
}
