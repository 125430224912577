import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4141 12L16.7071 8.70701C17.0981 8.31601 17.0981 7.68401 16.7071 7.29301C16.3161 6.90201 15.6841 6.90201 15.2931 7.29301L12.0001 10.586L8.70713 7.29301C8.31613 6.90201 7.68413 6.90201 7.29313 7.29301C6.90213 7.68401 6.90213 8.31601 7.29313 8.70701L10.5861 12L7.29313 15.293C6.90213 15.684 6.90213 16.316 7.29313 16.707C7.48813 16.902 7.74413 17 8.00013 17C8.25613 17 8.51213 16.902 8.70713 16.707L12.0001 13.414L15.2931 16.707C15.4881 16.902 15.7441 17 16.0001 17C16.2561 17 16.5121 16.902 16.7071 16.707C17.0981 16.316 17.0981 15.684 16.7071 15.293L13.4141 12Z"
      fill="#2E2D37"
    />
  </svg>
)
