import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'
import { MODALITY_TYPE } from '../utils/constants'

export const FETCH_HOTDEAL = 'buyer/hotDeal/FETCH_HOTDEAL'
export const FETCH_HOTDEAL_FAILURE = 'buyer/hotDeal/FETCH_HOTDEAL_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_HOTDEAL:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_HOTDEAL_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchHotDeal(vehicleType) {
  const url = `${getApiUrl()}carfair`
  const type = vehicleType ? MODALITY_TYPE[vehicleType] : MODALITY_TYPE.ALL
  const pandora = hasPandora()
  const requestConfig = {
    params: {
      type,
      pandora,
    },
  }

  return dispatch => {
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_HOTDEAL,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_HOTDEAL_FAILURE,
          payload: err.stack,
        })
      })
  }
}
