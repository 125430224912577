import { getFairs as getFairsService } from '@webmotors/wm-portal-lib-api-fairs'
import {
  isMegaFair,
  isBlackFair,
  isDefaultFair,
} from '@webmotors/wm-portal-lib-api-fairs/lib/helpers'
import { REQUEST_STATUS } from '@/utils/constants'
import { reducerFactory } from '@/utils/utils'

const fairsTypeActions = {
  GET_FAIRS: 'fairs/GET_FAIRS',
  GET_FAIRS_ERROR: 'fairs/GET_FAIRS_ERROR',
  GET_FAIRS_SUCCESS: 'fairs/GET_FAIRS_SUCCESS',
}

const getFairsAction = ({ type }) => ({
  type: fairsTypeActions.GET_FAIRS,
  payload: {
    type,
  },
})

const getFairsActionError = ({ error }) => ({
  type: fairsTypeActions.GET_FAIRS_ERROR,
  payload: {
    error,
  },
})

const getFairsActionSuccess = ({ fairs }) => ({
  type: fairsTypeActions.GET_FAIRS_SUCCESS,
  payload: {
    fairs,
    hasFairs: fairs.length > 0,
    hasMegaFair: fairs.some(isMegaFair),
    hasBlackFair: fairs.some(isBlackFair),
    hasDefaultFair: fairs.some(isDefaultFair),
    megaFair: fairs.filter(isMegaFair) || null,
    blackFair: fairs.find(isBlackFair) || null,
  },
})

const getFairs = ({ type }) => {
  return dispatch => {
    dispatch(getFairsAction({ type }))

    getFairsService({ type })
      .then(fairs => dispatch(getFairsActionSuccess({ fairs })))
      .catch(error => dispatch(getFairsActionError({ error })))
  }
}

const fairsInitialState = {
  status: REQUEST_STATUS.IDLE,
  fairs: [],
  hasFairs: false,
  hasMegaFair: false,
  hasBlackFair: false,
  hasDefaultFair: false,
  megaFair: null,
  blackFair: null,
}

const getFairsReducer = state => {
  return {
    ...state,
    status: REQUEST_STATUS.PENDING,
  }
}

const getFairsErrorReducer = state => {
  return {
    ...state,
    ...fairsInitialState,
    status: REQUEST_STATUS.REJECTED,
  }
}

const getFairsSuccessReducer = (state, action) => {
  const {
    fairs,
    hasFairs,
    hasMegaFair,
    hasBlackFair,
    hasDefaultFair,
    megaFair,
    blackFair,
  } = action.payload
  return {
    ...state,
    status: REQUEST_STATUS.RESOLVED,
    fairs,
    hasFairs,
    hasMegaFair,
    hasBlackFair,
    hasDefaultFair,
    megaFair,
    blackFair,
  }
}

const handlers = {}
handlers[fairsTypeActions.GET_FAIRS] = getFairsReducer
handlers[fairsTypeActions.GET_FAIRS_ERROR] = getFairsErrorReducer
handlers[fairsTypeActions.GET_FAIRS_SUCCESS] = getFairsSuccessReducer

const fairsReducer = reducerFactory(fairsInitialState, handlers)

export default fairsReducer

export { getFairs }
