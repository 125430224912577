import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0926 4.92124C20.9691 6.79714 22 9.29833 22 11.9618C22 17.4463 17.5534 21.9141 12.095 21.9189C10.3468 21.9189 8.64608 21.4606 7.14014 20.5919L2.00475 22L3.38242 16.7208C2.59382 15.2649 2.17577 13.6277 2.17577 11.9618L2.17577 11.957C2.17577 6.47255 6.62233 2.00477 12.0855 2C14.7316 2.00477 17.2209 3.04057 19.0926 4.92124ZM3.85273 11.9618C3.85273 13.4511 4.25178 14.9069 5.00238 16.1766L5.1829 16.4773L4.3658 19.6086L7.40618 18.7733L7.71972 18.969C9.03088 19.7995 10.5463 20.2339 12.0998 20.2339C16.6318 20.2339 20.3278 16.5203 20.3278 11.957C20.3278 9.74702 19.4679 7.67064 17.9145 6.10501C16.3563 4.54415 14.2898 3.68496 12.0903 3.68496C7.54394 3.68974 3.85273 7.40334 3.85273 11.9618ZM10.019 7.42243C10.1425 7.67064 10.7411 8.8926 10.8266 9.12172C10.9074 9.35084 10.9501 9.5179 10.7031 9.68019C10.4561 9.84726 9.89549 10.3246 9.73397 10.4678C9.5677 10.611 9.54869 10.759 9.67221 11.0072C9.79572 11.2554 10.057 12.0573 10.9026 13.0072C11.5582 13.747 12.3705 14.2434 12.6176 14.3914C12.8646 14.5346 12.9976 14.4057 13.1211 14.2816C13.2304 14.1718 13.4109 14.0334 13.5534 13.9093C13.696 13.7852 13.8005 13.7422 13.9667 13.6611C14.133 13.58 14.2755 13.6181 14.399 13.6802C14.5226 13.7422 15.7435 14.2387 16.2375 14.4487C16.7221 14.6492 16.6556 14.8544 16.6603 15.0072C16.6698 15.1504 16.6698 15.3174 16.6698 15.4845C16.6698 15.6516 16.6081 15.9189 16.361 16.148C16.114 16.3771 15.5154 17.0167 14.2945 17.0167C13.0736 17.0167 11.9002 16.1241 11.734 16C11.5677 15.8759 9.06888 14.2482 7.99525 11.7518C7.73872 11.1599 7.5867 10.6969 7.47268 10.3341C7.28266 9.73747 7.31117 9.19809 7.37292 8.7685C7.44418 8.29117 7.9715 7.29833 8.55107 7.08831C9.13064 6.88306 9.6247 6.88306 9.72922 6.94511C9.83373 7.00716 9.89549 7.17422 10.019 7.42243Z"
      fill="#2E2D37"
    />
  </svg>
)
