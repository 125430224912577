import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 17C20 17.551 19.551 18 19 18H5C4.449 18 4 17.551 4 17V9C4 8.449 4.449 8 5 8H7C7.265 8 7.52 7.895 7.707 7.707L9.121 6.293C9.31 6.104 9.562 6 9.829 6H14.171C14.438 6 14.69 6.104 14.879 6.293L16.293 7.707C16.48 7.895 16.735 8 17 8H19C19.551 8 20 8.449 20 9V10H18C17.448 10 17 10.448 17 11C17 11.552 17.448 12 18 12H20V17ZM19 6H17.414L16.293 4.879C15.727 4.312 14.973 4 14.171 4H9.829C9.027 4 8.273 4.312 7.707 4.879L6.586 6H5C3.346 6 2 7.346 2 9V17C2 18.654 3.346 20 5 20H19C20.654 20 22 18.654 22 17V9C22 7.346 20.654 6 19 6ZM12 15C10.897 15 10 14.103 10 13C10 11.897 10.897 11 12 11C13.103 11 14 11.897 14 13C14 14.103 13.103 15 12 15ZM12 9C9.794 9 8 10.794 8 13C8 15.206 9.794 17 12 17C14.206 17 16 15.206 16 13C16 10.794 14.206 9 12 9Z"
      fill="#2E2D37"
    />
  </svg>
)
