import React, { Component } from 'react';
import PhotoSlider from 'components/PhotoSlider'
import GoodDealSeal from 'components/GoodDealSeal'

export default class CardVehicleSignature extends Component {
    constructor(props) {
        super(props)
    }
    handleClick() {
        this.props.onClick && this.props.onClick()
    }
    render() {
        return (
            <div id={this.props.id ? this.props.id : undefined} className='CardVehicle CardVehicle--signature'>
                <figure>
                    <PhotoSlider linkCarousel={this.props.data.linkCard} listPhotos={this.props.data.listPhotos} tag={this.props.data.tag} className={'CardVehicle__thumb'} alt='Foto do veículo' width={176} height={136}></PhotoSlider>
                </figure>
                <a rel="noreferrer nofollow" href={this.props.data.linkCard} onClick={this.handleClick.bind(this)} target='_blank' id={`IdDoAnuncioSignature_${this.props.data.uniqueId}`} className='CardVehicle__link'>
                    <div className='CardVehicle__details'>
                        <div className='CardVehicle__details__header'>
                            <strong id="TagVehicleSignature" className='CardVehicle__seal CardVehicle__seal--signature'>IPVA, seguro e manutenção incluidos</strong>
                            {this.props.data.goodDeal ? <GoodDealSeal /> : ''}
                            <h2 className="CardVehicle__details__header__title">{this.props.data.title}</h2>
                            <h3 className="CardVehicle__details__header__description">{this.props.data.description}</h3>
                        </div>
                        <div className='CardVehicle__details__content'>
                            <b className='CardVehicle__details__content__signature'>A partir de</b>
                            <strong className={`${this.props.data.price.length > 20 ? "CardVehicle__details__content__price-resize" : "CardVehicle__details__content__price"}`}>{this.props.data.price}</strong>
                            <b className='CardVehicle__details__content__signature'>assinatura mensal</b>
                        </div>
                        <div className='CardVehicle__details__specification'>
                            <span className="CardVehicle__details__specification__year">{this.props.data.year}</span>
                            <span className={"CardVehicle__details__specification__km" + (this.props.data.odometer == '0' ? ' CardVehicle__details__specification__km--zero' : '')}>{this.props.data.km}</span>
                        </div>
                        <div className='CardVehicle__details__footer'>
                            <span className="CardVehicle__details__footer__state">{this.props.data.city}</span>
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}
