import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3 17.966C4.63054 19.0127 6.57437 19.625 8.6606 19.625C15.5174 19.625 19.3837 13.8362 19.1559 8.64557C19.875 8.12579 20.5016 7.47785 21 6.73734C20.3378 7.02927 19.6258 7.22864 18.8782 7.3212C19.64 6.86551 20.2239 6.13924 20.5016 5.27769C19.7896 5.69778 18.9992 6.01108 18.159 6.17484C17.4755 5.44858 16.5214 5 15.4533 5C13.068 5 11.3165 7.22152 11.8576 9.5356C8.79589 9.37896 6.07595 7.91218 4.25316 5.67642C3.28481 7.33544 3.75475 9.50712 5.3924 10.6036C4.78718 10.5823 4.21756 10.4185 3.71915 10.1408C3.67642 11.8497 4.90823 13.4517 6.68117 13.8078C6.16139 13.9502 5.59177 13.9786 5.01503 13.8718C5.48497 15.3386 6.84494 16.4066 8.46123 16.4351C6.91614 17.6527 4.95807 18.1938 3 17.966Z"
      fill="#2E2D37"
    />
  </svg>
)
