import React from 'react'
import ModalContainer from '@/modern/components/ModalContainer'
import useModal from '@/modern/actions/useModal'

import Icon from '@/modern/components/Icon'
import Button from '@/modern/components/Button'
import {
  useCookieNoticeDispatch,
  useCookieNoticeState,
} from '../../../store/context/cookie-notice-context'
import Switches from './Switches'

import * as S from './style'

const CookieControlModal = () => {
  const { modal, closeModal } = useModal('cookieControlModal')
  const { showCookieNotice } = useCookieNoticeState()
  const {
    persistCookieNotice,
    resetState,
    hideNotice,
  } = useCookieNoticeDispatch()

  const handleSave = () => {
    persistCookieNotice()
    closeModal()

    if (showCookieNotice) {
      hideNotice()
    }
  }

  const handleClose = () => {
    resetState()
    closeModal()
  }

  return (
    <ModalContainer show={modal} smaller useWhiteBox onBgClick={handleClose}>
      <S.Container>
        <S.MainInfo>
          <S.IconButton onClick={handleClose}>
            <Icon name="Close_1" fill="#2E2E37" size="20" />
          </S.IconButton>
          <S.Title>Uso de Cookies</S.Title>
          <S.Text marginTop={5}>
            Os Cookies são pequenos arquivos que ajustam a sua navegação pra que
            você tenha a melhor experiência dentro da Webmotors, assim como os
            ajustes que você faz no carro pra dirigir com conforto e segurança.
            ;) Saiba mais na nossa{' '}
            <S.Link
              target="_blank"
              href="https://www.webmotors.com.br/assets/termos-de-uso-e-politica-de-privacidade.pdf"
            >
              Política de privacidade{' '}
            </S.Link>
            e
            <S.Link
              target="_blank"
              href="https://www.webmotors.com.br/assets/politica-de-cookies.pdf"
            >
              {' '}
              Política de Cookies
            </S.Link>
            .
          </S.Text>
          <S.Text marginTop={30}>
            Defina os tipos de Cookies que podemos usar:
          </S.Text>
        </S.MainInfo>
        <S.SwitchArea>
          <Switches />
        </S.SwitchArea>
        <S.Flex>
          <Button onClick={handleSave}>Salvar preferências</Button>
        </S.Flex>
      </S.Container>
    </ModalContainer>
  )
}

export default CookieControlModal
