import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM8.5 12C9.328 12 10 11.329 10 10.5C10 9.671 9.328 9 8.5 9C7.672 9 7 9.671 7 10.5C7 11.329 7.672 12 8.5 12ZM15.5 9C14.672 9 14 9.671 14 10.5C14 11.329 14.672 12 15.5 12C16.328 12 17 11.329 17 10.5C17 9.671 16.328 9 15.5 9ZM15.2026 14.397C14.4386 15.416 13.2716 16 11.9996 16C10.7296 16 9.5626 15.417 8.7986 14.398C8.4666 13.957 7.8406 13.869 7.3986 14.199C6.9566 14.531 6.8676 15.158 7.1996 15.6C8.3446 17.125 10.0946 18 11.9996 18C13.9066 18 15.6576 17.124 16.8026 15.597C17.1336 15.155 17.0446 14.528 16.6026 14.197C16.1596 13.865 15.5336 13.956 15.2026 14.397Z"
      fill="#2E2D37"
    />
  </svg>
)
