import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7588 15.2607L14.5388 13.5807C14.8258 13.1207 14.9998 12.5817 14.9998 11.9997C14.9998 11.9557 14.9888 11.9137 14.9868 11.8697L16.9238 11.2407C16.9618 11.4897 16.9998 11.7397 16.9998 11.9997C16.9998 13.2517 16.5218 14.3827 15.7588 15.2607ZM11.9998 16.9997C11.2178 16.9997 10.4868 16.8037 9.8258 16.4817L11.0298 14.8247C11.3358 14.9307 11.6588 14.9997 11.9998 14.9997C12.3418 14.9997 12.6638 14.9307 12.9698 14.8247L14.1738 16.4817C13.5138 16.8037 12.7818 16.9997 11.9998 16.9997ZM6.9998 11.9997C6.9998 11.7397 7.0378 11.4897 7.0768 11.2407L9.0128 11.8697C9.0108 11.9137 8.9998 11.9557 8.9998 11.9997C8.9998 12.5817 9.1738 13.1207 9.4618 13.5807L8.2408 15.2607C7.4788 14.3827 6.9998 13.2517 6.9998 11.9997ZM10.9998 7.1007V9.1837C10.5218 9.3547 10.1018 9.6407 9.7698 10.0127L7.7698 9.3627C8.4848 8.2197 9.6368 7.3787 10.9998 7.1007ZM10.9998 11.9997C10.9998 11.4487 11.4488 10.9997 11.9998 10.9997C12.5508 10.9997 12.9998 11.4487 12.9998 11.9997C12.9998 12.5517 12.5508 12.9997 11.9998 12.9997C11.4488 12.9997 10.9998 12.5517 10.9998 11.9997ZM16.2298 9.3627L14.2308 10.0127C13.8988 9.6407 13.4788 9.3547 12.9998 9.1837V7.1007C14.3628 7.3787 15.5148 8.2197 16.2298 9.3627ZM11.9998 4.9997C8.1338 4.9997 4.9998 8.1337 4.9998 11.9997C4.9998 15.8657 8.1338 18.9997 11.9998 18.9997C15.8658 18.9997 18.9998 15.8657 18.9998 11.9997C18.9998 8.1337 15.8658 4.9997 11.9998 4.9997ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z"
      fill="#2E2D37"
    />
  </svg>
)
