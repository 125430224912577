import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M11.2901 9.71C11.3301 9.75 11.3801 9.77 11.4301 9.8C11.4901 9.84 11.5501 9.89 11.6201 9.92C11.7401 9.97 11.8701 10 12.0001 10C12.0501 10 12.1001 9.98 12.1501 9.97C12.2201 9.96 12.3001 9.95 12.3801 9.92C12.5001 9.87 12.6201 9.79 12.7101 9.7L15.7001 6.71C16.0901 6.32 16.0901 5.69 15.7001 5.3C15.3101 4.91 14.6801 4.91 14.2901 5.3L13.0001 6.58V3C13.0001 2.45 12.5501 2 12.0001 2C11.4501 2 11.0001 2.45 11.0001 3V6.59L9.70006 5.29C9.31006 4.9 8.68006 4.9 8.29006 5.29C7.90006 5.68 7.90006 6.31 8.29006 6.7L11.2901 9.71Z"
      fill="black"
    />
    <path
      d="M19.99 9.95005C19.99 9.92005 19.98 9.90005 19.98 9.88005C19.97 9.78005 19.94 9.68005 19.9 9.59005C19.89 9.58005 19.89 9.56005 19.88 9.55005C19.83 9.45005 19.76 9.36005 19.68 9.29005C19.66 9.27005 19.64 9.26005 19.62 9.24005C19.59 9.22005 19.57 9.20005 19.55 9.18005C19.48 9.13005 19.4 9.10005 19.32 9.07005L16.32 8.07005C15.79 7.89005 15.23 8.18005 15.05 8.70005C14.88 9.22005 15.16 9.79005 15.68 9.97005L16.18 10.1401L12 11.9101L7.81 10.1201L8.32 9.95005C8.84 9.78005 9.13 9.21005 8.95 8.68005C8.77 8.16005 8.21 7.88005 7.68 8.05005L4.68 9.05005C4.6 9.08005 4.52 9.12005 4.45 9.17005C4.42 9.18005 4.4 9.21005 4.38 9.23005C4.36 9.24005 4.34 9.26005 4.32 9.28005C4.24 9.36005 4.17 9.44005 4.12 9.54005C4.11 9.56005 4.1 9.57005 4.1 9.59005C4.05 9.68005 4.03 9.77005 4.02 9.88005C4.02 9.91005 4.01 9.93005 4.01 9.95005C4.01 9.97005 4 9.98005 4 10.0001V18.0001C4 18.4001 4.24 18.7601 4.61 18.9201L11.61 21.9201C11.73 21.9701 11.86 22.0001 12 22.0001C12.14 22.0001 12.27 21.9701 12.39 21.9201L19.39 18.9201C19.76 18.7601 20 18.4001 20 18.0001V10.0001C20 9.98005 19.99 9.97005 19.99 9.95005ZM6 11.5201L11 13.6601V19.4801L6 17.3401V11.5201ZM18 17.3401L13 19.4801V13.6501L18 11.5101V17.3401Z"
      fill="black"
    />
  </svg>
)
