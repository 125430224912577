import 'reflect-metadata'
import { AppContainer } from 'react-hot-loader'
import React from 'react'
import { render } from 'react-dom'
import Loadable from 'react-loadable'
import FairsService from '@webmotors/wm-portal-lib-api-fairs'
import { config } from 'config/config'

import '@/scss/normalize.css'
import '@/scss/global.scss'
import '@/scss/layout.scss'

import { App as _App } from '@webmotors/wm-portal-business'
import App from './App'

FairsService.setConfigurationLib({
  baseURLFairs: config.urlApi,
})

_App.init({
  apiUrl: config.urlApi,
  authCookieName: config.cookieJwt,
  cookieDomains: config.cookieDomains,
  logging: false,
})

Promise.all([Loadable.preloadReady()]).then(() => {
  render(
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById('root')
  )
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', () => {
      render(
        <AppContainer>
          <App />
        </AppContainer>,
        document.getElementById('root')
      )
    })
  }
})
