import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../../styles/theme'

import { Container, Inner } from './style'

const Button = React.forwardRef(
  ({ children, size, variation, outline, icon, disabled, ...rest }, ref) => (
    <Container ref={ref} disabled={disabled} {...rest}>
      <Inner
        size={size}
        variation={variation}
        outline={outline}
        disabled={disabled}
        theme={theme}
      >
        {children}
      </Inner>
    </Container>
  )
)

Button.defaultProps = {
  size: 'big',
  variation: 'primary',
  outline: false,
  disabled: false,
  icon: null,
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  variation: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'cancel']),
  size: PropTypes.oneOf(['big', 'small']),
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
}

export default Button
