import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16C12.256 16 12.512 15.902 12.707 15.707L16.707 11.707C17.098 11.316 17.098 10.684 16.707 10.293C16.316 9.902 15.684 9.902 15.293 10.293L11.293 14.293C10.902 14.684 10.902 15.316 11.293 15.707C11.488 15.902 11.744 16 12 16ZM12 7C16.411 7 20 10.589 20 15C20 15.68 19.915 16.35 19.747 17H4.253C4.085 16.351 4 15.68 4 15C4 10.589 7.589 7 12 7ZM21.431 18.333C21.809 17.264 22 16.143 22 15C22 9.486 17.514 5 12 5C6.486 5 2 9.486 2 15C2 16.144 2.192 17.266 2.57 18.334C2.575 18.35 2.586 18.361 2.593 18.376C2.6 18.393 2.61 18.406 2.618 18.423C2.663 18.519 2.72 18.604 2.79 18.677C2.808 18.697 2.828 18.714 2.848 18.733C2.919 18.798 2.997 18.85 3.083 18.892C3.107 18.904 3.13 18.917 3.155 18.927C3.269 18.971 3.388 19.001 3.513 19.001C3.516 19.001 3.519 19 3.521 19H20.488C20.497 19 20.505 18.995 20.514 18.995C20.596 18.993 20.671 18.969 20.749 18.947C20.798 18.934 20.849 18.93 20.895 18.909C20.957 18.881 21.008 18.836 21.064 18.795C21.112 18.76 21.166 18.732 21.207 18.689C21.247 18.647 21.271 18.593 21.305 18.544C21.345 18.484 21.389 18.428 21.415 18.359C21.419 18.35 21.427 18.343 21.431 18.333Z"
      fill="#2E2D37"
    />
  </svg>
)
