import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8815 11.2388C15.86 10.5896 15.6896 9.9403 15.3697 9.35821L12.5545 4.23134C12.3412 3.85075 12.1919 3.42537 12.1066 3L11.9787 3.22388C11.2749 4.5 11.2749 6.04478 11.9787 7.3209L14.2393 11.4179C14.9431 12.694 14.9431 14.2388 14.2393 15.5149L14.1114 15.7388C14.0261 15.3134 13.8768 14.8881 13.6635 14.5075L11.5948 10.7463L10.2938 8.35075C10.0806 7.97015 9.93128 7.54478 9.84597 7.1194L9.71801 7.34328C9.01422 8.59701 9.01422 10.1642 9.71801 11.4179L12 15.5149C12.7038 16.791 12.7038 18.3358 12 19.6119L11.872 19.8358C11.7867 19.4104 11.6374 18.9851 11.4242 18.6045L8.609 13.4776C8.22512 12.7836 8.0545 12.0224 8.09716 11.2612C5.09005 12.0896 3 13.8358 3 15.8731C3 18.694 7.03081 21 12 21C16.9692 21 21 18.7164 21 15.8731C20.9785 13.8134 18.91 12.0672 15.8815 11.2388Z"
      fill="white"
    />
  </svg>
)
