import { AD_TYPE, VEHICLE_TYPE } from '@/utils/constants'

export function formattedInsiderTrackObject({ details = {} } = {}) {
  if (Object.keys(details).length === 0) return {}

  const {
    UniqueId = '',
    ListingType = '',
    Prices: { Price = '' } = {},
    Seller: { SellerType = '', City = '', State = '' } = {},
    Type = '',
    VehiclePhotos = [{}],
    Specification: {
      Make = {},
      Model = {},
      Version = {},
      BodyType = '',
      Color = {},
      Fuel = '',
      Transmission = '',
      Odometer = '',
      VehicleAttributes = [],
      YearFabrication = '',
      YearModel = '',
      FinalPlate = '',
    } = {},
  } = details

  const availableAttributes = VehicleAttributes.map(attribute => {
    return attribute.Name
  })
  const exchangeable = availableAttributes.includes('Aceita troca')
    ? 'Aceita troca'
    : 'Não aceita troca'
  const year = YearFabrication
    ? `${parseInt(YearFabrication, 10)}/${parseInt(YearModel, 10)}`
    : ''
  let vehicleType
  switch (Type) {
    case VEHICLE_TYPE.car:
      vehicleType = 'Carro'
      break
    case VEHICLE_TYPE.bike:
      vehicleType = 'Moto'
      break
    default:
      vehicleType = ''
      break
  }
  const city =
    City && State
      ? `${City.replace(/-/g, ' ')} - ${State.substr(
          State.indexOf('(') + 1,
          2
        )}`
      : ''

  return {
    id: String(UniqueId),
    make: Make.Value || '',
    model: Model.Value || '',
    version: Version.Value || '',
    bodyType: BodyType,
    color: Color.Primary || '',
    fuel: Fuel,
    transmission: Transmission,
    kilometer: parseFloat(Odometer),
    condition: ListingType === AD_TYPE.NEW ? 'Novo' : 'Usado',
    exchangeable,
    IPVA: availableAttributes.includes('IPVA pago') ? 'Pago' : 'Não pago',
    year,
    finalPlate: FinalPlate ? parseFloat(FinalPlate) : '',
    price: Price ? parseFloat(Price) : '',
    vehicleType,
    city,
    sellerType: SellerType,
    vehiclePhoto: VehiclePhotos[0].urlBig || '',
    status: details.status || '',
    releasedValue: details.releasedValue || '',
  }
}

export function insiderEventModel({ details = {}, eventName = '' } = {}) {
  const {
    make,
    model,
    price,
    vehicleType,
    city,
    sellerType,
    status,
    releasedValue
  } = formattedInsiderTrackObject({ details });

  const insiderObject = {
    event_name: eventName,
    timestamp: new Date().toISOString().replace(/\.\d+Z$/, 'Z'),
    event_params: {
      custom: {
        page_url: window.location.href,
        dealer_city: city,
        dealer_type: sellerType,
        vehicle_make: make,
        vehicle_model: model,
        vehicle_price: price,
        vehicle_type: vehicleType,
        ...(status && { simulation_status: status }),
        ...(releasedValue && { financing_valor_liberado: releasedValue }),
      },
    },
  }

  return [insiderObject]
}
