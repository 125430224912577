import React, { Fragment, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Layout from '@/App/layout'
import useFavorites from '@/modern/actions/useFavorites'
import useAuth from '@/modern/actions/useAuth'
import FavoritesModal from '@/modern/components/FavoritesModal'
import CookieControlModal from '@/modern/components/CookieControlModal'

const Router = () => {
  const { userIsAuth } = useAuth()

  const { setFavoriteList, checkFavoritesInLocalStorage } = useFavorites()
  useEffect(() => {
    setFavoriteList()
  }, [])

  useEffect(() => {
    checkFavoritesInLocalStorage()
  }, [userIsAuth])

  return (
    <Fragment>
      <FavoritesModal />
      <CookieControlModal />
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </Fragment>
  )
}

export default Router
