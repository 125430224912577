import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.99997C10.346 6.99997 9 8.34597 9 9.99997C9 11.654 10.346 13 12 13C13.654 13 15 11.654 15 9.99997C15 8.34597 13.654 6.99997 12 6.99997ZM12 2.00037C7.589 2.00037 4 5.58837 4 10.0004C4 16.4404 11.116 21.5974 11.419 21.8144C11.593 21.9384 11.796 22.0004 12 22.0004C12.204 22.0004 12.407 21.9384 12.581 21.8144C12.884 21.5974 20 16.4404 20 10.0004C20 5.58837 16.411 2.00037 12 2.00037Z"
      fill="#2E2D37"
    />
  </svg>
)
