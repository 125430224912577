import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21.5785 7.20232C21.3467 6.33825 20.6723 5.65332 19.8082 5.4215C18.254 5 12 5 12 5C12 5 5.74605 5 4.18651 5.4215C3.32771 5.65332 2.64805 6.33298 2.41623 7.20232C2 8.77239 2 12.0443 2 12.0443C2 12.0443 2 15.3214 2.41623 16.8915C2.64805 17.7555 3.32244 18.4405 4.18651 18.6723C5.74605 19.0938 12 19.0938 12 19.0938C12 19.0938 18.254 19.0938 19.8135 18.6723C20.6723 18.4405 21.3519 17.7608 21.5838 16.8915C22 15.3214 22 12.0443 22 12.0443C22 12.0443 22 8.77239 21.5785 7.20232ZM9.95574 15.0211V9.07271L15.1823 12.0443L9.95574 15.0211Z"
      fill="#2E2D37"
    />
  </svg>
)
