import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5309 8.45985C15.1409 8.06985 14.5079 8.06885 14.1159 8.45985L11.9949 10.5819L9.87389 8.45985C9.48389 8.06985 8.85089 8.06885 8.45989 8.45985C8.06889 8.85085 8.06989 9.48385 8.45989 9.87485L10.5809 11.9958L8.45989 14.1168C8.06889 14.5079 8.06989 15.1409 8.45989 15.5308C8.84989 15.9208 9.48289 15.9219 9.87389 15.5308L11.9949 13.4099L14.1159 15.5308C14.5069 15.9208 15.1399 15.9219 15.5309 15.5308C15.9219 15.1399 15.9209 14.5069 15.5309 14.1168L13.4089 11.9958L15.5309 9.87485C15.9219 9.48285 15.9209 8.85085 15.5309 8.45985ZM17.652 17.6523C14.533 20.7712 9.45699 20.7712 6.33899 17.6523C3.21899 14.5332 3.21899 9.45825 6.33899 6.33925C9.45699 3.21925 14.533 3.21925 17.652 6.33925C20.771 9.45825 20.771 14.5332 17.652 17.6523ZM19.067 4.92425C15.167 1.02525 8.82299 1.02525 4.92399 4.92425C1.02599 8.82325 1.02599 15.1672 4.92399 19.0672C8.82299 22.9653 15.167 22.9653 19.067 19.0672C22.965 15.1672 22.965 8.82325 19.067 4.92425Z"
      fill="#2E2D37"
    />
  </svg>
)
