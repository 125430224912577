import { slugify } from '@/modern/utils/transformString'

const adKeyBuilder = ad => ({
  ad: {
    id: ad.UniqueId || ad.id,
  },
})

const dealerKeyBuilder = ad => ({
  dealer: {
    id: (ad.Seller && ad.Seller.Id) || ad.sellerId,
  },
})

const vehicleKeyBuilder = ad => ({
  vehicle: {
    make: {
      name: slugify(
        (ad.Specification && ad.Specification.Make.Value) || ad.make
      ),
      id: (ad.Specification && ad.Specification.Make.id) || ad.makeId,
    },
    model: {
      name: slugify(
        (ad.Specification && ad.Specification.Model.Value) || ad.model
      ),
      id: (ad.Specification && ad.Specification.Model.id) || ad.modelId,
    },
    version: {
      name: slugify(
        (ad.Specification &&
          ad.Specification.Version &&
          ad.Specification.Version.Value) ||
          ad.version ||
          ''
      ),
      id: (ad.Specification && ad.Specification.Make.id) || ad.versionId,
    },
  },
})

const generalKeysBuilder = (
  ad,
  neededKeys = [adKeyBuilder, dealerKeyBuilder, vehicleKeyBuilder]
) => {
  const contextsArray = neededKeys.reduce(
    (acc, cur) => ({
      ...acc,
      ...cur(ad),
    }),
    {}
  )

  return contextsArray
}

export default generalKeysBuilder
export { adKeyBuilder, dealerKeyBuilder, vehicleKeyBuilder }
