import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, getUserTokenAuth, hasPandora } from '../utils/utils'
import { SELLER_TYPE_ENUM } from '@/utils/constants'

export const FETCH_PHONES = 'buyer/detail/FETCH_PHONES'
export const FETCH_PHONES_FAILURE = 'buyer/detail/FETCH_PHONES_FAILURE'

const api = axios.create({
  baseURL: getApiUrl(),
  headers: {
    Authorization: `${getUserTokenAuth()}`,
  },
})

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_PHONES:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('fetched', true)
        .done()

    case FETCH_PHONES_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('requested', action.requested)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

const extractVehicleData = (url, vehicleType) => {
  const [, brand, model, ...rest] = url.split('/')

  if (vehicleType === 'bike') {
    const [cc, years, id] = rest
    return { brand, model, cc, years, id }
  }

  const [version, ports, years, id] = rest
  return { brand, model, version, ports, years, id }
}

const createUrl = (data, sellerType) => {
  if (!location.href) {
    return ''
  }

  const { vehicleType } = data
  const [, slug] = location.href.split('comprar')
  const [url] = slug.split('?')

  const { brand, model, version, cc, years, id } = extractVehicleData(
    url,
    vehicleType
  )
  const versionOrCC = vehicleType === 'car' ? version : cc
  const [year] = years.split('-')

  const phoneEndpoint =
    sellerType === SELLER_TYPE_ENUM.PJ
      ? 'detail/phone/dealer'
      : 'detail/phone/private-seller'

  return `/${phoneEndpoint}/${vehicleType}/${brand}/${model}/${versionOrCC}/${year}/${id}`
}

export function fetchPhonesDetail(data, sellerType) {
  const url = createUrl(data, sellerType)
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    return api
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_PHONES,
          payload: res.data,
          requested: data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_PHONES_FAILURE,
          payload: err.stack,
          requested: data,
        })
      })
  }
}
