import React from 'react'

import useModal from '@/modern/actions/useModal'
import {
  useCookieNoticeState,
  useCookieNoticeDispatch,
} from '../../../store/context/cookie-notice-context'
import * as S from './style'

const CookieNotice = () => {
  const { showCookieNotice } = useCookieNoticeState()
  const { persistCookieNotice, hideNotice } = useCookieNoticeDispatch()

  const { openModal } = useModal('cookieControlModal')

  const handleOpenModal = () => {
    openModal()
  }

  const handleOk = () => {
    hideNotice(true)
  }

  React.useEffect(() => {
    if (!showCookieNotice) {
      persistCookieNotice()
    }
  }, [showCookieNotice, persistCookieNotice])

  return (
    <S.Container show={showCookieNotice}>
      <S.Main>
        <p>
          A Webmotors usa Cookies, pequenos arquivos para aprimorar e proteger a
          sua experiência :)
        </p>
        <p>
          <button
            type="button"
            id="btn_cookieWarnSettings"
            data-qa="btn_cookieWarnSettings"
            onClick={handleOpenModal}
          >
            Clique aqui
          </button>{' '}
          para mudar suas preferências ou saiba mais na nossa{' '}
          <S.Link
            id="btn_cookieWarnPrivacy"
            data-qa="btn_cookieWarnPrivacy"
            target="_blank"
            rel="noopener"
            href="https://www.webmotors.com.br/seguranca/politica-de-privacidade"
          >
            Termos de Uso e Política de Privacidade{' '}
          </S.Link>{' '}
          e{' '}
          <S.Link
            id="btn_cookieWarnCookiesPrivacy"
            data-qa="btn_cookieWarnCookiesPrivacy"
            target="_blank"
            rel="noopener"
            href="https://www.webmotors.com.br/assets/politica-de-cookies.pdf"
          >
            Política de Cookies
          </S.Link>
          .
        </p>
      </S.Main>
      <S.BtnUnderstood
        id="btn_understoodCookieWarn"
        data-qa="btn_understoodCookieWarn"
        onClick={handleOk}
      >
        Entendi
      </S.BtnUnderstood>
    </S.Container>
  )
}

export default CookieNotice
