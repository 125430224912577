import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 19H17.414L16 17.586V16C16 15.448 15.552 15 15 15H13.414L12.297 13.883C12.296 13.882 12.295 13.882 12.293 13.881C12.201 13.789 12.091 13.717 11.972 13.667C11.903 13.639 11.829 13.635 11.756 13.623C11.701 13.613 11.649 13.591 11.593 13.591C11.465 13.591 11.337 13.614 11.216 13.663C11.214 13.664 11.211 13.664 11.209 13.665C10.667 13.887 10.091 14 9.5 14C7.019 14 5 11.981 5 9.5C5 7.019 7.019 5 9.5 5C11.981 5 14 7.019 14 9.5C14 10.092 13.887 10.667 13.665 11.209C13.563 11.454 13.573 11.715 13.66 11.944C13.663 11.952 13.662 11.961 13.666 11.969C13.715 12.09 13.788 12.201 13.882 12.295C13.883 12.296 13.884 12.298 13.885 12.299L19 17.414V19ZM20.707 16.293L15.738 11.324C15.911 10.734 16 10.124 16 9.5C16 5.916 13.084 3 9.5 3C5.916 3 3 5.916 3 9.5C3 13.084 5.916 16 9.5 16C10.124 16 10.734 15.911 11.324 15.738L12.293 16.707C12.48 16.895 12.735 17 13 17H14V18C14 18.265 14.105 18.52 14.293 18.707L16.293 20.707C16.48 20.895 16.735 21 17 21H20C20.552 21 21 20.552 21 20V17C21 16.735 20.895 16.48 20.707 16.293ZM8.5 7C7.672 7 7 7.671 7 8.5C7 9.329 7.672 10 8.5 10C9.328 10 10 9.329 10 8.5C10 7.671 9.328 7 8.5 7Z"
      fill="#2E2D37"
    />
  </svg>
)
