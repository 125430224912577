import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.714 17H4.285C4.128 17 4 16.872 4 16.714V8.166L11.375 14.066C11.558 14.212 11.778 14.286 12 14.286C12.221 14.286 12.442 14.212 12.624 14.066L20 8.166V16.714C20 16.872 19.872 17 19.714 17ZM18.256 7L12 12.005L5.743 7H18.256ZM19.714 5H4.285C3.025 5 2 6.025 2 7.286V16.714C2 17.975 3.025 19 4.285 19H19.714C20.974 19 22 17.975 22 16.714V7.286C22 6.025 20.974 5 19.714 5Z"
      fill="#2E2D37"
    />
  </svg>
)
