import Cookie from 'js-cookie'
import { encrypt, decrypt } from '@/modern/utils/crypto'
import { COOKIE_NOTICE_GROUPS, COOKIES } from '@/utils/constants'

import { config } from 'config/config'

export const getCookieCategory = cookieName => {
  const category = COOKIE_NOTICE_GROUPS.find(type => {
    const { cookies } = type
    const hasCookieInCategory = cookies.find(cookie => cookie === cookieName)

    return hasCookieInCategory
  })

  return category.key
}

export const cookieCategoryIsActive = (context, cookieName) => {
  const cookieCategory = getCookieCategory(cookieName)
  const trueKeys = context && Object.keys(context).filter(key => {
    return context[key]
  }) || []
  const isActive = trueKeys.find(key => key === cookieCategory)

  return !!isActive
}

export const getCookiesInCategory = category => {
  const cookieCategory = COOKIE_NOTICE_GROUPS.find(
    ({ key }) => key === category
  )

  if (cookieCategory) return cookieCategory.cookies

  return []
}

const handleFormDataCompatibility = content => JSON.parse(content)

const handleBackCompatibilityCookie = (content, name) => {
  const needsCompatibility = {
    [COOKIES.FORM_DATA]: handleFormDataCompatibility,
  }

  return needsCompatibility[name] ? needsCompatibility[name](content) : content
}

const getCookieEncriptionSecret = () => {
  return config.cookieEncryptionSecret
}

/**
 * Cria um cookie
 *
 * @see {@link https://github.com/js-cookie/js-cookie}
 * @param {string} name - Nome do cookie
 * @param {*} value - Valor que será encriptado
 * @param {object} [config={ path: '/' }] - Objeto de configuração do Cookie e/ou do secret de encriptação
 * @param {number|Date} [config.expires] - Objeto de configuração do Cookie e/ou do secret de encriptação
 * @param {string}  [config.path=/] - Indica o path aonde o cookie é visivel
 * @param {string} [config.domain] - Indica um domínio válido aonde o cookie vai ser visivel
 * @param {boolean} [config.secure] - Define se a trasmissão do cookie requer um protocolo seguro ou não
 * @param {string} [config.sameSite]
 */
export const setCookie = (name, value, config) =>
  Cookie.set(name, value, config)

/**
 * Cria um cookie com seu conteúdo encriptado
 *
 * @see {@link https://github.com/js-cookie/js-cookie}
 * @param {string} name - Nome do cookie
 * @param {*} value - Valor que será encriptado
 * @param {object} [config={ path: '/' }] - Objeto de configuração do Cookie e/ou do secret de encriptação
 * @param {number|Date} [config.expires] - Objeto de configuração do Cookie e/ou do secret de encriptação
 * @param {string}  [config.path=/] - Indica o path aonde o cookie é visivel
 * @param {string} [config.domain] - Indica um domínio válido aonde o cookie vai ser visivel
 * @param {boolean} [config.secure] - Define se a trasmissão do cookie requer um protocolo seguro ou não
 * @param {string} [config.sameSite]
 * @param {string} [config.secret] - Objeto de configuração do Cookie e/ou do secret de encriptação
 * @returns
 */
export const setEncryptedCookie = (name, value, config = {}) => {
  const secret = getCookieEncriptionSecret()
  const encryptedValue = encrypt(value, config.secret || secret)
  return setCookie(name, encryptedValue, config)
}

export const getCookie = name => Cookie.get(name)

export const getEncryptedCookie = (name, secret) => {
  const text = getCookie(name)

  if (!text) return text

  try {
    const defaultSecret = getCookieEncriptionSecret()

    return decrypt(text, secret || defaultSecret)
  } catch (error) {
    return handleBackCompatibilityCookie(text, name)
  }
}

export const removeCookie = name => Cookie.remove(name)
