import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 13.5C16.673 13.5 16 12.827 16 12C16 11.173 16.673 10.5 17.5 10.5C18.327 10.5 19 11.173 19 12C19 12.827 18.327 13.5 17.5 13.5ZM6.5 19C5.673 19 5 18.327 5 17.5C5 16.673 5.673 16 6.5 16C7.327 16 8 16.673 8 17.5C8 18.327 7.327 19 6.5 19ZM5 6.5C5 5.673 5.673 5 6.5 5C7.327 5 8 5.673 8 6.5C8 7.327 7.327 8 6.5 8C5.673 8 5 7.327 5 6.5ZM17.5 8.5C16.581 8.5 15.751 8.863 15.125 9.444L9.963 6.863C9.977 6.742 10 6.625 10 6.5C10 4.57 8.43 3 6.5 3C4.57 3 3 4.57 3 6.5C3 8.43 4.57 10 6.5 10C7.586 10 8.547 9.492 9.189 8.713L14.109 11.173C14.044 11.439 14 11.714 14 12C14 12.286 14.044 12.561 14.109 12.827L9.189 15.287C8.547 14.508 7.586 14 6.5 14C4.57 14 3 15.57 3 17.5C3 19.43 4.57 21 6.5 21C8.43 21 10 19.43 10 17.5C10 17.375 9.977 17.258 9.963 17.137L15.125 14.556C15.751 15.137 16.581 15.5 17.5 15.5C19.43 15.5 21 13.93 21 12C21 10.07 19.43 8.5 17.5 8.5Z"
      fill="#2E2D37"
    />
  </svg>
)
