import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 11C5.672 11 5 11.672 5 12.5C5 13.329 5.672 14 6.5 14C7.328 14 8 13.329 8 12.5C8 11.672 7.328 11 6.5 11ZM9.5 7C8.672 7 8 7.672 8 8.5C8 9.329 8.672 10 9.5 10C10.328 10 11 9.329 11 8.5C11 7.672 10.328 7 9.5 7ZM14.5 7C13.672 7 13 7.672 13 8.5C13 9.329 13.672 10 14.5 10C15.328 10 16 9.329 16 8.5C16 7.672 15.328 7 14.5 7ZM16 12.5C16 13.329 16.672 14 17.5 14C18.328 14 19 13.329 19 12.5C19 11.672 18.328 11 17.5 11C16.672 11 16 11.672 16 12.5ZM17 16H14C12.346 16 11 17.346 11 19C11 19.055 11.022 19.103 11.031 19.155C11.022 19.208 11 19.255 11 19.31C11 20.265 10.645 20.62 9.69 20.62C7.258 20.62 4 16.853 4 13C4 8.589 7.589 5 12 5C16.411 5 20 8.589 20 13C20 14.654 18.654 16 17 16ZM12 3C6.486 3 2 7.486 2 13C2 17.765 5.881 22.62 9.69 22.62C11.763 22.62 13 21.382 13 19.31C13 19.255 12.978 19.208 12.969 19.155C12.978 19.103 13 19.055 13 19C13 18.449 13.449 18 14 18H17C19.757 18 22 15.757 22 13C22 7.486 17.514 3 12 3Z"
      fill="#2E2D37"
    />
  </svg>
)
