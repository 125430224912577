import React from 'react'

export default () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
    <g clip-path="url(#clip0)">
      <path
        d="M17.04 4.39C16.99 4.38 11.77 3.43 9.76 0.42C9.39 -0.14 8.47 -0.14 8.1 0.42C6.1 3.42 0.879996 4.38 0.819996 4.39C0.349996 4.47 -3.93239e-06 4.89 -3.93239e-06 5.37V6.62C-0.0500039 10.3 2.47 17.69 8.74 18.95C8.8 18.96 8.87 18.97 8.94 18.97C9.01 18.97 9.07 18.96 9.14 18.95C15.41 17.7 17.92 10.3 17.88 6.64V5.37C17.87 4.89 17.52 4.47 17.04 4.39Z"
        fill="#2E2D37"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17.87" height="18.97" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
