import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9C14.448 9 14 9.448 14 10V14C14 14.552 14.448 15 15 15C15.552 15 16 14.552 16 14V10C16 9.448 15.552 9 15 9ZM18.7673 15.7176C17.7911 16.9667 16.3962 17.7528 14.8403 17.9308C12.6196 18.1838 10.3872 17.7338 8.44562 16.6527C8.65842 16.6387 8.78727 16.6207 8.80191 16.6177C10.4057 16.4346 11.5653 14.9505 11.3867 13.3093C11.2901 12.4182 10.7678 10.8891 8.34801 10.663C8.32653 10.661 8.30506 10.66 8.28358 10.659C6.72274 10.62 5.65095 9.98898 4.95692 9.33692C6.85745 9.1979 8.1479 8.41882 9.40614 7.65874C10.6126 6.92967 11.7528 6.2406 13.5362 6.03658C16.7496 5.67254 19.6507 8.03878 20.0099 11.3181C20.1846 12.9073 19.7424 14.4694 18.7673 15.7176ZM21.9504 11.0951C21.4721 6.72065 17.5988 3.56333 13.3195 4.04938C11.1212 4.3004 9.68336 5.16949 8.41438 5.93657C6.84183 6.88667 5.59921 7.63774 3.11983 7.2587C2.77428 7.2057 2.4219 7.34871 2.20617 7.63374C1.99044 7.91777 1.93969 8.29981 2.07439 8.63284C2.13979 8.79186 3.70551 12.5252 8.20061 12.6573C9.36514 12.7743 9.41395 13.2283 9.44713 13.5303C9.5057 14.0754 9.11915 14.5694 8.55983 14.6335C8.54226 14.6355 6.85159 14.8595 5.52698 14.1484C5.12286 13.9324 4.62991 14.0304 4.33317 14.3844C4.0374 14.7375 4.01885 15.2535 4.28826 15.6286C4.32731 15.6816 5.24976 16.9537 6.55387 17.8178C8.64768 19.2449 11.1173 20 13.6299 20C14.1052 20 14.5806 19.973 15.057 19.918C17.1303 19.681 18.9898 18.6339 20.291 16.9677C21.5932 15.3015 22.1828 13.2163 21.9504 11.0951Z"
      fill="#2E2D37"
    />
  </svg>
)
