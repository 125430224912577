import { generateMD5Hash, getLoggedUserProperties } from '@/utils/utils'
import { config } from 'config/config'

function handleId({ tntId, mcmid, sessionId }) {
  const userData = getLoggedUserProperties() || {}
  const hash = userData.email ? generateMD5Hash(userData.email) : ''
  const thirdPartyId = hash || sessionId
  return {
    ...(tntId && { tntId }),
    ...(mcmid && { marketingCloudVisitorId: mcmid }),
    ...(!tntId && !mcmid && { thirdPartyId }),
  }
}

function getGPUInfo() {
  const gl = document.createElement('canvas').getContext('webgl')
  if (!gl) {
    return 'WebGL not supported'
  }
  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
  return debugInfo
    ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
    : 'Could not get GPU info'
}

function getPlatform() {
  return navigator.userAgentData ? navigator.userAgentData.platform : 'unknown'
}

function isMobile() {
  return /Mobi/.test(navigator.userAgent)
}

function getOrientation() {
  return window.screen.orientation
    ? window.screen.orientation.type.split('-')[0]
    : undefined
}

function handleNotificationData({ defaultEnv, mbox }) {
  const { env = defaultEnv, name } = mbox
  const prefix = `${env}${env ? '_' : ''}`
  const { eventToken = '' } =
    JSON.parse(window.localStorage.getItem(`${prefix}${name}`)) || {}

  return { name, eventToken }
}

function handleMethod({ method, defaultEnv, mboxesData }) {
  const mboxes = mboxesData.map(({ name }, index) => ({
    name,
    index: index + 1,
  }))
  if (method === 'notifications') {
    const notifications = mboxesData
      .filter(mbox => {
        const { eventToken } = handleNotificationData({ defaultEnv, mbox })
        return !!eventToken
      })
      .map(mbox => {
        const { name, eventToken } = handleNotificationData({
          defaultEnv,
          mbox,
        })

        return {
          id: `${name}-notification`,
          timestamp: new Date().getTime(),
          type: 'display',
          mbox: {
            name,
          },
          tokens: [eventToken],
        }
      })

    return {
      notifications,
    }
  }
  if (method === 'prefetch') {
    return {
      prefetch: {
        mboxes,
      },
    }
  }
  return {
    execute: {
      mboxes,
    },
  }
}

export function deliveryData({
  mcmid = '',
  sdid = '',
  tntId = '',
  sessionId = '',
  qaData = null,
  method,
  defaultEnv = '',
  mboxesData = [],
} = {}) {
  const methodData = handleMethod({ method, defaultEnv, mboxesData })
  return {
    context: {
      address: {
        url: config.baseUrl || window.location.href,
        referringUrl: document.referrer,
      },
      browser: {
        host: window.location.host,
        webGLRenderer: getGPUInfo(),
      },
      channel: 'web',
      clientHints: {
        mobile: isMobile(),
        platform: getPlatform(),
      },
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        orientation: getOrientation(),
        colorDepth: window.screen.colorDepth,
        pixelRatio: window.devicePixelRatio,
      },
      userAgent: navigator.userAgent,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    },
    experienceCloud: {
      analytics: {
        trackingServer: 'webmotors.sc.omtrdc.net',
        logging: 'server_side',
        supplementalDataId: sdid,
      },
    },
    id: handleId({ tntId, mcmid, sessionId }),
    ...methodData,
    ...(qaData && { qaMode: JSON.parse(qaData) }),
  }
}
