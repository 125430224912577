import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrlSantander } from '../utils/utils'

export const FETCH_PREREGISTRATION = 'buyer/proposal/FETCH_PREREGISTRATION'
export const FETCH_PREREGISTRATION_FAILURE =
  'buyer/proposal/FETCH_PREREGISTRATION_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_PREREGISTRATION:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_PREREGISTRATION_FAILURE:
      return immutable
        .set('error', action.payload.stack)
        .set('errorID', action.payload.statusCode)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchPreRegistration(data) {
  const _url = `${getApiUrlSantander()}financiamento/pre-cadastro`
  return dispatch => {
    return axios
      .post(_url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        dispatch({
          type: FETCH_PREREGISTRATION,
          payload: res.data.Result,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_PREREGISTRATION_FAILURE,
          payload: {
            stack:
              err.response.data.Messages.length > 0
                ? err.response.data.Messages[0]
                : err.stack,
            statusCode: err.response.data.StatusCode,
          },
        })
      })
  }
}
