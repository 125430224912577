import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 22C6.49 22 2 17.52 2 12C2 6.48 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.52 22 12 22ZM12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4Z"
      fill="#2E2D37"
    />
    <path
      d="M15.49 11.1399L10.52 8.15995C9.85 7.75995 9 8.23995 9 9.01995V14.9899C9 15.7699 9.85 16.2499 10.51 15.8499L15.48 12.8599C16.13 12.4699 16.13 11.5299 15.49 11.1399Z"
      fill="#2E2D37"
    />
  </svg>
)
