import styled from 'styled-components'

const config = {
  baseUrl: '/assets/img/vistoriado/card/',
  heightContainer: '24px',
  heightLine: '12px',
  color: '#2e2d37',
}

export const Stamp = styled.div`
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    border-top-left-radius: 4px;
    background: url(${config.baseUrl}vistoriado-brand.svg) 15px 6px
        no-repeat,
      url(${config.baseUrl}vistoriado-background.svg) left top no-repeat;
    width: 100%;
    height: ${config.heightContainer};
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    border-radius: 4px 4px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: ${config.heightLine};
    background-color: ${config.color};
  }
`
