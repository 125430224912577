import React from 'react'
import PropTypes from 'prop-types'

import { onClientSide } from '@/utils/utils'
import { COOKIE_NOTICE_TYPES } from '@/utils/constants'
import useReducerLocalStorage from '@/modern/hooks/useReducerLocalStorage'

const CookieNoticeStateContext = React.createContext()
const CookieNoticeDispatchContext = React.createContext()

const TOGGLE = 'cookie-notice/toggle'
const HIDE = 'cookie-notice/hide'
const RESET = 'cookie-notice/reset'
const RESET_PERSISTED = 'reset-persisted'

const cookieNoticeInitialState = {
	showCookieNotice: onClientSide(),
	cookies: {
		[COOKIE_NOTICE_TYPES.PROPOSALS]: true,
		[COOKIE_NOTICE_TYPES.SEARCH]: true,
		[COOKIE_NOTICE_TYPES.MARKETING]: true,
		[COOKIE_NOTICE_TYPES.EXPERIENCE]: true,
	},
}

function cookieNoticeReducer(state, action) {
	switch (action.type) {
		case TOGGLE: {
			const { cookieCategory } = action
			return {
				...state,
				cookies: {
					...state.cookies,
					[cookieCategory]: !state.cookies[cookieCategory],
				},
			}
		}
		case HIDE: {
			return { ...state, showCookieNotice: false }
		}
		case RESET: {
			return {
				...state,
				cookies: {
					...cookieNoticeInitialState.cookies,
				},
			}
		}
		case RESET_PERSISTED: {
			const mergeData = action.cookies || cookieNoticeInitialState
			return {
				...state,
				...mergeData,
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

const CookieNoticeProvider = ({ children }) => {
	const [
		state,
		dispatch,
		saveCookieNotice,
		resetToLastSavedCookieNotice,
	] = useReducerLocalStorage(
		cookieNoticeReducer,
		cookieNoticeInitialState,
		'wmCookieNotice',
		false
	)

	const toggleCategory = React.useCallback(
		cookieCategory => {
			dispatch({ type: TOGGLE, cookieCategory })
		},
		[dispatch]
	)

	const hideNotice = React.useCallback(() => dispatch({ type: HIDE }), [
		dispatch,
	])

	const resetCookies = React.useCallback(() => dispatch({ type: RESET }), [
		dispatch,
	])

	const persistCookieNotice = React.useCallback(() => saveCookieNotice(), [
		saveCookieNotice,
	])
	const resetState = React.useCallback(() => resetToLastSavedCookieNotice(), [
		resetToLastSavedCookieNotice,
	])

	const dispatchValue = {
		persistCookieNotice,
		resetState,
		toggleCategory,
		hideNotice,
		resetCookies,
	}
	return (
		<CookieNoticeStateContext.Provider value={state}>
			<CookieNoticeDispatchContext.Provider value={dispatchValue}>
				{children}
			</CookieNoticeDispatchContext.Provider>
		</CookieNoticeStateContext.Provider>
	)
}

CookieNoticeProvider.propTypes = {
	children: PropTypes.element.isRequired,
}

const useCookieNoticeState = () => {
	const context = React.useContext(CookieNoticeStateContext)
	if (onClientSide()) {
		if (context === undefined) {
			throw new Error(
				'useCookieNoticeState can only be used within CookieNoticeProvider'
			)
		}
	}
	return context || cookieNoticeInitialState;
}

const useCookieNoticeDispatch = () => {
	const context = React.useContext(CookieNoticeDispatchContext)
	if (onClientSide()) {
		if (context === undefined) {
			throw new Error(
				'useCookieNoticeDispatch can only be used within CookieNoticeProvider'
			)
		}
	}
	return context || cookieNoticeInitialState;
}

const CookieNoticeConsumer = ({ children }) => {
	return (
		<CookieNoticeStateContext.Consumer>
			{context => {
				if (onClientSide()) {
					if (context === undefined) {
						throw new Error(
							'useCookieNoticeDispatch can only be used within CookieNoticeProvider'
						)
					}
				}
				return children(context || cookieNoticeInitialState);
			}}
		</CookieNoticeStateContext.Consumer>
	)
}

CookieNoticeConsumer.propTypes = {
	children: PropTypes.func.isRequired,
}

const withCookieNotice = WrappedComponent => props => {
	return (
		<CookieNoticeConsumer>
			{cookies => <WrappedComponent {...props} cookieNoticeContext={cookies} />}
		</CookieNoticeConsumer>
	)
}

withCookieNotice.propTypes = {
	WrappedComponent: PropTypes.node.isRequired,
}

export {
	CookieNoticeProvider,
	useCookieNoticeState,
	useCookieNoticeDispatch,
	CookieNoticeConsumer,
	CookieNoticeStateContext,
	withCookieNotice,
}
