import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99991 11H18.5859L13.2929 5.70701C12.9019 5.31601 12.9019 4.68401 13.2929 4.29301C13.6839 3.90201 14.3159 3.90201 14.7069 4.29301L21.7069 11.292C21.7989 11.385 21.8719 11.496 21.9229 11.618C22.0239 11.862 22.0239 12.138 21.9229 12.382C21.8719 12.504 21.7989 12.615 21.7069 12.708L14.7069 19.707C14.5119 19.902 14.2559 20 13.9999 20C13.7439 20 13.4879 19.902 13.2929 19.707C12.9019 19.316 12.9019 18.684 13.2929 18.293L18.5859 13H2.99991C2.44791 13 1.99991 12.552 1.99991 12C1.99991 11.448 2.44791 11 2.99991 11Z"
      fill="#2E2D37"
    />
  </svg>
)
