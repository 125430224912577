const initialState = { schedule: 'no-info', weekSchedule: [], badge: false }

export default (state = initialState, { type, key, value = '' }) => {
  let newState

  switch (type) {
    case '@newSellerInfo/SET':
        newState = state
        newState[key] = value
        break
    case '@newSellerInfo/RESET':
        newState = { schedule: 'no-info', weekSchedule: [], badge: false }
        break
    default:
        newState = state
        break
  }

  return newState
}
