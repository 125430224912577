import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2417 12.0337C8.7937 12.0337 9.2417 11.5857 9.2417 11.0337C9.2417 10.4817 8.7937 10.0337 8.2417 10.0337H2.8597L4.8597 6.03369H13.6237L14.3467 7.48069C14.5947 7.97469 15.1947 8.17569 15.6887 7.92869C16.1827 7.68069 16.3837 7.08069 16.1367 6.58669L15.1367 4.58669C14.9667 4.24769 14.6207 4.03369 14.2417 4.03369H4.2417C3.8627 4.03369 3.5167 4.24769 3.3467 4.58669L0.346699 10.5867C0.341699 10.5987 0.339699 10.6117 0.333699 10.6237C0.324699 10.6457 0.319699 10.6677 0.310699 10.6897C0.274699 10.7887 0.253699 10.8907 0.249699 10.9947C0.248699 11.0087 0.241699 11.0197 0.241699 11.0337V17.0337C0.241699 17.0977 0.266699 17.1537 0.277699 17.2137C0.255699 17.3177 0.241699 17.4237 0.241699 17.5337V18.5337C0.241699 19.3587 0.916699 20.0337 1.7417 20.0337H2.7417C3.5667 20.0337 4.2417 19.3587 4.2417 18.5337V18.0337H8.2417C8.7937 18.0337 9.2417 17.5857 9.2417 17.0337C9.2417 16.4817 8.7937 16.0337 8.2417 16.0337H2.7417H2.2417V15.0337H4.2417C4.7937 15.0337 5.2417 14.5857 5.2417 14.0337C5.2417 13.4817 4.7937 13.0337 4.2417 13.0337H2.2417V12.0337H8.2417ZM14.9999 17.9644C13.1709 17.3664 12.2619 14.6214 12.2419 13.0904C12.9859 12.8474 14.1049 12.3764 14.9999 11.5544C15.8949 12.3764 17.0139 12.8474 17.7579 13.0904C17.7429 14.6084 16.8339 17.3654 14.9999 17.9644ZM18.951 11.3484C18.929 11.3444 16.681 10.8664 15.859 9.48744C15.498 8.88244 14.502 8.88244 14.141 9.48744C13.454 10.6394 11.674 11.2214 11.049 11.3484C10.58 11.4414 10.242 11.8524 10.242 12.3294V12.9894C10.222 14.8554 11.336 19.2054 14.782 19.9764C14.854 19.9924 14.927 20.0004 15 20.0004C15.073 20.0004 15.146 19.9924 15.218 19.9764C18.665 19.2054 19.779 14.8554 19.758 13.0004V12.3294C19.758 11.8524 19.42 11.4414 18.951 11.3484Z"
      fill="#2E2D37"
    />
  </svg>
)
