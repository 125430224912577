import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09984 10C9.09984 8.346 10.4458 7 12.0998 7C13.7538 7 15.0998 8.346 15.0998 10C15.0998 11.654 13.7538 13 12.0998 13C10.4458 13 9.09984 11.654 9.09984 10ZM19.8038 17.905C18.9058 15.982 17.3238 14.509 15.4338 13.699C16.4488 12.783 17.0998 11.471 17.0998 10C17.0998 7.243 14.8568 5 12.0998 5C9.34284 5 7.09984 7.243 7.09984 10C7.09984 11.471 7.75084 12.783 8.76584 13.699C6.87484 14.51 5.29184 15.984 4.39384 17.909C4.16084 18.409 4.37684 19.004 4.87684 19.238C5.01384 19.302 5.15784 19.332 5.29984 19.332C5.67584 19.332 6.03684 19.118 6.20584 18.754C7.27084 16.474 9.58384 15 12.0998 15C14.6148 15 16.9278 16.473 17.9928 18.752C18.2268 19.253 18.8248 19.468 19.3218 19.234C19.8218 19.001 20.0378 18.406 19.8038 17.905Z"
      fill="#2E2D37"
    />
  </svg>
)
