import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9993 20.9992V5.41324L18.2923 10.7062C18.6833 11.0972 19.3153 11.0972 19.7063 10.7062C20.0973 10.3152 20.0973 9.68324 19.7063 9.29224L12.7073 2.29224C12.6143 2.20024 12.5033 2.12724 12.3813 2.07624C12.1373 1.97524 11.8613 1.97524 11.6173 2.07624C11.4953 2.12724 11.3843 2.20024 11.2913 2.29224L4.29226 9.29224C4.09726 9.48724 3.99926 9.74324 3.99926 9.99924C3.99926 10.2552 4.09726 10.5112 4.29226 10.7062C4.68326 11.0972 5.31526 11.0972 5.70626 10.7062L10.9993 5.41324L10.9993 20.9992C10.9993 21.5512 11.4473 21.9992 11.9993 21.9992C12.5513 21.9992 12.9993 21.5512 12.9993 20.9992Z"
      fill="#2E2D37"
    />
  </svg>
)
