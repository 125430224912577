import React from 'react'
import PropTypes from 'prop-types'

import './PlaceHolder.scss'

export default class PlaceHolder extends React.Component {
  renderClass(width) {
    const { className } = this.props

    const classNameString = className || ''
    let placeHolderClass = `PlaceHolder${classNameString}`

    switch (width) {
      case '1-3':
        placeHolderClass += ' PlaceHolder--1-3'
        break
      case '1-2':
        placeHolderClass += ' PlaceHolder--1-2'
        break
      case '2-3':
        placeHolderClass += ' PlaceHolder--2-3'
        break
      default:
    }
    return placeHolderClass
  }

  render() {
    const { width, px, style } = this.props
    if (width === 'px' && px)
      return (
        <span className="PlaceHolder" style={{ width: `${px}px`, ...style }} data-testid={this.props.dataTestId}/>
      )
    return <span className={this.renderClass(width)} style={style} data-testid={this.props.dataTestId} />
  }
}

PlaceHolder.defaultProps = {
  width: null,
  px: null,
  className: null,
  style: null,
}

PlaceHolder.propTypes = {
  width: PropTypes.oneOf(['px', '1-3', '1-2', '2-3']),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  style: PropTypes.object,
}
