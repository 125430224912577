import axios from 'axios'
import Immutable from 'immutee'

import {
  getApiUrl,
  getChannelParameter,
  hasPandora,
  onClientSide,
} from '../utils/utils'

export const FETCH_FILTERSBIKELOCATION =
  'buyer/filters/FETCH_FILTERSBIKELOCATION'
export const FETCH_FILTERSBIKELOCATION_FAILURE =
  'buyer/filters/FETCH_FILTERSBIKELOCATION_FAILURE'
export const FETCH_FILTERSBIKELOCATION_LOADING =
  'buyer/filters/FETCH_FILTERSBIKELOCATION_LOADING'

const initialState = {
  fetched: false,
  result: [],
  error: null,
  isPending: false,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_FILTERSBIKELOCATION_LOADING:
      return immutable.set('isPending', true).done()

    case FETCH_FILTERSBIKELOCATION:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('isPending', false)
        .set('fetched', true)
        .done()

    case FETCH_FILTERSBIKELOCATION_FAILURE:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('result', [])
        .set('isPending', false)
        .set('fetched', false)
        .done()
    default:
      return state
  }
}

export function fetchFiltersBikeLocation(url) {
  const requestUrl = `${getApiUrl()}filter/bike/LocationSearch`
  const channelParameter = getChannelParameter()
  const pandora = hasPandora()
  const requestConfig = {
    params: {
      url,
      parentFilter: 'None',
      parentValue: channelParameter,
      pandora,
    },
  }

  return dispatch => {
    if (onClientSide()) {
      dispatch({ type: FETCH_FILTERSBIKELOCATION_LOADING })
    }
    return axios
      .get(requestUrl, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_FILTERSBIKELOCATION,
          payload: res.data,
          requested: { url },
        })
        return res.data
      })
      .catch(err => {
        dispatch({
          type: FETCH_FILTERSBIKELOCATION_FAILURE,
          payload: err.stack,
          requested: { url },
        })
      })
  }
}
