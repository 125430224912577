// All definitions here, including names, are based on Eleanor
// https://zeroheight.com/9vy22d1bc/v/0/p/407cb4-eleanor-design-system
// https://www.figma.com/file/cNEJAikz2zUKDKm0evfCQO/Core?node-id=0%3A1

export default {
  fonts: {
    poppins: 'Poppins',
  },
  colors: {
    primary1: '#F3123C',
    primary2: '#FF859C',
    primary3: '#FFC8D2',
    black: '#000',
    gray1: '#2E2D37',
    gray2: '#696977',
    gray3: '#AEAEBA',
    gray4: '#D6D6DE',
    gray5: '#ECEDF2',
    white: '#fff',
    success: '#27AE60',
    danger: '#FF8332',
    warning: '#F2C94C',
    error: '#9B51E0',
  },
}
