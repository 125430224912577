import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.06488 18.2838C8.29488 16.8488 10.0959 15.9998 11.9999 15.9998C13.9119 15.9998 15.7089 16.8448 16.9379 18.2818C15.5769 19.3538 13.8639 19.9998 11.9999 19.9998C10.1379 19.9998 8.42588 19.3548 7.06488 18.2838ZM8.99988 10.9998C8.99988 9.34576 10.3459 7.99976 11.9999 7.99976C13.6539 7.99976 14.9999 9.34576 14.9999 10.9998C14.9999 12.6548 13.6539 13.9998 11.9999 13.9998C10.3459 13.9998 8.99988 12.6548 8.99988 10.9998ZM11.9999 3.99976C16.4109 3.99976 19.9999 7.58876 19.9999 11.9998C19.9999 13.8248 19.3789 15.5028 18.3479 16.8498C17.5099 15.9088 16.4769 15.1848 15.3359 14.6968C16.3499 13.7818 16.9999 12.4708 16.9999 10.9998C16.9999 8.24276 14.7569 5.99976 11.9999 5.99976C9.24288 5.99976 6.99988 8.24276 6.99988 10.9998C6.99988 12.4718 7.65088 13.7838 8.66588 14.6988C7.52488 15.1868 6.49188 15.9138 5.65488 16.8528C4.62088 15.5048 3.99988 13.8258 3.99988 11.9998C3.99988 7.58876 7.58888 3.99976 11.9999 3.99976ZM11.9999 1.99976C6.47688 1.99976 1.99988 6.47676 1.99988 11.9998C1.99988 17.5228 6.47688 21.9998 11.9999 21.9998C17.5229 21.9998 21.9999 17.5228 21.9999 11.9998C21.9999 6.47676 17.5229 1.99976 11.9999 1.99976Z"
      fill="#2E2D37"
    />
  </svg>
)
