const initialState = {
  favorites: false,
  videoChamada: false,
  videoChamadaSucesso: false,
  leadConfirmation: false,
  cookieControlModal: false,
}

export default (state = initialState, { type, key }) => {
  switch (type) {
    case '@modal/OPEN':
      return { ...state, [key]: true }
    case '@modal/CLOSE':
      return { ...state, [key]: false }
    default:
      return state
  }
}
