import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 6C11.447 6 11 6.448 11 7V13C11 13.552 11.447 14 12 14C12.553 14 13 13.552 13 13V7C13 6.448 12.553 6 12 6ZM12 16C11.447 16 11 16.448 11 17C11 17.552 11.447 18 12 18C12.553 18 13 17.552 13 17C13 16.448 12.553 16 12 16Z"
      fill="#2E2D37"
    />
  </svg>
)
