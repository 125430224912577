import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 12V11H10V12C10 13.103 10.897 14 12 14C13.103 14 14 13.103 14 12ZM12 4C10.897 4 10 4.897 10 6V9H14V6C14 4.897 13.103 4 12 4ZM16 12C16 14.206 14.206 16 12 16C9.794 16 8 14.206 8 12V6C8 3.794 9.794 2 12 2C14.206 2 16 3.794 16 6V12ZM18 11C18.552 11 19 11.448 19 12C19 15.52 16.387 18.433 13 18.92V21C13 21.552 12.552 22 12 22C11.448 22 11 21.552 11 21V18.92C7.613 18.433 5 15.52 5 12C5 11.448 5.448 11 6 11C6.552 11 7 11.448 7 12C7 14.757 9.243 17 12 17C14.757 17 17 14.757 17 12C17 11.448 17.448 11 18 11Z"
      fill="#2E2D37"
    />
  </svg>
)
