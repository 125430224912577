import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 17.9098V15.9998C13 15.4478 12.552 14.9998 12 14.9998C11.448 14.9998 11 15.4478 11 15.9998V17.9098C8.493 17.4868 6.514 15.5078 6.09 12.9998H8C8.552 12.9998 9 12.5528 9 11.9998C9 11.4478 8.552 10.9998 8 10.9998H6.09C6.514 8.49276 8.493 6.51376 11 6.09076V7.99976C11 8.55276 11.448 8.99976 12 8.99976C12.552 8.99976 13 8.55276 13 7.99976V6.09076C15.507 6.51376 17.486 8.49276 17.91 10.9998H16C15.448 10.9998 15 11.4478 15 11.9998C15 12.5528 15.448 12.9998 16 12.9998H17.91C17.486 15.5078 15.507 17.4868 13 17.9098ZM21 10.9998H19.931C19.477 7.38876 16.611 4.52276 13 4.06976V2.99976C13 2.44776 12.552 1.99976 12 1.99976C11.448 1.99976 11 2.44776 11 2.99976V4.06976C7.389 4.52276 4.523 7.38876 4.069 10.9998H3C2.448 10.9998 2 11.4478 2 11.9998C2 12.5528 2.448 12.9998 3 12.9998H4.069C4.523 16.6118 7.389 19.4768 11 19.9308V20.9998C11 21.5528 11.448 21.9998 12 21.9998C12.552 21.9998 13 21.5528 13 20.9998V19.9308C16.611 19.4768 19.477 16.6118 19.931 12.9998H21C21.552 12.9998 22 12.5528 22 11.9998C22 11.4478 21.552 10.9998 21 10.9998Z"
      fill="#2E2D37"
    />
  </svg>
)
