import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0004 16.0001C21.0004 15.7441 20.9024 15.4881 20.7074 15.2931L12.7074 7.29313C12.3164 6.90213 11.6844 6.90213 11.2934 7.29313L3.29337 15.2931C2.90237 15.6841 2.90237 16.3161 3.29337 16.7071C3.68437 17.0981 4.31637 17.0981 4.70737 16.7071L12.0004 9.41413L19.2934 16.7071C19.6844 17.0981 20.3164 17.0981 20.7074 16.7071C20.9024 16.5121 21.0004 16.2561 21.0004 16.0001Z"
      fill="#2E2D37"
    />
  </svg>
)
