import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C8.141 5 5 8.14 5 12C5 15.86 8.141 19 12 19C15.859 19 19 15.86 19 12C19 8.14 15.859 5 12 5ZM12 21C7.037 21 3 16.962 3 12C3 7.038 7.037 3 12 3C16.963 3 21 7.038 21 12C21 16.962 16.963 21 12 21Z"
      fill="#2E2D37"
    />
  </svg>
)
