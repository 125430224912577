import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5859 13H13.9999H9.99988H7.99988V11H16.5859L17.5859 12L16.5859 13ZM7.41388 7H9.99988H13.9999H15.9999V9H7.41388L6.41388 8L7.41388 7ZM10.9999 5H12.9999V4H10.9999V5ZM19.7069 11.293L17.9999 9.586V6C17.9999 5.448 17.5519 5 16.9999 5H14.9999V3C14.9999 2.448 14.5519 2 13.9999 2H9.99988C9.44788 2 8.99988 2.448 8.99988 3V5H6.99988C6.73488 5 6.48088 5.105 6.29288 5.293L4.29288 7.293C3.90188 7.684 3.90188 8.316 4.29288 8.707L5.99988 10.414V14C5.99988 14.552 6.44788 15 6.99988 15H8.99988V21C8.99988 21.552 9.44788 22 9.99988 22C10.5519 22 10.9999 21.552 10.9999 21V15H12.9999V21C12.9999 21.552 13.4479 22 13.9999 22C14.5519 22 14.9999 21.552 14.9999 21V15H16.9999C17.2649 15 17.5199 14.895 17.7069 14.707L19.7069 12.707C20.0979 12.316 20.0979 11.684 19.7069 11.293Z"
      fill="#2E2D37"
    />
  </svg>
)
