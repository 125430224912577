import Immutable from 'immutee'

export const FETCH_DECLARESEO = 'buyer/seo/FETCH_DECLARESEO_FULFILLED'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  return action.type === FETCH_DECLARESEO
    ? immutable.set('result', action.payload).set('fetched', true).done()
    : state
}

export function fetchDeclareSeo(container) {
  return function (dispatch) {
    return dispatch({
      type: FETCH_DECLARESEO,
      payload: { container: container || '' },
    })
  }
}
