import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5C5.449 5 5 5.449 5 6V14.586L6.293 13.293C6.48 13.105 6.735 13 7 13H15.002C15.554 13 16.002 12.551 16.002 12V6C16.002 5.449 15.554 5 15.002 5H6ZM4 18C3.871 18 3.741 17.975 3.617 17.924C3.244 17.769 3 17.404 3 17V6C3 4.346 4.346 3 6 3H15.002C16.657 3 18.002 4.346 18.002 6V12C18.002 13.654 16.657 15 15.002 15H7.414L4.707 17.707C4.516 17.898 4.26 18 4 18Z"
      fill="#2E2D37"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 21C19.74 21 19.484 20.898 19.293 20.707L16.586 18H9.002C8.45 18 8.002 17.552 8.002 17C8.002 16.448 8.45 16 9.002 16H17C17.265 16 17.519 16.105 17.707 16.293L19 17.586V10.002C19 9.45 19.448 9.002 20 9.002C20.552 9.002 21 9.45 21 10.002V20C21 20.404 20.756 20.769 20.383 20.924C20.259 20.975 20.129 21 20 21Z"
      fill="#2E2D37"
    />
  </svg>
)
