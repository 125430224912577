import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 8.00144C18.225 8.00144 18 7.77744 18 7.50144C18 7.22544 18.225 7.00144 18.5 7.00144C18.775 7.00144 19 7.22544 19 7.50144C19 7.77744 18.775 8.00144 18.5 8.00144ZM5 19.0014H13V12.0014H5V19.0014ZM6 5.00144H12C12.552 5.00144 13 5.45044 13 6.00144V10.0014H5V6.00144C5 5.45044 5.449 5.00144 6 5.00144ZM17.316 3.05244C16.787 2.87544 16.227 3.16144 16.052 3.68544C15.877 4.20944 16.16 4.77544 16.684 4.95044C16.83 4.99944 17.099 5.11244 17.399 5.26844C16.574 5.67644 16 6.52044 16 7.50144C16 8.87944 17.121 10.0014 18.5 10.0014C18.671 10.0014 18.838 9.98344 19 9.95044V18.5014C19 18.7774 18.775 19.0014 18.5 19.0014C18.225 19.0014 18 18.7774 18 18.5014V13.0014C18 11.3474 16.654 10.0014 15 10.0014V6.00144C15 4.34744 13.654 3.00144 12 3.00144H6C4.346 3.00144 3 4.34744 3 6.00144V20.0014C3 20.5534 3.448 21.0014 4 21.0014H14C14.553 21.0014 15 20.5534 15 20.0014V12.0014C15.552 12.0014 16 12.4504 16 13.0014V18.5014C16 19.8804 17.121 21.0014 18.5 21.0014C19.879 21.0014 21 19.8804 21 18.5014V7.00144C21 4.35644 17.693 3.17844 17.316 3.05244Z"
      fill="#2E2D37"
    />
  </svg>
)
