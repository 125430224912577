import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.02369L4.03518 17.9763H19.9709L12 5.02369ZM19.9648 20H4.03518C3.29944 20 2.61968 19.6044 2.26047 18.9669C1.90023 18.3294 1.91448 17.5453 2.29812 16.921L10.2629 3.96834C10.6364 3.36123 11.2856 3 12 3C12.7144 3 13.3636 3.36123 13.736 3.96732L21.7019 16.921C22.0855 17.5453 22.0998 18.3294 21.7395 18.9669C21.3803 19.6044 20.7006 20 19.9648 20ZM12 13.9292C11.4373 13.9292 10.9824 13.4759 10.9824 12.9174V9.88186C10.9824 9.32332 11.4373 8.87001 12 8.87001C12.5627 8.87001 13.0176 9.32332 13.0176 9.88186V12.9174C13.0176 13.4759 12.5627 13.9292 12 13.9292ZM13.0176 15.9529C13.0176 15.3934 12.5627 14.9411 12 14.9411C11.4373 14.9411 10.9824 15.3934 10.9824 15.9529C10.9824 16.5125 11.4373 16.9648 12 16.9648C12.5627 16.9648 13.0176 16.5125 13.0176 15.9529Z"
      fill="#2E2D37"
    />
  </svg>
)
