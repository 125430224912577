import styled from 'styled-components'

export const Container = styled.div`
  display: ${({ show }) => (!show ? 'none' : 'flex')};
  position: fixed;
  bottom: 0;
  background-color: #2e2d37f2;
  color: white;
  z-index: 35;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`

export const Main = styled.main`
  min-width: auto;
  & p {
    margin-bottom: 0;
  }
  & p:first-child {
    margin-bottom: 4px;
  }

  & a,
  button {
    font-weight: bold;
  }
`

export const Link = styled.a`
  color: #ff859c;
`

export const BtnUnderstood = styled.button`
  margin-left: 80px;
  padding: 10px 32px;
  font-size: 12px;
  min-width: 64px;
  transition: background 0.5s;
  font-weight: 700;
  background-color: #e11138;
  color: white;
  border-radius: 8px;
  line-height: 1.5;

  &:active {
    background-color: #df526c;
    transition: background 0.2s;
  }

  @media screen and (max-width: 700px) {
    margin-top: 25px;
    margin-left: 0;
    width: 100%;
  }
`
