import { hasPandora } from '@/utils/utils'
import { api } from './api'

export default {
  create(leadInfo) {
    const pandora = hasPandora()
    const requestData = {
      ...leadInfo,
      pandora,
    }

    return api.post('detail/proposal/send', requestData)
  },
}
