import React from 'react'

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M9.12696 12.2066C9.12696 12.7846 9.59981 13.2574 10.1777 13.2574C10.7662 13.2574 11.2285 12.7951 11.2285 12.2066V11.5446C11.7224 11.1348 12.1847 10.641 12.6891 9.96846L12.8257 9.78982C13.6558 8.73904 13.8975 7.38353 13.4667 6.24868C13.1199 5.35551 12.4579 4.65149 11.6068 4.29422C11.155 4.09457 10.6716 4 10.1777 4C8.14973 4 6.5 5.64973 6.5 7.67775C6.5 8.25568 6.97285 8.72853 7.55078 8.72853C8.12872 8.72853 8.60157 8.25568 8.60157 7.67775C8.60157 6.80559 9.3161 6.10157 10.1777 6.10157C10.3879 6.10157 10.5981 6.1436 10.7872 6.22766C11.1024 6.36426 11.3546 6.63747 11.4912 6.99474C11.6699 7.44657 11.5438 8.0245 11.155 8.51837L11.0079 8.71802C10.6086 9.26443 10.2408 9.65322 9.87302 9.94744L9.87259 9.94778C9.67258 10.1057 9.12696 10.5364 9.12696 11.303V12.2066ZM11.1655 15.0123C11.1655 15.5578 10.7233 16 10.1778 16C9.63229 16 9.19007 15.5578 9.19007 15.0123C9.19007 14.4667 9.63229 14.0245 10.1778 14.0245C10.7233 14.0245 11.1655 14.4667 11.1655 15.0123ZM10.001 18C5.58998 18 2.00098 14.411 2.00098 10C2.00098 5.589 5.58998 2 10.001 2C14.412 2 18.001 5.589 18.001 10C18.001 14.411 14.412 18 10.001 18ZM10.001 0C4.48698 0 0.000976562 4.486 0.000976562 10C0.000976562 15.514 4.48698 20 10.001 20C15.515 20 20.001 15.514 20.001 10C20.001 4.486 15.515 0 10.001 0Z" 
      fill="#2F80ED"
    />
  </svg>
)