import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.38161 5C6.57961 5 5.91661 5.325 5.47561 5.893C5.04861 6.441 4.90061 7.137 5.06861 7.8C6.45761 13.277 10.7226 17.542 16.1996 18.931C16.8666 19.1 17.5586 18.952 18.1076 18.524C18.6746 18.083 18.9996 17.42 18.9996 16.705V15.092C18.9996 14.973 18.9196 14.867 18.8136 14.846C18.0276 14.686 17.2496 14.446 16.5026 14.132C16.4336 14.105 16.3286 14.133 16.2566 14.205L14.6716 15.79C14.3576 16.103 13.8736 16.172 13.4856 15.96C11.1746 14.699 9.28961 12.811 8.03161 10.502C7.81961 10.113 7.88961 9.63 8.20361 9.317L9.78961 7.73C9.86161 7.659 9.89161 7.556 9.86161 7.485C9.55061 6.74 9.31261 5.967 9.15361 5.187C9.13161 5.079 9.02761 5 8.90761 5H7.38161ZM16.7456 21C16.4006 21 16.0516 20.957 15.7076 20.87C9.51961 19.3 4.69961 14.48 3.13061 8.292C2.80861 7.026 3.08861 5.704 3.89761 4.665C4.71961 3.606 5.95861 3 7.29561 3H8.90761C9.97561 3 10.9036 3.752 11.1136 4.789C11.2466 5.441 11.4456 6.088 11.7066 6.712C12.0526 7.538 11.8556 8.493 11.2036 9.145L10.1636 10.185C11.0896 11.657 12.3316 12.899 13.8036 13.829L14.8426 12.791C15.4946 12.138 16.4506 11.942 17.2776 12.289C17.9026 12.551 18.5536 12.752 19.2116 12.886C20.2476 13.096 20.9996 14.023 20.9996 15.092V16.618C20.9996 18.042 20.3936 19.28 19.3356 20.103C18.5786 20.691 17.6716 21 16.7456 21Z"
      fill="#2E2D37"
    />
  </svg>
)
