import React, { Fragment } from 'react';

import Header from 'components/Header';
import Footer from 'components/Footer';

export default ({
    showHelpService,
    pagename,
    history,
    location,
    fixedHeader,
    openFooter,
    footerless,
    children
 }) => {
    const isPageResult = pagename === 'SearchResult'

    return (
        <Fragment>
            <Header
                fixedHeader={fixedHeader ? fixedHeader : false}
            />
            {children}
            {
                !footerless
                    ? <Footer isPageResult={isPageResult} openFooter={openFooter} location={location} showHelpService={showHelpService}/>
                    : null
            }
        </Fragment>
    );
}
