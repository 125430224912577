import { useEffect, useMemo, useState } from 'react'

function removeReservedMediaKeyWord(mediaQueryString) {
  return mediaQueryString.replace('@media', '').trim()
}

export function useMediaQuery(mediaQueryString) {
  const queryString = removeReservedMediaKeyWord(mediaQueryString)
  const query = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(queryString)
    }
    return false
  }, [queryString])
  const [matches, setMatches] = useState(query.matches)

  useEffect(() => {
    const listener = e => setMatches(e.matches)
    if (query.addEventListener) query.addEventListener('change', listener)

    return () => {
      if (query.removeEventListener)
        query.removeEventListener('change', listener)
    }
  }, [query])

  return matches
}
