import React from 'react'
import Loadable from 'react-loadable'

const loading = () => (
  <div class="loader loader--show">
    <div class="loader__spin">&nbsp;</div>
  </div>
)

export const AdDetail = Loadable({
  loader: () => import(/* webpackChunkName: "adDetail" */ 'container/AdDetail'),
  modules: ['container/AdDetail'],
  loading,
})

export const Recapture = Loadable({
  loader: () =>
    import(/* webpackChunkName: "recapture" */ 'container/Recapture'),
  modules: ['container/Recapture'],
  loading,
})

export const Search = Loadable({
  loader: () => import(/* webpackChunkName: "search" */ 'container/Search'),
  modules: ['container/Search'],
  loading,
})

export const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "notFound" */ 'container/NotFound'),
  modules: ['container/NotFound'],
  loading,
})

export const HotDeal = Loadable({
  loader: () => import(/* webpackChunkName: "hotDeal" */ 'container/HotDeal'),
  modules: ['container/HotDeal'],
  loading,
})

export const SiteMap = Loadable({
  loader: () => import(/* webpackChunkName: "siteMap" */ 'container/SiteMap'),
  modules: ['container/SiteMap'],
  loading,
})

export const LocationSiteMap = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "locationSiteMap" */ 'container/LocationSiteMap'
    ),
  modules: ['container/LocationSiteMap'],
  loading,
})

export const Financing = Loadable({
  loader: () =>
    import(/* webpackChunkName: "financing" */ 'container/Financing'),
  modules: ['container/Financing'],
  loading,
})
