import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14C8.278 14 6 12.705 6 12V8.732C7.542 9.57 9.777 10 12 10C14.223 10 16.458 9.57 18 8.732V12C18 12.705 15.722 14 12 14ZM12 20C8.278 20 6 18.705 6 18V14.732C7.542 15.57 9.777 16 12 16C14.223 16 16.458 15.57 18 14.732V18C18 18.705 15.722 20 12 20ZM12 4C15.722 4 18 5.295 18 6C18 6.705 15.722 8 12 8C8.278 8 6 6.705 6 6C6 5.295 8.278 4 12 4ZM12 2C8.024 2 4 3.374 4 6V18C4 20.626 8.024 22 12 22C15.976 22 20 20.626 20 18V6C20 3.374 15.976 2 12 2Z"
      fill="#2E2D37"
    />
  </svg>
)
