import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { config } from 'config/config'
import { hasNewCardLayout } from '@/utils/utils'

import './PhotoSlider.scss';

class PhotoSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            slidePosition: 0,
            maxSlidePosition: 0,
            lazyLoadAhead: 1
        }

        this.handleImageError = this.handleImageError.bind(this)
    }
    handleImageError(i) {
      const image = this.refs[`PhotoCarousel_${i}`]
      if (image) image.src = config.noPhoto
    }
    changeSlidePosition(i) {
        let pos = Math.min(this.props.listPhotos.length - 1, i)
        pos < 0 && (pos = 0)
        this.setState({ slidePosition: pos },
            () => {
                this.setState({ maxSlidePosition: Math.max(this.state.slidePosition, this.state.maxSlidePosition) })
            }
        )
    }
    renderNoPhoto() {
      const {
        width = '',
        height = '',
        className,
        tag = { title: '', alt: '' },
      } = this.props
      return (
        <img
          className={className}
          src={config.noPhoto}
          width={width}
          height={height}
          title={tag.title}
          loading="lazy"
          alt={tag.alt}
        />
      )
    }
    renderPhotos() {
        let listPhotosGroup = []
        const {width = '', height = '', linkCarousel, listPhotos, className, tag = {title: '', alt: ''}} = this.props
        for (let i = 0; i < listPhotos.length; i++) {
          listPhotosGroup.push(
                <a rel="noreferrer nofollow" key={i} className={'CardVehicle__linkPhoto'} href={linkCarousel} target='_blank'>
                    {i <= this.state.maxSlidePosition + this.state.lazyLoadAhead ?
                        <img key={i} className={className} src={listPhotos[i]}
                            width={width}
                            height={height}
                            title={tag.title}
                            loading="lazy"
                            alt={tag.alt}
                            onError={() => {
                              this.handleImageError(i)
                            }}
                            ref={`PhotoCarousel_${i}`}
                        />
                        : <span />}
                </a>
            )
        }
        return listPhotosGroup
    }
    renderCarousel() {
      const { listPhotos = [] } = this.props
      const { slidePosition } = this.state

      const hasTestABCard = hasNewCardLayout()

      return (
        <div className="PhotoSlider">
          {listPhotos.length === 0 && this.renderNoPhoto()}
          {listPhotos.length === 1 && this.renderPhotos()}
          {listPhotos.length > 1 && (
            <Fragment>
              <div
                className="PhotoSlider--container"
                style={{
                  transform: `translateX(-${slidePosition * 100}%)`,
                }}
              >
                {this.renderPhotos()}
              </div>
              {hasTestABCard ? this.renderPhotoCount() : this.renderDots()}
              {this.renderArrows()}
            </Fragment>
          )}
        </div>
      )
    }
    renderDots() {
        let dots = []
        for (let i = 0; i < this.props.listPhotos.length; i++) {
            dots.push(
                <li
                    key={i}
                    class={"PhotoSlider__dots-item" + (i == this.state.slidePosition ? ' __active' : '')}
                    onClick={this.changeSlidePosition.bind(this, i)}
                />
            )
        }
        return <ul class="PhotoSlider__dots"> {dots} </ul>
    }
    renderPhotoCount() {
      const { slidePosition } = this.state;
      const { listPhotos } = this.props;
      const currentPhoto = slidePosition + 1;
      const totalPhotos = listPhotos.length;

      return (
        <div className="PhotoSlider__count">
          <span>{currentPhoto}/{totalPhotos}</span>
        </div>
      );
    }
    renderArrows() {
        return (<Fragment>
            <div class="PhotoSlider__prev-btn">
                <div class="PhotoSlider__prev-btn-wrapper">
                    <p
                        class={"PhotoSlider__prev-btn-item" + (this.state.slidePosition == 0 ? ' __inactive' : '')}
                        onClick={this.changeSlidePosition.bind(this, this.state.slidePosition - 1)}
                    >
                        <span data-area="Prev"></span>
                    </p>
                </div>
            </div>
            <div class="PhotoSlider__next-btn">
                <div class="PhotoSlider__next-btn-wrapper">
                    <p
                        class={"PhotoSlider__next-btn-item" + (this.state.slidePosition >= this.props.listPhotos.length - 1 ? ' __inactive' : '')}
                        onClick={this.changeSlidePosition.bind(this, this.state.slidePosition + 1)}
                    >
                        <span data-area="Next"></span>
                    </p>
                </div>
            </div>
        </Fragment>)
    }
    render() {
        return (
            this.renderCarousel()
        )

    }
}
export default connect((store) => {
    return {
        responsive: store.responsiveState
    };
})(PhotoSlider);
