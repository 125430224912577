import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21.51 8.91C21.38 8.52 21.04 8.25 20.63 8.22L15.04 7.81L12.92 2.62C12.77 2.25 12.41 2 12 2C11.59 2 11.23 2.25 11.07 2.62L8.96002 7.81L3.37002 8.22C2.96002 8.25 2.62002 8.52 2.49002 8.91C2.36002 9.3 2.49002 9.72 2.80002 9.98L7.08002 13.6L5.74002 19.04C5.64002 19.43 5.79002 19.85 6.12002 20.09C6.29002 20.22 6.50002 20.28 6.71002 20.28C6.89002 20.28 7.08002 20.23 7.24002 20.13L12 17.18L16.76 20.13C17.1 20.34 17.55 20.33 17.87 20.09C18.2 19.85 18.35 19.44 18.25 19.04L16.91 13.6L21.19 9.98C21.51 9.72 21.64 9.3 21.51 8.91Z"
      fill="#2E2D37"
    />
  </svg>
)
