import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9988 11H5.41275L10.7058 5.70701C11.0968 5.31601 11.0968 4.68401 10.7058 4.29301C10.3148 3.90201 9.68275 3.90201 9.29175 4.29301L2.29175 11.292C2.19975 11.385 2.12675 11.496 2.07575 11.618C1.97475 11.862 1.97475 12.138 2.07575 12.382C2.12675 12.504 2.19975 12.615 2.29175 12.708L9.29175 19.707C9.48675 19.902 9.74275 20 9.99875 20C10.2548 20 10.5108 19.902 10.7058 19.707C11.0968 19.316 11.0968 18.684 10.7058 18.293L5.41275 13H20.9988C21.5508 13 21.9988 12.552 21.9988 12C21.9988 11.448 21.5508 11 20.9988 11Z"
      fill="#2E2D37"
    />
  </svg>
)
