import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 11H13V7C13 6.448 12.553 6 12 6C11.447 6 11 6.448 11 7V11H7C6.447 11 6 11.448 6 12C6 12.552 6.447 13 7 13H11V17C11 17.552 11.447 18 12 18C12.553 18 13 17.552 13 17V13H17C17.553 13 18 12.552 18 12C18 11.448 17.553 11 17 11Z"
      fill="#2E2D37"
    />
  </svg>
)
