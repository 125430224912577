import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0004 8.00012C21.0004 8.25612 20.9024 8.51212 20.7074 8.70712L12.7074 16.7071C12.3164 17.0981 11.6844 17.0981 11.2934 16.7071L3.29337 8.70712C2.90237 8.31612 2.90237 7.68412 3.29337 7.29312C3.68437 6.90212 4.31637 6.90212 4.70737 7.29312L12.0004 14.5861L19.2934 7.29312C19.6844 6.90212 20.3164 6.90212 20.7074 7.29312C20.9024 7.48812 21.0004 7.74412 21.0004 8.00012Z"
      fill="#2E2D37"
    />
  </svg>
)
