import React from 'react'
import { ModalContainer, Overlay, WhiteBox, Close } from './style'

export default ({
  show = true,
  onBgClick,
  children = [],
  useWhiteBox = false,
  useClose = false,
  smaller = false,
}) => {
  return (
    <ModalContainer show={show}>
      <Overlay onClick={() => onBgClick()} />
      {useWhiteBox ? (
        <WhiteBox smaller={smaller}>
          {useClose && (
            <Close className="modalCloseButton" onClick={() => onBgClick()} />
          )}
          {children}
        </WhiteBox>
      ) : (
        children
      )}
    </ModalContainer>
  )
}
