import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.761 4.06605C14.892 3.77205 13.125 4.48005 12 5.86405C10.875 4.48005 9.109 3.77005 7.239 4.06605C5.129 4.39805 3 6.17105 3 9.38505C3 14.6451 9.339 18.9471 11.282 20.1411C11.44 20.2371 11.621 20.2891 11.806 20.2891H12.194C12.379 20.2891 12.56 20.2371 12.718 20.1411C14.661 18.9471 21 14.6451 21 9.38505C21 6.17105 18.871 4.39805 16.761 4.06605Z"
      fill="#2E2D37"
    />
  </svg>
)
