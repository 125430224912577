import React, { Component } from 'react';
import { cleanString,createUrlIdc } from 'utils/utils'

import CarouselContainer from 'components/CarouselContainer';
import CardGradient from 'components/CarouselContainer/CardGradient';
import Slider from "react-slick";

export default class HomeProducts extends Component {

	renderCards() {
		if (this.props.source && this.props.source.length) {
			var _renderMap = this.props.source.map((item, index) =>
				<div key={index} className='slick-padding'>
					<CardGradient
						title={item.Nome ? item.Nome : ''}
						key={'homeCat' + item.Nome + index}
						id={item.Categoria ? item.Categoria : ''}
						href={item.href ? this.getUrlCard(item, index + 1) : undefined}
						target={this.props.target ? this.props.target : ''}
						rel={this.props.rel ? this.props.rel : ''}
					/>
				</div>
			)
			return (_renderMap.length ? _renderMap : false)
		}
		return false
	}
	getUrlCard(item, pos) {
		return createUrlIdc(item.href, undefined, `na-webmotors:posicao-${pos}`, cleanString(item.Nome, { toLowerCase : 1, removeAccents : 1, removeSpecial : 1, slugify : 1 }))
	}
	settings  = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive:[
			{
				breakpoint: 1930,
				settings: {
					slidesToShow: 7,				  
				}
			},
			{
				breakpoint: 1680,
				settings: {
					slidesToShow: 6,				  
				}
			},
			{
				breakpoint: 1368,
				settings: {
					slidesToShow: 5,				  
				}
			}
		]
		
	};
	render() {
		return (
			<CarouselContainer id={this.props.id ? this.props.id : ''} title={this.props.title ? this.props.title : ''}>
				<Slider {...this.settings}>
					{this.renderCards()}
				</Slider>
			</CarouselContainer>
		)
	}
}