import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_CATEGORIES = 'buyer/category/FETCH_CATEGORIES'
export const FETCH_CATEGORIES_SUCCESS =
  'buyer/category/FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE =
  'buyer/category/FETCH_CATEGORIES_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
  status: 'idle',
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_CATEGORIES:
      return immutable.set('status', 'pending').done()

    case FETCH_CATEGORIES_SUCCESS:
      return immutable
        .set('result', action.payload)
        .set('fetched', true)
        .set('status', 'resolved')
        .done()

    case FETCH_CATEGORIES_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .set('status', 'rejected')
        .done()
    default:
      return state
  }
}

export function fetchCategories() {
  const url = `${getApiUrl()}category`
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    dispatch({
      type: FETCH_CATEGORIES,
    })
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_CATEGORIES_SUCCESS,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_CATEGORIES_FAILURE,
          payload: err.stack,
        })
      })
  }
}
