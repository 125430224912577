import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3C19.4477 3 19 3.44772 19 4V10C19 10.5523 18.5523 11 18 11H14C13.4477 11 13 10.5523 13 10V4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V10C11 10.5523 10.5523 11 10 11H6C5.44772 11 5 10.5523 5 10V4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21C4.55228 21 5 20.5523 5 20V14C5 13.4477 5.44772 13 6 13H10C10.5523 13 11 13.4477 11 14V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V14C13 13.4477 13.4477 13 14 13H18C19.6569 13 21 11.6569 21 10V4C21 3.44772 20.5523 3 20 3Z"
      fill="#2E2D37"
    />
  </svg>
)
