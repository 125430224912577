import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11H13V8C13 7.448 12.553 7 12 7C11.447 7 11 7.448 11 8V11H8C7.447 11 7 11.448 7 12C7 12.552 7.447 13 8 13H11V16C11 16.552 11.447 17 12 17C12.553 17 13 16.552 13 16V13H16C16.553 13 17 12.552 17 12C17 11.448 16.553 11 16 11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
      fill="#2E2D37"
    />
  </svg>
)
