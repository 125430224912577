import Cookies from 'js-cookie'
import { config } from 'config/config'

export default () => {
  const hasUserInCookies = () => {
    const jwtLength = Cookies.get(config.cookieJwt) || false
    return jwtLength > 0
  }

  const getUserInCookies = () => {
    let jwt = ''
    const jwtLength = Cookies.get(config.cookieJwt)

    for (let index = 0; index < jwtLength; index++) {
      jwt += Cookies.get(`${config.cookieJwt}_${index}`)
    }

    return jwt
  }

  const clearUserInCookie = () => {
    const jwtLength = Cookies.get(config.cookieJwt)
    if (!jwtLength) return

    for (let index = 0; index < jwtLength; index++) {
      Cookies.remove(`${config.cookieJwt}_${index}`)
    }
    Cookies.remove(config.cookieJwt)
  }

  const getChannelInCookies = () => {
    return Cookies.get('WebMotorsChannel')
  }

  return {
    getUserInCookies,
    hasUserInCookies,
    clearUserInCookie,
    getChannelInCookies,
  }
}
