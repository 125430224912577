import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17.29 20.28C17.11 20.28 16.92 20.23 16.76 20.13L12 17.18L7.24002 20.13C6.90002 20.34 6.45002 20.33 6.13002 20.09C5.80002 19.85 5.65002 19.44 5.75002 19.04L7.09002 13.6L2.80002 9.98C2.49002 9.72 2.36002 9.3 2.49002 8.91C2.62002 8.52 2.96002 8.25 3.37002 8.22L8.96002 7.81L11.08 2.62C11.23 2.25 11.59 2 12 2C12.41 2 12.77 2.25 12.93 2.62L15.05 7.81L20.64 8.22C21.05 8.25 21.39 8.52 21.52 8.91C21.65 9.3 21.52 9.72 21.21 9.98L16.93 13.6L18.27 19.04C18.37 19.43 18.22 19.85 17.89 20.09C17.7 20.22 17.5 20.28 17.29 20.28ZM5.96002 10.04L8.84002 12.48C9.13002 12.73 9.26002 13.11 9.17002 13.48L8.27002 17.14L11.48 15.15C11.8 14.95 12.21 14.95 12.53 15.15L15.74 17.14L14.84 13.48C14.75 13.11 14.88 12.72 15.17 12.48L18.05 10.04L14.29 9.76C13.91 9.73 13.58 9.49 13.44 9.14L12 5.65L10.57 9.14C10.43 9.49 10.1 9.73 9.72002 9.76L5.96002 10.04Z"
      fill="#2E2D37"
    />
  </svg>
)
