import React from 'react'
import { IconContainer } from './style'
import Icons from './icons.js'

export default ({ fill, name, size = '16' }) => {
    const Icon = Icons[name] ? Icons[name] : 'span'

    return <IconContainer fill={fill} size={size}>
        <Icon />
    </IconContainer>
}
