import { remove } from 'remove-accents'

const slugify = string => {
  return remove(string).toLowerCase().replace(/\s/g, '-')
}

const intlFormat = (number, styleToFormat = null) => {
  if (!number) return undefined

  const numberWithoutPoints = number.replace(/\./g, '')
  const style = styleToFormat || {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
  }
  const intl = new Intl.NumberFormat('pt-br', style)
  return intl.format(numberWithoutPoints)
}

const toKebabCase = value => {
  return (
    value &&
    value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[.,<|>:;"'{}[\]+=_()*&^%$#@!~`?\\/]/g, '')
      .replace(/[\s]/g, '-')
      .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
      .toLowerCase()
  )
}

export { slugify, intlFormat, toKebabCase }
