import React from 'react'
import PropTypes from 'prop-types'
import './Tag.scss'

const Tag = props => {
  const { id, src, name, width, height } = props

  const tagStyles = {}

  if (width) tagStyles.maxWidth = width
  if (height) tagStyles.maxHeight = height

  return (
    <div id={id} className="Tag" style={tagStyles}>
      <img
        className="Tag__image"
        src={src}
        alt={name}
        style={{ width, height }}
      />
    </div>
  )
}

Tag.defaultProps = {
  id: '',
  src: '',
  name: 'Tag',
  width: null,
  height: null,
}

Tag.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default Tag
