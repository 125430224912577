import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrlSantander } from '../utils/utils'

export const FETCH_CETFILE = 'buyer/proposal/FETCH_CETFILE'
export const FETCH_CETFILE_FAILURE = 'buyer/proposal/FETCH_CETFILE_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_CETFILE:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_CETFILE_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchCETFile(id) {
  const _url = `${getApiUrlSantander()}financiamento/cet-file?id=${id}`
  return dispatch => {
    return axios
      .get(_url)
      .then(response => {
        dispatch({ type: FETCH_CETFILE, payload: response.data.Result })
      })
      .catch(err => {
        dispatch({ type: FETCH_CETFILE_FAILURE, payload: err })
      })
  }
}
