import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_AVERAGEPRICE = 'buyer/averageprice/FETCH_AVERAGEPRICE'
export const FETCH_AVERAGEPRICE_FAILURE =
  'buyer/averageprice/FETCH_AVERAGEPRICE_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_AVERAGEPRICE:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_AVERAGEPRICE_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .done()
    default:
      return state
  }
}

export function fetchAveragePrice(advertId, vehicleType) {
  const url = `${getApiUrl()}detail/averageprice/${vehicleType}/${advertId}`
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch =>
    axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_AVERAGEPRICE,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_AVERAGEPRICE_FAILURE,
          payload: err.stack,
        })
      })
}
