import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 19H19V12H5V19ZM5 8H5.278C5.625 8.595 6.264 9 7 9H8C8.736 9 9.375 8.595 9.722 8H14.278C14.625 8.595 15.264 9 16 9H17C17.736 9 18.375 8.595 18.722 8H19V10H5V8ZM7 6H8L7.997 7H7V6ZM16 6H17L16.997 7H16V6ZM20 6H19C19 4.897 18.103 4 17 4H16C14.897 4 14 4.897 14 6H10C10 4.897 9.103 4 8 4H7C5.897 4 5 4.897 5 6H4C3.447 6 3 6.448 3 7V20C3 20.552 3.447 21 4 21H20C20.553 21 21 20.552 21 20V7C21 6.448 20.553 6 20 6Z"
      fill="#2E2D37"
    />
  </svg>
)
