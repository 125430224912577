import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 12C15.672 12 15 12.671 15 13.5C15 14.329 15.672 15 16.5 15C17.328 15 18 14.329 18 13.5C18 12.671 17.328 12 16.5 12ZM20 17C20 17.551 19.551 18 19 18H6C4.897 18 4 17.103 4 16V14V13V8C4 7.254 4.415 6.61 5.021 6.267C5.128 7.454 5.953 9 8 9H19C19.551 9 20 9.449 20 10V14V17ZM19 6C19.551 6 20 6.449 20 7V7.184C19.686 7.072 19.353 7 19 7H8C7.195 7 7.012 6.45 7 6H19ZM19 4H6C3.794 4 2 5.794 2 8V13V14V16C2 18.206 3.794 20 6 20H19C20.654 20 22 18.654 22 17V14V10V7C22 5.346 20.654 4 19 4Z"
      fill="#2E2D37"
    />
  </svg>
)
