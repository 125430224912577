import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 11C11.447 11 11 11.448 11 12V17C11 17.552 11.447 18 12 18C12.553 18 13 17.552 13 17V12C13 11.448 12.553 11 12 11ZM12 7C11.447 7 11 7.448 11 8C11 8.552 11.447 9 12 9C12.553 9 13 8.552 13 8C13 7.448 12.553 7 12 7Z"
      fill="#2E2D37"
    />
  </svg>
)
