import { config } from 'config/config'
import { lkidListFooterByArea } from '@/utils/constants'

const createUrlWithLkId = (url, lkid) => {
  const separator = url.indexOf('?') === -1 ? '?' : '&'
  return `${url}${separator}lkid=${lkid}`
}

const { BUY, SELL, SERVICES, HELP, PARTNERS } = lkidListFooterByArea

export const footer = {
  urlWMHelp: createUrlWithLkId(
    `${config.urlWMHelp}/hc/pt-br`,
    HELP.WM_HELP_SERVICE
  ),
  config: [
    {
      area: 'Comprar',
      id: 'menuFooterBuy',
      toggleMenuName: 'buy_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerUsedOrNewCars',
          headingTag: 'h3',
          name: 'Carros usados ou seminovos',
          urlName: 'urlCarrosUsadosSemiNovos',
          urlAddress: createUrlWithLkId(config.urlUsedOrNewCars, BUY.USED_CARS),
        },
        {
          id: 'footerNewCars',
          headingTag: 'h3',
          name: 'Carros novos',
          urlName: 'urlCarrosNovos',
          urlAddress: createUrlWithLkId(config.urlNewCars, BUY.NEW_CARS),
        },
        {
          id: 'footerCarsByLocation',
          headingTag: 'h3',
          name: 'Carros por estado',
          urlName: 'urlCarrosPorEstado',
          urlAddress: createUrlWithLkId(
            `${config.urlMapSite}/carros`,
            BUY.CARS_BY_STATE
          ),
        },
        {
          id: 'footerUsedOrNewMotorbikes',
          headingTag: 'h3',
          name: 'Motos usadas ou seminovas',
          urlName: 'urlMotosUsadasSemiNovas',
          urlAddress: createUrlWithLkId(
            config.urlUsedOrNewMotorbikes,
            BUY.USED_MOTORCYCLES
          ),
        },
        {
          id: 'footerNewMotorbikes',
          headingTag: 'h3',
          name: 'Motos novas',
          urlName: 'urlMotosNovas',
          urlAddress: createUrlWithLkId(
            config.urlNewMotorsbike,
            BUY.NEW_MOTORCYCLES
          ),
        },
        {
          id: 'footerBikesByLocation',
          headingTag: 'h3',
          name: 'Motos por estado',
          urlName: 'urlMotosPorEstado',
          urlAddress: createUrlWithLkId(
            `${config.urlMapSite}/motos`,
            BUY.MOTORCYCLES_BY_STATE
          ),
        },
        {
          id: 'footer_services_inspected',
          name: 'Vistoriado',
          urlName: 'urlVistoriado',
          urlAddress: createUrlWithLkId(
            `${config.urlVistoriadoRB}`,
            BUY.VISTORIADO
          ),
        },
      ],
    },
    {
      area: 'Vender',
      id: 'menuFooterSeller',
      toggleMenuName: 'sell_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerSellMyCar',
          headingTag: 'h3',
          name: 'Vender meu veículo',
          urlName: 'urlVenderCarro',
          urlAddress: createUrlWithLkId(
            config.urlSellMyCar,
            SELL.SELL_MY_VEHICLE
          ),
        },
        {
          id: 'footerSellMyMotorbikes',
          headingTag: 'h3',
          name: 'Vender minha moto',
          urlName: 'urlVenderMoto',
          urlAddress: createUrlWithLkId(
            config.urlSellMyBike,
            SELL.SELL_MY_MOTORCYCLE
          ),
        },
        {
          id: 'footerManageMyAdvertising',
          name: 'Gerenciar meu anúncio',
          urlName: 'urlGerenciarAnuncio',
          urlAddress: createUrlWithLkId(
            config.urlMyAdverts,
            SELL.MANAGER_MY_AD
          ),
        },
        {
          id: 'footerFazTudo',
          name: 'FazTudo',
          urlName: 'urlFazTudo',
          urlAddress: createUrlWithLkId(
            config.urlFazTudo,
            SELL.DOES_EVERYTHING
          ),
        },
      ],
    },
    {
      area: 'Serviços',
      id: 'menuFooterServices',
      toggleMenuName: 'services_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerTableFipe',
          name: 'Tabela FIPE Webmotors',
          urlName: 'urlTabelaFipe',
          urlAddress: createUrlWithLkId(
            config.urlTableFipe,
            SERVICES.FIPE_TABLE_WEBMOTORS
          ),
        },
        {
          id: 'footer0KM',
          name: '0KM',
          urlName: 'url0KM',
          urlAddress: createUrlWithLkId(config.urlKM, SERVICES.ZERO_KM),
        },
        {
          id: 'footerNewsWM1',
          name: 'Notícias WM1',
          urlName: 'urlNoticiasWM1',
          urlAddress: createUrlWithLkId(config.urlNewsWM1, SERVICES.WM1_NEWS),
        },
        {
          id: 'footerAutoPaymentServices',
          name: 'Autopago',
          urlName: 'urlAutoPago',
          urlAddress: createUrlWithLkId(
            config.urlAutoPayment,
            SERVICES.SELF_PAID
          ),
        },
        {
          id: 'footerFinancing',
          name: 'Financiamento',
          urlName: 'urlFinanciamento',
          urlAddress: createUrlWithLkId(config.urlFinancig, SERVICES.FINANCING),
        },
        {
          id: 'footer_services_inspected',
          name: 'Vistoriado',
          urlName: 'urlVistoriado',
          urlAddress: createUrlWithLkId(
            config.urlVistoriado,
            SERVICES.VISTORIADO
          ),
        },
        {
          id: 'footerSafe',
          name: 'Seguro',
          urlName: 'urlSeguro',
          urlAddress: createUrlWithLkId(config.urlAutoCompara, SERVICES.SAFE),
        },
        {
          id: 'footerPlatformResellers',
          name: 'Plataforma Revendedores',
          urlName: 'urlRevendedor',
          urlAddress: createUrlWithLkId(
            config.urlCockPit,
            SERVICES.DEALER_PLATFORM
          ),
        },
        {
          id: 'footerAutoInsights',
          name: 'Autoinsights',
          urlName: 'urlAutoInsights',
          urlAddress: `${config.urlPublicity}?lkid=${SERVICES.AUTO_INSIGHTS}#autoinsights`,
        },
        {
          id: 'footerFazTudoServices',
          name: 'FazTudo',
          urlName: 'urlFazTudo',
          urlAddress: createUrlWithLkId(
            config.urlFazTudo,
            SERVICES.DOES_EVERYTHING
          ),
        },
      ],
    },
    {
      area: 'Ajuda',
      id: 'menuFooterHelp',
      toggleMenuName: 'help_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerContactUs',
          name: 'Para você',
          urlName: 'urlFaleConosco',
          urlAddress: createUrlWithLkId(
            config.urlContatcUs,
            HELP.BUYERS_ADVERTISERS
          ),
        },
        {
          id: 'footerHowToSell',
          name: 'Como vender',
          urlName: 'urlComoVender',
          urlAddress: createUrlWithLkId(config.urlHowToSell, HELP.HOW_TO_SELL),
        },
        {
          id: 'footerHowToBuy',
          name: 'Como comprar',
          urlName: 'urlComoComprar',
          urlAddress: createUrlWithLkId(config.urlHowToBuy, HELP.HOW_TO_BUY),
        },
        {
          id: 'footerLegalPerson',
          name: 'Para a sua loja',
          urlName: 'urlAnuncioPessoaJuridica',
          urlAddress: createUrlWithLkId(
            config.urlLegalPerson,
            HELP.DEALERSHIP_STORES
          ),
        },
        {
          id: 'footerSiteMap',
          name: 'Mapa do site',
          urlName: 'urlMapaSite',
          urlAddress: createUrlWithLkId(config.urlMapSite, HELP.SITE_MAP),
        },
        {
          id: 'footerCustomerProtection',
          name: 'Cód Defesa Consumidor',
          urlName: 'urlDefesaCosumidor',
          urlAddress: createUrlWithLkId(
            config.urlConsumerProtectionCode,
            HELP.CONSUMER_PROTECTION_CODE
          ),
        },
        {
          id: 'gerenciamentoCookies',
          name: 'Gerenciamento de Cookies',
          urlName: 'cookiesManagement',
          urlAddress: createUrlWithLkId(
            config.cookieManagement,
            HELP.COOKIES_MANAGER
          ),
          modalKey: 'cookieControlModal',
        },
        {
          id: 'footerWorkWithUs',
          name: 'Trabalhe com a gente',
          urlName: 'urlWorkWithUs',
          urlAddress: createUrlWithLkId(
            config.urlWorkWithUs,
            HELP.WORK_WITH_US
          ),
        },
        {
          id: 'footerInstitucional',
          name: 'Sobre nós',
          urlName: 'aboutUs',
          urlAddress: createUrlWithLkId(config.urlAboutUs, HELP.ABOUT_US),
        },
        {
          id: 'footerSeguranca',
          name: 'Segurança',
          urlName: 'urlSecurity',
          urlAddress: createUrlWithLkId(config.urlSecurity, HELP.SAFE),
        },
        {
          id: 'footerPoliticaDePrivacidade',
          name: 'Termos de Uso e Política de Privacidade',
          urlName: 'urlPrivacyPolitics',
          urlAddress: createUrlWithLkId(
            config.urlPrivacyPolitics,
            HELP.PRIVACY_POLICY
          ),
        },
        {
          id: 'footerLGPD',
          name: 'LGPD',
          urlName: 'urlLGPD',
          urlAddress: createUrlWithLkId(config.urlLGPD, HELP.LGPD),
        },
        {
          id: 'footerAloEtica',
          name: 'Alô Ética',
          urlName: 'urlHelloEthic',
          urlAddress: config.urlHelloEthic,
        },
        {
          id: 'footerCodigoDeConduta',
          name: 'Código de Conduta',
          urlName: 'codeOfConduct',
          urlAddress: `${config.urlSecurity}assets/files/codigo-de-conduta.pdf`,
        },
      ],
    },
    {
      area: 'Parceiros',
      id: 'menuFooterSites',
      toggleMenuName: 'sites_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerSitesCarsales',
          name: 'Carsales',
          urlName: 'urlSitesCarsales',
          urlAddress: createUrlWithLkId(
            config.urlSiteCarsales,
            PARTNERS.CARSALES
          ),
        },
        {
          id: 'footerSiteLoop',
          name: 'Loop',
          urlName: 'urlSiteLoops',
          urlAddress: createUrlWithLkId(config.urlSiteLoop, PARTNERS.LOOP_CARS),
        },
      ],
    },
    {
      area: 'Buscar por regiões',
      hidden: true,
      id: 'menuFooterRegion',
      toggleMenuName: 'region_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerRegionsSaoPaulo',
          name: 'São Paulo',
          urlName: 'urlRegionsSaoPaulo',
          urlAddress: `${config.urlApp}carros/sp-sao-paulo`,
          bold: true,
        },
        {
          id: 'footerRegionsSaoPauloGol',
          name: 'Gol',
          urlName: 'urlRegionsSaoPauloGol',
          urlAddress: `${config.urlApp}carros/sp-sao-paulo/volkswagen/gol`,
        },
        {
          id: 'footerRegionsSaoPauloPalio',
          name: 'Palio',
          urlName: 'urlRegionsSaoPauloPalio',
          urlAddress: `${config.urlApp}carros/sp-sao-paulo/fiat/palio`,
        },
        {
          id: 'footerRegionsSaoPauloOnix',
          name: 'Onix',
          urlName: 'urlRegionsSaoPauloOnix',
          urlAddress: `${config.urlApp}carros/sp-sao-paulo/chevrolet/onix`,
        },
        {
          id: 'footerRegionsSaoPauloUno',
          name: 'Uno',
          urlName: 'urlRegionsSaoPauloUno',
          urlAddress: `${config.urlApp}carros/sp-sao-paulo/fiat/uno`,
        },
        {
          id: 'footerRegionsSaoPauloHB20',
          name: 'HB20',
          urlName: 'urlRegionsSaoPauloHB20',
          urlAddress: `${config.urlApp}carros/sp-sao-paulo/hyundai/hb20`,
        },
        {
          id: 'footerRegionsPortoAlegre',
          name: 'Porto Alegre',
          urlName: 'urlRegionsPortoAlegre',
          urlAddress: `${config.urlApp}carros/rs-porto-alegre`,
          bold: true,
        },
        {
          id: 'footerRegionsPortoAlegreGol',
          name: 'Gol',
          urlName: 'urlRegionsPortoAlegreGol',
          urlAddress: `${config.urlApp}carros/rs-porto-alegre/volkswagen/gol`,
        },
        {
          id: 'footerRegionsPortoAlegreUno',
          name: 'Uno',
          urlName: 'urlRegionsPortoAlegreUno',
          urlAddress: `${config.urlApp}carros/rs-porto-alegre/fiat/uno`,
        },
        {
          id: 'footerRegionsPortoAlegreGolf',
          name: 'Golf',
          urlName: 'urlRegionsPortoAlegreGolf',
          urlAddress: `${config.urlApp}carros/rs-porto-alegre/volkswagen/golf`,
        },
        {
          id: 'footerRegionsPortoAlegreSandero',
          name: 'Sandero',
          urlName: 'urlRegionsPortoAlegreSandero',
          urlAddress: `${config.urlApp}carros/rs-porto-alegre/renault/sandero`,
        },
        {
          id: 'footerRegionsPortoAlegreFiesta',
          name: 'Fiesta',
          urlName: 'urlRegionsPortoAlegreFiesta',
          urlAddress: `${config.urlApp}carros/rs-porto-alegre/ford/fiesta`,
        },
        {
          id: 'footerRegionsBeloHorizonte',
          name: 'Belo Horizonte',
          urlName: 'urlRegionsBeloHorizonte',
          urlAddress: `${config.urlApp}carros/mg-belo-horizonte`,
          bold: true,
        },
        {
          id: 'footerRegionsBeloHorizonteGol',
          name: 'Gol',
          urlName: 'urlRegionsBeloHorizonteGol',
          urlAddress: `${config.urlApp}carros/mg-belo-horizonte/volkswagen/gol`,
        },
        {
          id: 'footerRegionsBeloHorizonteUno',
          name: 'Uno',
          urlName: 'urlRegionsBeloHorizonteUno',
          urlAddress: `${config.urlApp}carros/mg-belo-horizonte/fiat/uno`,
        },
        {
          id: 'footerRegionsBeloHorizonteCivic',
          name: 'Civic',
          urlName: 'urlRegionsBeloHorizonteCivic',
          urlAddress: `${config.urlApp}carros/mg-belo-horizonte/honda/civic`,
        },
        {
          id: 'footerRegionsBeloHorizonteStrada',
          name: 'Strada',
          urlName: 'urlRegionsBeloHorizonteStrada',
          urlAddress: `${config.urlApp}carros/mg-belo-horizonte/fiat/strada`,
        },
        {
          id: 'footerRegionsBeloHorizonteSandero',
          name: 'Sandero',
          urlName: 'urlRegionsBeloHorizonteSandero',
          urlAddress: `${config.urlApp}carros/mg-belo-horizonte/renault/sandero`,
        },
        {
          id: 'footerRegionsRioDeJaneiro',
          name: 'Rio de Janeiro',
          urlName: 'urlRegionsRioDeJaneiro',
          urlAddress: `${config.urlApp}carros/rj-rio-de-janeiro`,
          bold: true,
        },
        {
          id: 'footerRegionsRioDeJaneiroGol',
          name: 'Gol',
          urlName: 'urlRegionsRioDeJaneiroGol',
          urlAddress: `${config.urlApp}carros/rj-rio-de-janeiro/volkswagen/gol`,
        },
        {
          id: 'footerRegionsRioDeJaneiroPalio',
          name: 'Palio',
          urlName: 'urlRegionsRioDeJaneiroPalio',
          urlAddress: `${config.urlApp}carros/rj-rio-de-janeiro/fiat/palio`,
        },
        {
          id: 'footerRegionsRioDeJaneiroCivic',
          name: 'Civic',
          urlName: 'urlRegionsRioDeJaneiroCivic',
          urlAddress: `${config.urlApp}carros/rj-rio-de-janeiro/honda/civic`,
        },
        {
          id: 'footerRegionsRioDeJaneiroFox',
          name: 'Fox',
          urlName: 'urlRegionsRioDeJaneiroFox',
          urlAddress: `${config.urlApp}carros/rj-rio-de-janeiro/volkswagen/fox`,
        },
        {
          id: 'footerRegionsRioDeJaneiroFiesta',
          name: 'Fiesta',
          urlName: 'urlRegionsRioDeJaneiroFiesta',
          urlAddress: `${config.urlApp}carros/rj-rio-de-janeiro/ford/fiesta`,
        },
      ],
    },
    {
      area: 'Marcas mais populares',
      hidden: true,
      id: 'menuFooterMakes',
      toggleMenuName: 'makes_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerMakesVolkswagen',
          name: 'Volkswagen',
          urlName: 'urlMakesVolkswagen',
          urlAddress: `${config.urlApp}carros/estoque/volkswagen`,
        },
        {
          id: 'footerMakesCitroen',
          name: 'Citroën',
          urlName: 'urlMakesCitroen',
          urlAddress: `${config.urlApp}carros/estoque/citroen`,
        },
        {
          id: 'footerMakesChevrolet',
          name: 'Chevrolet',
          urlName: 'urlMakesChevrolet',
          urlAddress: `${config.urlApp}carros/estoque/chevrolet`,
        },
        {
          id: 'footerMakesKia',
          name: 'Kia',
          urlName: 'urlMakesKia',
          urlAddress: `${config.urlApp}carros/estoque/kia`,
        },
        {
          id: 'footerMakesNissan',
          name: 'Nissan',
          urlName: 'urlMakesNissan',
          urlAddress: `${config.urlApp}carros/estoque/nissan`,
        },
        {
          id: 'footerMakesFiat',
          name: 'Fiat',
          urlName: 'urlMakesFiat',
          urlAddress: `${config.urlApp}carros/estoque/fiat`,
        },
        {
          id: 'footerMakesFord',
          name: 'Ford',
          urlName: 'urlMakesFord',
          urlAddress: `${config.urlApp}carros/estoque/ford`,
        },
        {
          id: 'footerMakesLandRover',
          name: 'Land Rover',
          urlName: 'urlMakesLandRover',
          urlAddress: `${config.urlApp}carros/estoque/land-rover`,
        },
        {
          id: 'footerMakesHyundai',
          name: 'Hyundai',
          urlName: 'urlMakesHyundai',
          urlAddress: `${config.urlApp}carros/estoque/hyundai`,
        },
        {
          id: 'footerMakesBMW',
          name: 'BMW',
          urlName: 'urlMakesBMW',
          urlAddress: `${config.urlApp}carros/estoque/bmw`,
        },
        {
          id: 'footerMakesToyota',
          name: 'Toyota',
          urlName: 'urlMakesToyota',
          urlAddress: `${config.urlApp}carros/estoque/toyota`,
        },
        {
          id: 'footerMakesAudi',
          name: 'Audi',
          urlName: 'urlMakesAudi',
          urlAddress: `${config.urlApp}carros/estoque/audi`,
        },
        {
          id: 'footerMakesHonda',
          name: 'Honda',
          urlName: 'urlMakesHonda',
          urlAddress: `${config.urlApp}carros/estoque/honda`,
        },
        {
          id: 'footerMakesVolvo',
          name: 'Volvo',
          urlName: 'urlMakesVolvo',
          urlAddress: `${config.urlApp}carros/estoque/volvo`,
        },
        {
          id: 'footerMakesSuzuki',
          name: 'Suzuki',
          urlName: 'urlMakesSuzuki',
          urlAddress: `${config.urlApp}carros/estoque/suzuki`,
        },
        {
          id: 'footerMakesMitsubishi',
          name: 'Mitsubishi',
          urlName: 'urlMakesMitsubishi',
          urlAddress: `${config.urlApp}carros/estoque/mitsubishi`,
        },
        {
          id: 'footerMakesJeep',
          name: 'Jeep',
          urlName: 'urlMakesJeep',
          urlAddress: `${config.urlApp}carros/estoque/jeep`,
        },
        {
          id: 'footerMakesRenault',
          name: 'Renault',
          urlName: 'urlMakesRenault',
          urlAddress: `${config.urlApp}carros/estoque/renault`,
        },
        {
          id: 'footerMakesPeugeot',
          name: 'Peugeot',
          urlName: 'urlMakesPeugeot',
          urlAddress: `${config.urlApp}carros/estoque/peugeot`,
        },
        {
          id: 'footerMakesMercedesBenz',
          name: 'Mercedes-Benz',
          urlName: 'urlMakesMercedesBenz',
          urlAddress: `${config.urlApp}carros/estoque/mercedes-benz`,
        },
      ],
    },
    {
      area: 'Modelos mais populares',
      hidden: true,
      id: 'menuFooterModels',
      toggleMenuName: 'models_menu',
      instName: 'footer',
      children: [
        {
          id: 'footerModelsVolkswagenGol',
          name: 'Volkswagen Gol',
          urlName: 'urlModelsVolkswagenGol',
          urlAddress: `${config.urlApp}carros/estoque/volkswagen/gol`,
        },
        {
          id: 'footerModelsVolkswagenFox',
          name: 'Volkswagen Fox',
          urlName: 'urlModelsVolkswagenFox',
          urlAddress: `${config.urlApp}carros/estoque/volkswagen/fox`,
        },
        {
          id: 'footerModelsFordFiesta',
          name: 'Ford Fiesta',
          urlName: 'urlModelsFordFiesta',
          urlAddress: `${config.urlApp}carros/estoque/ford/fiesta`,
        },
        {
          id: 'footerModelsChevroletClassic',
          name: 'Chevrolet Classic',
          urlName: 'urlModelsChevroletClassic',
          urlAddress: `${config.urlApp}carros/estoque/chevrolet/classic`,
        },
        {
          id: 'footerModelsChevroletOnix',
          name: 'Chevrolet Onix',
          urlName: 'urlModelsChevroletOnix',
          urlAddress: `${config.urlApp}carros/estoque/chevrolet/onix`,
        },
        {
          id: 'footerModelsVolkswagenUp',
          name: 'Volkswagen Up',
          urlName: 'urlModelsVolkswagenUp',
          urlAddress: `${config.urlApp}carros/estoque/volkswagen/up`,
        },
        {
          id: 'footerModelsFordKa',
          name: 'Ford Ka',
          urlName: 'urlModelsFordKa',
          urlAddress: `${config.urlApp}carros/estoque/ford/ka`,
        },
        {
          id: 'footerModelsFiatPalio',
          name: 'Fiat Palio',
          urlName: 'urlModelsFiatPalio',
          urlAddress: `${config.urlApp}carros/estoque/fiat/palio`,
        },
        {
          id: 'footerModelsFiatMobi',
          name: 'Fiat Mobi',
          urlName: 'urlModelsFiatMobi',
          urlAddress: `${config.urlApp}carros/estoque/fiat/mobi`,
        },
        {
          id: 'footerModelsChevroletPrisma',
          name: 'Chevrolet Prisma',
          urlName: 'urlModelsChevroletPrisma',
          urlAddress: `${config.urlApp}carros/estoque/chevrolet/prisma`,
        },
        {
          id: 'footerModelsChevroletCelta',
          name: 'Chevrolet Celta',
          urlName: 'urlModelsChevroletCelta',
          urlAddress: `${config.urlApp}carros/estoque/chevrolet/celta`,
        },
        {
          id: 'footerModelsHyundaiHB20',
          name: 'Hyundai HB20',
          urlName: 'urlModelsHyundaiHB20',
          urlAddress: `${config.urlApp}carros/estoque/hyundai/hb20`,
        },
      ],
    },
  ],
}
