import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { adobeAPI } from '@/modern/services/api'
import { onClientSide } from '@/utils/utils'
import { deliveryData } from './deliveryDataPayload'
import { getVisitorData } from './getVisitorData'
import { targetApiConfig } from './targetApiConfig'

const handleCallback = ({ callback, data }) =>
  typeof callback === 'function' && callback(data)

const handleDelivery = ({
  data,
  defaultEnv = '',
  mboxesData,
  sessionId,
  method,
  callback,
}) => {
  const { delivery } = adobeAPI

  delivery({ data, sessionId })
    .then(res => {
      const { execute = {} } = res.data
      let mboxes = execute.mboxes || []

      if (method === 'prefetch') {
        const { prefetch = {} } = res.data
        mboxes = prefetch.mboxes || []
      }

      if (!mboxes.length) {
        handleCallback({ callback })
        return
      }

      const handleKeys = keys => {
        if (typeof keys === 'object') {
          return Object.values(keys)
        }
        if (typeof keys === 'string') {
          return [keys]
        }
        return []
      }

      const handleMboxContent = mbox => {
        const { options = [], name } = mbox
        const { env = defaultEnv, keys } =
          mboxesData.find(item => item.name === name) || {}
        const mboxData = {
          name,
          keys,
          env,
        }
        if (!options.length) return mboxData
        const { content = null, eventToken = '' } = options[0]
        if (!content && !eventToken) {
          return mboxData
        }

        return {
          ...mboxData,
          ...(content && { content }),
          eventToken,
        }
      }

      const abTests = mboxes.map(mbox => handleMboxContent(mbox))

      const removeKeysFromStorage = ({ prefix, abTest }) => {
        const { keys, name } = abTest
        const keysToRemove = handleKeys(keys)
        window.localStorage.removeItem(`${prefix}${name}`)
        keysToRemove.forEach(key => {
          window.localStorage.removeItem(`${prefix}${key}`)
        })
      }

      const saveKeysOnStorage = ({ prefix, abTest }) => {
        const { name, keys: _, ...rest } = abTest
        if (method === 'prefetch') {
          window.localStorage.setItem(
            `${prefix}${name}`,
            JSON.stringify({ ...rest })
          )
        }
        Object.entries(abTest.content || {}).forEach(([key, value]) => {
          window.localStorage.setItem(`${prefix}${key}`, value)
        })
      }

      abTests.forEach(abTest => {
        const { content, env, eventToken } = abTest
        const prefix = `${env}${env ? '_' : ''}`
        if (!content) {
          removeKeysFromStorage({ prefix, abTest })
        } else {
          saveKeysOnStorage({ prefix, abTest })
        }

        if (method === 'prefetch' && eventToken) {
          saveKeysOnStorage({ prefix, abTest })
        }
      })

      handleCallback({ callback, data: abTests })
    })
    .catch(() => {
      handleCallback({ callback })
    })
}

export const useTargetApi = ({
  mboxes = [],
  env,
  callback,
  method = 'execute',
} = {}) => {
  if (!onClientSide() || !mboxes.length) {
    handleCallback({ callback })
    return
  }

  const { adobeOrg, mboxCookies, qaModeData } = targetApiConfig
  const mboxCookie = Cookies.get(mboxCookies) || ''
  const qaData = Cookies.get(qaModeData) || ''
  const parts = mboxCookie.split('|')
  const sessionPart = parts.find(part => part.startsWith('session'))
  const sessionId = sessionPart
    ? sessionPart.split('#')[1]
    : uuidv4().replace(/-/g, '')
  const tntIdPart = parts.find(part => part.startsWith('PC'))
  const tntId = tntIdPart ? tntIdPart.split('#')[1] : ''

  getVisitorData({ adobeOrg })
    .then(res => {
      const { sdid, mcmid } = res

      if (sdid && mcmid) {
        const data = deliveryData({
          mcmid,
          sdid,
          tntId,
          sessionId,
          qaData,
          method,
          defaultEnv: env,
          mboxesData: mboxes,
        })

        if (
          method === 'notifications' &&
          data.notifications &&
          data.notifications.length === 0
        ) {
          handleCallback({ callback })
          return
        }

        handleDelivery({
          data,
          defaultEnv: env,
          mboxesData: mboxes,
          sessionId,
          method,
          callback,
        })
      } else {
        handleCallback({ callback })
      }
    })
    .catch(() => {
      handleCallback({ callback })
    })
}
