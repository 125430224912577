import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.579 6.185C21.244 5.946 20.796 5.938 20.451 6.165L16.233 8.936L12.799 4.396C12.61 4.147 12.315 4 12.001 4C11.688 4 11.393 4.146 11.204 4.396L7.76799 8.936L3.54899 6.164C3.20499 5.938 2.75599 5.946 2.42099 6.185C2.08499 6.423 1.92999 6.843 2.02999 7.243L3.99999 15.124V19C3.99999 19.552 4.44799 20 4.99999 20H19C19.552 20 20 19.552 20 19V15.124L21.97 7.243C22.07 6.843 21.915 6.423 21.579 6.185ZM6.66894 10.608C7.54694 11.185 8.72894 10.979 9.36194 10.143L12.0009 6.65698L14.6379 10.143C15.2709 10.979 16.4529 11.183 17.3319 10.607L19.4089 9.24298L18.2189 14H5.78094L4.59094 9.24198L6.66894 10.608ZM18 18H6V16H18V18Z"
      fill="#2E2D37"
    />
  </svg>
)
