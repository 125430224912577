import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 13H13.618L12.895 11.553C12.725 11.214 12.379 11 12 11H6V4H11.382L12.105 5.447C12.275 5.786 12.621 6 13 6H18V13ZM19 4H13.618L12.895 2.553C12.725 2.214 12.379 2 12 2H5C4.447 2 4 2.448 4 3V21C4 21.552 4.447 22 5 22C5.553 22 6 21.552 6 21V13H11.382L12.105 14.447C12.275 14.786 12.621 15 13 15H19C19.553 15 20 14.552 20 14V5C20 4.448 19.553 4 19 4Z"
      fill="#2E2D37"
    />
  </svg>
)
