import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7072 10.2071L12.7073 15.2071C12.3163 15.5981 11.6842 15.5981 11.2933 15.2071L6.29325 10.2071C5.90225 9.81612 5.90225 9.18412 6.29325 8.79312C6.68425 8.40212 7.31625 8.40212 7.70725 8.79312L12.0002 13.0861L16.2933 8.79312C16.6842 8.40212 17.3162 8.40212 17.7072 8.79312C17.9022 8.98812 18.0002 9.24412 18.0002 9.50012C18.0002 9.75612 17.9022 10.0121 17.7072 10.2071Z"
      fill="#2E2D37"
    />
  </svg>
)
