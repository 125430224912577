import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 15V19H9.001L9 15H5ZM9 21H5C3.897 21 3 20.103 3 19V15C3 13.897 3.897 13 5 13H9C10.103 13 11 13.897 11 15V19C11 20.103 10.103 21 9 21ZM5 5V9H9.001L9 5H5ZM15 5V9H19.001L19 5H15ZM19 11H15C13.897 11 13 10.103 13 9V5C13 3.897 13.897 3 15 3H19C20.103 3 21 3.897 21 5V9C21 10.103 20.103 11 19 11ZM9 11H5C3.897 11 3 10.103 3 9V5C3 3.897 3.897 3 5 3H9C10.103 3 11 3.897 11 5V9C11 10.103 10.103 11 9 11ZM15 15V19H19.001L19 15H15ZM19 21H15C13.897 21 13 20.103 13 19V15C13 13.897 13.897 13 15 13H19C20.103 13 21 13.897 21 15V19C21 20.103 20.103 21 19 21Z"
      fill="#2E2D37"
    />
  </svg>
)
