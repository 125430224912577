import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7072 17.7072L15.7072 12.7073C16.0982 12.3163 16.0982 11.6842 15.7072 11.2933L10.7072 6.29325C10.3162 5.90225 9.68424 5.90225 9.29324 6.29325C8.90224 6.68425 8.90224 7.31625 9.29324 7.70725L13.5862 12.0002L9.29324 16.2933C8.90224 16.6842 8.90224 17.3162 9.29324 17.7072C9.48824 17.9022 9.74424 18.0002 10.0002 18.0002C10.2562 18.0002 10.5122 17.9022 10.7072 17.7072Z"
      fill="#2E2D37"
    />
  </svg>
)
