import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8 15H17.8V12C17.8 11.448 17.352 11 16.8 11C16.248 11 15.8 11.448 15.8 12V15H12.8C12.248 15 11.8 15.448 11.8 16C11.8 16.552 12.248 17 12.8 17H15.8V20C15.8 20.552 16.248 21 16.8 21C17.352 21 17.8 20.552 17.8 20V17H20.8C21.352 17 21.8 16.552 21.8 16C21.8 15.448 21.352 15 20.8 15ZM6.80004 8C6.80004 6.346 8.14604 5 9.80004 5C11.454 5 12.8 6.346 12.8 8C12.8 9.654 11.454 11 9.80004 11C8.14604 11 6.80004 9.654 6.80004 8ZM13.959 13.178C14.199 12.681 13.991 12.083 13.494 11.843C13.378 11.787 13.256 11.746 13.138 11.696C14.151 10.78 14.8 9.47 14.8 8C14.8 5.243 12.557 3 9.80004 3C7.04304 3 4.80004 5.243 4.80004 8C4.80004 9.471 5.45104 10.783 6.46604 11.699C4.57504 12.51 2.99204 13.984 2.09404 15.909C1.86104 16.409 2.07704 17.004 2.57704 17.238C2.71404 17.302 2.85804 17.332 3.00004 17.332C3.37604 17.332 3.73704 17.118 3.90604 16.754C4.97104 14.474 7.28404 13 9.80004 13C10.273 13 10.743 13.051 11.2 13.151C11.691 13.259 12.17 13.424 12.624 13.644C13.118 13.884 13.717 13.676 13.959 13.178Z"
      fill="#2E2D37"
    />
  </svg>
)
