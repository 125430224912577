import styled from 'styled-components'

const height = 22
const width = 22
const animationDuration = 0.2
const delayDuration = animationDuration + 0.05

export const Checkbox = styled.input.attrs(({ checked }) => ({
  type: 'checkbox',
  'tab-index': 0,
  'aria-expanded': checked,
}))`
  opacity: 0;
  display: none;
`

export const Toggle = styled.label`
  cursor: pointer;
  align-items: center;
  background: rgba(174, 174, 186, 0.5);
  border-radius: ${height / 2}px;
  display: flex;
  height: ${height}px;
  margin: 2px 0;
  position: relative;
  transition: ${`all ${animationDuration}s ease-out`};
  width: 52px;

  &::before,
  &::after {
    content: '';
    border-radius: 100%;
    height: ${height + 4}px;
    left: 0;
    position: absolute;
    transition: ${`all ${animationDuration}s ease-out`};
    width: ${height + 4}px;
  }

  &::before {
    background: ${({ theme }) => theme.colors.gray3};
  }

  &::after {
    background: url('/assets/img/check.svg') no-repeat center center;
    opacity: 0;
  }

  input:checked + & {
    background: ${({ theme }) => theme.colors.primary2};

    &::before,
    &::after {
      left: calc(100% - ${width}px);
      transition: ${`all ${animationDuration}s ease-out`};
    }

    &::before {
      background: ${({ theme }) => theme.colors.primary1};
    }

    &::after {
      opacity: 1;
      transition: ${`opacity ${animationDuration}s ease-out ${delayDuration}s `};
    }
  }
`
