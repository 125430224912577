import axios from 'axios'
import Immutable from 'immutee'
import { config } from 'config/config'

import { getApiUrlSantander } from '../utils/utils'

export const FETCH_C2C = 'buyer/proposal/FETCH_C2C'
export const FETCH_C2C_FAILURE = 'buyer/proposal/FETCH_C2C_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_C2C:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_C2C_FAILURE:
      return immutable
        .set('error', action.payload.stack)
        .set('errorID', action.payload.statusCode)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function getC2CEnabled() {
  return dispatch => {
    return new Promise(resolve => {
      window.localStorage.getItem(config.abTest.c2c.key) === 'true'
        ? resolve(dispatch({ type: FETCH_C2C, payload: 'true' }))
        : resolve(dispatch({ type: FETCH_C2C, payload: false }))
    })
  }
}
