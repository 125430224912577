import styled from 'styled-components'

export const Switches = styled.div``

export const Switch = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }
`

export const Info = styled.div`
  padding-left: 20px;
  width: 95%;
`

export const Title = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 5px;
`
