import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18.21 16.26C17.66 16.26 17.21 16.71 17.21 17.26V18.2C17.21 18.63 16.86 18.98 16.43 18.98H5.78C5.35 18.98 5 18.63 5 18.21V7.55002C5 7.12002 5.35 6.77002 5.78 6.77002H6.67C7.22 6.77002 7.67 6.32002 7.67 5.77002C7.67 5.22002 7.22 4.77002 6.67 4.77002H5.78C4.25 4.78002 3 6.02002 3 7.55002V18.21C3 19.74 4.25 20.99 5.78 20.99H16.43C17.96 20.99 19.21 19.74 19.21 18.21V17.27C19.21 16.71 18.76 16.26 18.21 16.26Z"
      fill="#2E2D37"
    />
    <path
      d="M20.91 3.62C20.81 3.38 20.61 3.18 20.37 3.08C20.24 3.03 20.11 3 19.98 3H11.19C10.64 3 10.19 3.45 10.19 4C10.19 4.55 10.64 5 11.19 5H17.57L7.98 14.59C7.59 14.98 7.59 15.61 7.98 16C8.18 16.2 8.43 16.29 8.69 16.29C8.95 16.29 9.2 16.19 9.4 16L18.99 6.41V12.79C18.99 13.34 19.44 13.79 19.99 13.79C20.54 13.79 20.99 13.34 20.99 12.79V4C20.98 3.87 20.96 3.74 20.91 3.62Z"
      fill="#2E2D37"
    />
  </svg>
)
