import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Footer as FooterUI,
  getFooterLinks,
} from '@webmotors/buyer-design-system'
import { config } from 'config/config'
import { connect } from 'react-redux'
import { footer } from 'config/config.footer'
import { useMediaQuery } from '@/modern/hooks/useMediaQuery'
import './Footer.scss'
import useObjDataLayer from '@/modern/hooks/useObjDataLayer'

const Footer = props => {
  const { openFooter, showHelpService, dispatch } = props
  const isMobile = !useMediaQuery('(min-width: 900px)')
  const footerLinks = getFooterLinks({ baseUrl: `${config.urlApp}` })
  const openModal = e => {
    e.preventDefault()
    dispatch({ type: '@modal/OPEN', key: 'cookieControlModal' })
  }

  const [dispatchObjDataLayer, buildObjDataLayer] = useObjDataLayer()

  const handleMenuServicesInsurance = useCallback(() => {
    buildObjDataLayer()
    dispatchObjDataLayer('autoComparaFooter')
  }, [buildObjDataLayer, dispatchObjDataLayer])

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const element = document.getElementById('footer_services_insurance')
      if (element) {
        element.addEventListener('click', handleMenuServicesInsurance)

        return () => {
          element.removeEventListener('click', handleMenuServicesInsurance)
        }
      }
    }
  }, [handleMenuServicesInsurance])

  return (
    <div className="Footer">
      {showHelpService && (
        <a
          className="helpService"
          rel="noopener noreferrer"
          target="_blank"
          id="helpServiceLink"
          title="Link para o contato de ajuda."
          href={footer.urlWMHelp}
        >
          Nossos canais de atendimento são 100% digitais. Precisa de ajuda?{' '}
          <b>Clique aqui</b>
        </a>
      )}
      {openFooter ? (
        <FooterUI
          links={footerLinks}
          isMobile={isMobile}
          callCookies={e => openModal(e)}
        />
      ) : null}
    </div>
  )
}

Footer.defaultProps = {
  openFooter: false,
  showHelpService: false,
  dispatch: () => null,
}

Footer.propTypes = {
  openFooter: PropTypes.bool,
  showHelpService: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default connect()(Footer)
