import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import universalApplyMiddleware from 'redux-universal'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import reducers from '../reducers'

import { onClientSide } from '../utils/utils'

const middlewares = [promise(), thunk]

const isDevEnvironment = process.env.NODE_ENV === 'development'

const createStoreWithMiddleWare = onClientSide()
  ? applyMiddleware(...middlewares)(createStore)
  : universalApplyMiddleware(...middlewares)(createStore)

export default initialState => {
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      isDevEnvironment &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  const store = createStoreWithMiddleWare(
    combineReducers(reducers),
    initialState,
    composeEnhancers()
  )

  if (isDevEnvironment && module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = combineReducers(reducers)
      store.replaceReducer(nextReducer)
    })
  }

  return store
}
