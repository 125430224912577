import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 16C20 16.551 19.551 17 19 17H12.991C12.983 16.881 12.959 16.762 12.906 16.648C12.431 15.632 11.646 14.824 10.704 14.29C11.131 13.734 11.395 13.048 11.395 12.294C11.395 10.478 9.917 9 8.101 9C6.284 9 4.806 10.478 4.806 12.294C4.806 13.048 5.07 13.734 5.497 14.29C4.927 14.613 4.419 15.036 4 15.548V8C4 7.449 4.449 7 5 7H19C19.551 7 20 7.449 20 8V16ZM8.101 15.589C9.184 15.589 10.189 16.13 10.801 17H5.4C6.012 16.13 7.018 15.589 8.101 15.589ZM6.806 12.294C6.806 11.581 7.387 11 8.101 11C8.814 11 9.395 11.581 9.395 12.294C9.395 13.008 8.814 13.589 8.101 13.589C7.387 13.589 6.806 13.008 6.806 12.294ZM19 5H5C3.346 5 2 6.346 2 8V16C2 17.654 3.346 19 5 19H19C20.654 19 22 17.654 22 16V8C22 6.346 20.654 5 19 5ZM18 8H14.125C13.573 8 13.125 8.448 13.125 9C13.125 9.552 13.573 10 14.125 10H18C18.552 10 19 9.552 19 9C19 8.448 18.552 8 18 8ZM18 11H14.125C13.573 11 13.125 11.448 13.125 12C13.125 12.552 13.573 13 14.125 13H18C18.552 13 19 12.552 19 12C19 11.448 18.552 11 18 11ZM16 14H14.125C13.573 14 13.125 14.448 13.125 15C13.125 15.552 13.573 16 14.125 16H16C16.552 16 17 15.552 17 15C17 14.448 16.552 14 16 14Z"
      fill="#2E2D37"
    />
  </svg>
)
