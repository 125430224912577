import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_AUTOCOMPLETEBIKE =
  'buyer/autocomplete/FETCH_AUTOCOMPLETEBIKE'
export const FETCH_AUTOCOMPLETEBIKE_FAILURE =
  'buyer/autocomplete/FETCH_AUTOCOMPLETEBIKE_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_AUTOCOMPLETEBIKE:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_AUTOCOMPLETEBIKE_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchAutoCompleteBike() {
  const url = `${getApiUrl()}filter/bike/autocomplete`
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_AUTOCOMPLETEBIKE,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_AUTOCOMPLETEBIKE_FAILURE,
          payload: err.stack,
        })
      })
  }
}
