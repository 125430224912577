import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9994 15.0868L7.7064 11.7938C7.3154 11.4028 6.6834 11.4028 6.2924 11.7938C5.9014 12.1848 5.9014 12.8168 6.2924 13.2078L11.2914 18.2078C11.3844 18.2998 11.4954 18.3728 11.6174 18.4238C11.8614 18.5248 12.1374 18.5248 12.3814 18.4238C12.5034 18.3728 12.6144 18.2998 12.7074 18.2078L17.7064 13.2078C17.9014 13.0128 17.9994 12.7568 17.9994 12.5008C17.9994 12.2448 17.9014 11.9888 17.7064 11.7938C17.3154 11.4028 16.6834 11.4028 16.2924 11.7938L12.9994 15.0868V6.50076C12.9994 5.94876 12.5514 5.50076 11.9994 5.50076C11.4474 5.50076 10.9994 5.94876 10.9994 6.50076V15.0868Z"
      fill="#2E2D37"
    />
  </svg>
)
