import { useDispatch, useSelector } from 'react-redux'
import useAuth from '@/modern/actions/useAuth'
import useModal from '@/modern/actions/useModal'
import { favoritesAPI } from '@/modern/services/api'
import useObjDataLayer from '@/modern/hooks/useObjDataLayer'
import useTracker from '@/modern/hooks/useTracker'
import { dispatchInsiderTrack } from '@/modern/tracking/insiderTrack'
import { insiderEventModel } from '@/modern/tracking/insiderTrack/utils'

export default function useFavorite() {
  const dispatch = useDispatch()
  const favorites = useSelector(state => state.favorites)
  const favoritesCounter = useSelector(state => state.favoritesCounter)
  const { userIsAuth, logout } = useAuth()
  const { openModal } = useModal('favorites')
  const [dispatchObjDataLayer] = useObjDataLayer()
  const [dispatchEvent, buildEventObject] = useTracker()

  const dispatchAnEvent = (oldAd, eventLabel) =>
    !oldAd
      ? dispatchObjDataLayer(eventLabel)
      : dispatchEvent(eventLabel, buildEventObject(oldAd))

  const addFavoriteItem = (id, type, oldAd, details) => {
    favoritesAPI
      .store(id, type)
      .then(({ data }) => {
        dispatch({
          type: '@userIsAuth/SET',
          userIsAuth: true,
        })
        if (!data.id) return

        dispatch({
          type: '@favorites/ADD_ITEM',
          favorite: data,
        })

        dispatch({
          type: '@favoritesCounter/ADD_ITEM',
        })

        dispatchAnEvent(oldAd, 'favoriteOn')

        if (!details) return
        dispatchInsiderTrack({
          properties: insiderEventModel({
            details,
            eventName: 'selecaoFavoritos_comprar',
          }),
        })
      })
      .catch(() => {
        dispatch({
          type: '@userIsAuth/SET',
          userIsAuth: false,
        })
        logout()
      })
  }

  const removeFavoriteItem = (id, oldAd) => {
    const idToNumber = Number(id)

    const favorite = favorites.find(v => v.id === idToNumber)

    favoritesAPI
      .destroy(favorite.excludeFavoriteKey)
      .then(() => {
        dispatch({
          type: '@userIsAuth/SET',
          userIsAuth: true,
        })

        dispatchAnEvent(oldAd, 'favoriteOff')

        dispatch({
          type: '@favorites/DELETE_ITEM',
          excludeFavoriteKey: favorite.excludeFavoriteKey,
        })

        dispatch({
          type: '@favoritesCounter/REMOVE_ITEM',
        })
      })
      .catch(() => {
        dispatch({
          type: '@userIsAuth/SET',
          userIsAuth: false,
        })
        logout()
      })
  }

  const setFavoriteItem = ({
    id,
    type,
    wasFavorited,
    oldAd = null,
    details,
  }) => {
    if (userIsAuth) {
      const action = wasFavorited
        ? () => removeFavoriteItem(id, oldAd)
        : () => addFavoriteItem(id, type, oldAd, details)
      action()
    } else {
      localStorage.setItem('favoritedCard', id)
      localStorage.setItem('favoriteType', type)
      dispatchAnEvent(oldAd, 'aberturaModalFavorito')
      openModal()
    }
  }

  const setFavoriteList = () => {
    favoritesAPI
      .all()
      .then(([cars, bikes]) => {
        const favoritesList = [
          ...cars.data.results_new,
          ...bikes.data.results_new,
        ]

        dispatch({
          type: '@userIsAuth/SET',
          userIsAuth: true,
        })

        dispatch({
          type: '@favorites/SET_LIST',
          list: favoritesList,
        })

        dispatch({
          type: '@favoritesCounter/SET_LIST',
          count: cars.data.count + bikes.data.count,
        })
      })
      .catch(() => {
        dispatch({
          type: '@userIsAuth/SET',
          userIsAuth: false,
        })
        logout()
      })
  }

  const checkFavoritesInLocalStorage = () => {
    const cardId = localStorage.getItem('favoritedCard')
    const type = localStorage.getItem('favoriteType')
    if (userIsAuth && cardId && type) {
      addFavoriteItem(cardId, type)
      localStorage.removeItem('favoritedCard')
      localStorage.removeItem('favoriteType')
    }
  }

  const checkIfCardWasFavorited = id => {
    const idToNumber = Number(id)

    if (userIsAuth) {
      const cardWasFavorited = favorites.filter(
        favorite => favorite.id === idToNumber
      )
      return cardWasFavorited.length > 0
    }

    return false
  }

  return {
    setFavoriteList,
    setFavoriteItem,
    favorites,
    favoritesCounter,
    checkIfCardWasFavorited,
    checkFavoritesInLocalStorage,
  }
}
