import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.01 19V17C14.76 17 17 14.75 17 12C17 9.24 14.76 7 12 7V5C15.86 5 19 8.14 19 12C19 15.86 15.86 19 12.01 19Z"
      fill="black"
    />
    <path
      d="M11.01 6.98999L7.41 9.97999H5V14H7.41L11.01 16.99V6.98999Z"
      fill="black"
    />
    <path
      d="M12 9.01001V15C13.65 15 14.99 13.66 14.99 12.01C14.99 10.35 13.65 9.01001 12 9.01001Z"
      fill="black"
    />
  </svg>
)
