import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2595 15.7403L7.2925 13.7733C6.9025 13.3833 6.9025 12.7502 7.2925 12.3593C7.6835 11.9693 8.3165 11.9693 8.7065 12.3593L9.9665 13.6192L15.2925 8.29325C15.6835 7.90225 16.3165 7.90225 16.7065 8.29325C17.0975 8.68325 17.0975 9.31625 16.7065 9.70725L10.6735 15.7403C10.4785 15.9353 10.2225 16.0332 9.9665 16.0332C9.7105 16.0332 9.4545 15.9353 9.2595 15.7403Z"
      fill="#2E2D37"
    />
  </svg>
)
