import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3955 15.2012C7.9545 15.5332 7.8665 16.1602 8.1985 16.6012C8.5315 17.0432 9.1575 17.1332 9.5985 16.7992C10.9915 15.7502 13.0115 15.7492 14.4055 16.8002C14.5855 16.9352 14.7965 17.0012 15.0065 17.0012C15.3105 17.0012 15.6095 16.8642 15.8065 16.6022C16.1385 16.1612 16.0505 15.5342 15.6085 15.2022C13.5165 13.6262 10.4885 13.6262 8.3955 15.2012ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM10 10.5C10 9.671 9.328 9 8.5 9C7.672 9 7 9.671 7 10.5C7 11.329 7.672 12 8.5 12C9.328 12 10 11.329 10 10.5ZM15.5 9C14.672 9 14 9.671 14 10.5C14 11.329 14.672 12 15.5 12C16.328 12 17 11.329 17 10.5C17 9.671 16.328 9 15.5 9Z"
      fill="#2E2D37"
    />
  </svg>
)
