import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9942 10.947C4.543 10.947 4.9884 11.395 4.9884 11.947C4.9884 15.836 8.13405 19 12.0005 19C15.8659 19 19.0116 15.836 19.0116 11.947C19.0116 8.058 15.8659 4.894 12.0005 4.894C10.9496 4.894 9.92461 5.134 8.99304 5.579H10.2209C10.7697 5.579 11.2151 6.027 11.2151 6.579C11.2151 7.131 10.7697 7.579 10.2209 7.579H6.66263C6.11384 7.579 5.66843 7.131 5.66843 6.579V3C5.66843 2.447 6.11384 2 6.66263 2C7.21143 2 7.65684 2.447 7.65684 3V4.028C8.97813 3.294 10.4664 2.894 12.0005 2.894C16.9626 2.894 21 6.955 21 11.947C21 16.939 16.9626 21 12.0005 21C7.03745 21 3 16.939 3 11.947C3 11.395 3.4454 10.947 3.9942 10.947Z"
      fill="#2E2D37"
    />
  </svg>
)
