import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M19.0823 4.91771C15.192 1.02743 8.80798 1.02743 4.91771 4.91771C1.02743 8.80798 1.02743 15.192 4.91771 19.0823C6.91272 20.9776 9.40648 21.9751 12 21.9751C14.5935 21.9751 17.0873 20.9776 19.0823 19.0823C22.9726 15.192 22.9726 8.80798 19.0823 4.91771ZM6.31421 6.31421C7.91022 4.81796 9.90524 4.01995 12 4.01995C13.6958 4.01995 15.4913 4.61845 16.8878 5.71571L5.71571 16.8878C3.22195 13.7955 3.5212 9.20698 6.31421 6.31421ZM6.31421 17.586C6.41397 17.6858 6.31421 17.6858 6.31421 17.586V17.586ZM17.6858 17.6858C14.793 20.5786 10.3042 20.7781 7.11222 18.384L18.2843 7.11222C20.6783 10.2045 20.4788 14.793 17.6858 17.6858Z"
      fill="#2E2D37"
    />
  </svg>
)
