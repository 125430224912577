import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00062 9.17581V9.63881C4.96262 12.6158 7.02062 18.8158 11.9336 19.9488C16.8466 18.8158 18.9046 12.6158 18.8666 9.65181V9.17581C17.3366 8.79581 14.0426 7.76581 11.9336 5.55981C9.82462 7.76581 6.53162 8.79581 5.00062 9.17581ZM11.9336 21.9718C11.8676 21.9718 11.8026 21.9658 11.7376 21.9528C5.46962 20.6988 2.95462 13.3058 3.00062 9.62581V8.37181C3.00062 7.88781 3.34862 7.47281 3.82562 7.38781C3.87862 7.37781 9.10362 6.41481 11.1016 3.41781C11.4726 2.86081 12.3946 2.86081 12.7656 3.41781C14.7716 6.42681 19.9876 7.37781 20.0406 7.38681C20.5186 7.47181 20.8666 7.88681 20.8666 8.37181V9.63881C20.9136 13.3058 18.3976 20.6988 12.1296 21.9528C12.0646 21.9658 11.9996 21.9718 11.9336 21.9718Z"
      fill="#2E2D37"
    />
  </svg>
)
