const initialState = {videoChamadaDate : {day: {}, formattedTime: {}} }

export default (state = initialState, { type, key, value = '' }) => {
  let newState

  switch (type) {
    case '@leadInfo/SET':
        newState = state
        newState[key] = value
        break
    case '@leadInfo/RESET':
        newState = {videoChamadaDate : {day: {}, formattedTime: {}} }
        break
    default:
        newState = state
        break
  }

  return newState
}
