import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.99997V7.99997C11 7.44797 10.553 6.99997 10 6.99997C9.447 6.99997 9 7.44797 9 7.99997V8.99997H8C7.447 8.99997 7 9.44797 7 9.99997C7 10.552 7.447 11 8 11H9V12C9 12.552 9.447 13 10 13C10.553 13 11 12.552 11 12V11H12C12.553 11 13 10.552 13 9.99997C13 9.44797 12.553 8.99997 12 8.99997H11ZM5 10.0576C5 7.26857 7.269 4.99957 10.058 4.99957C12.846 4.99957 15.115 7.26857 15.115 10.0576C15.115 12.8466 12.846 15.1146 10.058 15.1146C7.269 15.1146 5 12.8466 5 10.0576ZM20.207 18.7926L15.697 14.2826C16.583 13.1026 17.115 11.6426 17.115 10.0576C17.115 6.16557 13.949 2.99957 10.058 2.99957C6.166 2.99957 3 6.16557 3 10.0576C3 13.9486 6.166 17.1146 10.058 17.1146C11.643 17.1146 13.103 16.5826 14.283 15.6966L18.793 20.2066C18.988 20.4026 19.244 20.4996 19.5 20.4996C19.756 20.4996 20.012 20.4026 20.207 20.2066C20.598 19.8166 20.598 19.1836 20.207 18.7926Z"
      fill="#2E2D37"
    />
  </svg>
)
