import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M8.97998 3.00007C9.52998 3.00007 9.97998 3.45007 9.97998 4.00007L9.97998 7.90007C9.97998 9.03007 9.05998 9.95007 7.92998 9.95007L3.99998 9.95007C3.44998 9.95007 2.99998 9.50007 2.99998 8.95007C2.99998 8.40007 3.44998 7.95007 3.99998 7.95007L7.92998 7.95007L7.97998 4.00007C7.97998 3.44007 8.42998 3.00007 8.97998 3.00007Z"
      fill="#2E2D37"
    />
    <path
      d="M14.9699 2.99995C15.5199 2.99995 15.9699 3.44995 15.9699 3.99995L15.9699 7.89995L20.0599 7.94995C20.6099 7.94995 21.0599 8.39995 21.0599 8.94995C21.0599 9.49995 20.6099 9.94995 20.0599 9.94995L16.0199 9.94995C14.8899 9.94995 13.9699 9.02995 13.9699 7.89995L13.9699 3.98995C13.9699 3.43995 14.4199 2.99995 14.9699 2.99995Z"
      fill="#2E2D37"
    />
    <path
      d="M16.03 13.9799L19.98 13.9799C20.53 13.9799 20.98 14.4299 20.98 14.9799C20.98 15.5299 20.53 15.9799 19.98 15.9799L16.03 15.9799L15.98 19.9299C15.98 20.4799 15.53 20.9299 14.98 20.9299C14.43 20.9299 13.98 20.4799 13.98 19.9299L13.98 16.0199C13.99 14.8999 14.9 13.9799 16.03 13.9799Z"
      fill="#2E2D37"
    />
    <path
      d="M3.99997 13.9499L7.91997 13.9499C9.04997 13.9499 9.96997 14.8699 9.96997 15.9999L9.96997 19.9299C9.96997 20.4799 9.51997 20.9299 8.96997 20.9299C8.41997 20.9299 7.96997 20.4799 7.96997 19.9299L7.96997 15.9999L3.99997 15.9499C3.44997 15.9499 2.99997 15.4999 2.99997 14.9499C2.99997 14.3999 3.44997 13.9499 3.99997 13.9499Z"
      fill="#2E2D37"
    />
  </svg>
)
