import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_DEALERINFO = 'buyer/detail/FETCH_DEALERINFO'
export const FETCH_DEALERINFO_FAILURE = 'buyer/detail/FETCH_DEALERINFO_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_DEALERINFO:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('fetched', true)
        .done()

    case FETCH_DEALERINFO_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('requested', action.requested)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchDealerInfo(id) {
  const url = `${getApiUrl()}dealer/info/${id}`
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_DEALERINFO,
          payload: res.data,
          requested: { Id: id },
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_DEALERINFO_FAILURE,
          payload: err.stack,
          requested: { Id: id },
        })
      })
  }
}
