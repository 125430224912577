import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M4 9.95C3.45 9.95 3 9.5 3 8.95V5.05C3 3.92 3.92 3 5.05 3H8.98C9.53 3 9.98 3.45 9.98 4C9.98 4.55 9.53 5 8.98 5H5.05L5 8.95C5 9.51 4.55 9.95 4 9.95Z"
      fill="#2E2D37"
    />
    <path
      d="M20.06 9.95C19.51 9.95 19.06 9.5 19.06 8.95V5.05L14.97 5C14.42 5 13.97 4.55 13.97 4C13.97 3.45 14.42 3 14.97 3H19.01C20.14 3 21.06 3.92 21.06 5.05V8.96C21.06 9.51 20.61 9.95 20.06 9.95Z"
      fill="#2E2D37"
    />
    <path
      d="M18.93 20.93H14.98C14.43 20.93 13.98 20.48 13.98 19.93C13.98 19.38 14.43 18.93 14.98 18.93H18.93L18.98 14.98C18.98 14.43 19.43 13.98 19.98 13.98C20.53 13.98 20.98 14.43 20.98 14.98V18.89C20.97 20.01 20.06 20.93 18.93 20.93Z"
      fill="#2E2D37"
    />
    <path
      d="M8.97 20.93H5.05C3.92 20.93 3 20.01 3 18.88V14.95C3 14.4 3.45 13.95 4 13.95C4.55 13.95 5 14.4 5 14.95V18.88L8.97 18.93C9.52 18.93 9.97 19.38 9.97 19.93C9.97 20.48 9.52 20.93 8.97 20.93Z"
      fill="#2E2D37"
    />
  </svg>
)
