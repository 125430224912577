import React from 'react'
import { window } from 'window-or-global'

import useLocalStorage from './useLocalStorage'

const useReducerLocalStorage = (
  reducer,
  initialState,
  key,
  saveOnUpdate = true
) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, () => {
    let value

    try {
      value = JSON.parse(
        window.localStorage.getItem(key) || JSON.stringify(initialState)
      )
    } catch (e) {
      value = initialState
    }
    return value
  })

  const [localData, setLocalData] = useLocalStorage(key, initialState)
  const [persist, setPersist] = React.useState(false)
  const [reset, setReset] = React.useState(false)

  const setPersistData = React.useCallback(() => {
    setPersist(true)
  }, [])

  const resetToLastSavedState = React.useCallback(() => {
    setReset(true)
  }, [])

  React.useEffect(() => {
    if (saveOnUpdate) {
      setPersist(true)
    }
  }, [saveOnUpdate, state])

  React.useEffect(() => {
    if (persist) {
      setLocalData(state)
      setPersist(false)
    }
  }, [persist, state, setLocalData])

  React.useEffect(() => {
    if (reset) {
      dispatch({ type: 'reset-persisted', cookies: localData })
      setReset(false)
    }
  }, [reset, localData])

  return [state, dispatch, setPersistData, resetToLastSavedState]
}

export default useReducerLocalStorage
