import React, { Component } from 'react';
import { createUrlIdc, setEventToLazyDispatch, cleanString } from 'utils/utils'
import { ORIGIN_PAGES, COOKIES } from 'utils/constants'

import { withCookieNotice } from '@/store/context/cookie-notice-context'
import { cookieCategoryIsActive } from '@/modern/utils/cookies'

import CarouselContainer from 'components/CarouselContainer';
import CardGradient from 'components/CarouselContainer/CardGradient';
import Slider from "react-slick";

class HomeCategories extends Component {
  settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1930,
				settings: {
					slidesToShow: 6,
				}
			},
			{
				breakpoint: 1680,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 1368,
				settings: {
					slidesToShow: 4,
				}
			}
		]

  };

	renderCards() {
    const { cookies } = this.props.cookieNoticeContext
    const isCookieCategoryActive = cookieCategoryIsActive(
      cookies,
      COOKIES.TRACKING_FROM
    )

		if (this.props.source && this.props.source.length) {
			let _listSource = this.props.source.slice(0, 10)
			var _renderMap = _listSource.map((item, index) =>
				<div key={index} className='slick-padding'>
					<CardGradient
						textColor={item.textColor ? item.textColor : ''}
						bgImg={item.CardDesktop ? item.CardDesktop : ''}
						title={item.Nome ? item.Nome : ''}
						linkTo={item.Url ? this.getUrlCard(item, index + 1) : undefined}
						key={'homeCat' + item.Nome + index}
						id={item.Categoria ? item.Categoria : ''}
						className={this.props.className ? this.props.className : ''}
						onClick={() => {
              if ( isCookieCategoryActive ) {
                setEventToLazyDispatch('buscaRealizada')
              }
            }}
					/>
				</div>
			)
			return (_renderMap.length ? _renderMap : false)
		}
		return false
	}
	getUrlCard(item, pos) {
		let _origin = this.props.origin ? ORIGIN_PAGES[this.props.origin] : undefined
		let _pcd = (item.Nome == 'Carros para PCD')
		let _nome = cleanString( item.Nome, { toLowerCase : 1, removeAccents : 1, removeSpecial : 1, slugify : 1 } )
		let _url = cleanString( item.Url, { toLowerCase : 1, removeAccents : 1, removeSpecial : 1, slugify : 1 } )
		let urlCategory = ('/' + _url + (_pcd ? '/carros-novos/' : '/carros/' ) + this.props.locationLeft + '?' + (this.props.locationRight ? 'estadocidade=' + this.props.locationRight + '&' : '') + 'necessidade=' + encodeURIComponent(item.NomeCategoria) + ( _pcd ? '&tipoveiculo=carros-novos' : '' ) )
		return createUrlIdc(urlCategory, _origin && _origin.URL, `busque-por-categorias`, `${_nome}:comprar`) + (
			this.props.lastSearch
				&& this.props.lastSearch.localizacao
				&& this.props.lastSearch.localizacao.latitude
				&& this.props.lastSearch.localizacao.longitude ?
				('&localizacao=' + this.props.lastSearch.localizacao.latitude + ',' + this.props.lastSearch.localizacao.longitude + 'x100km')
				:
				''
		)
	}
	render() {
		return (
			<CarouselContainer id={this.props.id ? this.props.id : ''} title={this.props.title ? this.props.title : ''} className={this.props.className ? this.props.className : ''}>
				<Slider {...this.settings}>
					{this.renderCards()}
				</Slider>
			</CarouselContainer>
		)
	}
}

export default withCookieNotice(HomeCategories)
