import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0002 6C8.9562 6 9.7372 6.729 9.8372 7.66C9.0112 8.127 8.3452 8.838 7.9572 9.71C6.9532 9.687 6.1432 8.867 6.1432 7.857C6.1432 6.833 6.9762 6 8.0002 6ZM14.4292 11.5C14.4292 12.839 13.3392 13.929 12.0002 13.929C10.6612 13.929 9.5712 12.839 9.5712 11.5C9.5712 10.161 10.6612 9.071 12.0002 9.071C13.3392 9.071 14.4292 10.161 14.4292 11.5ZM16.0002 6C17.0242 6 17.8572 6.833 17.8572 7.857C17.8572 8.867 17.0472 9.687 16.0432 9.71C15.6552 8.838 14.9892 8.127 14.1632 7.66C14.2632 6.729 15.0442 6 16.0002 6ZM21.9232 13.898C21.2822 12.361 20.1742 11.161 18.8392 10.446C19.4652 9.76 19.8572 8.856 19.8572 7.857C19.8572 5.73 18.1272 4 16.0002 4C14.1352 4 12.5762 5.331 12.2202 7.094C12.1462 7.09 12.0752 7.071 12.0002 7.071C11.9252 7.071 11.8542 7.09 11.7802 7.094C11.4242 5.331 9.8652 4 8.0002 4C5.8732 4 4.1432 5.73 4.1432 7.857C4.1432 8.856 4.5352 9.76 5.1612 10.446C3.8262 11.162 2.7182 12.362 2.0772 13.901C1.8652 14.411 2.1062 14.997 2.6162 15.209C2.7412 15.261 2.8722 15.286 3.0002 15.286C3.3912 15.286 3.7632 15.054 3.9232 14.67C4.6122 13.016 6.0262 11.914 7.5972 11.748C7.6612 12.887 8.1432 13.913 8.9092 14.663C7.2472 15.481 5.8632 16.941 5.0772 18.83C4.8652 19.34 5.1062 19.925 5.6162 20.138C5.7412 20.19 5.8722 20.215 6.0002 20.215C6.3922 20.215 6.7632 19.983 6.9232 19.599C7.8512 17.369 9.8442 15.929 12.0002 15.929C14.1562 15.929 16.1492 17.368 17.0772 19.596C17.2902 20.105 17.8742 20.349 18.3852 20.134C18.8952 19.921 19.1362 19.336 18.9232 18.826C18.1362 16.938 16.7532 15.48 15.0912 14.662C15.8572 13.913 16.3392 12.886 16.4032 11.748C17.9742 11.913 19.3882 13.015 20.0772 14.668C20.2372 15.052 20.6092 15.284 21.0002 15.284C21.1292 15.284 21.2592 15.259 21.3852 15.206C21.8952 14.994 22.1362 14.408 21.9232 13.898Z"
      fill="#2E2D37"
    />
  </svg>
)
