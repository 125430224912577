import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0981 11.8032L14.0881 11.7952C13.6311 11.4642 13.0461 11.2252 12.2341 11.0432L12.0591 11.0082C11.6181 10.9032 11.2901 10.7692 11.0851 10.6102C10.8671 10.4422 10.8491 10.3272 10.8491 10.2162C10.8491 9.9782 11.0211 9.8002 11.1181 9.7202C11.3251 9.5502 11.6211 9.4422 11.8861 9.4252L12.0521 9.4242C12.5151 9.4432 12.8981 9.7012 13.4161 10.0822C13.6161 10.2172 13.8651 10.2592 14.0981 10.2012L14.4521 10.0812L14.6111 9.8172C14.8501 9.4192 14.7421 8.9062 14.3921 8.6692C14.0201 8.3892 13.5041 8.0032 12.8331 7.8102V7.2692C12.8331 6.3092 12.0111 6.2982 11.9761 6.2982L11.5981 6.3192L11.3771 6.5442C11.1271 6.7982 11.1331 7.1072 11.1361 7.2692V7.8102C9.9721 8.1352 9.1541 9.0792 9.1431 10.2162C9.1431 10.9192 9.4691 11.5502 10.0601 11.9942C10.4831 12.3122 11.0421 12.5502 11.7351 12.7042L11.9211 12.7412C12.4601 12.8612 12.8461 13.0112 13.0741 13.1882C13.3061 13.3662 13.3531 13.5112 13.3531 13.6722C13.3531 13.9442 13.1741 14.1492 13.0261 14.2722C12.7751 14.4802 12.4351 14.5982 12.0831 14.6042L11.9421 14.6062C11.2041 14.5522 10.5471 14.0002 10.3571 13.8272C10.1871 13.6752 9.9731 13.6062 9.7411 13.6132L9.3131 13.6702L9.1061 13.9602C8.8471 14.3202 8.9031 14.8332 9.2251 15.1202C9.5201 15.3912 10.2181 15.9582 11.1361 16.2132V16.7602C11.1331 16.8912 11.1271 17.2002 11.3771 17.4542L11.6601 17.7012H11.9761C12.0111 17.7012 12.8331 17.6902 12.8331 16.7292V16.2442C14.1171 15.9392 15.0481 14.9132 15.0581 13.6722C15.0581 12.9332 14.7171 12.2692 14.0981 11.8032ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
      fill="#2E2D37"
    />
  </svg>
)
