import React, { Component } from 'react'
import { config } from 'config/config'
import { Link } from 'react-router-dom'
import {
  cleanString,
  createUrlIdc,
  getPathNameByEnvironment,
} from 'utils/utils'
import './CarouselContainer.scss'

export default class CardModelo extends Component {
  getUrlCard(_make, _model) {
    const _urlCard = `/carros/${
      this.props.locationLeft
    }/${_make}/${_model}?estadocidade=${
      this.props.locationRight
    }&marca1=${encodeURIComponent(
      this.props.make
    )}&modelo1=${encodeURIComponent(this.props.model)}`

    const _localizacao =
      this.props.lastSearch &&
      this.props.lastSearch.localizacao &&
      this.props.lastSearch.localizacao.latitude &&
      this.props.lastSearch.localizacao.longitude
        ? `&localizacao=${this.props.lastSearch.localizacao.latitude},${this.props.lastSearch.localizacao.longitude}x100km`
        : ''

    return createUrlIdc(
      _urlCard,
      'carros-mais-buscados',
      `posicao-${this.props.order}`,
      `${this.props.make ? this.props.make : ''}-${
        this.props.model ? this.props.model : ''
      }${_localizacao}`
    )
  }

  render() {
    if (this.props.make && this.props.model) {
      const _make = cleanString(this.props.make, {
        toLowerCase: 1,
        removeAccents: 1,
        removeSpecial: 1,
        slugify: 1,
      })
      const _model = cleanString(this.props.model, {
        toLowerCase: 1,
        removeAccents: 1,
        removeSpecial: 1,
        slugify: 1,
      })
      const _ulrBase = getPathNameByEnvironment()
      return (
        <Link
          to={this.getUrlCard(_make, _model)}
          id={`CardMostWanted${this.props.order}`}
          className="CardModelo"
          onClick={this.props.onClick ? this.props.onClick.bind(this) : 0}
        >
          <div
            className="CardModelo__bg"
            style={{
              backgroundImage: `url(${_ulrBase}img/bestseller/${_make}-${_model}.jpg?t=${config.randomUrl}`,
            }}
          />
          <div className="CardModelo__info">
            <h3 className="CardModelo__make">
              {this.props.make}{' '}
              <strong className="CardModelo__model">{this.props.model}</strong>
            </h3>

            {this.props.year ? (
              <div className="CardModelo__year">{this.props.year}</div>
            ) : (
              ''
            )}
          </div>
        </Link>
      )
    }
    return false
  }
}
