import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 6.99995C11.448 6.99995 11 7.44795 11 7.99995V12C11 12.1299 11.026 12.2599 11.077 12.382C11.128 12.504 11.201 12.615 11.293 12.708L14.2928 15.7069C14.4878 15.9019 14.7438 16 14.9998 16C15.2558 16 15.5118 15.9019 15.7068 15.7069C16.0977 15.316 16.0977 14.684 15.7068 14.2929L12.9999 11.5859V7.99995C12.9999 7.44795 12.5519 6.99995 11.9999 6.99995ZM17.652 17.6523C14.533 20.7712 9.45699 20.7712 6.33899 17.6523C3.21899 14.5332 3.21899 9.45825 6.33899 6.33925C9.45699 3.21925 14.533 3.21925 17.652 6.33925C20.771 9.45825 20.771 14.5332 17.652 17.6523ZM19.067 4.92425C15.167 1.02525 8.82299 1.02525 4.92399 4.92425C1.02599 8.82325 1.02599 15.1672 4.92399 19.0672C8.82299 22.9653 15.167 22.9653 19.067 19.0672C22.965 15.1672 22.965 8.82325 19.067 4.92425Z"
      fill="#2E2D37"
    />
  </svg>
)
