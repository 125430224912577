import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M10.0001 5V7.04C10.0001 7.56 10.3901 8 10.9101 8.03C19.9401 8.68 19.0001 19 19.0001 19C19.0001 19 17.2201 11.87 11.1301 11.07C10.5301 10.99 10.0001 11.47 10.0001 12.07V14L5.00006 9.38L10.0001 5ZM10.0001 3C9.52006 3 9.05006 3.17 8.68006 3.49L3.68006 7.87C3.26006 8.24 3.01006 8.78 3.00006 9.35C2.99006 9.92 3.23006 10.46 3.64006 10.85L8.64006 15.47C9.02006 15.82 9.51006 16 10.0001 16C10.2701 16 10.5401 15.95 10.8001 15.83C11.5301 15.51 12.0001 14.79 12.0001 14V13.32C15.7601 14.6 17.0001 19.26 17.0601 19.49C17.2901 20.38 18.0901 21 19.0001 21C19.0501 21 19.1101 21 19.1601 20.99C20.1301 20.91 20.9001 20.15 20.9901 19.18C21.0101 18.93 21.5001 13.04 17.9801 9.19C16.4501 7.51 14.4401 6.49 12.0001 6.14V5C12.0001 4.22 11.5401 3.5 10.8301 3.18C10.5601 3.06 10.2801 3 10.0001 3Z"
      fill="#2E2D37"
    />
  </svg>
)
