import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.61824 6H16.3822L18.3822 10H5.61824L7.61824 6ZM5.50024 16H5.00024V15H7.00024C7.55224 15 8.00024 14.552 8.00024 14C8.00024 13.448 7.55224 13 7.00024 13H5.00024V12H19.0002V13H17.0002C16.4482 13 16.0002 13.448 16.0002 14C16.0002 14.552 16.4482 15 17.0002 15H19.0002V16H18.5002H5.50024ZM21.0002 11C21.0002 10.986 20.9932 10.975 20.9922 10.961C20.9882 10.857 20.9672 10.755 20.9312 10.656C20.9222 10.634 20.9172 10.612 20.9082 10.59C20.9022 10.578 20.9002 10.565 20.8942 10.553L17.8942 4.553C17.7252 4.214 17.3792 4 17.0002 4H7.00024C6.62124 4 6.27524 4.214 6.10524 4.553L3.10524 10.553C3.09924 10.565 3.09724 10.578 3.09224 10.59C3.08224 10.612 3.07724 10.634 3.06924 10.656C3.03324 10.755 3.01224 10.857 3.00824 10.961C3.00724 10.975 3.00024 10.986 3.00024 11V17C3.00024 17.064 3.02524 17.12 3.03624 17.18C3.01424 17.284 3.00024 17.39 3.00024 17.5V18.5C3.00024 19.325 3.67524 20 4.50024 20H5.50024C6.32524 20 7.00024 19.325 7.00024 18.5V18H17.0002V18.5C17.0002 19.325 17.6752 20 18.5002 20H19.5002C20.3252 20 21.0002 19.325 21.0002 18.5V17.5C21.0002 17.39 20.9862 17.284 20.9642 17.18C20.9752 17.12 21.0002 17.064 21.0002 17V11Z"
      fill="#2E2D37"
    />
  </svg>
)
