import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00024 21.0002C8.25624 21.0002 8.51224 20.9023 8.70724 20.7073L16.7072 12.7072C17.0982 12.3162 17.0982 11.6842 16.7072 11.2933L8.70724 3.29325C8.31624 2.90225 7.68424 2.90225 7.29324 3.29325C6.90224 3.68425 6.90224 4.31625 7.29324 4.70725L14.5862 12.0002L7.29324 19.2932C6.90224 19.6842 6.90224 20.3162 7.29324 20.7073C7.48824 20.9023 7.74424 21.0002 8.00024 21.0002Z"
      fill="#2E2D37"
    />
  </svg>
)
