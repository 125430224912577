import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM10 10.5C10 9.671 9.328 9 8.5 9C7.672 9 7 9.671 7 10.5C7 11.329 7.672 12 8.5 12C9.328 12 10 11.329 10 10.5ZM15.5 9C14.672 9 14 9.671 14 10.5C14 11.329 14.672 12 15.5 12C16.328 12 17 11.329 17 10.5C17 9.671 16.328 9 15.5 9ZM15 15H9C8.448 15 8 15.448 8 16C8 16.552 8.448 17 9 17H15C15.552 17 16 16.552 16 16C16 15.448 15.552 15 15 15Z"
      fill="#2E2D37"
    />
  </svg>
)
