import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.707 7.70725L11 5.41425V13.5002C11 14.0522 11.448 14.5002 12 14.5002C12.552 14.5002 13 14.0522 13 13.5002V5.41425L15.293 7.70725C15.488 7.90225 15.744 8.00025 16 8.00025C16.256 8.00025 16.512 7.90225 16.707 7.70725C17.098 7.31625 17.098 6.68425 16.707 6.29325L12.707 2.29325C12.316 1.90225 11.684 1.90225 11.293 2.29325L7.293 6.29325C6.902 6.68425 6.902 7.31625 7.293 7.70725C7.684 8.09825 8.316 8.09825 8.707 7.70725ZM18 10.5002V19.0266C18 19.5776 17.551 20.0266 17 20.0266H7C6.449 20.0266 6 19.5776 6 19.0266V10.5002C6 9.94825 5.552 9.50025 5 9.50025C4.448 9.50025 4 9.94825 4 10.5002V19.0266C4 20.6806 5.346 22.0266 7 22.0266H17C18.654 22.0266 20 20.6806 20 19.0266V10.5002C20 9.94825 19.552 9.50025 19 9.50025C18.448 9.50025 18 9.94825 18 10.5002Z"
      fill="#2E2D37"
    />
  </svg>
)
