import React, { Component } from 'react';
import './StatusHeader.scss';

class StatusHeader extends Component {
	render() {
		return (
			<div className="StatusHeader">
				<div className="container StatusHeader__header">
					<h1 className="StatusHeader__header__title">{this.props.title}</h1>
					<p className="StatusHeader__header__subtitle">{this.props.subtitle}</p>
				</div>
				<div className="StatusHeader__separator" />
				<div className=" container StatusHeader__content">
					<p className="StatusHeader__content__text">{this.props.contentText}</p>
				</div>
			</div>
		)
	}
}

export default StatusHeader;