import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_REPORT_REASONS = 'buyer/report/FETCH_REPORT_REASONS'
export const FETCH_REPORT_REASONS_FAILURE =
  'buyer/report/FETCH_REPORT_REASONS_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_REPORT_REASONS:
      return immutable.set('result', action.payload).set('fetched', true).done()

    case FETCH_REPORT_REASONS_FAILURE:
      return immutable.set('result', []).set('fetched', false).done()

    default:
      return state
  }
}

export function fetchReasons() {
  const url = `${getApiUrl()}detail/report`
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_REPORT_REASONS,
          payload: res.data.reason,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_REPORT_REASONS_FAILURE,
          payload: err.stack,
        })
      })
  }
}
