const vars = {
    redwebmotors: "#f3123c",
    redsantander: "#EA0002",
    white: "#fff",
    black: "#000",
    background: "#f3f5f8",

    colorFA93A6: "#FA93A6",
    colorf76882: "#f76882",
    colorf53d57: "#f53d57",
    colorED1A3E: "#ED1A3E",
    colorc70f32: "#c70f32",
    color850a21: "#850a21",
    color590716: "#590716",
    color541387: "#541387",
    color4B127A: "#4B127A",
    color3A0D5F: "#3A0D5F",
    color250A3A: "#250A3A",
    color220736: "#220736",
    colorFFCE33: "#FFCE33",
    colorFFE490: "#FFE490",
    color00D6A6: "#00D6A6",
    color069676: "#069676",
    color72E8CE: "#72E8CE",
    colorFF6D00: "#FF6D00",
    colorFFB073: "#FFB073",
    color8826BB: "#8826BB",
    colorC89CE0: "#C89CE0",
    color41BCCE: "#41BCCE",
    color98DBE4: "#98DBE4",
    color33cc00: "#33cc00",

    colorGrey1: "#ECEDF2",
    colorGrey2: "#DFE0E7",
    colorGrey3: "#D3D4DC",
    colorGrey4: "#C5C6D0",
    colorGrey5: "#AEAEBA",
    colorGrey6: "#8B8C99",
    colorGrey7: "#686976",
    colorGrey8: "#4C4C59",
    colorGrey9: "#2E2E37",

    borderGrey: "#D3D4DC",
    grayTextColor: "#686976",

    radius: 4,
    standardMargin: "20px 0 32px 0",
    whiteBoxShadow: "0 2px 9px 0 rgba(158, 184, 209, 0.41)",
    recaptureSearchShadow: "0 -2px 9px 0 rgba(158,184,209,0.43)",
    filterSubmitShadow: "0 0px 25px 0 rgba(158, 184, 209, 0.4)",
    textShadow: "0px 0px 9px rgba(0,0,0,1)",
    sliderDotShadow: "0px 0px 4px 0px rgba(0,0,0,0.6)",
    wm1CardShadow: `inset 0px -60px 70px -55px #ECEDF2`,

    gradient1: "linear-gradient(270deg, #FAD961 0%, #F76B1C 100%)",
    gradient2: "linear-gradient(90deg, #3023AE 0%, #C86DD7 100%)",
    gradient3: "linear-gradient(90deg, #3023AE 0%, #53A0FD 47.52%, #B4EC51 100%)",
}

export default vars
