import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 15C6.449 15 6 15.449 6 16V19C6 19.551 6.449 20 7 20H17C17.552 20 18 19.551 18 19V16C18 15.449 17.552 15 17 15H7ZM17 22H7C5.346 22 4 20.654 4 19V16C4 14.346 5.346 13 7 13H17C18.654 13 20 14.346 20 16V19C20 20.654 18.654 22 17 22ZM7 4C6.449 4 6 4.449 6 5V8C6 8.551 6.449 9 7 9H17C17.552 9 18 8.551 18 8V5C18 4.449 17.552 4 17 4H7ZM17 11H7C5.346 11 4 9.654 4 8V5C4 3.346 5.346 2 7 2H17C18.654 2 20 3.346 20 5V8C20 9.654 18.654 11 17 11Z"
      fill="#2E2D37"
    />
  </svg>
)
