import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_OPTIN_RECAPTURE = 'buyer/optin/FETCH_OPTIN_RECAPTURE'
export const FETCH_OPTIN_RECAPTURE_FAILURE =
  'buyer/optin/FETCH_OPTIN_RECAPTURE_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_OPTIN_RECAPTURE:
      return immutable.set('fetched', true).done()

    case FETCH_OPTIN_RECAPTURE_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchOptinRecapture(data) {
  const url = `${getApiUrl()}optin/recapture`
  const pandora = hasPandora()
  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: { pandora },
  }

  return dispatch => {
    return axios
      .post(url, data, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_OPTIN_RECAPTURE,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_OPTIN_RECAPTURE_FAILURE,
          payload: err.stack,
        })
      })
  }
}
