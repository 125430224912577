export default (state = [], { type, favorite, excludeFavoriteKey, list = [] }) => {
    let newState

    switch (type) {
    case '@favorites/ADD_ITEM':
            newState = [...state, favorite]
        break
    case '@favorites/DELETE_ITEM':
            newState = state.filter(v => v.excludeFavoriteKey !== excludeFavoriteKey)
        break
    case '@favorites/SET_LIST':
        newState = list
        break
    case '@favorites/CLEAR_LIST':
        newState = []
        break
    default:
        newState = state
        break
    }

    return newState
}
