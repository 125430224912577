import React from 'react'

import './CarouselContainer.scss'

const renderTitle = title => <h2 className="container">{title}</h2>

export default function CarouselContainer({ className, title, id, children }) {
  return (
    (children && (
      <div className={`CarouselContainer ${className}`}>
        {title && renderTitle(title)}
        <div id={id} className="CarouselContainer__scroll">
          <div className="CarouselContainer__wrap">{children}</div>
        </div>
      </div>
    )) ||
    false
  )
}
