import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 14.5C16 15.891 15.178 17.085 14 17.649V14.5C14 13.675 13.325 13 12.5 13H11.5C10.675 13 10 13.675 10 14.5V17.649C8.822 17.085 8 15.891 8 14.5V11.5C8 10.473 8.455 9.521 9.216 8.862C9.937 9.564 10.917 10 12 10C13.083 10 14.063 9.564 14.784 8.863C15.545 9.521 16 10.473 16 11.5V14.5ZM12 4C13.103 4 14 4.897 14 6C14 7.103 13.103 8 12 8C10.897 8 10 7.103 10 6C10 4.897 10.897 4 12 4ZM19 5H15.857C15.411 3.28 13.858 2 12 2C10.142 2 8.589 3.28 8.143 5H5C4.447 5 4 5.448 4 6C4 6.552 4.447 7 5 7H8.143C8.153 7.043 8.169 7.085 8.182 7.128C6.821 8.161 6 9.763 6 11.5V14.5C6 17.012 7.695 19.131 10 19.787V20.5C10 21.325 10.675 22 11.5 22H12.5C13.325 22 14 21.325 14 20.5V19.787C16.305 19.131 18 17.012 18 14.5V11.5C18 9.763 17.179 8.161 15.818 7.128C15.831 7.085 15.847 7.043 15.857 7H19C19.553 7 20 6.552 20 6C20 5.448 19.553 5 19 5Z"
      fill="#2E2D37"
    />
  </svg>
)
