import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1211 9.707L11.1211 15.707C10.9261 15.902 10.6701 16 10.4141 16C10.1581 16 9.90212 15.902 9.70712 15.707L6.87912 12.878C6.48812 12.487 6.48812 11.854 6.87912 11.464C7.26912 11.073 7.90212 11.073 8.29312 11.464L10.4141 13.586L15.7071 8.293C16.0981 7.902 16.7301 7.902 17.1211 8.293C17.5121 8.684 17.5121 9.316 17.1211 9.707ZM18.0001 3H6.00012C4.34612 3 3.00012 4.346 3.00012 6V18C3.00012 19.654 4.34612 21 6.00012 21H18.0001C19.6541 21 21.0001 19.654 21.0001 18V6C21.0001 4.346 19.6541 3 18.0001 3Z"
      fill="#2E2D37"
    />
  </svg>
)
