import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.19512 7.42808C6.40622 7.42808 7.39023 6.43649 7.39023 5.21026C7.39023 3.98402 6.41379 3 5.19512 3C3.98402 3 3 3.99159 3 5.21782C3 6.44406 3.98402 7.42808 5.19512 7.42808ZM7.07234 9.00251H3.35578V21H7.07234V9.00251ZM15.27 12.0075C13.7864 12.0075 13.0068 13.0143 13.0068 14.7022V14.7029C13.0068 16.5503 13.0068 21 13.0143 21H9.44915V9.0025H12.9992V10.6148C12.9992 10.6148 14.074 8.6316 16.6173 8.6316C19.1607 8.6316 20.9849 10.1833 20.9849 13.4003V21H17.291V14.7022C17.291 12.9689 16.6325 12.0075 15.27 12.0075Z"
      fill="#2E2D37"
    />
  </svg>
)
