import React from 'react'
import { Provider } from 'react-redux'
import Router from '@/modern/components/Router'
import { ThemeProvider } from 'styled-components'
import { CookieNoticeProvider } from '../store/context/cookie-notice-context'
import configureStore from './store'
import theme from '../styles/theme'

const initialState = window.INITIAL_STATE
const store = configureStore(initialState)

export default () => {
  return (
    <CookieNoticeProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </CookieNoticeProvider>
  )
}
