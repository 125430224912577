import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 8H5C4.448 8 4 7.552 4 7C4 6.448 4.448 6 5 6H19C19.552 6 20 6.448 20 7C20 7.552 19.552 8 19 8ZM19 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13H19C19.552 13 20 12.552 20 12C20 11.448 19.552 11 19 11ZM19 16H5C4.448 16 4 16.448 4 17C4 17.552 4.448 18 5 18H19C19.552 18 20 17.552 20 17C20 16.448 19.552 16 19 16Z"
      fill="#2E2D37"
    />
  </svg>
)
