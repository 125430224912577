import axios from 'axios'
import Immutable from 'immutee'

import { getApiUrl, hasPandora } from '../utils/utils'

export const FETCH_DEALERSCHEDULE = 'buyer/detail/FETCH_DEALERSCHEDULE'
export const FETCH_DEALERSCHEDULE_FAILURE =
  'buyer/detail/FETCH_DEALERSCHEDULE_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_DEALERSCHEDULE:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('fetched', true)
        .done()

    case FETCH_DEALERSCHEDULE_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('requested', action.requested)
        .set('result', [])
        .set('fetched', false)
        .done()

    default:
      return state
  }
}

export function fetchDealerSchedule(id) {
  const url = `${getApiUrl()}dealer/schedule/${id}`
  const pandora = hasPandora()
  const requestConfig = {
    params: { pandora },
  }

  return dispatch => {
    return axios
      .get(url, requestConfig)
      .then(res => {
        dispatch({
          type: FETCH_DEALERSCHEDULE,
          payload: res.data,
          requested: { id },
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_DEALERSCHEDULE_FAILURE,
          payload: err.stack,
          requested: { id },
        })
      })
  }
}
