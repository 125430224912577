import axios from 'axios';
import Immutable from 'immutee';

import { getApiUrlSantander } from '../utils/utils';

export const FETCH_SIMULATE_VEHICLE = 'buyer/proposal/FETCH_SIMULATE_VEHICLE';
export const FETCH_SIMULATE_VEHICLE_FAILURE = 'buyer/proposal/FETCH_SIMULATE_VEHICLE_FAILURE';

const initialState = {
    fetched: false,
    result: [],
    error: null
};

export default function reducer(state = initialState, action) {
    const immutable = Immutable(state);
    switch (action.type) {
        case FETCH_SIMULATE_VEHICLE:
            return immutable
                .set('result', action.payload)
                .set('fetched', true)
                .done();

        case FETCH_SIMULATE_VEHICLE_FAILURE:
            return immutable
                .set('error', action.payload.stack)
                .set('errorID', action.payload.statusCode)
                .set('result', action.payload.result)
                .set('fetched', false)
                .done();

        default:
            return state;
    }
}

export function fetchVehicleFinancing(data) {
		let payload = {
			marca: data.Marca,
			modelo: data.Modelo,
			ano: data.Ano,
			vehicleType: data.VehicleType,
			versao: data.Versao,
            documentNumber: data.documentNumber,
		};

    const params = new URLSearchParams(payload);

    const _url = `${getApiUrlSantander()}financiamento/buscar-veiculo?${params}`
    return (dispatch) => {
        return axios.get(_url).
            then((res) => {
                dispatch({
                    type: FETCH_SIMULATE_VEHICLE,
                    payload: res.data.Result
                });
            }).catch((err) => {
                dispatch({
                    type: FETCH_SIMULATE_VEHICLE_FAILURE,
                    payload: {
                      stack: err && err.response && err.response.data && err.response.data.Messages && err.response.data.Messages.length > 0 ? err.response.data.Messages[0] : err.stack ? err : err.Error ? !err : undefined,
                      statusCode: err && err.response && err.response.data ? err.response.data.StatusCode : undefined,
                      result: err && err.response && err.response.data ? err.response.data.Result : undefined
                    }
                })
            });
    }
}
