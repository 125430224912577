import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0472 11.7843H13.7717L13.7777 12.4177C13.7777 15.8177 11.4174 18.3095 8.15476 18.3095C4.7547 18.3095 2 15.5548 2 12.1548C2 8.7547 4.7547 6 8.15476 6C9.83985 6 11.3636 6.67523 12.475 7.76277L11.2082 8.85629C10.4075 8.10935 9.33193 7.65521 8.15476 7.65521C5.66895 7.65521 3.66119 9.66895 3.66119 12.1488C3.66119 14.6286 5.67493 16.6424 8.15476 16.6424C10.049 16.6424 11.4592 15.4174 11.9133 13.4216H8.05317L8.0472 11.7843ZM19.6337 11.856H22V13.3618H19.6337V15.7281H18.1279V13.3618H15.7676V11.856H18.1279V9.48967H19.6337V11.856Z"
      fill="#2E2D37"
    />
  </svg>
)
